import React from 'react'

export const Expand = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.4575 1.5H13.5V0H24V10.5H22.5V2.58L14.5575 10.5L13.5 9.4575L21.4575 1.5ZM2.5875 22.5H10.5V24H0V13.5H1.5V21.4575L9.4575 13.5L10.5 14.5575L2.5875 22.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
