import React from 'react'

export const CaloriesBurned = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.053 9.367C14.48 7.367 13.403 4.161 15.653 0.28C14.951 0.097 14.214 0 13.455 0C8.645 0 4.746 3.899 4.746 8.709C4.746 8.918 4.753 9.125 4.768 9.33C3.4 8.438 1.753 7.92 0 7.92L0.053 8C0.498 8.683 0.757 9.499 0.757 10.375C0.757 10.826 0.688 11.26 0.561 11.669C0.3 12.496 0.16 13.377 0.16 14.291C0.16 19.101 4.059 23 8.869 23C13.679 23 17.578 19.101 17.578 14.291C17.578 12.463 17.083 10.875 16.053 9.367ZM8.867 21C6.365 21 4.404 18.979 4.404 16.399C4.404 15.46 4.692 14.562 5.236 13.8L5.604 13.285L6.227 13.398C6.853 13.511 7.438 13.753 7.95 14.103C8.163 13.484 8.273 12.83 8.273 12.167C8.273 11.502 8.164 10.85 7.95 10.228L7.084 7.717L9.391 9.033C11.866 10.445 13.403 13.09 13.403 15.937C13.404 19.053 11.67 20.993 8.867 21ZM8.896 9.902C9.141 10.612 9.274 11.374 9.274 12.167C9.274 13.493 8.902 14.732 8.258 15.786C7.743 15.064 6.958 14.546 6.05 14.382C5.643 14.951 5.404 15.647 5.404 16.399C5.404 18.314 6.815 20 8.867 20H8.876C10.974 19.995 12.404 18.615 12.404 15.937C12.404 13.352 10.991 11.097 8.896 9.902Z"
        fill="currentColor"
      />
    </svg>
  )
}
