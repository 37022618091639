import React from 'react'

export const Collapse = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 9V10.5H13.5V0H15V9H16.0425H24ZM0 15V13.5H10.5V24H9V16.035V15H0Z"
        fill="currentColor"
      />
    </svg>
  )
}
