import React from 'react'

export const MoreHorizontal = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 0.5C9.172 0.5 8.5 1.172 8.5 2C8.5 2.828 9.172 3.5 10 3.5C10.828 3.5 11.5 2.828 11.5 2C11.5 1.172 10.828 0.5 10 0.5ZM18 0.5C17.172 0.5 16.5 1.172 16.5 2C16.5 2.828 17.172 3.5 18 3.5C18.828 3.5 19.5 2.828 19.5 2C19.5 1.172 18.828 0.5 18 0.5ZM2 0.5C1.172 0.5 0.5 1.172 0.5 2C0.5 2.828 1.172 3.5 2 3.5C2.828 3.5 3.5 2.828 3.5 2C3.5 1.172 2.828 0.5 2 0.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
