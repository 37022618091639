import React from 'react'

export const MoreVertical = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2002 19.4734C10.1943 19.8893 10.3122 20.2976 10.5389 20.6463C10.7657 20.995 11.091 21.2684 11.4735 21.4317C11.8561 21.595 12.2786 21.6408 12.6872 21.5633C13.0959 21.4858 13.4723 21.2885 13.7685 20.9965C14.0647 20.7045 14.2674 20.331 14.3508 19.9235C14.4341 19.516 14.3944 19.0929 14.2366 18.7081C14.0788 18.3232 13.8102 17.994 13.4648 17.7622C13.1193 17.5305 12.7128 17.4068 12.2969 17.4067C11.7455 17.4051 11.2158 17.6214 10.8231 18.0084C10.4304 18.3955 10.2065 18.9221 10.2002 19.4734ZM10.2002 12.2849C10.199 12.6998 10.321 13.1058 10.5507 13.4514C10.7803 13.797 11.1074 14.0666 11.4904 14.2262C11.8734 14.3858 12.2951 14.4282 12.7022 14.348C13.1093 14.2678 13.4835 14.0686 13.7773 13.7756C14.0711 13.4826 14.2714 13.109 14.3528 12.7021C14.4342 12.2953 14.393 11.8734 14.2345 11.4899C14.076 11.1065 13.8072 10.7787 13.4623 10.548C13.1174 10.3174 12.7118 10.1943 12.2969 10.1943C12.0216 10.1927 11.7487 10.2456 11.494 10.3501C11.2393 10.4545 11.0078 10.6083 10.8129 10.8027C10.618 10.9971 10.4635 11.2281 10.3583 11.4825C10.2532 11.7369 10.1994 12.0096 10.2002 12.2849ZM10.2002 5.09642C10.2009 5.36877 10.2561 5.63823 10.3625 5.88895C10.4688 6.13967 10.6243 6.36658 10.8196 6.55635C11.015 6.74612 11.2463 6.89491 11.5 6.99397C11.7537 7.09303 12.0246 7.14035 12.2969 7.13316C12.7056 7.1272 13.1033 7.00018 13.4398 6.76817C13.7763 6.53616 14.0365 6.20958 14.1874 5.82973C14.3383 5.44987 14.3732 5.0338 14.2876 4.63412C14.2021 4.23444 13.9999 3.8691 13.7067 3.5843C13.4136 3.2995 13.0425 3.10803 12.6405 3.03409C12.2385 2.96016 11.8237 3.00709 11.4483 3.16894C11.073 3.33079 10.7541 3.6003 10.5319 3.94339C10.3098 4.28647 10.1943 4.68773 10.2002 5.09642Z"
        fill="currentColor"
      />
    </svg>
  )
}
