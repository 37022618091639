import {
  cssVar,
  mode,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'

const $size = cssVar('spinner-size')

const baseStyle: SystemStyleObject = {
  borderLeftColor: 'currentColor',
  width: [$size.reference],
  height: [$size.reference],
}

const variantPrimary: SystemStyleFunction = (props) => {
  const spinnerColor = mode('light.brand.primary', 'dark.brand.primary')(props)
  return {
    color: spinnerColor,
  }
}

const variantSecondary: SystemStyleFunction = (props) => {
  const spinnerColor = mode(
    'light.brand.secondary',
    'dark.brand.secondary'
  )(props)
  return {
    color: spinnerColor,
  }
}

const variantTertiary: SystemStyleFunction = (props) => {
  const spinnerColor = mode(
    'light.text.textInverted',
    'dark.text.textInverted'
  )(props)
  return {
    color: spinnerColor,
  }
}

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
}

const sizes: Record<string, SystemStyleObject> = {
  xs: {
    [$size.variable]: '0.75rem',
  },
  sm: {
    [$size.variable]: '1rem',
  },
  md: {
    [$size.variable]: '1.5rem',
  },
  lg: {
    [$size.variable]: '2rem',
  },
  xl: {
    [$size.variable]: '3rem',
  },
}

const defaultProps = {
  size: 'md',
}

export const Spinner = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
}
