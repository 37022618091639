import React from 'react'

export const Food = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.733 5.70725L20.7513 1.70725C21.1441 1.31725 21.1441 0.68325 20.7513 0.29325C20.3585 -0.09775 19.7236 -0.09775 19.3308 0.29325L15.3126 4.29325L13.8409 2.82925C12.2718 1.26725 9.72721 1.26725 8.15809 2.82925L2.96349 8.00025H19.0365L16.733 5.70725ZM20.9894 9.00025H1.01062C0.415916 9.00025 -0.0542193 9.51425 0.00504998 10.1032C0.560573 15.6602 5.27097 20.0002 11 20.0002C16.729 20.0002 21.4394 15.6602 21.9949 10.1032C22.0542 9.51425 21.5851 9.00025 20.9894 9.00025Z"
        fill="currentColor"
      />
    </svg>
  )
}
