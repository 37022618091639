import React from 'react'

export const Refresh = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.707 8.29303C23.316 7.90203 22.683 7.90203 22.293 8.29303L19.967 10.619C19.98 10.413 20 10.209 20 10C20 4.38303 15.369 -0.15197 9.71696 0.00402968C4.49896 0.14803 0.148956 4.49803 0.00395629 9.71603C-0.153044 15.369 4.38296 20 9.99996 20C12.854 20 15.423 18.799 17.244 16.881C17.62 16.484 17.617 15.859 17.23 15.472L17.228 15.47C16.827 15.069 16.182 15.09 15.791 15.501C14.004 17.385 11.319 18.405 8.42296 17.847C5.29496 17.244 2.76896 14.732 2.15696 11.606C1.15696 6.49503 5.06396 2.00003 9.99996 2.00003C14.59 2.00003 18.29 5.88603 17.982 10.541C17.969 10.546 17.975 10.544 17.962 10.549L15.707 8.29403C15.316 7.90303 14.683 7.90303 14.293 8.29403C13.902 8.68503 13.902 9.31703 14.293 9.70803L18.293 13.708C18.683 14.099 19.317 14.099 19.707 13.708L23.707 9.70803C24.098 9.31603 24.098 8.68403 23.707 8.29303Z"
        fill="currentColor"
      />
    </svg>
  )
}
