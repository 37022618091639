import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export const RAGOnTarget = (props: React.SVGProps<SVGSVGElement>) => {
  const textColor = useColorModeValue('#FFFFFF', '#121212')
  const bgColor = useColorModeValue('#008A20', '#10B035')

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle fill={bgColor} cx="12" cy="12.0002" r="12" />
      <mask
        id="mask0_3374_8142"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <circle cx="12" cy="12" r="12" fill={bgColor} />
      </mask>
      <g mask="url(#mask0_3374_8142)">
        <path
          opacity="0.15"
          d="M39.769 27.8246V3.72612C39.769 2.18312 38.8816 0.777709 37.4884 0.114482L19.769 -8.3208L-4.23096 32.1055L14.261 40.9085C15.3514 41.4276 16.618 41.4262 17.7073 40.9048L37.4961 31.4325C38.8851 30.7676 39.769 29.3645 39.769 27.8246Z"
          fill="#32383D"
        />
      </g>
      <circle stroke={textColor} cx="12" cy="12.0002" r="10" />
      <path
        d="M16 9.25024L10.5 14.7502L8 12.2502"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
