import React from 'react'

export const Reward = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.2905 4C18.7743 2.748 19.1626 1.409 19.4383 0H4.56305C4.8388 1.409 5.22703 2.747 5.70983 4H18.2905ZM8.18555 10.438C6.86457 8.715 5.74865 6.801 4.90251 4.711C4.27536 3.161 3.83736 1.584 3.54071 0L1.0829 2.469C0.548337 3.006 0.366167 3.799 0.600102 4.521C0.784263 5.089 0.986342 5.655 1.2143 6.218C2.32027 8.95 3.81148 11.403 5.58042 13.567C6.12295 12.282 7.03778 11.196 8.18555 10.438ZM22.9175 2.469L20.4597 0C20.162 1.584 19.724 3.161 19.0969 4.711C18.2507 6.802 17.1348 8.715 15.8138 10.438C16.9616 11.196 17.8774 12.282 18.4189 13.566C20.1889 11.402 21.6801 8.949 22.7851 6.217C23.013 5.655 23.2151 5.088 23.3993 4.52C23.6342 3.799 23.452 3.006 22.9175 2.469ZM12.0002 10.289C8.7012 10.289 6.02738 12.975 6.02738 16.289C6.02738 19.603 8.7012 22.289 12.0002 22.289C15.2992 22.289 17.973 19.603 17.973 16.289C17.973 12.975 15.2992 10.289 12.0002 10.289Z"
        fill="currentColor"
      />
    </svg>
  )
}
