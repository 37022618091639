import React from 'react'

export const Notes = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.3289 8.65034L14.9986 0.66066H14.9883V0.640015H0.639893V23.36H23.3496V8.65034H23.3289ZM21.5741 8.65034H14.9986V2.35356L21.5741 8.65034ZM22.1315 22.142H1.86828V1.8684H13.7805V9.8684H22.1315V22.142Z"
        fill="currentColor"
      />
      <path
        d="M10.5599 18.8594H3.71606V20.0774H10.5599V18.8594Z"
        fill="currentColor"
      />
      <path
        d="M19.778 13.0065H3.71606V14.2245H19.778V13.0065Z"
        fill="currentColor"
      />
      <path
        d="M19.778 15.9381H3.71606V17.1562H19.778V15.9381Z"
        fill="currentColor"
      />
    </svg>
  )
}
