import React from 'react'

export const ShareIos = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3569 15.6826V1.86435L18.0652 7.80558L18.5686 7.28168L11.9963 0.448708L5.43119 7.28168L5.93456 7.80558L11.6429 1.86435V15.6826H12.3569Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9963 0L19 7.28156L18.0653 8.25442L12.6619 2.63056V16H11.338V2.63056L5.9346 8.25442L5 7.28168L11.9963 0ZM11.9965 0.897424L5.86248 7.28168L5.9346 7.35675L11.9479 1.09814V15.3653H12.052V1.09814L18.0653 7.35675L18.1373 7.28181L11.9965 0.897424Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.8368H1.41176V22.6043H22.5882V11.8368H24V24H0V11.8368Z"
        fill="currentColor"
      />
    </svg>
  )
}
