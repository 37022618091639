import { formErrorAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleText: SystemStyleFunction = (props) => {
  return {
    color: mode('light.status.error', 'dark.status.error')(props),
    ...props.theme.textStyles['caption'],
  }
}

const baseStyleIcon: SystemStyleFunction = (props) => {
  return {
    marginEnd: '0.5em',
    color: mode('light.status.error', 'dark.status.error')(props),
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  text: baseStyleText(props),
  icon: baseStyleIcon(props),
})

export const FormError = {
  parts: parts.keys,
  baseStyle,
}
