import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export const OffTarget = (props: React.SVGProps<SVGSVGElement>) => {
  const pathColor = useColorModeValue('#D13400', '#FA3E00')
  const bgColor = useColorModeValue('#FCECF4', '#221A18')
  return (
    <svg
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill={bgColor} y="4" width={16} height={16} rx={8} />
      <path
        stroke={pathColor}
        d="M8 8V13"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke={pathColor}
        d="M8 16H8.01"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
