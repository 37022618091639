import React from 'react'

export const Delete = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27869)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1983 2.40272V1.20287C16.2099 1.04232 16.1868 0.881156 16.1306 0.730317C16.0744 0.579478 15.9864 0.442498 15.8726 0.328678C15.7588 0.214858 15.6218 0.126864 15.471 0.0706714C15.3201 0.0144789 15.159 -0.00859603 14.9984 0.00301329H8.99916C8.83861 -0.00859603 8.67745 0.0144789 8.52661 0.0706714C8.37577 0.126864 8.23879 0.214858 8.12497 0.328678C8.01115 0.442498 7.92316 0.579478 7.86696 0.730317C7.81077 0.881156 7.7877 1.04232 7.7993 1.20287V2.40272H2.39996V3.60258H3.29985L4.61369 24.0001H19.3779L20.6977 3.60258H21.5976V2.40272H16.1983ZM8.99916 1.80279C8.99916 1.38884 9.18514 1.20287 9.59909 1.20287H14.3985C14.8184 1.20287 14.9984 1.38285 14.9984 1.80279V2.40272H8.99916V1.80279ZM18.178 22.8002H5.81955L4.49971 3.60258H19.4979L18.178 22.8002ZM8.93917 21.0005L8.33924 6.60221H7.19938L7.7993 21.0005H8.93917ZM11.3989 21.0005H12.5987V6.60221H11.3989V21.0005ZM16.7982 6.60221H15.6343L15.0344 21.0005H16.1983L16.7982 6.60221Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27869">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
