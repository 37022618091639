import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export const AlertError = () => {
  const pathColor = useColorModeValue('#D13400', '#FA3E00')
  const bgColor = useColorModeValue('#FFE4E0', '#221A18')

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
    >
      <rect y="2" width="16" height="16" rx="8" fill={bgColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6C9 5.44772 8.55228 5 8 5C7.44772 5 7 5.44772 7 6V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V6ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H8.01C8.56229 15 9.01 14.5523 9.01 14C9.01 13.4477 8.56229 13 8.01 13H8Z"
        fill={pathColor}
      />
    </svg>
  )
}
