import { selectAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

import { Input } from './input'

const baseStyleIcon: SystemStyleFunction = (props) => ({
  width: '1em',
  insetEnd: 4,
  position: 'relative',
  color: mode('light.functional.graphic', 'dark.functional.graphic')(props),
  _disabled: {
    opacity: 0.5,
  },
})

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  field: Input.baseStyle(props).field,
  icon: baseStyleIcon(props),
})

export const Select = {
  parts: parts.keys,
  baseStyle,
  variants: Input.variants,
  defaultProps: Input.defaultProps,
}
