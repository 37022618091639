import React from 'react'

export const Chevron = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5479 12.4348L18.9914 12L18.5523 11.5652L11.6001 4.61304L12.2131 4L20.2088 11.9957L20.2001 12L20.2088 12.0043L12.2131 20L11.6001 19.3826L18.5479 12.4348Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
    </svg>
  )
}
