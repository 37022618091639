import React from 'react'

export const Temperature = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9224 16.26H12.9224V1H9.92236V16.26H8.92236V0H13.9224V16.26Z"
        fill="currentColor"
      />
      <path
        d="M11.4224 23.98C10.4985 23.9641 9.60189 23.6641 8.85441 23.1209C8.10692 22.5776 7.5448 21.8174 7.24441 20.9436C6.94402 20.0697 6.91993 19.1246 7.17541 18.2365C7.4309 17.3485 7.95356 16.5607 8.6724 15.98L9.2924 16.77C8.72321 17.2215 8.30861 17.8389 8.10611 18.5366C7.90361 19.2343 7.92325 19.9778 8.16229 20.6638C8.40134 21.3499 8.84794 21.9445 9.44017 22.3653C10.0324 22.7861 10.7409 23.0122 11.4674 23.0122C12.1939 23.0122 12.9024 22.7861 13.4946 22.3653C14.0869 21.9445 14.5335 21.3499 14.7725 20.6638C15.0116 19.9778 15.0312 19.2343 14.8287 18.5366C14.6262 17.8389 14.2116 17.2215 13.6424 16.77C13.4924 16.65 13.0924 16.37 12.9424 16.28L13.4424 15.42C13.6524 15.54 14.1024 15.86 14.2724 15.99C14.9956 16.5745 15.52 17.3686 15.7735 18.2633C16.027 19.1579 15.9972 20.1091 15.688 20.9861C15.3789 21.863 14.8057 22.6227 14.0472 23.1606C13.2887 23.6986 12.3823 23.9883 11.4524 23.99L11.4224 23.98Z"
        fill="currentColor"
      />
      <path
        d="M8.95239 16.35C9.18358 16.171 9.4239 16.0041 9.67239 15.85L8.95239 16.35Z"
        fill="currentColor"
      />
      <path
        d="M9.26236 16.7501L8.67236 15.9801C8.9257 15.7828 9.18941 15.5992 9.46236 15.4301L9.95236 16.3101C9.71279 16.4411 9.48227 16.5881 9.26236 16.7501Z"
        fill="currentColor"
      />
      <path d="M11.9224 6H10.9224V18H11.9224V6Z" fill="currentColor" />
      <path
        d="M11.4224 21.5C12.5269 21.5 13.4224 20.6046 13.4224 19.5C13.4224 18.3954 12.5269 17.5 11.4224 17.5C10.3178 17.5 9.42236 18.3954 9.42236 19.5C9.42236 20.6046 10.3178 21.5 11.4224 21.5Z"
        fill="currentColor"
      />
      <path
        d="M17.6724 3.97998H13.6724V4.97998H17.6724V3.97998Z"
        fill="currentColor"
      />
      <path
        d="M15.6724 5.97998H13.6724V6.97998H15.6724V5.97998Z"
        fill="currentColor"
      />
      <path
        d="M17.6724 7.97998H13.6724V8.97998H17.6724V7.97998Z"
        fill="currentColor"
      />
      <path
        d="M15.6724 9.97998H13.6724V10.98H15.6724V9.97998Z"
        fill="currentColor"
      />
      <path
        d="M17.6724 11.98H13.6724V12.98H17.6724V11.98Z"
        fill="currentColor"
      />
    </svg>
  )
}
