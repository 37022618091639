import React from 'react'

export const Jab = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.7073 4.53601L23.0002 3.82801L20.1723 1.00001L19.4653 0.293006C19.0742 -0.0979941 18.4412 -0.0979941 18.0513 0.293006C17.6613 0.684006 17.6613 1.31701 18.0513 1.70701L18.7582 2.41401L16.6372 4.53501L14.5162 2.41401C14.1252 2.02301 13.4923 2.02301 13.1023 2.41401C12.7122 2.80401 12.7122 3.43801 13.1023 3.82801L13.8092 4.53501L11.6882 6.65601L14.5972 9.56501L13.8702 10.292L10.9613 7.38301L8.79725 9.54601L11.7063 12.455L10.9792 13.182L8.07025 10.273L5.84825 12.495L8.75725 15.404L8.03025 16.132L5.12125 13.223L3.20125 15.143C2.42025 15.924 2.42025 17.19 3.20125 17.971L3.90825 18.678L0.29325 22.293C-0.09775 22.684 -0.09775 23.317 0.29325 23.707C0.68325 24.097 1.31725 24.097 1.70725 23.707L5.32225 20.092L6.02925 20.799C6.81025 21.58 8.07625 21.58 8.85725 20.799L19.4643 10.192L20.1712 10.899C20.5612 11.289 21.1952 11.289 21.5852 10.899C21.9752 10.508 21.9752 9.87501 21.5852 9.48501L19.4643 7.36401L21.5852 5.24301L22.2922 5.95001C22.6822 6.34101 23.3162 6.34101 23.7062 5.95001C24.0982 5.55901 24.0983 4.92601 23.7073 4.53601Z"
        fill="currentColor"
      />
    </svg>
  )
}
