import React from 'react'

export const FavouriteFill = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M10.471 21.2987C11.27 22.2342 12.736 22.2342 13.535 21.2987L21.895 11.5119C22.735 10.3271 23.159 8.837 22.945 7.24186C22.593 4.6111 20.435 2.4525 17.761 2.0677C15.431 1.73199 13.307 2.67926 12 4.29207C10.692 2.67926 8.56801 1.73199 6.23801 2.0677C3.56401 2.4525 1.40601 4.61208 1.05401 7.24382C0.841009 8.83798 1.26501 10.3271 2.10501 11.5119L10.471 21.2987Z"
      />
    </svg>
  )
}
