import { ChakraProps, Icon as ChakraIcon } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import type { StyleProps } from '../../core/style-props'
import { useIcon } from '../../core/useIcon'
import { IconType } from './iconRegistry'

export type IconProps = {
  /** Type of icon to display */
  icon: IconType
  role?: string
  sx?: ChakraProps['sx']
  name?: string
} & StyleProps

export const Icon = ({
  icon,
  color,
  role,
  sx,
  name,
  ...rest
}: IconProps): ReactElement => {
  const { icons } = useIcon()
  const SelectedIcon = icons[icon]
  return (
    <ChakraIcon
      as={SelectedIcon}
      color={color}
      role={role}
      aria-label={name}
      sx={sx}
      {...rest}
    />
  )
}
