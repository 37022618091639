import React from 'react'

export const Bmi = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM9.5 16.5C9.5 17.878 10.621 19 12 19C13.378 19 14.5 17.878 14.5 16.5C14.5 16.27 14.442 15.979 14.417 15.864L12.966 10.445L12 6.84L11.034 10.446L9.594 15.819C9.558 15.979 9.5 16.27 9.5 16.5ZM20 12C20 12.276 20.224 12.5 20.5 12.5C20.776 12.5 21 12.277 21 12C21 7.037 16.962 3 12 3C7.038 3 3 7.037 3 12C3 12.277 3.224 12.5 3.5 12.5C3.776 12.5 4 12.276 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12ZM10.56 16.078L12 10.704L13.44 16.078C13.44 16.078 13.5 16.353 13.5 16.5C13.5 17.328 12.828 18 12 18C11.172 18 10.5 17.328 10.5 16.5C10.5 16.353 10.56 16.078 10.56 16.078Z"
        fill="currentColor"
      />
    </svg>
  )
}
