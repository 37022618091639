import React from 'react'

export const SmartWatch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 2.5C3.806 2.5 0 6.306 0 11C0 15.694 3.806 19.5 8.5 19.5C13.194 19.5 17 15.694 17 11C17 6.306 13.194 2.5 8.5 2.5ZM15.206 13H13.176C12.028 13 10.921 12.409 10.485 11.562L9.28 9.166C8.977 8.564 8.023 8.564 7.72 9.166L6.517 11.558C5.994 12.469 4.992 13 3.823 13H1.793C1.697 12.676 1.628 12.341 1.579 12H3.823C4.629 12 5.307 11.657 5.636 11.084L6.826 8.717C7.146 8.081 7.787 7.686 8.499 7.686C9.211 7.686 9.852 8.082 10.172 8.717L11.375 11.109C11.636 11.616 12.411 12.001 13.175 12.001H15.419C15.372 12.341 15.303 12.676 15.206 13ZM15.283 4.361L14.946 3.102L14.512 1.482C14.278 0.608 13.485 0 12.58 0H4.42C3.515 0 2.722 0.608 2.488 1.482L2.054 3.102L1.717 4.362C3.442 2.599 5.844 1.5 8.5 1.5C11.156 1.5 13.558 2.599 15.283 4.361ZM1.717 17.638L2.054 18.897L2.488 20.517C2.722 21.392 3.515 22 4.42 22H12.58C13.485 22 14.278 21.392 14.512 20.517L14.946 18.897L15.283 17.638C13.557 19.402 11.156 20.5 8.5 20.5C5.844 20.5 3.442 19.402 1.717 17.638ZM17.507 8C17.823 8.944 18 9.951 18 11C18 12.049 17.822 13.056 17.507 14H18.5C19.052 14 19.5 13.552 19.5 13V9C19.5 8.448 19.052 8 18.5 8H17.507Z"
        fill="currentColor"
      />
    </svg>
  )
}
