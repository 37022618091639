import React from 'react'

export const Confirm = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 4.9943L8.22286 20.2L0 12.2743L0.822857 11.4857L8.22286 18.6172L23.1771 4.20001L24 4.9943Z"
        fill="currentColor"
      />
    </svg>
  )
}
