import { progressAnatomy as parts } from '@chakra-ui/anatomy'
import { PartsStyleFunction } from '@chakra-ui/theme-tools'
import { dnaTheme } from '../..'
import { getModeColor } from '../../../themes/dna/utils'

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  ...dnaTheme.components.Progress.baseStyle(props),
  filledTrack: {
    transitionProperty: 'all',
    transitionDuration: 'slow',
    transitionTimingFunction: 'ease',
    height: 1,
    borderRadius: '0.125rem',
    position: 'relative',
    bottom: '1.5px',
    bgColor: getModeColor('brand.primary', props),
    maxWidth: '100%',
  },
})

export const Progress = {
  parts: parts.keys,
  baseStyle,
}
