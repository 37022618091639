import React, { forwardRef } from 'react'
import { useModalContext, useStyles } from '@chakra-ui/react'
import { callAllHandlers } from '@chakra-ui/utils'
import { CloseButton, CloseButtonProps } from '../CloseButton'

export const PopOverCloseButton = forwardRef<
  HTMLButtonElement,
  CloseButtonProps
>((props, ref) => {
  const { onClick, ...rest } = props
  const { onClose } = useModalContext()

  const styles = useStyles()

  return (
    <CloseButton
      ref={ref}
      __css={styles.closeButton}
      onClick={callAllHandlers(
        onClick,
        (event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation()
          onClose()
        }
      )}
      {...rest}
    />
  )
})

PopOverCloseButton.displayName = 'PopOverCloseButton'
