import { createBreakpoints } from '@chakra-ui/theme-tools'

export const breakpoints = createBreakpoints({
  xsm: '20em',
  sm: '40em',
  md: '48em',
  lg: '64em',
  xl: '79.625em',
  '2xl': '95.250em',
})
