import React from 'react'

export const GetStarted = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.99 5.32H18.24C19.21 5.32 19.99 6.11 19.99 7.07V20.25C19.99 21.22 19.21 22 18.24 22H6.82C5.26 22 4 20.74 4 19.18V4.26C4 2.88 5.11 2 6.4 2H16.21C17.19 2 17.99 2.82 17.99 3.8V5.32ZM16.19 3.5H6.42C5.91 3.5 5.5 3.91 5.5 4.42C5.5 4.93 5.91 5.32 6.42 5.32H16.49V3.8C16.49 3.63 16.36 3.5 16.19 3.5ZM12.46 10.04C12.95 9.71 13.53 9.53 14.14 9.53C15.73 9.53 16.94 10.84 16.96 12.44C16.96 14.27 16.03 15.69 14.54 16.72C13.68 17.32 12.82 17.67 12.22 17.83C12.16 17.85 12.09 17.86 12.02 17.86C11.95 17.86 11.89 17.85 11.82 17.83C11.22 17.67 10.36 17.32 9.5 16.72C8 15.69 7.08 14.27 7.08 12.44C7.08 10.84 8.29 9.53 9.88 9.53C10.49 9.53 11.07 9.71 11.56 10.04C11.72 10.15 11.87 10.28 12.01 10.42C12.14 10.28 12.29 10.15 12.46 10.04ZM12.36 16.12C12.8 15.94 13.24 15.71 13.64 15.43C14.74 14.66 15.38 13.69 15.38 12.44C15.38 11.68 14.84 11.09 14.14 11.09C13.84 11.09 13.56 11.17 13.33 11.33C13.08 11.5 12.88 11.76 12.81 12.06C12.7957 12.1206 12.7878 12.1825 12.7799 12.2443C12.7657 12.3558 12.7515 12.467 12.7 12.57C12.57 12.82 12.28 12.96 12 12.96C11.71 12.96 11.42 12.83 11.3 12.57C11.2485 12.467 11.2343 12.3558 11.2201 12.2443C11.2122 12.1825 11.2043 12.1206 11.19 12.06C11.12 11.76 10.92 11.5 10.67 11.33C10.44 11.18 10.16 11.09 9.86 11.09C9.16 11.09 8.62 11.68 8.62 12.44C8.62 13.69 9.25 14.67 10.36 15.43C10.76 15.71 11.2 15.94 11.64 16.12C11.6928 16.1389 11.7428 16.1577 11.79 16.1755C11.8677 16.2049 11.9377 16.2313 12 16.25C12.1 16.22 12.22 16.18 12.36 16.12Z"
        fill="currentColor"
      />
    </svg>
  )
}
