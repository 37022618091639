import React from 'react'

export const Delivery = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.37209 17.7629H24V6.6001H8.37209V17.7629ZM21.9293 7.71638L16.186 12.6224L10.4428 7.71638H21.9293ZM9.48837 8.3694L13.9535 12.1815L9.48837 15.9936V8.3694ZM10.4428 16.6466L14.813 12.9127L15.8233 13.7778C15.9244 13.8642 16.053 13.9118 16.186 13.9118C16.3191 13.9118 16.4477 13.8642 16.5488 13.7778L17.5591 12.9127L21.9293 16.6466H10.4428ZM22.8837 15.9936L18.4186 12.1815L22.8837 8.3694V15.9936ZM0 14.9722H6.13953V16.0885H0V14.9722ZM1.11628 12.7396H6.13953V13.8559H1.11628V12.7396ZM2.23256 10.5071H6.13953V11.6234H2.23256V10.5071ZM3.34884 8.27452H6.13953V9.39079H3.34884V8.27452Z"
        fill="currentColor"
      />
    </svg>
  )
}
