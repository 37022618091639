import React from 'react'

export const Privacy = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27508)">
        <path
          d="M19.8943 13.3037C18.2623 17.0844 15.6699 20.9577 12.2271 23.1913C8.77755 20.9455 6.17822 17.0797 4.53783 13.3083C3.71005 11.4052 3.13821 9.55249 2.82691 7.98755C2.5094 6.39141 2.48288 5.19177 2.65496 4.54365L2.65524 4.5426C2.75674 4.15806 2.90006 4.00963 2.97507 3.9527C3.05518 3.89188 3.13044 3.88032 3.17181 3.88032H3.18849L3.20514 3.87958C4.65433 3.81511 6.73851 3.31604 8.48907 2.75472C9.37218 2.47155 10.1968 2.16413 10.84 1.87296C11.1604 1.72795 11.4504 1.58046 11.6849 1.43519C11.844 1.33669 12.0455 1.19951 12.2018 1.02127C12.3559 1.20089 12.5537 1.33699 12.7051 1.4323C12.9379 1.57885 13.2266 1.72702 13.5454 1.87222C14.1857 2.16389 15.0095 2.47154 15.8931 2.75474C17.6451 3.3163 19.7368 3.81517 21.1961 3.87959L21.2127 3.88032H21.2292C21.2669 3.88032 21.3426 3.89129 21.4253 3.95431C21.5031 4.01362 21.6479 4.16526 21.7516 4.55023C21.9247 5.19733 21.9008 6.39543 21.5877 7.98967C21.2807 9.55271 20.7147 11.4032 19.8943 13.3037Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27508">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
