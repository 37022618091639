import {
  mode,
  SystemStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleFunction = (props) => {
  const borderColor = mode(
    'light.functional.divider',
    'dark.functional.divider'
  )(props)

  return {
    opacity: 1.0,
    borderColor,
    borderStyle: 'solid',
  }
}

const variantSolid: SystemStyleObject = {
  borderWidth: '1px',
}

const variantThin: SystemStyleObject = {
  borderWidth: '0.5px',
}

const variants = {
  solid: variantSolid,
  thin: variantThin,
}

const defaultProps = {
  variant: 'solid',
}

export const Divider = {
  baseStyle,
  variants,
  defaultProps,
}
