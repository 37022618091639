import React from 'react'

export const AudioClip = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_376_26836)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.2 22.8H22.8V1.19995H1.2V22.8ZM0 24H24V-4.57764e-05H0V24ZM18 6.59995L16.8 7.00195V14.646C16.8123 15.1363 16.6881 15.6203 16.4412 16.0441C16.1944 16.4679 15.8346 16.8148 15.402 17.046C14.076 17.694 12.564 17.43 12.12 16.518C11.676 15.606 12.39 14.556 13.65 14.052C14.2667 13.7931 14.95 13.7384 15.6 13.896V7.39795L9.6 9.40195V17.046C9.6123 17.5363 9.48808 18.0203 9.24122 18.4441C8.99436 18.8679 8.63456 19.2148 8.202 19.446C6.876 20.094 5.364 19.83 4.92 18.918C4.476 18.006 5.19 16.956 6.45 16.452C7.06667 16.1931 7.75002 16.1384 8.4 16.296V9.79795L7.2 10.2V7.79995L18 4.19995V6.59995Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_376_26836">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
