import * as React from 'react'
export const RAGNearTarget = (
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M23.84 21L13.045 1.814a1.199 1.199 0 00-2.092 0L.16 21.001A1.2 1.2 0 001.204 22.8H22.79a1.2 1.2 0 001.05-1.8zM11.396 7.21h1.2v9.593h-1.2V7.21zm.6 13.191a.9.9 0 110-1.8.9.9 0 010 1.8z"
      fill="#C85204"
    />
  </svg>
)
