import React from 'react'

export const Stomach = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3998 2.72C8.3398 2.31 7.9898 2 7.5698 2C7.1098 2 6.7398 2.37 6.7398 2.83L6.7798 4.18C6.8898 6.37 8.7098 8.12 10.9398 8.12V10.05C10.9398 11.89 9.4498 13.38 7.6098 13.38C7.5598 13.38 7.0998 13.38 6.2198 13.39C3.9198 13.39 2.0498 15.26 2.0498 17.56V21.17C2.0498 21.63 2.4198 22 2.8798 22C3.3398 22 3.7098 21.63 3.7098 21.17V17.56C3.7098 16.69 4.1498 15.93 4.8298 15.48C4.7598 15.73 4.7498 16.29 4.8198 17.14C5.1498 19.37 6.7698 21.09 9.2598 21.45C11.7498 21.8 14.8598 20.89 17.5898 18.67C20.3198 16.45 22.0298 13.28 22.0298 9.78V8.67C22.0298 5.6 19.5398 3.11 16.4698 3.11H16.0498C13.8498 3.11 11.9698 4.49 11.2398 6.44L10.7398 6.43C9.4398 6.35 8.4098 5.26 8.4098 3.94V2.83L8.3998 2.72ZM16.2335 4.37548C15.9604 4.33469 15.7059 4.52303 15.6652 4.79615C15.6244 5.06892 15.8122 5.3231 16.0848 5.36436C16.085 5.36439 16.0852 5.36442 16.0854 5.36445L16.0893 5.36509L16.1131 5.36921C16.1354 5.37324 16.1703 5.3799 16.2161 5.38978C16.3078 5.40956 16.4424 5.44208 16.6066 5.49181C16.9363 5.59168 17.3774 5.75865 17.8264 6.0259C18.7174 6.55619 19.6236 7.46683 19.7925 9.05292C19.8217 9.32751 20.068 9.52641 20.3426 9.49719C20.6172 9.46796 20.8161 9.22166 20.7869 8.94707C20.5757 6.96316 19.4169 5.8088 18.3379 5.16659C17.8019 4.84759 17.2817 4.65143 16.8965 4.53474C16.7032 4.47619 16.5419 4.43707 16.427 4.41228C16.3695 4.39987 16.3235 4.39102 16.2907 4.3851C16.2743 4.38214 16.2612 4.37991 16.2516 4.37833L16.2398 4.37645L16.236 4.37585L16.2346 4.37564L16.234 4.37555C16.2338 4.37551 16.2335 4.37548 16.1597 4.87L16.2335 4.37548Z"
        fill="currentColor"
      />
    </svg>
  )
}
