import React from 'react'

export const Progress = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 3V21C2 21.552 2.448 22 3 22H21C21.552 22 22 21.552 22 21V3C22 2.448 21.552 2 21 2H3C2.448 2 2 2.448 2 3ZM20 20H4V4H5V15.467L8.758 11.709L11.418 14.369L19.17 6.617L19.877 7.324L11.418 15.783L8.758 13.123L5 16.881V19H20V20Z"
        fill="currentColor"
      />
    </svg>
  )
}
