import React from 'react'

export const Play = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.729 9.135L1.502 0.872005C0.836 0.485005 0 0.966005 0 1.73701V18.263C0 19.034 0.836 19.515 1.502 19.128L15.729 10.865C16.393 10.479 16.393 9.521 15.729 9.135Z"
        fill="currentColor"
      />
    </svg>
  )
}
