import React from 'react'

export const Next = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.8 22.8V1.2H24V22.8H22.8ZM19.044 11.4L12 4.356L12.846 3.504L21.336 12L12.846 20.484L12 19.638L19.038 12.6H0V11.4H19.044Z"
        fill="currentColor"
      />
    </svg>
  )
}
