import React from 'react'

export const FoodAndDrink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 14 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52 1.72998C5.52 1.31998 5.85 0.98998 6.26 0.98998C6.67 0.98998 7 1.31998 7 1.71998V6.98998V8.06998C7 9.4635 6.18151 10.6641 5 11.2239V19.9801C5 20.5301 4.55 20.9801 4 20.9801H3C2.45 20.9801 2 20.5301 2 19.9801V11.222C0.818488 10.6593 0 9.4535 0 8.05998V6.97998V1.71998C0 1.31998 0.32 0.97998 0.73 0.97998C1.14 0.97998 1.47 1.30998 1.47 1.71998V6.97998H2.76V1.71998C2.76 1.31998 3.09 0.97998 3.5 0.97998C3.91 0.97998 4.24 1.30998 4.24 1.71998V6.98998H5.52V1.72998ZM13 0.98999C10.79 0.98999 9 4.78999 9 6.99999V13.99H11V19.99C11 20.54 11.45 20.99 12 20.99H13C13.55 20.99 14 20.54 14 19.99V13.99V2.98999V1.98999C14 1.43999 13.55 0.98999 13 0.98999Z"
        fill="currentColor"
      />
    </svg>
  )
}
