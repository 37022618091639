// export * from './typography'
import { colors } from './colors'
import { typography } from './typography'
import { shadows } from './shadows'
import { breakpoints } from './breakpoints'

import borders from './borders'
import radii from './radius'
import sizes from './sizes'
import { spacing } from './spacing'
import transition from './transition'
import zIndices from './z-index'
import blur from './blur'

export const foundations = {
  breakpoints,
  colors,
  shadows,
  ...typography,
  zIndices,
  radii,
  blur,
  sizes,
  space: spacing,
  borders,
  transition,
}
