import React from 'react'

export const Information = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM11 17C10.448 17 10 16.552 10 16V9C10 8.448 10.448 8 11 8C11.552 8 12 8.448 12 9V16C12 16.552 11.552 17 11 17ZM11 7C10.31 7 9.75 6.44 9.75 5.75C9.75 5.06 10.31 4.5 11 4.5C11.69 4.5 12.25 5.06 12.25 5.75C12.25 6.44 11.69 7 11 7Z"
        fill="currentColor"
      />
    </svg>
  )
}
