import React from 'react'

export const MicOn = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 14C11.75 14 14 11.75 14 9V5C14 2.25 11.75 0 9 0C6.25 0 4 2.25 4 5V9C4 11.75 6.25 14 9 14ZM16 8V8.756C16 12.313 13.45 15.488 9.921 15.941C5.653 16.489 2 13.163 2 9V8C2 7.448 1.552 7 1 7C0.448 7 0 7.448 0 8V9C0 13.634 3.503 17.446 8.005 17.942V20.005H4C3.448 20.005 3 20.453 3 21.005V22H15V21.005C15 20.453 14.552 20.005 14 20.005H10V17.942C14.499 17.444 18 13.633 18 9.001V8C18 7.448 17.552 7 17 7C16.448 7 16 7.448 16 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
