import React from 'react'

export const Alert = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9 20.1C12.9 20.278 12.8472 20.452 12.7483 20.6C12.6494 20.748 12.5089 20.8634 12.3444 20.9315C12.18 20.9996 11.999 21.0174 11.8244 20.9827C11.6498 20.948 11.4895 20.8623 11.3636 20.7364C11.2377 20.6105 11.152 20.4502 11.1173 20.2756C11.0826 20.101 11.1004 19.92 11.1685 19.7556C11.2366 19.5911 11.352 19.4506 11.5 19.3517C11.648 19.2528 11.822 19.2 12 19.2C12.2387 19.2 12.4676 19.2948 12.6364 19.4636C12.8052 19.6324 12.9 19.8613 12.9 20.1ZM1.2 22.8H22.8V1.2H1.2V22.8ZM0 24V0H24V24H0ZM11.4 16.8V3.6H12.6V16.8H11.4Z"
        fill="currentColor"
      />
    </svg>
  )
}
