import React from 'react'

export const Upload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27859)">
        <path
          d="M22.8 1.2L1.19999 1.2L1.19999 -1.88833e-06L22.8 0L22.8 1.2ZM11.4 4.956L4.35599 12L3.50399 11.154L12 2.664L20.484 11.154L19.638 12L12.6 4.962L12.6 24L11.4 24L11.4 4.956Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27859">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(24 24) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
