const white = '#FFFFFF'

const black = '#000000'

export const light = {
  brand: {
    primary: '#022345',
    primaryFocus: '#176FD7',
    secondary: '#F75F69',
    tertiary: '#9C2C5A',
  },
  text: {
    heading: '#32383D',
    body: '#44525F',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: white,
    textInverted: white,
  },
  status: {
    success: '#008725',
    warning: '#B68F00',
    error: '#D13400',
    errorFocus: '#F4D5D0',
  },
  functional: {
    inactive: '#BBC2C7',
    graphic: '#728492',
    divider: '#D7DADC',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: white,
    graphicInverted: white,
    overlay: 'rgba(0,0,0,0.3)',
  },
  background: {
    backgroundPrimary: white,
    backgroundSecondary: '#FCFCFC',
    backgroundHighlight: '#DBF4CC',
    /**
     * please use buttonSecondary
     * @deprecated
     */
    buttonPurple: '#F7F7F8',
    /**
     * please use bannerInteractive
     * @deprecated
     */
    bannerPurple: '#F7F5FF',
    /**
     * please use bannerError
     * @deprecated
     */
    bannerRed: '#FFE4E0',
    /**
     * please use bannerSuccess
     * @deprecated
     */
    bannerGreen: '#EDFFF1',
    /**
     * please use bannerWarning
     * @deprecated
     */
    bannerOrange: '#FFF2E8',
    /**
     * please use bannerNeutral
     * @deprecated
     */
    bannerGrey: '#ECECEC',
    buttonSecondary: '#F7F7F8',
    bannerInteractive: '#F7F5FF',
    bannerError: '#FFE4E0',
    bannerSuccess: '#EDFFF1',
    bannerWarning: '#FFF2E8',
    bannerNeutral: '#ECECEC',
  },
  shadow: {
    shadow: black,
  },
  data: {
    primaryData: '#5500CC',
    secondaryData: '#0096FA',
  },
}

const dark: typeof light = {
  brand: {
    primary: '#1287FD',
    primaryFocus: '#8DBCF4',
    secondary: '#F49298',
    tertiary: '#DE4C77',
  },
  text: {
    heading: white,
    body: '#D9D9D9',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: '#121212',
    textInverted: '#121212',
  },
  status: {
    success: '#00992A',
    warning: '#B68F00',
    error: '#FF4000',
    errorFocus: '#F4D5D0',
  },
  functional: {
    inactive: '#2C2C2C',
    graphic: '#777777',
    divider: '#6B6B6B',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: '#121212',
    graphicInverted: '#121212',
    overlay: 'rgba(0,0,0,0.6)',
  },
  background: {
    backgroundPrimary: '#1E1E1E',
    backgroundSecondary: '#121212',
    backgroundHighlight: '#54624B',
    /**
     * please use buttonSecondary
     * @deprecated
     */
    buttonPurple: '#32313F',
    /**
     * please use bannerInteractive
     * @deprecated
     */
    bannerPurple: '#1B1921',
    /**
     * please use bannerError
     * @deprecated
     */
    bannerRed: '#221A18',
    /**
     * please use bannerSuccess
     * @deprecated
     */
    bannerGreen: '#1A211B',
    /**
     * please use bannerWarning
     * @deprecated
     */
    bannerOrange: '#211D1A',
    /**
     * please use bannerNeutral
     * @deprecated
     */
    bannerGrey: '#282828',

    buttonSecondary: '#181818',
    bannerInteractive: '#1B1921',
    bannerError: '#221A18',
    bannerSuccess: '#1A211B',
    bannerWarning: '#211D1A',
    bannerNeutral: '#282828',
  },
  shadow: {
    shadow: black,
  },
  data: {
    primaryData: '#9E3DFF',
    secondaryData: '#00DCFA',
  },
}

export const colors = {
  white,
  black,

  light,
  dark,
}
