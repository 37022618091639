import React from 'react'

export const BodyFat = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85903 11.6729C8.38483 11.6729 7.92128 11.5325 7.52699 11.2695C7.13271 11.0064 6.8254 10.6325 6.64393 10.195C6.46246 9.75752 6.41498 9.27614 6.5075 8.81172C6.60001 8.3473 6.82836 7.9207 7.16367 7.58587C7.49898 7.25105 7.92619 7.02303 8.39128 6.93065C8.85637 6.83827 9.33844 6.88568 9.77655 7.06689C10.2147 7.2481 10.5891 7.55496 10.8526 7.94868C11.116 8.34239 11.2566 8.80528 11.2566 9.27879C11.2566 9.91376 11.004 10.5227 10.5544 10.9717C10.1047 11.4207 9.49491 11.6729 8.85903 11.6729ZM8.85903 8.48074C8.70096 8.48074 8.54644 8.52755 8.41501 8.61524C8.28359 8.70293 8.18115 8.82757 8.12066 8.97339C8.06017 9.11922 8.04435 9.27968 8.07518 9.43448C8.10602 9.58929 8.18214 9.73149 8.29391 9.8431C8.40568 9.95471 8.54808 10.0307 8.70311 10.0615C8.85814 10.0923 9.01883 10.0765 9.16487 10.0161C9.3109 9.95569 9.43572 9.8534 9.52354 9.72216C9.61136 9.59092 9.65823 9.43663 9.65823 9.27879C9.65823 9.06714 9.57403 8.86415 9.42415 8.71448C9.27427 8.56482 9.07099 8.48074 8.85903 8.48074Z"
        fill="currentColor"
      />
      <path
        d="M6.53345 16.3174L7.66032 17.4427L17.5384 7.58679L16.4036 6.46154L6.53345 16.3174Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1327 17.5385C14.6585 17.5385 14.195 17.3981 13.8007 17.135C13.4064 16.8719 13.0991 16.498 12.9176 16.0605C12.7361 15.623 12.6887 15.1417 12.7812 14.6772C12.8737 14.2128 13.102 13.7862 13.4373 13.4514C13.7727 13.1166 14.1999 12.8886 14.665 12.7962C15.13 12.7038 15.6121 12.7512 16.0502 12.9324C16.4883 13.1136 16.8628 13.4205 17.1262 13.8142C17.3897 14.2079 17.5303 14.6708 17.5303 15.1443C17.5303 15.7793 17.2777 16.3882 16.8281 16.8372C16.3784 17.2862 15.7686 17.5385 15.1327 17.5385ZM15.1327 14.3463C14.9746 14.3463 14.8201 14.3931 14.6887 14.4808C14.5573 14.5685 14.4548 14.6931 14.3943 14.8389C14.3339 14.9847 14.318 15.1452 14.3489 15.3C14.3797 15.4548 14.4558 15.597 14.5676 15.7086C14.6794 15.8202 14.8218 15.8962 14.9768 15.927C15.1318 15.9578 15.2925 15.942 15.4386 15.8816C15.5846 15.8212 15.7094 15.7189 15.7972 15.5877C15.885 15.4564 15.9319 15.3022 15.9319 15.1443C15.9319 14.9327 15.8477 14.7297 15.6978 14.58C15.548 14.4303 15.3447 14.3463 15.1327 14.3463Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.8C17.9647 22.8 22.8 17.9647 22.8 12C22.8 6.03532 17.9647 1.2 12 1.2C6.03532 1.2 1.2 6.03532 1.2 12C1.2 17.9647 6.03532 22.8 12 22.8ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="currentColor"
      />
    </svg>
  )
}
