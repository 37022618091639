import React from 'react'

export const Rewind = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 6.30464V1.00164C14 0.184644 13.073 -0.287356 12.412 0.192644L1.35 8.22964C0.8 8.62864 0.8 9.44864 1.35 9.84764L12.412 17.8846C13.073 18.3646 14 17.8926 14 17.0756V11.7726L22.412 17.8846C23.073 18.3656 24 17.8926 24 17.0756V1.00164C24 0.184644 23.073 -0.287356 22.412 0.192644L14 6.30464Z"
        fill="currentColor"
      />
    </svg>
  )
}
