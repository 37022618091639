import React from 'react'

export const Collapse = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.1259 14.7751H18.5958C19.123 14.7751 19.5509 14.3482 19.5509 13.8201C19.5509 13.2919 19.123 12.8651 18.5958 12.8651H13.8203C13.2931 12.8651 12.8653 13.2919 12.8653 13.8201V18.5952C12.8653 19.1233 13.2931 19.5502 13.8203 19.5502C14.3476 19.5502 14.7754 19.1233 14.7754 18.5952V16.1255L18.3694 19.7202C18.5557 19.9064 18.8002 20 19.0447 20C19.2892 20 19.5337 19.9064 19.7199 19.7202C20.0934 19.3468 20.0934 18.7432 19.7199 18.3698L16.1259 14.7751ZM6.17965 12.8651H1.40422C0.877009 12.8651 0.44913 13.2919 0.44913 13.8201C0.44913 14.3482 0.877009 14.7751 1.40422 14.7751H3.87407L0.280079 18.3698C-0.0933598 18.7432 -0.0933598 19.3468 0.280079 19.7202C0.466321 19.9064 0.710823 20 0.955326 20C1.19983 20 1.44433 19.9064 1.63057 19.7202L5.22457 16.1255V18.5952C5.22457 19.1233 5.65244 19.5502 6.17965 19.5502C6.70686 19.5502 7.13474 19.1233 7.13474 18.5952V13.8201C7.13474 13.2919 6.70686 12.8651 6.17965 12.8651ZM13.8203 7.13494H18.5958C19.123 7.13494 19.5509 6.70805 19.5509 6.17992C19.5509 5.6518 19.123 5.22491 18.5958 5.22491H16.1259L19.7199 1.63022C20.0934 1.2568 20.0934 0.653233 19.7199 0.27982C19.5337 0.0935918 19.2892 0 19.0447 0C18.8002 0 18.5557 0.0935918 18.3694 0.27982L14.7754 3.87451V1.40483C14.7754 0.876707 14.3476 0.449814 13.8203 0.449814C13.2931 0.449814 12.8653 0.876707 12.8653 1.40483V6.17992C12.8653 6.7071 13.2931 7.13494 13.8203 7.13494ZM6.17965 0.449814C5.65244 0.449814 5.22457 0.876707 5.22457 1.40483V3.87451L1.63057 0.27982C1.44338 0.0935918 1.19983 0 0.955326 0C0.710823 0 0.466321 0.0935918 0.280079 0.27982C-0.0933598 0.653233 -0.0933598 1.2568 0.280079 1.63022L3.87407 5.22491H1.40422C0.877009 5.22491 0.44913 5.6518 0.44913 6.17992C0.44913 6.70805 0.877009 7.13494 1.40422 7.13494H6.17965C6.70686 7.13494 7.13474 6.70805 7.13474 6.17992V1.40483C7.13474 0.876707 6.70686 0.449814 6.17965 0.449814Z"
        fill="currentColor"
      />
    </svg>
  )
}
