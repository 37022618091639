import React from 'react'

export const Skin = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3011 3.00077C15.3033 3 15.3058 3 15.31 3C15.34 3 15.37 3.04 15.33 3.06C14.47 3.94 11.58 7.29 12.16 11.69C12.3 12.62 12.56 13.58 13.06 14.38C13.0798 14.4117 13.0997 14.4419 13.1189 14.4711C13.1481 14.5155 13.1759 14.5578 13.2 14.6C13.46 15.01 13.67 15.38 13.67 15.91C13.67 16.97 12.85 17.84 11.84 17.84C10.93 17.84 10.14 17.14 10.01 16.22C9.74001 14.71 10.15 11.7 10.15 11.7C10.15 11.7 10.76 5.75 15.29 3.01C15.2958 3.00425 15.2982 3.00181 15.3011 3.00077ZM20.02 12.842H21C21.55 12.842 22 13.292 22 13.832V19.832C22 20.382 21.55 20.832 21 20.832H3C2.45 20.832 2 20.382 2 19.832V13.832C2 13.282 2.45 12.832 3 12.832H9.01C8.92 13.892 8.84 15.382 9.02 16.382C9.22 17.762 10.44 18.832 11.84 18.832C13.4 18.832 14.67 17.522 14.67 15.902C14.67 15.472 14.57 15.052 14.39 14.662C14.2958 14.4631 14.1796 14.2779 14.0631 14.092C13.957 13.9228 13.8505 13.7531 13.76 13.572C13.748 13.548 13.7356 13.5235 13.723 13.4985C13.6205 13.2954 13.5045 13.0658 13.46 12.852C13.5056 12.8368 13.5627 12.839 13.6138 12.8409C13.63 12.8415 13.6456 12.842 13.66 12.842H15.31H17.78H20.02Z"
        fill="currentColor"
      />
    </svg>
  )
}
