import dnaTheme, { dnaConfig } from './dna'
import { extendTheme, ThemeConfig } from '@chakra-ui/react'

const telusPurple = 'rgb(75, 40, 109)'
const boxShadow = `0 0 0 0.25rem ${telusPurple}`
const disabledColor = 'rgb(114, 132, 146)'

const config: ThemeConfig = {
  ...dnaConfig,
  cssVarPrefix: 'legacy-telus',
}

const overrides = {
  config,
  colors: {
    light: {
      brand: {
        primary: telusPurple,
        secondary: telusPurple,
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '8px',
        height: '40px',
        _hover: {
          boxShadow,
          textDecoration: 'underline',
        },
        _pressed: {
          boxShadow,
          textDecoration: 'underline',
        },
        _focus: {
          boxShadow,
          textDecoration: 'underline',
        },
      },
      variants: {
        primary: {
          background: telusPurple,
        },
        secondary: {
          background: 'white',
          border: `2px solid ${telusPurple}`,
          color: telusPurple,
          _disabled: {
            bg: 'white',
            color: disabledColor,
            textDecoration: 'none',
            borderColor: disabledColor,
            _hover: {
              textDecoration: 'none',
            },
          },
          _loading: {
            _disabled: {
              bg: 'white',
              color: disabledColor,
              textDecoration: 'none',
            },
          },
        },
      },
    },
  },
}

export default extendTheme(overrides, dnaTheme)
