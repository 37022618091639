import React from 'react'

export const SortDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2012)">
        <path
          d="M0.5 17.75L6.45 23.5L12 18.14"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M6.44995 23.5V0.5"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M23.5 1.46002L12.29 1.48002"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M23.5 8.83002L12 8.85002"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M23.4999 16.78L19.6699 16.79"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_2012">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
