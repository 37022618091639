import React from 'react'

export const ForwardArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.293 0.292994C9.902 0.683994 9.902 1.31599 10.293 1.707L16.586 7.99999L1 7.99999C0.448 7.99999 0 8.44799 0 8.99999C0 9.55199 0.448 9.99999 1 9.99999L16.586 9.99999L10.293 16.293C9.902 16.684 9.902 17.316 10.293 17.707C10.684 18.098 11.316 18.098 11.707 17.707L19.707 9.70699C19.902 9.51199 20 9.25599 20 8.99999C20 8.74399 19.902 8.48799 19.707 8.29299L11.707 0.292994C11.316 -0.0980072 10.684 -0.0980072 10.293 0.292994Z"
        fill="currentColor"
      />
    </svg>
  )
}
