import React from 'react'

export const Download = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 14.5857L16.293 11.2928C16.684 10.9018 17.316 10.9018 17.707 11.2928C18.098 11.6838 18.098 12.3158 17.707 12.7068L12.707 17.7067C12.316 18.0977 11.684 18.0977 11.293 17.7067L6.29299 12.7067C5.90199 12.3158 5.90199 11.6838 6.29299 11.2928C6.48799 11.0978 6.74399 10.9998 6.99999 10.9998C7.25599 10.9998 7.51199 11.0978 7.70699 11.2928L11 14.5857L11 3C11 2.447 11.448 2 12 2C12.552 2 13 2.447 13 3L13 14.5857ZM3 20C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20H3Z"
        fill="currentColor"
      />
    </svg>
  )
}
