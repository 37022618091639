import React, { useRef } from 'react'
import {
  ModalProps as ChakraModalProps,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { PopOverCloseButton } from './PopOverCloseButton'
import { Heading } from '../Heading'
import { Box } from '../Box'
import { PopOverBody } from './PopOverBody'
import { Stack } from '../Stack'
import { StyleProps } from '../../core/style-props'

export type PopOverProps = Pick<
  ChakraModalProps,
  | 'children'
  | 'onClose'
  | 'isOpen'
  | 'autoFocus'
  | 'finalFocusRef'
  | 'initialFocusRef'
  | 'onEsc'
  | 'onOverlayClick'
> &
  Pick<StyleProps, 'marginTop'> & {
    title?: React.ReactNode
    primaryButton?: React.ReactNode
    secondaryButton?: React.ReactNode
    showCloseButton?: boolean
    withAriaDescribedBy?: boolean
    variant?: 'base' | 'large' | 'fullscreen'
  }

export const PopOver = ({
  title,
  primaryButton,
  secondaryButton,
  showCloseButton = true,
  children,
  initialFocusRef,
  withAriaDescribedBy = true,
  variant = 'base',
  marginTop,
  ...props
}: PopOverProps): React.ReactElement => {
  const withFooter =
    primaryButton !== undefined || secondaryButton !== undefined
  const stylesPopOver = useMultiStyleConfig('PopOver', {
    withFooter,
    scrollBehavior: 'outside',
    showCloseButton,
    variant,
  })
  const modalContentRef = useRef<HTMLHeadingElement>(null)

  return (
    <ChakraModal
      initialFocusRef={initialFocusRef || modalContentRef}
      {...props}
    >
      <ModalOverlay sx={stylesPopOver.overlay} />
      <ModalContent sx={stylesPopOver.dialog} marginTop={marginTop}>
        <ModalHeader mb={2} sx={stylesPopOver.header}>
          <Box
            display="flex"
            flex={1}
            alignItems="flex-start"
            justifyContent={title ? 'space-between' : 'flex-end'}
          >
            {title && (
              <Heading as="h1" size="sm" ref={modalContentRef} tabIndex={-1}>
                {title}
              </Heading>
            )}
            {showCloseButton && (
              <PopOverCloseButton sx={stylesPopOver.closeButton} />
            )}
          </Box>
        </ModalHeader>

        {withAriaDescribedBy ? (
          <PopOverBody sx={stylesPopOver.body} id="content">
            {children}
          </PopOverBody>
        ) : (
          <Box sx={stylesPopOver.body}>{children}</Box>
        )}
        {withFooter && (
          <ModalFooter sx={stylesPopOver.footer}>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing={4}
              width={{ base: '100%', sm: 'auto' }}
            >
              {primaryButton}
              {secondaryButton}
            </Stack>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  )
}
