import React from 'react'

export const Height = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2141)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6364 1.09079V2.54715H21.8182V3.63806H19.6364V5.08897H21.8182V6.17988H19.6364V7.63624H24V8.72715H19.6364V10.1835H21.8182V11.2744H19.6364V12.7253H21.8182V13.8162H19.6364V15.2726H24V16.3635H19.6364V17.8199H21.8182V18.9108H19.6364V20.3617H21.8182V21.4526H19.6364V22.909H24V23.9999H18.5455V-0.00012207H24V1.09079H19.6364ZM16.3636 16.2599V23.9999H0V16.2599C0 14.0235 2.18182 13.1453 3.93818 12.4417C5.13818 11.9617 6.16364 11.4326 6.36 10.7508C4.56545 9.71442 3.63273 7.1617 3.63273 4.63079C3.63818 1.81624 5.42182 -0.00012207 8.18182 -0.00012207C10.9418 -0.00012207 12.7255 1.81624 12.7255 4.63079C12.7255 7.18351 11.7873 9.75806 9.96545 10.7835C10.1727 11.449 11.2309 11.9726 12.4145 12.4199C14.1818 13.1453 16.3636 14.0235 16.3636 16.2599ZM4.86545 3.53442C5.38068 3.24713 5.95607 3.08461 6.54545 3.05988C7.36424 3.02313 8.17741 3.2118 8.89636 3.60533C9.73956 4.04785 10.6719 4.29398 11.6236 4.32533C11.5091 2.30715 10.2491 1.09079 8.18182 1.09079C6.40909 1.09079 5.23636 1.99079 4.86545 3.53442ZM6.89455 9.81806L7.39636 10.0417C7.90776 10.2018 8.45587 10.2018 8.96727 10.0417L9.46909 9.81806C10.6145 9.14715 11.4218 7.45624 11.6018 5.45442C10.489 5.41922 9.39844 5.13399 8.41091 4.61988C7.84749 4.28653 7.19954 4.1236 6.54545 4.15079C5.88631 4.21361 5.25936 4.46553 4.74 4.87624C4.79454 7.11806 5.64 9.07079 6.89455 9.81806ZM15.2727 16.2599C15.2727 14.869 13.9255 14.2199 12 13.4562C10.7127 12.9108 9.32727 12.289 8.93454 11.149C8.67917 11.2 8.4202 11.231 8.16 11.2417C7.88661 11.2345 7.61451 11.2016 7.34727 11.1435C6.97091 12.2344 5.67818 12.9108 4.32 13.4562C2.41636 14.2199 1.04727 14.869 1.04727 16.2599V22.909H15.2727V16.2599Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_2141">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
