import React from 'react'

export const Profile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5419 15.4801C16.0473 14.8965 14.7437 14.3892 14.471 13.5601C16.7291 12.2456 17.891 9.03285 17.891 5.84194C17.891 2.38376 15.7582 0.00012207 12.1637 0.00012207C8.56914 0.00012207 6.43641 2.38376 6.43641 5.84194C6.43641 9.03285 7.59823 12.2456 9.85641 13.5601C9.58369 14.3729 8.28005 14.8965 6.7855 15.4801C4.5655 16.3638 1.80005 17.4547 1.80005 20.2201V24.0001H22.5273V20.2201C22.5273 17.4547 19.7619 16.3638 17.5419 15.4801ZM7.52732 5.84194C7.52732 2.86376 9.26187 1.09103 12.1637 1.09103C15.0655 1.09103 16.8 2.86376 16.8 5.84194C16.8 8.9074 15.6437 11.6292 13.9146 12.6274L13.2873 12.911C12.5575 13.1512 11.7699 13.1512 11.04 12.911L10.4128 12.6274C8.68369 11.6292 7.52732 8.9074 7.52732 5.84194ZM10.8655 13.9856C11.713 14.2474 12.6198 14.2474 13.4673 13.9856C13.8819 15.0765 15.1037 15.671 16.5437 16.2601C15.2374 17.1006 13.717 17.5475 12.1637 17.5475C10.6104 17.5475 9.08992 17.1006 7.78369 16.2601C9.2455 15.671 10.4564 15.0819 10.8655 13.9856ZM21.4364 22.9092H2.89096V20.2201C2.89096 18.4965 4.39096 17.6401 6.56732 16.751C8.17604 17.9755 10.142 18.6386 12.1637 18.6386C14.1854 18.6386 16.1513 17.9755 17.76 16.751C19.9419 17.6401 21.4364 18.4965 21.4364 20.2201V22.9092Z"
        fill="currentColor"
      />
    </svg>
  )
}
