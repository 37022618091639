import React from 'react'

export const FavouriteOutline = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_28044)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0001 23.5049L9.75329 21.5089C3.84712 15.9627 0.00238363 12.2379 0.00238363 7.58061C-0.0215923 6.67527 0.135508 5.77426 0.464466 4.93045C0.793424 4.08665 1.28761 3.31705 1.91803 2.66682C2.54844 2.01659 3.30239 1.49884 4.13561 1.14394C4.96884 0.789034 5.86456 0.604138 6.77021 0.600098C7.75374 0.604199 8.72585 0.811187 9.62575 1.20811C10.5256 1.60504 11.334 2.18337 12.0001 2.90694C12.663 2.18502 13.4676 1.60757 14.3637 1.21065C15.2599 0.813735 16.2282 0.605888 17.2083 0.600098C18.1153 0.602723 19.0127 0.786491 19.8478 1.14062C20.6829 1.49474 21.4388 2.0121 22.0713 2.66232C22.7037 3.31254 23.1999 4.08253 23.5308 4.9271C23.8616 5.77168 24.0204 6.67383 23.9979 7.58061C23.9979 12.2379 20.1804 15.9627 14.247 21.5089L12.0001 23.5049ZM1.09309 7.68968C1.09309 11.8671 4.78513 15.3246 10.3641 20.5927L12.0001 22.1088L13.6362 20.6091C19.2206 15.3573 22.9072 11.8834 22.9072 7.70604C22.9072 4.28668 20.3822 1.6908 17.061 1.6908C16.1472 1.6983 15.2463 1.90807 14.4232 2.30503C13.6 2.702 12.875 3.27631 12.3001 3.98674L12.0001 4.36303L11.7002 4.0031C11.1233 3.28829 10.3952 2.7102 9.56824 2.31038C8.74127 1.91057 7.83597 1.69897 6.91746 1.6908C6.13833 1.6927 5.36745 1.85046 4.65022 2.1548C3.93299 2.45914 3.28389 2.9039 2.74115 3.4629C2.19841 4.0219 1.773 4.68384 1.48997 5.40974C1.20693 6.13564 1.07199 6.91084 1.09309 7.68968Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_28044">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
