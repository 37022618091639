import React from 'react'

export const Caffeine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27530)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2 16.8H16.8C17.4365 16.8 18.047 16.5472 18.4971 16.0971C18.9471 15.647 19.2 15.0366 19.2 14.4V13.2H19.8C20.9139 13.2 21.9822 12.7575 22.7698 11.9699C23.5575 11.1822 24 10.114 24 9.00005C24 7.88614 23.5575 6.81785 22.7698 6.0302C21.9822 5.24255 20.9139 4.80005 19.8 4.80005H6C5.68174 4.80005 5.37652 4.92648 5.15147 5.15152C4.92643 5.37656 4.8 5.68179 4.8 6.00005V14.4C4.8 15.0366 5.05286 15.647 5.50294 16.0971C5.95303 16.5472 6.56348 16.8 7.2 16.8ZM19.8 6.00005C20.5956 6.00005 21.3587 6.31612 21.9213 6.87873C22.4839 7.44134 22.8 8.2044 22.8 9.00005C22.8 9.7957 22.4839 10.5588 21.9213 11.1214C21.3587 11.684 20.5956 12 19.8 12H19.2V6.00005H19.8ZM6 6.00005H18V14.4C18 14.7183 17.8736 15.0235 17.6485 15.2486C17.4235 15.4736 17.1183 15.6 16.8 15.6H7.2C6.88174 15.6 6.57652 15.4736 6.35147 15.2486C6.12643 15.0235 6 14.7183 6 14.4V6.00005ZM0 19.2H24V18H0V19.2Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27530">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
