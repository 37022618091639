import React from 'react'

export const Image = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.8 3.60002V20.4H1.2V3.60002H22.8ZM24 2.40002H0V21.6H24V2.40002Z"
        fill="currentColor"
      />
      <path
        d="M12.45 17.4121L6.08998 10.8481L1.07398 17.3701L0.125977 16.6381L5.98798 9.01208L12.642 15.8881L17.988 12.6301L23.724 16.2961L23.076 17.3041L17.97 14.0401L12.45 17.4121Z"
        fill="currentColor"
      />
      <path
        d="M18.6001 6.00005C18.9561 6.00005 19.3041 6.10562 19.6001 6.3034C19.8961 6.50119 20.1268 6.78231 20.2631 7.11122C20.3993 7.44013 20.435 7.80205 20.3655 8.15121C20.2961 8.50038 20.1246 8.82111 19.8729 9.07284C19.6212 9.32457 19.3004 9.49601 18.9513 9.56546C18.6021 9.63492 18.2402 9.59927 17.9113 9.46303C17.5824 9.32679 17.3012 9.09608 17.1035 8.80007C16.9057 8.50407 16.8001 8.15605 16.8001 7.80005C16.8001 7.32266 16.9897 6.86482 17.3273 6.52726C17.6649 6.18969 18.1227 6.00005 18.6001 6.00005ZM18.6001 4.80005C18.0068 4.80005 17.4267 4.976 16.9334 5.30564C16.44 5.63528 16.0555 6.10382 15.8285 6.652C15.6014 7.20018 15.542 7.80338 15.6577 8.38532C15.7735 8.96726 16.0592 9.50181 16.4788 9.92137C16.8983 10.3409 17.4329 10.6266 18.0148 10.7424C18.5968 10.8582 19.2 10.7987 19.7482 10.5717C20.2963 10.3446 20.7649 9.96011 21.0945 9.46676C21.4242 8.97341 21.6001 8.39339 21.6001 7.80005C21.6001 7.0044 21.284 6.24134 20.7214 5.67873C20.1588 5.11612 19.3957 4.80005 18.6001 4.80005Z"
        fill="currentColor"
      />
    </svg>
  )
}
