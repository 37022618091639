import { mode, SystemStyleObject } from '@chakra-ui/theme-tools'
import type { SystemStyleFunction } from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleObject = {
  px: 2,
  py: 1,
  font: 'body',
  fontSize: '3xs',
  borderRadius: 'md',
  fontWeight: 'medium',
  textTransform: '',
}

const variantPrimarySolid: SystemStyleFunction = (props) => {
  const bg = mode('light.brand.secondary', 'dark.brand.secondary')(props)
  const textColor = mode(
    'light.text.textWhite',
    'dark.text.textInverted'
  )(props)

  return {
    bg,
    color: textColor,
  }
}

const variantPrimaryOutline: SystemStyleFunction = (props) => {
  const textColor = mode('light.brand.secondary', 'dark.brand.secondary')(props)

  return {
    bg: 'transparent',
    color: textColor,
    border: '1px',
    borderColor: textColor,
  }
}

const variantSecondarySolid: SystemStyleFunction = (props) => {
  const textColor = mode('light.brand.secondary', 'dark.brand.secondary')(props)
  const bg = mode(
    'light.background.backgroundPrimary',
    'dark.background.backgroundPrimary'
  )(props)
  return {
    bg,
    color: textColor,
  }
}

const variantSecondaryOutline: SystemStyleFunction = (props) => {
  const textColor = mode(
    'light.text.textWhite',
    'dark.text.textInverted'
  )(props)
  const outlineBorder = mode(
    'light.functional.graphicInverted',
    'dark.functional.graphicInverted'
  )(props)
  return {
    bg: 'transparent',
    color: textColor,
    border: '1px',
    borderColor: outlineBorder,
  }
}

const variants = {
  primarySolid: variantPrimarySolid,
  secondarySolid: variantSecondarySolid,
  primaryOutline: variantPrimaryOutline,
  secondaryOutline: variantSecondaryOutline,
}

const defaultProps = {
  variant: 'primarySolid',
}

export const Badge = {
  baseStyle,
  variants,
  defaultProps,
}
