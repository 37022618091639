import React from 'react'

export const SmartPhone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0H2C0.895 0 0 0.895 0 2V20C0 21.104 0.895 22 2 22H12C13.105 22 14 21.104 14 20V2C14 0.895 13.105 0 12 0ZM12 20H2V2H12V20ZM11 3H3V19H11V3Z"
        fill="currentColor"
      />
    </svg>
  )
}
