import React, { useEffect } from 'react'
import { ChakraProvider, useColorMode } from '@chakra-ui/react'
import { styleDict, dnaTheme, iconRegistryDict } from '../../themes'
import { IconContext } from '../../core/IconProvider'
import { iconRegistry } from '../Icon/iconRegistry'
import { ThemeType } from '../../themes'

type ThemeProviderProps = {
  colorMode?: 'light' | 'dark'
  theme?: ThemeType | keyof typeof styleDict
}

type ColorModeProps = {
  mode: 'light' | 'dark'
}

const ColorMode: React.FC<ColorModeProps> = ({ children, mode }) => {
  const { colorMode, toggleColorMode } = useColorMode()

  useEffect(() => {
    if (colorMode !== mode) {
      toggleColorMode()
    }
  }, [mode])

  return <>{children}</>
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  theme = dnaTheme,
  colorMode = 'light',
}) => {
  if (typeof theme === 'string') {
    theme = styleDict[theme] || dnaTheme
  }

  return (
    <ChakraProvider theme={theme}>
      <ColorMode mode={colorMode}>
        <IconContext.Provider
          value={{
            icons: iconRegistryDict.get(theme) || iconRegistry,
          }}
        >
          {children}
        </IconContext.Provider>
      </ColorMode>
    </ChakraProvider>
  )
}
