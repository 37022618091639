import React from 'react'

export const Close = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M17.7167 0.284008C17.339 -0.0946693 16.725 -0.0946693 16.3473 0.284008L9.00036 7.63093L1.65344 0.284008C1.27574 -0.0946693 0.662685 -0.0946693 0.284008 0.284008C-0.0946693 0.661717 -0.0946693 1.27574 0.284008 1.65344L7.63093 9.00036L0.284008 16.3473C-0.0946693 16.725 -0.0946693 17.338 0.284008 17.7167C0.662685 18.0944 1.27574 18.0944 1.65344 17.7167L9.00036 10.3698L16.3473 17.7167C16.726 18.0944 17.339 18.0944 17.7167 17.7167C18.0944 17.338 18.0944 16.725 17.7167 16.3473L10.3698 9.00036L17.7167 1.65344C18.0944 1.27574 18.0944 0.661717 17.7167 0.284008Z"
      />
    </svg>
  )
}
