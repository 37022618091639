import React from 'react'

export const DownTrend = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27551)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.6798 17.4004V16.2181H21.7241L14.0453 9.31369L8.37044 14.0132L0 7.82995L0.703448 6.87822L8.32907 12.5117L14.0749 7.7531L22.8177 15.6093V12.0802H24V17.4004H18.6798Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27551">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(1 0 0 -1 0 24.0004)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
