import { alertAnatomy as parts } from '@chakra-ui/anatomy'
import { mode } from '@chakra-ui/theme-tools'
import type {
  SystemStyleFunction,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools'
import { dnaTheme } from '../..'

const baseStyle: SystemStyleFunction = (props: StyleFunctionProps) => {
  return {
    ...dnaTheme.components.Banner.baseStyle(props),
  }
}

const variantAlert: SystemStyleFunction = (props: StyleFunctionProps) => {
  const bgColor = mode('light.brand.primary', 'dark.brand.primary')(props)
  return {
    container: {
      ...dnaTheme.components.Banner.variants.alert(props),
      bg: bgColor,
    },
  }
}

const variantInformation: SystemStyleFunction = (props: StyleFunctionProps) => {
  const bgColor = mode('light.brand.primary', 'dark.brand.primary')(props)
  return {
    container: {
      ...dnaTheme.components.Banner.variants.information(props),
      bg: bgColor,
    },
  }
}

const variants = {
  alert: variantAlert,
  information: variantInformation,
}

const defaultProps = {
  variant: 'information',
}

export const Banner = {
  parts: parts.keys,
  baseStyle,
  variants,
  defaultProps,
}
