import React from 'react'

export const Child = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.00977 6.21995C10.4498 6.21995 11.6198 5.04995 11.6198 3.60995V3.05995C11.6198 1.61995 10.4498 0.449951 9.00977 0.449951C7.56977 0.449951 6.38977 1.61995 6.38977 3.05995V3.60995C6.38977 5.05995 7.55977 6.21995 9.00977 6.21995ZM12.6098 12.32C11.8598 11.66 11.3898 10.69 11.3898 9.61995V9.05995C11.3898 8.50995 11.5198 7.98995 11.7498 7.51995C10.9698 7.19995 10.0598 7.00995 9.00977 6.99995C5.64977 7.01995 3.50977 8.71995 3.50977 12V22H8.50977V18C8.50977 15.07 9.99977 13.06 12.6098 12.32ZM15.0098 13C11.6498 13.02 9.50977 14.72 9.50977 18V22H20.5098V18C20.5098 14.72 18.3698 13.02 15.0098 13ZM15.0098 12.22C16.4498 12.22 17.6198 11.05 17.6198 9.60995V9.05995C17.6198 7.61995 16.4498 6.44995 15.0098 6.44995C13.5698 6.44995 12.3998 7.61995 12.3998 9.05995V9.60995C12.3898 11.05 13.5598 12.22 15.0098 12.22Z"
        fill="currentColor"
      />
    </svg>
  )
}
