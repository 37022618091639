import { formAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleRequiredIndicator: SystemStyleFunction = (props) => {
  return {
    marginStart: 1,
    color: mode('light.status.error', 'dark.status.error')(props),
  }
}

const baseStyleHelperText: SystemStyleFunction = (props) => {
  return {
    mt: 1,
    color: mode('light.text.body', 'dark.text.body')(props),
    ...props.theme.textStyles['caption'],
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: { width: '100%', position: 'relative' },
  requiredIndicator: baseStyleRequiredIndicator(props),
  helperText: baseStyleHelperText(props),
})

export const Form = {
  parts: parts.keys,
  baseStyle,
}
