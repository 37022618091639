import React from 'react'

export const MoreVertical = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 4 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 10C3.5 9.172 2.828 8.5 2 8.5C1.172 8.5 0.5 9.172 0.5 10C0.5 10.828 1.172 11.5 2 11.5C2.828 11.5 3.5 10.828 3.5 10ZM3.5 18C3.5 17.172 2.828 16.5 2 16.5C1.172 16.5 0.5 17.172 0.5 18C0.5 18.828 1.172 19.5 2 19.5C2.828 19.5 3.5 18.828 3.5 18ZM3.5 2C3.5 1.172 2.828 0.5 2 0.5C1.172 0.5 0.5 1.172 0.5 2C0.5 2.828 1.172 3.5 2 3.5C2.828 3.5 3.5 2.828 3.5 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
