import React from 'react'

export const VideoOff = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27962)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.453 12.8381V12.7925L22.8604 6.78678V18.8438L15.453 12.8381ZM22.5185 5.5959L15.453 11.2939V7.11727C15.453 7.07168 15.453 7.11727 15.453 7.11727C15.453 7.11727 15.4245 7.16285 15.4131 7.11727L14.3134 8.18849V18.5019H3.9886L2.91738 19.5845C3.00855 19.5845 2.82051 19.6415 2.91738 19.6415H14.3134C14.6156 19.6415 14.9055 19.5214 15.1192 19.3077C15.3329 19.094 15.453 18.8041 15.453 18.5019V14.3139L22.5185 20.0119C22.6523 20.1138 22.8114 20.1773 22.9787 20.1954C23.1459 20.2135 23.315 20.1855 23.4675 20.1145C23.62 20.0436 23.7502 19.9323 23.8441 19.7926C23.9379 19.653 23.9918 19.4904 24 19.3224V6.28536C23.9978 6.16738 23.9722 6.05101 23.9249 5.94295C23.8775 5.83488 23.8092 5.73726 23.7239 5.65569C23.6387 5.57413 23.5381 5.51024 23.428 5.46771C23.318 5.42518 23.2006 5.40484 23.0826 5.40787C22.8789 5.40618 22.6805 5.47233 22.5185 5.5959ZM2.91738 7.11727H11.8974L2.91738 16.0973V7.11727ZM13.037 5.97767H2.91738C2.61514 5.97767 2.32528 6.09773 2.11156 6.31145C1.89784 6.52517 1.77778 6.81503 1.77778 7.11727V17.2369L0 19.0147L0.803419 19.8181L1.83476 18.7925L2.91738 17.7099L13.51 7.11727L14.5926 6.03465L15.6182 5.00331L14.8148 4.19989L13.037 5.97767Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27962">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
