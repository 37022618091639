import React from 'react'

export const SortUp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.16 7.00003L0.5 6.32003L6.69 0.340027L12.49 5.94003L11.84 6.62003L6.69 1.65003L1.16 7.00003Z"
        fill="currentColor"
      />
      <path
        d="M7.15997 0.98999H6.21997V23.66H7.15997V0.98999Z"
        fill="currentColor"
      />
      <path
        d="M12.4485 22.226L12.4465 23.166L23.4965 23.1892L23.4985 22.2492L12.4485 22.226Z"
        fill="currentColor"
      />
      <path
        d="M12.1582 14.9605L12.1562 15.9005L23.4962 15.9243L23.4982 14.9843L12.1582 14.9605Z"
        fill="currentColor"
      />
      <path
        d="M19.7193 7.13807L19.7163 8.07806L23.4963 8.08994L23.4992 7.14994L19.7193 7.13807Z"
        fill="currentColor"
      />
    </svg>
  )
}
