import React from 'react'

export const Notification = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <g clipPath="url(#Notification_svg__clip0_304_27827)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.756 20.81L20.9 17.66a9.432 9.432 0 01-1.31-4.808v-2.753a7.694 7.694 0 00-5.17-7.292v-.402a2.405 2.405 0 00-4.81 0v.402A7.694 7.694 0 004.44 10.1v2.753a9.431 9.431 0 01-1.31 4.809l-1.858 3.15a.565.565 0 000 .553.554.554 0 00.463.276H8.63a3.607 3.607 0 006.769 0h6.894a.547.547 0 00.463-.83zM10.812 2.406a1.202 1.202 0 112.405 0v.108a6.732 6.732 0 00-2.405 0v-.108zm1.202 20.438a2.404 2.404 0 01-2.068-1.203h4.136a2.404 2.404 0 01-2.068 1.203zM2.89 20.438l1.275-2.164a10.651 10.651 0 001.478-5.41v-2.765a6.372 6.372 0 0112.744 0v2.753c0 1.903.512 3.771 1.479 5.41l1.274 2.176H2.89z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="Notification_svg__clip0_304_27827">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
