import React from 'react'

export const Test = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_28053)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4546 4.47262V19.7453C17.4546 20.6133 17.7994 21.4457 18.4131 22.0595C19.0269 22.6733 19.8593 23.0181 20.7273 23.0181C21.5953 23.0181 22.4277 22.6733 23.0414 22.0595C23.6552 21.4457 24 20.6133 24 19.7453V4.47262H17.4546ZM22.9091 19.7453C22.9091 20.324 22.6792 20.8789 22.2701 21.2881C21.8609 21.6973 21.3059 21.9271 20.7273 21.9271C20.1486 21.9271 19.5937 21.6973 19.1845 21.2881C18.7753 20.8789 18.5455 20.324 18.5455 19.7453V10.4235C19.201 10.5378 19.8427 10.7206 20.46 10.969C21.24 11.2685 22.0749 11.3987 22.9091 11.3508V19.7453ZM22.9091 10.2708C22.215 10.3185 21.5188 10.2143 20.8691 9.96534C20.1216 9.66168 19.3421 9.44392 18.5455 9.31625V5.56352H22.9091V10.2708ZM12 9.92716V1.19989H4.36365V9.92716L2.12729 14.3235L1.81638 14.9344L0.343658 17.8853C0.1156 18.364 -0.00184533 18.8878 2.19213e-05 19.4181C0.00146461 20.3724 0.381212 21.2872 1.05603 21.962C1.73085 22.6369 2.64568 23.0166 3.60002 23.0181H12.8073C13.7616 23.0166 14.6765 22.6369 15.3513 21.962C16.0261 21.2872 16.4058 20.3724 16.4073 19.4181C16.4091 18.8878 16.2917 18.364 16.0636 17.8853L12 9.92716ZM5.33456 10.4235L5.45456 10.189V8.83625H8.72729V7.74534H5.45456V5.56352H8.72729V4.47262H5.45456V2.2908H10.9091V10.189L11.0291 10.4235L12.8182 13.9472C11.2527 14.569 9.37092 14.9781 7.7782 14.329C6.50407 13.7951 5.13411 13.5278 3.75275 13.5435L5.33456 10.4235ZM12.8073 21.9271H3.60002C2.93457 21.9271 2.29637 21.6628 1.82582 21.1923C1.35528 20.7217 1.09093 20.0835 1.09093 19.4181C1.08944 19.0605 1.16575 18.7068 1.31457 18.3817L3.19638 14.6726C4.62858 14.5446 6.0701 14.7752 7.39092 15.3435C9.33819 16.129 11.5527 15.6381 13.3364 14.9235L15.0764 18.349C15.2564 18.7313 15.337 19.153 15.3106 19.5748C15.2842 19.9966 15.1517 20.4049 14.9254 20.7618C14.6991 21.1187 14.3862 21.4127 14.0159 21.6164C13.6457 21.8201 13.2299 21.927 12.8073 21.9271Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_28053">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
