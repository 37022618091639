import React from 'react'

export const Unit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85625 5.44315L3.88606 6.47295L5.42884 4.93017L6.20023 5.70156L4.65745 7.24434L5.6834 8.27029L7.22617 6.72751L7.99756 7.4989L6.45478 9.04168L7.48459 10.0715L10.5701 6.98592L11.3415 7.75731L8.25598 10.8429L9.28578 11.8727L10.8286 10.3299L11.5999 11.1013L10.0572 12.6441L11.0831 13.67L12.6259 12.1272L13.3973 12.8986L11.8545 14.4414L12.8843 15.4712L15.9699 12.3856L16.7413 13.157L13.6557 16.2426L14.6855 17.2724L16.2283 15.7296L16.9997 16.501L15.4569 18.0438L16.4828 19.0697L18.0256 17.527L18.797 18.2983L17.2542 19.8411L18.284 20.8709L21.3696 17.7854L22.141 18.5568L18.284 22.4137L1.31348 5.44315L5.17042 1.5862L5.94181 2.35759L2.85625 5.44315Z"
        fill="currentColor"
      />
    </svg>
  )
}
