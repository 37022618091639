import React from 'react'

export const Search = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9732 13.7798C17.0251 13.8097 17.0714 13.8483 17.1101 13.8939L23.7076 20.4914C23.9014 20.7053 24.003 20.987 23.9902 21.2753C23.9773 21.5636 23.8512 21.8352 23.6392 22.031L22.0254 23.6448C21.8304 23.8576 21.5592 23.9848 21.2709 23.9987C20.9825 24.0126 20.7004 23.912 20.4858 23.7189L13.8883 17.11C13.8443 17.0697 13.8059 17.0237 13.7742 16.9731C11.8623 18.1059 9.60261 18.5018 7.41941 18.0867C5.23621 17.6716 3.27959 16.4739 1.91682 14.7184C0.554058 12.963 -0.121142 10.7705 0.017963 8.55255C0.157068 6.33459 1.10091 4.24365 2.67233 2.67224C4.24375 1.10082 6.33468 0.156977 8.55264 0.0178714C10.7706 -0.121234 12.9631 0.553967 14.7185 1.91673C16.474 3.27949 17.6717 5.23611 18.0868 7.41931C18.5019 9.60251 18.106 11.8622 16.9732 13.7741V13.7798ZM14.7094 14.829L14.8234 14.715C16.3005 13.2078 17.1207 11.1771 17.1047 9.06686C17.0887 6.95662 16.2378 4.93857 14.7381 3.45392C13.2384 1.96928 11.2119 1.13883 9.10157 1.14416C6.99128 1.14949 4.96895 1.99016 3.47675 3.48236C1.98455 4.97456 1.14388 6.99689 1.13855 9.10718C1.13322 11.2175 1.96366 13.244 3.44831 14.7437C4.93296 16.2435 6.95101 17.0943 9.06124 17.1103C11.1715 17.1263 13.2022 16.3061 14.7094 14.829ZM14.7094 16.3345L21.2157 22.8123L22.8066 21.2213L16.306 14.7207C16.0717 15.0199 15.8184 15.3037 15.5476 15.5703C15.2865 15.8423 15.0084 16.0976 14.7151 16.3345H14.7094Z"
        fill="currentColor"
      />
    </svg>
  )
}
