import React from 'react'

export const Hide = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="m4.046 17.126 1.416-1.416A12.193 12.193 0 0 1 2.248 12a12.117 12.117 0 0 1 3.824-4.158 6.453 6.453 0 0 0 .722 6.535l1.441-1.441A4.446 4.446 0 0 1 7.5 10.5C7.5 8.019 9.519 6 12 6c.901 0 1.733.277 2.436.736l1.93-1.93A12.387 12.387 0 0 0 12 4C6.762 4 2.233 7.255 0 12a14.463 14.463 0 0 0 4.046 5.126ZM19.165 6.249l4.541-4.541a1.003 1.003 0 0 0 0-1.414 1.003 1.003 0 0 0-1.414 0L.293 22.292a1.003 1.003 0 0 0 0 1.414 1.003 1.003 0 0 0 1.414 0l4.924-4.924A12.47 12.47 0 0 0 12 20c5.237 0 9.767-3.255 12-8-1.109-2.358-2.786-4.346-4.835-5.751Zm-2.896 2.896c.137.43.231.88.231 1.355 0 2.481-2.019 4.5-4.5 4.5-.475 0-.924-.094-1.355-.231l5.624-5.624ZM12 18c-1.334 0-2.623-.274-3.836-.75l.938-.938A6.469 6.469 0 0 0 12 17a6.5 6.5 0 0 0 6.5-6.5 6.48 6.48 0 0 0-.572-2.658A12.107 12.107 0 0 1 21.752 12c-2.091 3.724-5.751 6-9.752 6Z"
      />
    </svg>
  )
}
