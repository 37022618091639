import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'
import { Button } from './button'
import { getModeColor } from '../utils'

const baseStyle: SystemStyleFunction = (props) => {
  return {
    ...Button.baseStyle(props),
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }
}

const variantPrimary: SystemStyleFunction = (props) => {
  const styles = Button.variants.primary(props)
  const textColor = mode(
    'light.text.textInverted',
    'dark.text.textInverted'
  )(props)

  return {
    ...styles,
    borderRadius: 'full',
    color: textColor,
  }
}

const variantSecondary: SystemStyleFunction = (props) => {
  const styles = Button.variants.secondary(props)

  return {
    ...styles,
    borderRadius: 'full',
    color: getModeColor('brand.secondary', props),
  }
}

const variantTertiary: SystemStyleFunction = (props) => {
  const styles = Button.variants.tertiary(props)

  return {
    ...styles,
    borderRadius: 'full',
    color: getModeColor('brand.secondary', props),
  }
}

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
  'input-icon': Button.variants['input-icon'],
  'autosuggest-clear': Button.variants['autosuggest-clear'],
}

const defaultProps = Button.defaultProps

export const IconButton = {
  baseStyle,
  variants,
  defaultProps,
}
