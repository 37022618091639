import React from 'react'

export const Qualified = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4C8.14 4 5 7.14 5 11C5 14.86 8.14 18 12 18C15.86 18 19 14.86 19 11C19 7.14 15.86 4 12 4ZM15.665 9.004L10.781 13.889C10.683 13.987 10.555 14.035 10.427 14.035C10.299 14.035 10.171 13.986 10.073 13.889L7.957 11.774C7.762 11.579 7.762 11.262 7.957 11.067C8.152 10.872 8.469 10.872 8.664 11.067L10.426 12.829L14.957 8.298C15.152 8.103 15.469 8.103 15.664 8.298C15.861 8.492 15.861 8.808 15.665 9.004ZM19.93 17.075C21.223 15.39 22 13.288 22 11C22 5.477 17.523 1 12 1C6.477 1 2 5.477 2 11C2 16.523 6.477 21 12 21C13.046 21 14.054 20.838 15.002 20.54L17 24L18.098 19.902L22.196 21L19.93 17.075ZM12 19C7.582 19 4 15.418 4 11C4 6.582 7.582 3 12 3C16.418 3 20 6.582 20 11C20 15.418 16.418 19 12 19Z"
        fill="currentColor"
      />
    </svg>
  )
}
