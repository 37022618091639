import React from 'react'

export const ShareIos = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M7.707 7.86399L11 4.57099V14.842C11 15.395 11.448 15.842 12 15.842C12.552 15.842 13 15.395 13 14.842V4.57099L16.293 7.86399C16.488 8.05899 16.744 8.15699 17 8.15699C17.256 8.15699 17.512 8.05899 17.707 7.86399C18.098 7.47299 18.098 6.84099 17.707 6.44999L12.707 1.44999C12.316 1.05899 11.684 1.05899 11.293 1.44999L6.293 6.44999C5.902 6.84099 5.902 7.47299 6.293 7.86399C6.684 8.25499 7.316 8.25499 7.707 7.86399ZM21 12C20.448 12 20 12.448 20 13V19C20 19.552 19.552 20 19 20H5C4.448 20 4 19.552 4 19V13C4 12.448 3.552 12 3 12C2.448 12 2 12.448 2 13V20C2 21.104 2.895 22 4 22H20C21.105 22 22 21.104 22 20V13C22 12.448 21.552 12 21 12Z"
      />
    </svg>
  )
}
