import React from 'react'

export const SortDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 19H2C1.45 19 1 19.45 1 20C1 20.55 1.45 21 2 21H8C8.55 21 9 20.55 9 20C9 19.45 8.55 19 8 19ZM8 11H2C1.45 11 1 11.45 1 12C1 12.55 1.45 13 2 13H8C8.55 13 9 12.55 9 12C9 11.45 8.55 11 8 11ZM2 5H13C13.55 5 14 4.55 14 4C14 3.45 13.55 3 13 3H2C1.45 3 1 3.45 1 4C1 4.55 1.45 5 2 5ZM22.707 16.135C22.316 15.744 21.684 15.744 21.293 16.135L19 18.428V4.157C19 3.604 18.552 3.157 18 3.157C17.448 3.157 17 3.604 17 4.157V18.428L14.707 16.135C14.316 15.744 13.684 15.744 13.293 16.135C12.902 16.526 12.902 17.159 13.293 17.549L17.293 21.549C17.488 21.744 17.744 21.842 18 21.842C18.256 21.842 18.512 21.744 18.707 21.549L22.707 17.549C23.098 17.159 23.098 16.526 22.707 16.135Z"
        fill="currentColor"
      />
    </svg>
  )
}
