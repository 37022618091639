import React from 'react'

export const Work = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.633 12H14V15H8V12H5.367C3.759 12 2.274 11.142 1.47 9.75L0 7.203V19C0 20.104 0.895 21 2 21H20C21.104 21 22 20.104 22 19V7.203L20.53 9.749C19.726 11.142 18.24 12 16.633 12ZM9 12V14H13V12H9ZM21 4H16V2C16 0.895 15.104 0 14 0H8C6.896 0 6 0.895 6 2V4H1C0.448 4 0 4.448 0 5V5.203L2.336 9.25C2.96 10.329 4.122 11 5.368 11H16.633C17.879 11 19.041 10.329 19.664 9.25L22 5.203V5C22 4.448 21.552 4 21 4ZM14 4H8V2H14V4Z"
        fill="currentColor"
      />
    </svg>
  )
}
