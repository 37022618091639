import React from 'react'

export const Appointments = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.966 3H21C21.552 3 22 3.448 22 4V6H2V4C2 3.448 2.448 3 3 3H3.034C3.568 3 4 2.568 4 2.034V2C4 1.426 4.484 0.964005 5.066 1.002C5.598 1.036 6 1.501 6 2.034C6 2.568 6.432 3 6.966 3H17.034C17.568 3 18 2.568 18 2.034V2C18 1.426 18.484 0.964005 19.067 1.002C19.598 1.036 20 1.501 20 2.034C20 2.568 20.432 3 20.966 3ZM2 19C2 20.657 3.343 22 5 22H19C20.657 22 22 20.657 22 19V7H2V19ZM16 13C17.657 13 19 14.343 19 16C19 17.657 17.657 19 16 19C14.343 19 13 17.657 13 16C13 14.343 14.343 13 16 13ZM16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
        fill="currentColor"
      />
    </svg>
  )
}
