import { default as dnaTheme } from './dna'
export { default as dnaTheme } from './dna'
import { default as telusTheme } from './telus'
export { default as telusTheme } from './telus'
import { default as bupaTheme } from './bupa'
export { default as bupaTheme } from './bupa'
import { default as legacyTelusTheme } from './legacy-telus'
export { default as legacyTelusTheme } from './legacy-telus'
import { default as rioTheme } from './rio'
export { default as rioTheme } from './rio'
import { default as rioNeoTheme } from './rioNeo'
export { default as rioNeoTheme } from './rioNeo'
import { default as miamiTheme } from './miami'
export { default as miamiTheme } from './miami'

import { ChakraProvider } from '@chakra-ui/react'
import { bupaIconRegistry } from '../components/Icon/icons/bupa/iconRegistry'
import { IconRegistry } from '../components/Icon/iconRegistry'

export type ThemeType = React.ComponentProps<typeof ChakraProvider>['theme']

const styleDict: Record<string, ThemeType> = {
  DNA: dnaTheme,
  Telus: telusTheme,
  Bupa: bupaTheme,
  'Legacy Telus': legacyTelusTheme,
  Rio: rioTheme,
  RioNeo: rioNeoTheme,
  Miami: miamiTheme,
}

const iconRegistryDict: Map<ThemeType, IconRegistry> = new Map([
  [bupaTheme, bupaIconRegistry],
])

export { styleDict, iconRegistryDict }
