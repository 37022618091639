import React from 'react'

export const Add = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6867 12.6867V24H11.3133V12.6867H0V11.3133H11.3133V0H12.6867V11.3133H24V12.6867H12.6867Z"
        fill="currentColor"
      />
    </svg>
  )
}
