import React from 'react'

export const Unlocked = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 9H19C20.1 9 21 9.9 21 11V20C21 21.1 20.1 22 19 22H5C3.9 22 3 21.1 3 20V11C3 9.9 3.9 9 5 9H7H9H15H17Z"
        fill="currentColor"
      />
      <path
        d="M8 13V6C8 3.79086 9.79086 2 12 2V2V2C14.2091 2 16 3.79086 16 6V6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
