import React from 'react'

export const DownArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7067 10.293C17.3157 9.902 16.6837 9.902 16.2927 10.293L9.99975 16.586L9.99975 1C9.99975 0.448001 9.55175 7.38569e-07 8.99975 7.86827e-07C8.44775 8.35084e-07 7.99975 0.448001 7.99975 1L7.99975 16.586L1.70675 10.293C1.31575 9.902 0.683749 9.902 0.292749 10.293C-0.0982522 10.684 -0.0982521 11.316 0.292749 11.707L8.29275 19.707C8.48775 19.902 8.74375 20 8.99975 20C9.25575 20 9.51175 19.902 9.70675 19.707L17.7067 11.707C18.0977 11.316 18.0977 10.684 17.7067 10.293Z"
        fill="currentColor"
      />
    </svg>
  )
}
