import { getColor, mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

type FocusShadow = (props: StyleFunctionProps) => string

export const focusShadow: FocusShadow = (props) => {
  const innerColor = getColor(
    props.theme,
    mode(
      'light.background.backgroundPrimary',
      'dark.background.backgroundPrimary'
    )(props) as string
  )
  const outerColor = getColor(
    props.theme,
    mode('light.brand.primaryFocus', 'dark.brand.primaryFocus')(props) as string
  )

  return `0 0 0 2px ${innerColor}, 0 0 0 4px ${outerColor}`
}

type ThemeColor = (colorName: string, props: StyleFunctionProps) => string

export const getModeColor: ThemeColor = (colorName, props) => {
  const themeColor = getColor(
    props.theme,
    mode(`light.${colorName}`, `dark.${colorName}`)(props) as string
  )
  return themeColor
}
