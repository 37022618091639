const white = '#FFFFFF'

const black = '#000000'

export const light = {
  brand: {
    primary: '#2B2D42',
    primaryFocus: '#176FD7',
    secondary: '#A833B9',
    tertiary: '',
  },
  text: {
    heading: '#29292E',
    body: '#42424A',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: white,
    textInverted: white,
    caption: '#6A6E81',
  },
  status: {
    success: '#008725',
    warning: '#B68F00',
    error: '#D13400',
    errorFocus: '',
  },
  functional: {
    inactive: '#BBC2C7',
    graphic: '#728492',
    divider: '#D7DADC',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: white,
    graphicInverted: white,
    overlay: 'rgba(0,0,0,0.3)',
  },
  background: {
    backgroundPrimary: white,
    backgroundSecondary: '#F9FBFD',
    backgroundTertiary: '#00A7EB',
    backgroundQuarternary: '#A63AB7',
    backgroundQuinary: '#DB176A',
    bannerNeutral: '#E9EFF5',

    backgroundHighlight: '',
    buttonPurple: '',
    bannerPurple: '',
    bannerRed: '',
    bannerGreen: '',
    bannerOrange: '',
    bannerGrey: '',
    buttonSecondary: '',
    bannerInteractive: '',
    bannerError: '',
    bannerSuccess: '',
    bannerWarning: '',
  },
  shadow: {
    shadow: '',
  },
  data: {
    primaryData: '#A833B9',
    secondaryData: '#0096FA',
  },
  graphics: {
    indigo: '#D7DADC',
    purple: '#A833B9',
    blue: '#3DA5D9',
    yellow: '#EBB517',
    white: 'rgba(255,255,255,255.9)',
    whiteTint: 'rgba(255,255,255,255.3)',
  },
}

const dark: typeof light = {
  brand: {
    primary: '#2290FF',
    primaryFocus: '#8DBCF4',
    secondary: '#BF53CE',
    tertiary: '',
  },
  text: {
    heading: white,
    body: '#D9D9D9',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: white,
    textInverted: '#121212',
    caption: '#A8A7A7',
  },
  status: {
    success: '#00992A',
    warning: '#B68F00',
    error: '#FF4000',
    errorFocus: '',
  },
  functional: {
    inactive: '#2C2C2C',
    graphic: '#777777',
    divider: '#6B6B6B',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: white,
    graphicInverted: '#121212',
    overlay: 'rgba(0,0,0,0.6)',
  },
  background: {
    backgroundPrimary: '#1E1E1E',
    backgroundSecondary: '#121212',
    backgroundTertiary: '#16465F',
    backgroundQuarternary: '#501A55',
    backgroundQuinary: '#820329',
    bannerNeutral: '#282828',

    backgroundHighlight: '',
    buttonPurple: '',
    bannerPurple: '',
    bannerRed: '',
    bannerGreen: '',
    bannerOrange: '',
    bannerGrey: '',
    buttonSecondary: '',
    bannerInteractive: '',
    bannerError: '',
    bannerSuccess: '',
    bannerWarning: '',
  },
  shadow: {
    shadow: '',
  },
  data: {
    primaryData: '#BF53CE',
    secondaryData: '#00DCFA',
  },
  graphics: {
    indigo: '#454A8C',
    purple: '#8A2C98',
    blue: '#4594BB',
    yellow: '#9E7E18',
    white: 'rgba(0,0,0,0.9)',
    whiteTint: 'rgba(0,0,0,0.3)',
  },
}

export const colors = {
  white,
  black,

  light,
  dark,
}
