import React from 'react'

export const Id = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27490)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8 3.59985H1.2C0.88174 3.59985 0.576515 3.72628 0.351472 3.95133C0.126428 4.17637 0 4.48159 0 4.79985V19.1999C0 19.5181 0.126428 19.8233 0.351472 20.0484C0.576515 20.2734 0.88174 20.3999 1.2 20.3999H22.8C23.1183 20.3999 23.4235 20.2734 23.6485 20.0484C23.8736 19.8233 24 19.5181 24 19.1999V4.79985C24 4.48159 23.8736 4.17637 23.6485 3.95133C23.4235 3.72628 23.1183 3.59985 22.8 3.59985ZM1.2 19.1999H22.8V4.79985H1.2V19.1999ZM12 17.9999H13.2V5.99985H12V17.9999ZM2.4 7.19985H7.2V5.99985H2.4V7.19985ZM2.4 9.59985H10.8V8.39985H2.4V9.59985ZM2.4 14.3999H10.8V13.1999H2.4V14.3999ZM2.4 11.9999H9.6V10.7999H2.4V11.9999ZM2.4 17.9999H7.2V16.7999H2.4V17.9999ZM16.8 11.9999H21.6V5.99985H16.8V11.9999ZM18 10.7999H20.4V7.19985H18V10.7999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27490">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
