import { Typography } from '@storybook/theming'

const systemFontStack =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

export const typography: Typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },

  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
  },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    // @ts-expect-error: chakra-typings being odd
    heading: `Poppins, ${systemFontStack}`,
    body: `Poppins, ${systemFontStack}`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
    hyperlegible: `"Atkinson Hyperlegible", ${systemFontStack}`,
  },

  fontSizes: {
    body: '1rem', // 16px
    caption: '0.875rem',
    '3xs': '0.875rem',
    '2xs': '1rem',
    xs: '1.125rem',
    sm: '1.25rem',
    md: '1.75rem',
    lg: '2rem',
    xl: '2.25rem',
    '2xl': '2.5rem',
  },

  textStyles: {
    body: {
      fontFamily: 'body',
      fontSize: 'body',
      fontWeight: 'normal',
      lineHeight: '1.5rem',
    },
    caption: {
      fontFamily: 'body',
      fontSize: 'caption',
      fontWeight: 'normal',
      lineHeight: '1.25rem',
    },
    '3xs': {
      fontFamily: 'body',
      fontSize: '3xs',
      fontWeight: 'medium',
      lineHeight: '1.25rem',
    },
    '2xs': {
      fontFamily: 'body',
      fontSize: '2xs',
      fontWeight: 'semibold',
      lineHeight: '1.5rem',
    },
    xs: {
      fontFamily: 'body',
      fontSize: 'xs',
      fontWeight: 'semibold',
      lineHeight: '1.5rem',
    },
    sm: {
      fontFamily: 'body',
      fontSize: 'sm',
      fontWeight: 'semibold',
      lineHeight: '1.75rem',
    },
    md: {
      fontFamily: 'heading',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: '2rem',
    },
    lg: {
      fontFamily: 'heading',
      fontSize: 'lg',
      fontWeight: 'normal',
      lineHeight: '2.5rem',
    },
    xl: {
      fontFamily: 'heading',
      fontSize: 'xl',
      fontWeight: 'normal',
      lineHeight: '2.5rem',
    },
    '2xl': {
      fontFamily: 'heading',
      fontSize: '2xl',
      fontWeight: 'normal',
      lineHeight: '3rem',
    },
  },
}
