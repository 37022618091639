import React from 'react'

export const Location = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 9C9.897 9 9 9.897 9 11C9 12.103 9.897 13 11 13C12.103 13 13 12.103 13 11C13 9.897 12.103 9 11 9ZM11 5C7.692 5 5 7.692 5 11C5 14.308 7.692 17 11 17C14.308 17 17 14.308 17 11C17 7.691 14.308 5 11 5ZM11 14C9.343 14 8 12.657 8 11C8 9.343 9.343 8 11 8C12.657 8 14 9.343 14 11C14 12.657 12.657 14 11 14ZM11 0.5C5.201 0.5 0.5 5.201 0.5 11C0.5 16.799 5.201 21.5 11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 5.201 16.799 0.5 11 0.5ZM17.975 11.5C17.729 14.966 14.966 17.729 11.5 17.975V19.5H10.5V17.975C7.034 17.73 4.271 14.966 4.025 11.5H2.5V10.5H4.025C4.271 7.034 7.034 4.271 10.5 4.025V2.5H11.5V4.025C14.966 4.271 17.729 7.034 17.975 10.5H19.5V11.5H17.975Z"
        fill="currentColor"
      />
    </svg>
  )
}
