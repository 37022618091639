import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'

const variantPrimary: SystemStyleFunction = (props) => {
  const spinnerColor = mode(
    'light.brand.secondary',
    'dark.brand.secondary'
  )(props)
  return {
    color: spinnerColor,
  }
}

const variantSecondary: SystemStyleFunction = (props) => {
  const spinnerColor = mode('light.brand.primary', 'dark.brand.primary')(props)
  return {
    color: spinnerColor,
  }
}

const variantTertiary: SystemStyleFunction = (props) => {
  const spinnerColor = mode(
    'light.text.textInverted',
    'dark.text.textInverted'
  )(props)
  return {
    color: spinnerColor,
  }
}

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
}

export const Spinner = {
  variants,
}
