import React, { forwardRef } from 'react'
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  ButtonOptions,
} from '@chakra-ui/react'
import type { StyleProps } from '../../core/style-props'
import { Spinner } from '../Spinner'

export type ButtonProps = Pick<
  ChakraButtonProps,
  keyof ButtonOptions | 'children' | 'type' | 'onClick' | 'name'
> & { variant?: 'primary' | 'secondary' | 'tertiary' } & StyleProps

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, ...props }, ref) => {
    const spinnerVariant =
      variant === 'secondary' || variant === 'tertiary'
        ? 'secondary'
        : 'tertiary'

    return (
      <ChakraButton
        loadingText={props.children as unknown as string}
        spinnerPlacement="end"
        spinner={<Spinner variant={spinnerVariant} />}
        {...props}
        ref={ref}
        variant={variant}
      />
    )
  }
)

Button.displayName = 'Button'
