import React from 'react'

export const Workout = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.6218 8.02801C16.3328 7.70301 16.2788 7.23301 16.4958 6.85601L16.8948 6.16401C17.4658 5.17301 17.5558 3.99301 17.1398 2.92701C16.7318 1.88001 15.8868 1.08701 14.8218 0.752014C11.6428 -0.247986 8.15077 -0.247986 4.97177 0.752014C3.90677 1.08701 3.06177 1.88001 2.65377 2.92801C2.23877 3.99501 2.32877 5.17501 2.89977 6.16501L3.29977 6.85601C3.51377 7.22601 3.47177 7.69501 3.18677 8.01301C1.79777 9.56701 0.937768 11.603 0.899768 13.842C0.814768 18.8 4.81477 22.931 9.77377 22.998C14.8018 23.066 18.8978 19.011 18.8978 13.999C18.8978 11.706 18.0358 9.61701 16.6218 8.02801ZM14.8478 9.75801C14.7498 9.85601 14.6218 9.90501 14.4938 9.90501C14.3658 9.90501 14.2378 9.85601 14.1398 9.75801C13.0068 8.62401 11.5008 8.00001 9.89777 8.00001C8.29577 8.00001 6.78877 8.62401 5.65477 9.75801C5.45977 9.95301 5.14277 9.95301 4.94777 9.75801C4.75277 9.56301 4.75277 9.24601 4.94777 9.05101C6.27077 7.72801 8.02777 7.00001 9.89777 7.00001C11.7678 7.00001 13.5258 7.72801 14.8478 9.05101C15.0428 9.24601 15.0428 9.56201 14.8478 9.75801ZM15.1638 5.16501L14.9998 5.44901C14.7378 5.90301 14.1728 6.07201 13.6988 5.84901C12.5438 5.30701 11.2578 5.00001 9.89777 5.00001C8.53777 5.00001 7.25177 5.30701 6.09777 5.85001C5.62377 6.07301 5.05877 5.90301 4.79677 5.45001L4.63277 5.16501C4.36577 4.70301 4.32377 4.15201 4.51877 3.65401C4.70577 3.17401 5.08977 2.81201 5.57277 2.66101C8.36377 1.78401 11.4338 1.78401 14.2238 2.66101C14.7068 2.81201 15.0908 3.17501 15.2778 3.65401C15.4718 4.15201 15.4298 4.70301 15.1638 5.16501Z"
        fill="currentColor"
      />
    </svg>
  )
}
