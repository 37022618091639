import React from 'react'

export const ExternalLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.9 22H5.1C3.4 22 2 20.6 2 18.9V7.1C2 5.4 3.4 4 5.1 4H11.5C12.1 4 12.5 4.4 12.5 5C12.5 5.6 12.1 6 11.5 6H5.1C4.5 6 4 6.5 4 7.1V18.8C4 19.5 4.5 20 5.1 20H16.8C17.4 20 17.9 19.5 17.9 18.9V12.5C17.9 11.9 18.3 11.5 18.9 11.5C19.5 11.5 19.9 11.9 19.9 12.5V18.9C20 20.6 18.6 22 16.9 22Z"
        fill="currentColor"
      />
      <path
        d="M22 3C22 2.9 22 2.7 21.9 2.6C21.8 2.4 21.6 2.2 21.4 2.1C21.3 2 21.1 2 21 2H14.6C14 2 13.6 2.4 13.6 3C13.6 3.6 14 4 14.6 4H18.6L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.8 15 10 15C10.2 15 10.5 14.9 10.7 14.7L20 5.4V9.4C20 10 20.4 10.4 21 10.4C21.6 10.4 22 10 22 9.4V3Z"
        fill="currentColor"
      />
    </svg>
  )
}
