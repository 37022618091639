import React from 'react'

export const Help = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0C5.38327 0 0 5.38327 0 12C0 18.6167 5.38327 24 12 24C18.6167 24 24 18.6167 24 12C24 5.38327 18.6167 0 12 0ZM12 22.3653C6.28606 22.3653 1.63468 17.7176 1.63468 12C1.63468 6.28606 6.28234 1.63468 12 1.63468C17.7176 1.63468 22.3653 6.28234 22.3653 12C22.3653 17.7176 17.7176 22.3653 12 22.3653ZM7.84643 8.87554C7.92817 6.28607 9.53685 4.81856 12.0669 4.81856C14.5115 4.81856 16.1424 6 16.1424 8.22167C16.1424 11.4427 12.9214 11.5839 12.9214 14.3368H11.1269C11.1269 11.0564 13.9616 11.034 13.9616 8.56718C13.9616 7.38575 13.1257 6.81363 11.8625 6.81363C10.5362 6.81363 9.74117 7.50836 9.68173 8.89411L7.84643 8.87554ZM13.2892 17.8031C13.2892 18.5981 12.7393 19.1703 12.0037 19.1703C11.2681 19.1703 10.7183 18.5981 10.7183 17.8031C10.7183 17.0303 11.2681 16.4768 12.0037 16.4768C12.7356 16.4805 13.2892 17.0303 13.2892 17.8031Z"
        fill="currentColor"
      />
    </svg>
  )
}
