import React from 'react'

export const Information = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0C5.38328 0 0 5.38328 0 12C0 18.6167 5.38328 24 12 24C18.6167 24 24 18.6167 24 12C24 5.38328 18.6167 0 12 0ZM12 22.3653C6.28607 22.3653 1.63468 17.7139 1.63468 12C1.63468 6.28607 6.28235 1.63466 12 1.63466C17.7139 1.63466 22.3653 6.28607 22.3653 12C22.3653 17.7139 17.7176 22.3653 12 22.3653ZM12.3158 4.59567C12.0074 4.91517 11.8588 5.29783 11.8588 5.7548C11.8588 6.21176 12.0112 6.59814 12.3195 6.91393C12.6279 7.23343 12.9994 7.39318 13.4378 7.39318C13.8762 7.39318 14.2514 7.22972 14.5598 6.91393C14.8644 6.59442 15.0242 6.21176 15.0242 5.7548C15.0242 5.29783 14.8681 4.91146 14.5635 4.59567C14.2551 4.27245 13.8799 4.11642 13.4378 4.11642C12.9957 4.11642 12.6242 4.27245 12.3158 4.59567ZM13.3077 8.85696L9.74491 9.58514V9.95294C9.89723 9.94179 10.0198 9.93808 10.1313 9.93808C10.3691 9.93808 10.5548 10.0012 10.6923 10.1276C10.826 10.2502 10.8966 10.395 10.8966 10.5548C10.8966 10.8149 10.8223 11.1975 10.6774 11.6954L9.16535 16.956C9.03531 17.3796 8.97959 17.7214 8.97959 17.9889C8.97959 18.4124 9.1059 18.7542 9.37339 19.0254C9.63346 19.2929 9.9864 19.4303 10.4396 19.4303C11.6062 19.4303 12.7022 18.4161 13.735 16.3839L13.3821 16.1498C12.9474 16.9003 12.5833 17.4167 12.2749 17.6991C12.1561 17.818 12.0372 17.8737 11.922 17.8737C11.8551 17.8737 11.7882 17.8403 11.7325 17.766C11.6656 17.6954 11.6359 17.6136 11.6359 17.5282C11.6359 17.3907 11.6879 17.0935 11.8105 16.6514L13.9356 8.85696H13.3077Z"
        fill="currentColor"
      />
    </svg>
  )
}
