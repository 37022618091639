import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import { focusShadow, getModeColor } from '../utils'

const baseStyleControl: SystemStyleFunction = (props) => ({
  w: '100%',
  transitionProperty: 'box-shadow',
  transitionDuration: 'normal',
  border: '1px solid',
  borderColor: mode(
    'light.functional.graphic',
    'dark.functional.graphic'
  )(props),
  borderRadius: 'base',
  color: 'white',
  _checked: {
    bg: mode('light.brand.primary', 'dark.brand.primary')(props),
    borderColor: mode('light.brand.primary', 'dark.brand.primary')(props),
  },

  _focus: {
    boxShadow: focusShadow(props),
    borderColor: mode('light.brand.primary', 'dark.brand.primary')(props),
    outline: 0,
  },
})

const baseStyleLabel: SystemStyleFunction = (props) => ({
  userSelect: 'none',
  ...props.theme.textStyles['caption'],
  color: mode('light.text.body', 'dark.text.body')(props),
  width: '100%',
})

const baseStyleIcon: SystemStyleObject = {
  transitionProperty: 'transform',
  transitionDuration: 'normal',
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  const backgroundPrimary = getModeColor('background.backgroundPrimary', props)

  return {
    icon: baseStyleIcon,
    control: { ...baseStyleControl(props), backgroundColor: backgroundPrimary },
    label: baseStyleLabel(props),
    container: {
      alignItems: 'flex-start',
      width: '100%',
    },
    formError: {
      marginBottom: 2,
    },
    hintText: {
      marginTop: 1,
      color: mode('light.text.body', 'dark.text.body')(props),
      display: 'block',
    },
    content: {
      marginTop: 3,
      marginLeft: 9,
    },
    groupContainer: {
      padding: 4,
      border: '0.5px solid',
      backgroundColor: backgroundPrimary,
      borderColor: mode(
        'light.functional.graphic',
        'dark.functional.graphic'
      )(props),
      borderRadius: 'base',
    },
  }
}

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    control: { w: 6, h: 6 },
    label: { fontSize: 'body', marginLeft: 3 },
    icon: { fontSize: '0.625rem' },
  },
}

const defaultProps = {
  size: 'md',
}

export const Checkbox = {
  parts: [...parts.keys, 'hintText', 'formError', 'content', 'groupContainer'],
  baseStyle,
  sizes,
  defaultProps,
}
