import React from 'react'

export const Forward = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66299 19.7158L10.7151 10.6869C11.095 10.307 11.095 9.69201 10.7151 9.31309L1.66299 0.284188C1.2831 -0.0947292 0.666512 -0.0947292 0.285648 0.284188C-0.095216 0.663104 -0.095216 1.27812 0.285648 1.658L8.64907 10L0.285648 18.342C-0.095216 18.7209 -0.095216 19.3369 0.285648 19.7158C0.666512 20.0947 1.2831 20.0947 1.66299 19.7158Z"
        fill="currentColor"
      />
    </svg>
  )
}
