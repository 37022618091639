import { extendTheme, ThemeConfig, theme as BupaTheme } from '@chakra-ui/react'
import dnaTheme, { dnaConfig } from '../dna'

import * as components from './components'

import { foundations } from './foundations'

const config: ThemeConfig = {
  ...dnaConfig,
  cssVarPrefix: 'bupa',
}

const overrides = {
  ...BupaTheme,
  ...foundations,
  components: {
    ...BupaTheme.components,
    ...components,
  },
  config,
}

export default extendTheme(overrides, dnaTheme)
