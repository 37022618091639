import React from 'react'

export const RecreationalDrugs = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.567 2.323C8.143 2.323 7.713 2.431 7.319 2.658C6.272 3.262 5.828 4.51 6.198 5.614L10.425 3.173C9.944 2.632 9.269 2.323 8.567 2.323ZM7.819 12.658C7.425 12.431 6.996 12.323 6.572 12.323C5.87 12.323 5.194 12.632 4.713 13.174L8.94 15.615C9.31 14.51 8.866 13.262 7.819 12.658ZM5.319 16.987C5.713 17.214 6.143 17.322 6.567 17.322C7.269 17.322 7.944 17.013 8.425 16.471L4.198 14.03C3.828 15.134 4.272 16.382 5.319 16.987ZM6.713 6.471C7.194 7.013 7.869 7.322 8.572 7.322C8.996 7.322 9.426 7.214 9.819 6.987C10.866 6.382 11.31 5.134 10.94 4.03L6.713 6.471ZM13.319 12.987C13.713 13.214 14.143 13.322 14.567 13.322C15.269 13.322 15.945 13.013 16.426 12.471L12.199 10.03C11.828 11.134 12.272 12.382 13.319 12.987ZM18 0H2C0.895 0 0 0.895 0 2V18C0 19.104 0.895 20 2 20H18C19.104 20 20 19.104 20 18V2C20 0.895 19.104 0 18 0ZM9.6 16.572C8.977 17.652 7.815 18.322 6.567 18.322C5.955 18.322 5.351 18.16 4.819 17.853C4.009 17.386 3.43 16.631 3.188 15.728C2.946 14.825 3.07 13.882 3.538 13.072C4.161 11.992 5.323 11.322 6.571 11.322C7.182 11.322 7.787 11.484 8.318 11.791C9.128 12.258 9.707 13.013 9.948 13.916C10.191 14.819 10.067 15.763 9.6 16.572ZM10.319 7.853C9.787 8.16 9.183 8.322 8.572 8.322C7.324 8.322 6.162 7.652 5.539 6.572C5.071 5.762 4.947 4.819 5.189 3.917C5.431 3.014 6.01 2.259 6.819 1.792C7.351 1.485 7.955 1.323 8.567 1.323C9.814 1.323 10.977 1.993 11.6 3.073C12.067 3.882 12.192 4.826 11.95 5.728C11.707 6.631 11.128 7.386 10.319 7.853ZM17.6 12.572C16.977 13.652 15.815 14.322 14.567 14.322C13.955 14.322 13.351 14.16 12.819 13.853C12.009 13.386 11.43 12.631 11.188 11.728C10.946 10.825 11.07 9.882 11.538 9.073C12.161 7.993 13.323 7.323 14.571 7.323C15.182 7.323 15.787 7.485 16.319 7.792C17.129 8.259 17.708 9.014 17.95 9.917C18.191 10.819 18.067 11.763 17.6 12.572ZM15.819 8.658C15.425 8.431 14.995 8.323 14.571 8.323C13.869 8.323 13.193 8.632 12.712 9.174L16.939 11.615C17.31 10.51 16.866 9.262 15.819 8.658Z"
        fill="currentColor"
      />
    </svg>
  )
}
