import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'
import { focusShadow } from '../utils'

const baseStyle: SystemStyleFunction = (props) => {
  const boxShadow = focusShadow(props)
  const bg = mode(
    'light.background.buttonPurple',
    'dark.background.buttonPurple'
  )(props)
  const color = mode('light.brand.secondary', 'dark.brand.secondary')(props)
  const disabledColor = mode(
    'light.text.textInverted',
    'light.text.textInverted'
  )(props)
  const inactive = mode(
    'light.functional.inactive',
    'dark.functional.inactive'
  )(props)

  return {
    bg,
    color: color,
    minWidth: 10,
    px: 4,
    height: '48px',
    lineHeight: '1.2',
    borderRadius: '100%',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    transition: 'none',
    boxShadow: mode('buttonOutlineLight', 'buttonOutlineDark')(props),
    border: 'none',
    _disabled: {
      opacity: 1.0,
      cursor: 'not-allowed',
      boxShadow: 'none',
      bg: inactive,
      color: disabledColor,
    },
    _hover: {
      boxShadow,
      _disabled: {
        boxShadow: 'none',
      },
    },
    _pressed: {
      boxShadow,
    },
    _focus: {
      boxShadow,
    },
  }
}

export const CloseButton = {
  baseStyle,
}
