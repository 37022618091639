import React from 'react'

export const Fluid = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 16 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0498 11.216L9.73183 1.99603C8.96283 0.663027 7.03783 0.663027 6.26783 1.99603L0.949833 11.216C0.125833 12.748 -0.214167 14.579 0.136833 16.505C0.714833 19.668 3.24383 22.234 6.40083 22.845C11.5208 23.835 15.9998 19.941 15.9998 15C15.9998 13.631 15.6558 12.343 15.0498 11.216ZM7.99983 21C4.69083 21 1.99983 18.309 1.99983 15C1.99983 14.724 2.22383 14.5 2.49983 14.5C2.77583 14.5 2.99983 14.723 2.99983 15C2.99983 17.757 5.24283 20 7.99983 20C8.27583 20 8.49983 20.224 8.49983 20.5C8.49983 20.776 8.27583 21 7.99983 21Z"
        fill="currentColor"
      />
    </svg>
  )
}
