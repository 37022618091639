import React from 'react'

export const NearTarget = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      y={-0.89}
      width={16}
      height={24}
      viewBox="0 0 16 24"
      fill="none"
      {...props}
    >
      <path
        y={-0.89}
        d="M15.893 18.038L8.698 5.404A.794.794 0 008 5a.807.807 0 00-.698.404L.107 18.038a.782.782 0 00.295 1.08c.122.069.26.105.4.104h14.391a.808.808 0 00.698-.392.782.782 0 00.002-.792z"
        fill="#FBEACF"
      />
      <path
        y={-0.89}
        d="M8.19 9h-.552v5.069h.552V9zM7.684 16.93a.414.414 0 10.46-.689.414.414 0 00-.46.69z"
        fill="#C85204"
      />
      <path
        y={-0.89}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.388 8.75H8.44v5.569H7.388V8.75zm.526 8.5a.663.663 0 110-1.327.663.663 0 010 1.327zM8.19 9v5.069h-.552V9h.552zm-.276 8a.414.414 0 110-.829.414.414 0 010 .829z"
        fill="#C85204"
      />
      <path
        y={-0.89}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.138 8.5H8.69v6.069H7.138V8.5zm1.245 8.556l.177.176a.914.914 0 11-.297-1.49l-.095.23a.664.664 0 10.215 1.084zm0 0l.177.176a.914.914 0 00-.297-1.49l-.095.23a.665.665 0 01.215 1.084zm-.7-.126a.414.414 0 10.46-.689.414.414 0 00-.46.69zm.14-.208a.164.164 0 10.182-.272.164.164 0 00-.182.272zM7.94 9.25h-.052v4.569h.052V9.25zm-.552-.5v5.569H8.44V8.75H7.388zM8.19 9h-.552v5.069h.552V9z"
        fill="#C85204"
      />
    </svg>
  )
}
