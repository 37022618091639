import React from 'react'

export const SmartWatch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27960)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2 6.59988C19.1983 6.06914 19.0207 5.55395 18.695 5.13489C18.3693 4.71584 17.9139 4.41656 17.4 4.28388L16.476 0.827878C16.4255 0.610753 16.3089 0.414607 16.1424 0.266358C15.9759 0.11811 15.7676 0.0250247 15.546 -0.00012207H8.45405C8.23253 0.0250247 8.02422 0.11811 7.85771 0.266358C7.6912 0.414607 7.57465 0.610753 7.52405 0.827878L6.60005 4.28388C6.08616 4.41656 5.63075 4.71584 5.30506 5.13489C4.97937 5.55395 4.80177 6.06914 4.80005 6.59988V17.3999C4.80177 17.9306 4.97937 18.4458 5.30506 18.8649C5.63075 19.2839 6.08616 19.5832 6.60005 19.7159L7.52405 23.1719C7.57465 23.389 7.6912 23.5851 7.85771 23.7334C8.02422 23.8816 8.23253 23.9747 8.45405 23.9999H15.546C15.7676 23.9747 15.9759 23.8816 16.1424 23.7334C16.3089 23.5851 16.4255 23.389 16.476 23.1719L17.4 19.7159C17.9139 19.5832 18.3693 19.2839 18.695 18.8649C19.0207 18.4458 19.1983 17.9306 19.2 17.3999V6.59988ZM8.67005 1.19988H15.33L16.134 4.19988H7.86605L8.67005 1.19988ZM15.33 22.7999H8.67005L7.86605 19.7999H16.134L15.33 22.7999ZM18 8.99988V17.3999C18 17.7181 17.8736 18.0234 17.6486 18.2484C17.4235 18.4734 17.1183 18.5999 16.8 18.5999H7.20005C6.88179 18.5999 6.57656 18.4734 6.35152 18.2484C6.12648 18.0234 6.00005 17.7181 6.00005 17.3999V6.59988C6.00005 6.28162 6.12648 5.97639 6.35152 5.75135C6.57656 5.52631 6.88179 5.39988 7.20005 5.39988H16.8C17.1183 5.39988 17.4235 5.52631 17.6486 5.75135C17.8736 5.97639 18 6.28162 18 6.59988V8.99988Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27960">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
