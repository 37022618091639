import React from 'react'

export const Notes = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 2V6H21L17 2ZM16 2H4C3.448 2 3 2.448 3 3V21C3 21.552 3.448 22 4 22H20C20.552 22 21 21.552 21 21V7H16V2ZM13 18H6V17H13V18ZM18 14H6V13H18V14ZM18 9V10H6V9H18Z"
        fill="currentColor"
      />
    </svg>
  )
}
