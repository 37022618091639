import React from 'react'

export const Down = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_1893)">
        <path
          d="M11.3478 15.8283L11.987 16.474L12.6522 15.8283L23.0804 5.40007L24 6.32615L12.013 18.3131L12 18.3001L11.987 18.3131L1.04793e-06 6.32615L0.926089 5.40007L11.3478 15.8283Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_1893">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
