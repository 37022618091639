import React from 'react'

export const Secure = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 9H17V6C17 3.239 14.762 1 12 1C9.238 1 7 3.239 7 6V9H5C3.9 9 3 9.9 3 11V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V11C21 9.9 20.1 9 19 9ZM9 6C9 4.346 10.346 3 12 3C13.654 3 15 4.346 15 6V9H9V6Z"
        fill="currentColor"
      />
    </svg>
  )
}
