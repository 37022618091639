import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'
import { focusShadow } from '../utils'

const baseStyle: SystemStyleFunction = (props) => {
  const boxShadow = focusShadow(props)
  return {
    ...props.theme.textStyles['2xs'],
    minWidth: 12,
    px: 4,
    height: '48px',
    lineHeight: '1.2',
    borderRadius: 'full',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    transition: 'none',
    boxShadow: mode('buttonOutlineLight', 'buttonOutlineDark')(props),
    border: 'none',
    _loading: {
      _disabled: {
        boxShadow: 'none',
        textDecoration: 'none',
      },
    },
    _disabled: {
      opacity: 1.0,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _hover: {
      boxShadow: ['none', 'none', boxShadow],
      _disabled: {
        boxShadow: 'none',
      },
    },
    _pressed: {
      boxShadow,
    },
    _focus: {
      boxShadow,
    },
    _active: {
      boxShadow,
    },
  }
}

const variantPrimary: SystemStyleFunction = (props) => {
  const bg = mode('light.brand.primary', 'dark.brand.primary')(props)
  const textColor = mode(
    'light.text.textInverted',
    'dark.text.textInverted'
  )(props)
  const inactive = mode(
    'light.functional.inactive',
    'dark.functional.inactive'
  )(props)

  return {
    bg,
    color: textColor,
    _disabled: {
      bg: inactive,
    },
    _loading: {
      _disabled: {
        bg,
      },
    },
  }
}

const variantSecondary: SystemStyleFunction = (props) => {
  const bg = mode(
    'light.background.buttonPurple',
    'dark.background.buttonPurple'
  )(props)
  const textColor = mode('light.brand.primary', 'dark.brand.primary')(props)
  const inactive = mode(
    'light.functional.inactive',
    'dark.functional.inactive'
  )(props)

  return {
    bg,
    color: textColor,
    _disabled: {
      bg: inactive,
      color: mode('light.text.textInverted', 'light.text.textInverted')(props),
    },
    _loading: {
      _disabled: {
        bg,
        color: textColor,
      },
    },
  }
}

const variantTertiary: SystemStyleFunction = (props) => {
  const textColor = mode('light.brand.primary', 'dark.brand.primary')(props)
  const inactive = mode(
    'light.functional.inactive',
    'dark.functional.inactive'
  )(props)

  return {
    bg: 'transparent',
    textDecoration: 'underline',
    color: textColor,
    _disabled: {
      color: inactive,
      textDecoration: 'none',
    },
    _loading: {
      _disabled: {
        color: textColor,
      },
    },
  }
}

const variantInputIcon: SystemStyleFunction = (props) => {
  const textColor = mode(
    'light.functional.graphic',
    'dark.functional.graphic'
  )(props)
  const inactive = mode(
    'light.functional.inactive',
    'dark.functional.inactive'
  )(props)

  return {
    bg: 'transparent',
    textDecoration: 'underline',
    height: '40px',
    width: '40px',
    minWidth: '40px',
    minHeight: '40px',
    borderRadius: 'md',
    color: textColor,
    _hover: {
      color: mode('light.brand.primary', 'dark.brand.primary')(props),
    },
    _disabled: {
      color: inactive,
      textDecoration: 'none',
    },
    _pressed: {
      boxShadow: 'none',
      color: mode('light.brand.primary', 'dark.brand.primary')(props),
    },
    _focus: {
      color: mode('light.brand.primary', 'dark.brand.primary')(props),
    },
    _active: {
      boxShadow: 'none',
      color: mode('light.brand.primary', 'dark.brand.primary')(props),
    },
    _loading: {
      _disabled: {
        color: textColor,
      },
    },
  }
}

const variantAutoSuggest: SystemStyleFunction = (props) => {
  const textColor = mode(
    'light.functional.graphic',
    'dark.functional.graphic'
  )(props)
  const inactive = mode(
    'light.functional.inactive',
    'dark.functional.inactive'
  )(props)

  return {
    bg: 'transparent',
    textDecoration: 'underline',
    height: '40px',
    width: '40px',
    minWidth: '40px',
    minHeight: '40px',
    borderRadius: 'md',
    boxShadow: 'none !important',
    color: mode('light.brand.secondary', 'dark.brand.secondary')(props),
    _disabled: {
      color: inactive,
      textDecoration: 'none',
    },
    _pressed: {
      color: mode('light.brand.secondary', 'dark.brand.secondary')(props),
    },
    _focus: {
      outline: `1px solid`,
      color: mode('light.brand.secondary', 'dark.brand.secondary')(props),
    },
    _active: {
      color: mode('light.brand.secondary', 'dark.brand.secondary')(props),
    },
    _loading: {
      _disabled: {
        color: textColor,
      },
    },
  }
}

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
  'input-icon': variantInputIcon,
  'autosuggest-clear': variantAutoSuggest,
}

const defaultProps = {
  variant: 'primary',
}

export const Button = {
  baseStyle,
  variants,
  defaultProps,
}
