import React from 'react'

export const Report = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 2H14C14 0.895 13.104 0 12 0C10.896 0 10 0.895 10 2H7V5H17V2ZM18 2V6H6V2H4C3.448 2 3 2.448 3 3V21C3 21.552 3.448 22 4 22H20C20.552 22 21 21.552 21 21V3C21 2.448 20.552 2 20 2H18ZM13 18H6V17H13V18ZM18 14H6V13H18V14ZM18 10H6V9H18V10Z"
        fill="currentColor"
      />
    </svg>
  )
}
