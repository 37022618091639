import React from 'react'

export const Urine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.45 2 5 2.45 5 3V5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5V3C19 2.45 18.55 2 18 2H6ZM7 7C6.45 7 6 7.45 6 8V12H9V13H6V15H9V16H6V18H9V19H6V21C6 21.55 6.45 22 7 22H17C17.55 22 18 21.55 18 21V19H13V18V10V9H18V8C18 7.45 17.55 7 17 7H7ZM18 10V18H14V10H18Z"
        fill="currentColor"
      />
    </svg>
  )
}
