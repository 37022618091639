import React from 'react'

export const Unlocked = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_203_1217)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4183 19.3201C13.4183 20.2256 12.8728 20.7274 11.7819 20.7274C10.691 20.7274 10.1456 20.2365 10.1456 19.3201V17.7819C10.1456 16.871 10.691 16.3638 11.7819 16.3638C12.8728 16.3638 13.4183 16.871 13.4183 17.7819V19.3201ZM11.2365 17.8256V19.2765C11.2365 19.4783 11.4819 19.6419 11.7819 19.6419C12.0819 19.6419 12.3274 19.4783 12.3274 19.2765V17.8256C12.3274 17.6238 12.0819 17.4601 11.7819 17.4601C11.4819 17.4601 11.2365 17.6238 11.2365 17.8256ZM4.69101 22.9092H18.8728V13.091H4.69101V22.9092ZM11.7819 1.09103C11.0638 1.08448 10.3516 1.22109 9.68683 1.49289C9.02209 1.76468 8.41818 2.16622 7.91037 2.67403C7.40255 3.18184 7.00102 3.78575 6.72923 4.45049C6.45743 5.11522 6.32082 5.82745 6.32737 6.54558V7.63649H7.41828V6.54558C7.38505 5.96383 7.47515 5.38164 7.68269 4.83716C7.89022 4.29268 8.21051 3.79822 8.62253 3.38619C9.03455 2.97417 9.52902 2.65388 10.0735 2.44635C10.618 2.23881 11.2002 2.14871 11.7819 2.18194C12.3637 2.14871 12.9459 2.23881 13.4903 2.44635C14.0348 2.65388 14.5293 2.97417 14.9413 3.38619C15.3533 3.79822 15.6736 4.29268 15.8811 4.83716C16.0887 5.38164 16.1788 5.96383 16.1456 6.54558V12.0001H17.2365V6.54558C17.243 5.82745 17.1064 5.11522 16.8346 4.45049C16.5628 3.78575 16.1613 3.18184 15.6535 2.67403C15.1457 2.16622 14.5417 1.76468 13.877 1.49289C13.2123 1.22109 12.5 1.08448 11.7819 1.09103ZM3.6001 12.0001H15.0546V6.54558C15.0546 5.6776 14.7098 4.84516 14.0961 4.23141C13.4823 3.61765 12.6499 3.27285 11.7819 3.27285C10.9139 3.27285 10.0815 3.61765 9.46775 4.23141C8.85399 4.84516 8.50919 5.6776 8.50919 6.54558V8.7274H5.23646V6.54558C5.23646 4.80961 5.92607 3.14475 7.15358 1.91724C8.38109 0.68973 10.046 0.00012207 11.7819 0.00012207C13.5179 0.00012207 15.1827 0.68973 16.4103 1.91724C17.6378 3.14475 18.3274 4.80961 18.3274 6.54558V12.0001H19.9637V24.0001H3.6001V12.0001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_203_1217">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
