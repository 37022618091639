import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export const NearTarget = (props: React.SVGProps<SVGSVGElement>) => {
  const pathColor = useColorModeValue('#B25100', '#E56800')
  const bgColor = useColorModeValue('#FFF2E8', '#211D1A')

  return (
    <svg viewBox="0 0 16 24" fill="none" {...props}>
      <rect y="4" width={16} height={16} rx={8} fill={bgColor} />
      <path
        d="M8 8V13"
        stroke={pathColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke={pathColor}
        d="M8 16H8.01"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
