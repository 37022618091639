import React from 'react'

export const Back = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.33774 19.7158L0.285648 10.6869C-0.095216 10.307 -0.095216 9.69201 0.285648 9.31309L9.33774 0.284188C9.71763 -0.0947292 10.3352 -0.0947292 10.7151 0.284188C11.095 0.663104 11.095 1.27909 10.7151 1.658L2.35166 10L10.7151 18.342C11.095 18.7219 11.095 19.3369 10.7151 19.7158C10.3352 20.0947 9.71763 20.0947 9.33774 19.7158Z"
        fill="currentColor"
      />
    </svg>
  )
}
