import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'
export const RAGNearTarget = (props: React.SVGProps<SVGSVGElement>) => {
  const textColor = useColorModeValue('#FFFFFF', '#121212')
  const bgColor = useColorModeValue('#B25100', '#E56800')

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle fill={bgColor} cx="12" cy="12.0002" r="12" />
      <mask
        id="mask0_3374_8141"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <circle cx="12" cy="12" r="12" fill={bgColor} />
      </mask>
      <g mask="url(#mask0_3374_8141)">
        <path
          opacity="0.1"
          d="M39.769 27.8246V3.72612C39.769 2.18312 38.8816 0.777709 37.4884 0.114482L19.769 -8.3208L-4.23096 32.1055L14.261 40.9085C15.3514 41.4276 16.618 41.4262 17.7073 40.9048L37.4961 31.4325C38.8851 30.7676 39.769 29.3645 39.769 27.8246Z"
          fill="#32383D"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9956 8.5481C12.9956 7.99581 12.5479 7.5481 11.9956 7.5481C11.4433 7.5481 10.9956 7.99581 10.9956 8.5481V12.8634C10.9956 13.4157 11.4433 13.8634 11.9956 13.8634C12.5479 13.8634 12.9956 13.4157 12.9956 12.8634V8.5481ZM11.9956 14.4524C11.4433 14.4524 10.9956 14.9001 10.9956 15.4524C10.9956 16.0047 11.4433 16.4524 11.9956 16.4524H12.0042C12.5565 16.4524 13.0042 16.0047 13.0042 15.4524C13.0042 14.9001 12.5565 14.4524 12.0042 14.4524H11.9956Z"
        fill="white"
      />
      <circle stroke={textColor} cx="12" cy="12.0002" r="10" />
    </svg>
  )
}
