import React from 'react'

export const DownArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3854 21.7698V0H12.5561V21.7698L21.9103 12.4098L22.7415 13.2351L11.9766 24L11.9707 23.9883L11.9649 24L1.20001 13.2351L2.02538 12.4098L11.3854 21.7698Z"
        fill="currentColor"
      />
    </svg>
  )
}
