import React, { FC, createContext } from 'react'
import { iconRegistry } from '../components/Icon/iconRegistry'

type IconProviderProps = {
  icons: typeof iconRegistry
  children: React.ReactChild
}

export const IconContext = createContext({
  icons: iconRegistry,
})

export const IconProvider: FC<IconProviderProps> = ({ icons, children }) => {
  return (
    <IconContext.Provider value={{ icons }}>{children}</IconContext.Provider>
  )
}
