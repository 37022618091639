import React from 'react'

export const Music = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.9999 4.52683V18.0367C4.3629 17.8923 3.6319 17.9176 2.8849 18.1629C0.9819 18.7868 -0.2841 20.5495 0.0548998 22.1011C0.3949 23.6528 2.2129 24.405 4.1149 23.781C5.8679 23.2066 7.0649 21.665 6.9789 20.2123H6.9999V9.92593L17.9999 6.95077V15.5138C17.3629 15.3684 16.6319 15.3947 15.8849 15.64C13.9819 16.2639 12.7149 18.0266 13.0549 19.5783C13.3949 21.13 15.2129 21.8821 17.1149 21.2582C18.8679 20.6837 20.0649 19.1421 19.9789 17.6894H19.9999V1.01056C19.9999 0.347279 19.3759 -0.136297 18.7409 0.0353269L5.7409 3.5516C5.3039 3.66972 4.9999 4.06951 4.9999 4.52683Z"
        fill="currentColor"
      />
    </svg>
  )
}
