import { tagAnatomy as parts } from '@chakra-ui/anatomy'
import {
  mode,
  PartsStyleFunction,
  PartsStyleInterpolation,
} from '@chakra-ui/theme-tools'
import type {
  SystemStyleFunction,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools'

import { dnaTheme } from '../..'

const baseStyleContainer: SystemStyleFunction = (props: StyleFunctionProps) => {
  // const boxShadow = focusShadow(props)
  const baseContainer = dnaTheme.components.Chip.baseStyle(props).container
  const background = mode(
    'light.background.backgroundSecondary',
    'dark.background.backgroundSecondary'
  )(props)
  return {
    ...baseContainer,
    _focus: {
      bg: background,
    },
    _hover: {
      bg: background,
    },
    _active: {
      bg: background,
      color: mode('light.brand.primary', 'dark.brand.primary')(props),
      borderColor: mode('light.brand.primary', 'dark.brand.primary')(props),
    },
    color: mode('light.text.heading', 'dark.text.heading')(props),
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (
  props: StyleFunctionProps
) => {
  return {
    ...dnaTheme.components.Chip.baseStyle,
    container: baseStyleContainer(props),
  }
}

const variants: Record<string, PartsStyleInterpolation> = {
  selected: (props: StyleFunctionProps) => {
    const background = mode(
      'light.background.backgroundSecondary',
      'dark.background.backgroundSecondary'
    )(props)
    return {
      container: {
        px: 3,
        bg: background,
        borderColor: mode('light.brand.primary', 'dark.brand.primary')(props),
        color: mode('light.brand.primary', 'dark.brand.primary')(props),
      },
      icon: {
        color: mode('light.brand.primary', 'dark.brand.primary')(props),
      },
    }
  },
}

export const Chip = {
  baseStyle,
  variants,
}
