import React from 'react'

export const OffTargetLine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 3 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.25 10.5L1.25 1"
        stroke="#6A56EB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="1.25" cy="13.75" r="1.25" fill="#6A56EB" />
    </svg>
  )
}
