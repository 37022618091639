import React from 'react'

export const Previous = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.2 22.8V1.20001H0V22.8H1.2ZM4.956 11.4L12 4.35601L11.154 3.50401L2.664 12L11.154 20.484L12 19.638L4.962 12.6H24V11.4H4.956Z"
        fill="currentColor"
      />
    </svg>
  )
}
