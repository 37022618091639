import React from 'react'

export const Phone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21.5501 17.212L18.1511 13.813C17.8611 13.523 17.4761 13.364 17.0661 13.364C16.7381 13.364 16.4241 13.466 16.1601 13.66L14.4221 14.933C14.2381 15.068 14.0201 15.139 13.7911 15.139C13.5831 15.139 13.3791 15.079 13.2021 14.966C11.9571 14.169 10.8061 13.244 9.78012 12.219C8.75512 11.194 7.83112 10.043 7.03312 8.797C6.78912 8.416 6.80212 7.938 7.06612 7.577L8.33912 5.839C8.79012 5.225 8.72612 4.387 8.18712 3.848L4.78912 0.45C4.49912 0.16 4.11312 0 3.70412 0C3.29312 0 2.90712 0.16 2.61712 0.452L0.958117 2.117C0.0891172 2.99 -0.217883 4.257 0.156117 5.425C1.39812 9.296 3.49312 12.725 6.38412 15.615C9.27612 18.507 12.7061 20.603 16.5801 21.845C16.9011 21.947 17.2351 22 17.5711 22C18.4291 22 19.2701 21.652 19.8791 21.046L21.5481 19.384C21.8391 19.094 21.9991 18.708 21.9991 18.298C22.0001 17.888 21.8401 17.502 21.5501 17.212Z"
      />
    </svg>
  )
}
