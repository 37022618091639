import React from 'react'

export const Meditation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0001 5.26029C12.3157 5.26029 13.3817 4.19521 13.3817 2.88064V2.37966C13.3817 1.06508 12.3157 0 11.0001 0C9.68441 0 8.61845 1.06508 8.61845 2.37966V2.88064C8.61845 4.19521 9.68441 5.26029 11.0001 5.26029ZM21.5594 14.0134C21.5594 14.0134 14.7705 7.25219 14.7475 7.23215C13.874 6.40854 12.5925 5.97669 11.0001 5.96667C9.40764 5.97669 8.12608 6.40854 7.25265 7.23215C7.22959 7.25319 0.440725 14.0134 0.440725 14.0134C-0.146908 14.6006 -0.146908 15.5524 0.440725 16.1386C0.734541 16.4322 1.11961 16.5784 1.50468 16.5784C1.88975 16.5784 2.27382 16.4322 2.56763 16.1386L6.98892 11.7209V15.6586C6.98892 16.3089 6.67906 16.854 6.2268 17.2167C7.75906 17.7207 9.35449 17.9902 11.0001 17.9902C12.6456 17.9902 14.2411 17.7207 15.7723 17.2167C15.3211 16.855 15.0102 16.3089 15.0102 15.6586V11.7209L19.4315 16.1386C19.7253 16.4322 20.1104 16.5784 20.4944 16.5784C20.8795 16.5784 21.2646 16.4322 21.5584 16.1386C22.147 15.5524 22.147 14.6006 21.5594 14.0134ZM11.0001 23C7.8824 23 4.88608 22.3107 2.09833 20.951C1.10256 20.466 0.689415 19.2657 1.17577 18.2707C1.65911 17.2778 2.85844 16.862 3.85722 17.3489C6.09443 18.4391 8.49711 18.9922 11.0001 18.9922C13.503 18.9922 15.9057 18.4391 18.1429 17.3489C19.1387 16.864 20.339 17.2768 20.8254 18.2707C21.3107 19.2657 20.8976 20.465 19.9018 20.95C17.113 22.3107 14.1177 23 11.0001 23Z"
        fill="currentColor"
      />
    </svg>
  )
}
