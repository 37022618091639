import React from 'react'

export const Play = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.4 18.246V5.244L18.51 11.748L8.4 18.246ZM9.6 7.446V16.044L16.29 11.748L9.6 7.446ZM0 0H24V24H0V0ZM1.2 1.2V22.8H22.8V1.2H1.2Z"
        fill="currentColor"
      />
    </svg>
  )
}
