import React from 'react'

export const Smoking = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27939)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 19.606H18.9611V14.8657H0V19.606ZM1.18507 18.4209H17.776V16.0508H1.18507V18.4209ZM22.5163 19.606H23.7014V14.8657H22.5163V19.606ZM20.1462 19.606H21.3312V14.8657H20.1462V19.606ZM21.1061 9.60399C21.9119 10.5698 21.5979 12.2348 21.4083 13.2244C21.3718 13.3744 21.346 13.5269 21.3312 13.6806H20.1462C20.1633 13.4556 20.195 13.232 20.241 13.0111C20.3713 12.3237 20.6439 10.8957 20.1936 10.3565C20.1137 10.2688 20.0137 10.202 19.9022 10.1617C19.7907 10.1214 19.6711 10.109 19.5536 10.1254H18.9611V9.53288C18.9611 7.25755 18.173 6.59984 17.776 6.57021V5.38514C17.8708 5.38514 19.9447 5.42662 20.1462 9.00553C20.5228 9.09517 20.8598 9.3053 21.1061 9.60399ZM23.7666 13.1888C23.7666 13.4199 23.7014 13.5977 23.7014 13.6806H22.5163C22.5326 13.4562 22.5603 13.2327 22.5993 13.0111C22.7296 12.1519 23.0733 9.88248 22.4452 9.15366C22.3828 9.07762 22.3025 9.01831 22.2115 8.98107C22.1204 8.94383 22.0216 8.92983 21.9238 8.94035H21.3312V8.34781C21.3312 6.07248 20.5432 5.41477 20.1462 5.38514V4.20007C20.241 4.20007 22.3208 4.24155 22.5163 7.83823C22.8426 7.93381 23.1346 8.12094 23.3577 8.37744C24.3295 9.53288 23.9621 11.903 23.7666 13.1888Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27939">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
