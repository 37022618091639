import React from 'react'

export const Settings = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.6249 13.4951C21.6999 13.0061 21.7509 12.5101 21.7509 12.0001C21.7509 11.4901 21.6999 10.9941 21.6249 10.5051L23.1109 9.36514C23.4689 9.09014 23.5989 8.60814 23.4259 8.19114L22.7729 6.61614C22.5999 6.19814 22.1679 5.94914 21.7199 6.00814L19.8639 6.25214C19.2709 5.44314 18.5579 4.73014 17.7489 4.13714L17.9929 2.28114C18.0519 1.83314 17.8029 1.40114 17.3849 1.22814L15.8099 0.576138C15.3919 0.403138 14.9109 0.532138 14.6359 0.891138L13.4959 2.37714C13.0069 2.30114 12.5109 2.25014 12.0009 2.25014C11.4909 2.25014 10.9949 2.30114 10.5059 2.37614L9.36593 0.890138C9.09093 0.532138 8.60893 0.403138 8.19193 0.576138L6.61693 1.22914C6.19893 1.40114 5.94993 1.83314 6.00893 2.28114L6.25293 4.13714C5.44393 4.73014 4.73093 5.44314 4.13793 6.25214L2.28193 6.00814C1.83393 5.94914 1.40193 6.19814 1.22893 6.61614L0.576926 8.19114C0.403926 8.60814 0.532926 9.09014 0.891926 9.36514L2.37793 10.5051C2.30193 10.9941 2.25093 11.4901 2.25093 12.0001C2.25093 12.5101 2.30193 13.0061 2.37693 13.4951L0.890926 14.6351C0.532926 14.9101 0.402926 15.3921 0.575926 15.8091L1.22893 17.3841C1.40193 17.8011 1.83393 18.0511 2.28193 17.9921L4.13793 17.7481C4.73093 18.5571 5.44393 19.2701 6.25293 19.8631L6.00893 21.7191C5.94993 22.1671 6.19893 22.5991 6.61693 22.7721L8.19193 23.4241C8.60993 23.5971 9.09093 23.4681 9.36593 23.1091L10.5059 21.6231C10.9949 21.6991 11.4909 21.7501 12.0009 21.7501C12.5109 21.7501 13.0069 21.6991 13.4959 21.6241L14.6359 23.1101C14.9109 23.4691 15.3929 23.5981 15.8099 23.4251L17.3849 22.7731C17.8029 22.6001 18.0519 22.1681 17.9929 21.7201L17.7489 19.8641C18.5589 19.2711 19.2709 18.5581 19.8639 17.7491L21.7199 17.9931C22.1679 18.0521 22.5999 17.8031 22.7729 17.3851L23.4259 15.8101C23.5989 15.3921 23.4699 14.9111 23.1109 14.6361L21.6249 13.4951ZM12.0009 18.0001C8.68693 18.0001 6.00093 15.3141 6.00093 12.0001C6.00093 8.68614 8.68693 6.00014 12.0009 6.00014C15.3149 6.00014 18.0009 8.68614 18.0009 12.0001C18.0009 15.3141 15.3149 18.0001 12.0009 18.0001Z"
        fill="currentColor"
      />
    </svg>
  )
}
