import { IconRegistry, iconRegistry } from '../../../Icon/iconRegistry'
import * as DNAIcons from '../../../Icon/icons'

import * as bupaIcons from './'
import * as bupaManuallyUpdatedIcons from './manualIcons'

export const bupaIconRegistry: IconRegistry = {
  ...DNAIcons,
  ...bupaIcons,
  ...bupaManuallyUpdatedIcons,
}

export type IconType = keyof typeof iconRegistry
