import React from 'react'

export const Payment = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 3V7H19C20.105 7 21 7.895 21 9V16H23C23.552 16 24 15.552 24 15V3C24 2.448 23.552 2 23 2H5C4.448 2 4 2.448 4 3ZM5.878 12.122H3.122V13.879H5.879V12.122H5.878ZM1 22H19C19.552 22 20 21.552 20 21V9C20 8.448 19.552 8 19 8H1C0.448 8 0 8.448 0 9V21C0 21.552 0.448 22 1 22ZM2 12C2 11.448 2.448 11 3 11H6C6.552 11 7 11.448 7 12V14C7 14.552 6.552 15 6 15H3C2.448 15 2 14.552 2 14V12ZM2 18H18V19H2V18Z"
        fill="currentColor"
      />
    </svg>
  )
}
