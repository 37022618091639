import React from 'react'

export const ExternalLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09091 22.9091H8.72727V15.2727H1.09091V22.9091ZM9.81818 19.6364V24H0V14.1818H4.36364V0H24V19.6364H9.81818ZM9.81818 14.8855V18.5455H22.9091V1.09091H5.45455V14.1818H9.10909L14.5636 8.72727H9.81818V7.63636H16.3636V14.1818H15.2727V9.43091L9.81818 14.8855Z"
        fill="currentColor"
      />
    </svg>
  )
}
