import React from 'react'

export const List = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.9091 1.09091V22.9091H1.09091V1.09091H22.9091ZM24 0H0V24H24V0Z"
        fill="currentColor"
      />
      <path
        d="M20.7272 4.36386H5.45447V5.45477H20.7272V4.36386Z"
        fill="currentColor"
      />
      <path
        d="M4.36361 4.36386H3.27271V5.45477H4.36361V4.36386Z"
        fill="currentColor"
      />
      <path
        d="M18.5454 8.72739H5.45447V9.81829H18.5454V8.72739Z"
        fill="currentColor"
      />
      <path
        d="M4.36361 8.72739H3.27271V9.81829H4.36361V8.72739Z"
        fill="currentColor"
      />
      <path
        d="M20.7272 13.0909H5.45447V14.1818H20.7272V13.0909Z"
        fill="currentColor"
      />
      <path
        d="M4.36361 13.0909H3.27271V14.1818H4.36361V13.0909Z"
        fill="currentColor"
      />
      <path
        d="M14.1817 17.4548H5.45447V18.5457H14.1817V17.4548Z"
        fill="currentColor"
      />
      <path
        d="M4.36361 17.4548H3.27271V18.5457H4.36361V17.4548Z"
        fill="currentColor"
      />
    </svg>
  )
}
