import * as React from 'react'

export const RAGOffTarget = (
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M0 0v24h24V0H0zm11.4 3.3h1.2v13.2h-1.2V3.3zm.6 17.4a.9.9 0 110-1.8.9.9 0 010 1.8z"
      fill="#D60023"
    />
  </svg>
)
