import React from 'react'

export const Flag = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_1933)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2331 -0.119995H1.87988V24.12H3.11988V15.12H22.2164L17.1442 7.00445L22.2331 -0.119995ZM3.11988 13.88V1.12H19.9517L16.0431 6.39837L15.6685 7L16.0382 7.59368L19.9732 13.88H3.11988Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_1933">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
