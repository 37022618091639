import React from 'react'

export const Height = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M7 6.25C8.312 6.25 9.375 5.187 9.375 3.875V3.375C9.375 2.063 8.312 1 7 1C5.688 1 4.625 2.063 4.625 3.375V3.875C4.625 5.187 5.688 6.25 7 6.25ZM7 6.955C3.945 6.973 2 8.519 2 11.5V17.5C2 18.052 2.448 18.5 3 18.5H4V22.5H10V18.5H11C11.552 18.5 12 18.052 12 17.5V11.5C12 8.519 10.055 6.973 7 6.955ZM20.586 17.5L19 19.086V4.914L20.586 6.5C20.976 6.89 21.61 6.89 22 6.5C22.39 6.109 22.39 5.476 22 5.086L18.707 1.793C18.317 1.403 17.683 1.403 17.293 1.793L14 5.086C13.609 5.477 13.609 6.11 14 6.5C14.39 6.89 15.024 6.89 15.414 6.5L17 4.914V19.086L15.414 17.5C15.024 17.11 14.39 17.11 14 17.5C13.609 17.89 13.609 18.523 14 18.914L17.293 22.207C17.683 22.597 18.316 22.597 18.707 22.207L22 18.914C22.39 18.523 22.39 17.89 22 17.5C21.609 17.11 20.976 17.11 20.586 17.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
