import React from 'react'

export const UpTrend = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27546)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.6798 6.60034V7.78261H21.7241L14.0453 14.687L8.37044 9.98753L0 16.1708L0.703448 17.1225L8.32907 11.489L14.0749 16.2476L22.8177 8.39148V11.9205H24V6.60034H18.6798Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27546">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000366211)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
