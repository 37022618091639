import React from 'react'

export const Forward = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8216 12.6522L16.4869 12L15.8282 11.3478L5.3999 0.919565L6.31947 0L18.3129 11.9935L18.2999 12L18.3129 12.0065L6.31947 24L5.3999 23.0739L15.8216 12.6522Z"
        fill="currentColor"
      />
    </svg>
  )
}
