import React from 'react'

export const Fish = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9062 4.56118C17.713 4.56118 17.5242 4.5039 17.3636 4.39658C17.2029 4.28925 17.0778 4.13671 17.0038 3.95825C16.9299 3.77978 16.9106 3.5834 16.9483 3.39393C16.9859 3.20447 17.079 3.03044 17.2156 2.89385C17.3522 2.75725 17.5262 2.66423 17.7156 2.62654C17.9051 2.58886 18.1015 2.6082 18.28 2.68212C18.4584 2.75605 18.611 2.88123 18.7183 3.04185C18.8256 3.20247 18.8829 3.39131 18.8829 3.58448C18.8829 3.84352 18.78 4.09194 18.5968 4.27511C18.4137 4.45828 18.1652 4.56118 17.9062 4.56118ZM13.1269 1.68968C15.3404 0.531121 17.811 -0.0489986 20.3089 0.0032404C21.4426 -0.00522727 22.5745 0.0972722 23.6883 0.309273C23.6883 0.309273 24.5543 6.78155 22.7767 9.93955C21.2432 12.7485 18.7731 14.9305 15.7965 16.1058C14.1971 16.7118 12.5384 17.1483 10.8479 17.4081C10.743 19.6072 9.78535 21.6789 8.17824 23.1836C5.31977 26.0486 7.45549 20.6116 5.41744 18.5736C3.80262 16.9327 0.0325567 17.9745 0 17.0174C0 16.7699 0.247431 16.4053 0.813918 15.8388C2.31975 14.2341 4.39131 13.2789 6.58948 13.1757C6.85655 11.4353 7.30839 9.72839 7.93733 8.08382C9.16797 5.51835 10.2098 3.5975 13.1269 1.68968ZM15.8616 7.2113C16.2849 7.63459 16.7874 7.97037 17.3404 8.19945C17.8934 8.42854 18.4861 8.54645 19.0847 8.54645C19.6833 8.54645 20.2761 8.42854 20.8291 8.19945C21.3821 7.97037 21.8846 7.63459 22.3079 7.2113C22.7076 5.31486 22.7868 3.36486 22.5423 1.44225C20.4782 1.1883 15.1649 0.92134 11.7725 4.30724C9.36331 6.71643 8.32149 10.5581 7.8657 13.358L7.68989 14.4519H6.58297C4.97264 14.5395 3.44345 15.1866 2.25944 16.2816C3.5617 16.3662 5.21558 16.5616 6.34205 17.688C7.46851 18.8145 7.64431 20.4423 7.74198 21.7707C8.82789 20.5812 9.46363 19.0494 9.53911 17.4406V16.2816L10.6395 16.1058C13.4329 15.6565 17.2746 14.6082 19.6903 12.199C20.527 11.3347 21.1902 10.3179 21.6437 9.20377C20.549 9.74854 19.3113 9.93723 18.1039 9.74341C16.8966 9.54958 15.7802 8.98295 14.911 8.12288L15.8616 7.2113Z"
        fill="currentColor"
      />
    </svg>
  )
}
