import React from 'react'

export const Sleep = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8001 13.1999H1.2001C1.08164 13.1993 0.966013 13.1637 0.86778 13.0975C0.769548 13.0313 0.69311 12.9375 0.648098 12.8279C0.60215 12.7186 0.589596 12.5982 0.612021 12.4818C0.634445 12.3654 0.690843 12.2583 0.774098 12.1739L11.7541 1.19991H0.600098V-9.15527e-05H13.2001C13.3186 0.000499736 13.4342 0.0361411 13.5324 0.102341C13.6306 0.168541 13.7071 0.262337 13.7521 0.371908C13.798 0.481175 13.8106 0.601603 13.7882 0.717997C13.7657 0.83439 13.7094 0.941534 13.6261 1.02591L2.6461 11.9999H13.8001V13.1999ZM23.4001 14.3999H17.7961L23.1961 9.44391C23.2846 9.36177 23.3463 9.25483 23.373 9.13708C23.3998 9.01933 23.3904 8.89624 23.3461 8.78391C23.3033 8.67303 23.2286 8.57733 23.1314 8.50897C23.0342 8.44061 22.9189 8.40265 22.8001 8.39991H15.9001V9.59991H21.2581L15.8581 14.5559C15.7689 14.6369 15.7062 14.7429 15.6781 14.8601C15.6501 14.9772 15.6579 15.1001 15.7006 15.2127C15.7433 15.3254 15.819 15.4226 15.9177 15.4916C16.0164 15.5607 16.1336 15.5984 16.2541 15.5999H23.4001V14.3999ZM15.0001 22.7999H10.5361L14.7961 19.0499C14.8874 18.9693 14.9521 18.8629 14.9815 18.7447C15.0109 18.6265 15.0036 18.5021 14.9606 18.3882C14.9177 18.2742 14.841 18.176 14.7409 18.1067C14.6408 18.0373 14.5219 18.0001 14.4001 17.9999H8.4001V19.1999H12.8101L8.5501 22.9499C8.45875 23.0305 8.39408 23.1369 8.36469 23.2551C8.3353 23.3733 8.34258 23.4977 8.38556 23.6116C8.42854 23.7256 8.50519 23.8238 8.60531 23.8931C8.70544 23.9625 8.8243 23.9997 8.9461 23.9999H15.0001V22.7999Z"
        fill="currentColor"
      />
    </svg>
  )
}
