import React from 'react'

export const Time = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM12 21C7.037 21 3 16.963 3 12C3 7.037 7.037 3 12 3C16.963 3 21 7.037 21 12C21 16.963 16.963 21 12 21ZM17 11H13V6C13 5.45 12.55 5 12 5C11.45 5 11 5.45 11 6V12C11 12.55 11.45 13 12 13H17C17.55 13 18 12.55 18 12C18 11.45 17.55 11 17 11Z"
        fill="currentColor"
      />
    </svg>
  )
}
