import React from 'react'

export const Temperature = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7902 17.2699C15.5902 15.9799 14.8702 14.8399 13.8502 14.0699V10.9399V8.93994V3.93994C13.8502 2.27994 12.5102 0.939941 10.8502 0.939941C9.19021 0.939941 7.85021 2.27994 7.85021 3.93994V14.0699C6.45021 15.1299 5.61021 16.8999 5.91021 18.8499C6.23021 20.9599 7.95021 22.6799 10.0602 22.9999C13.4402 23.5199 16.3002 20.6499 15.7902 17.2699ZM14.8203 4.42041C14.8203 4.14427 15.0442 3.92041 15.3203 3.92041H18.1503C18.4265 3.92041 18.6503 4.14427 18.6503 4.42041C18.6503 4.69655 18.4265 4.92041 18.1503 4.92041H15.3203C15.0442 4.92041 14.8203 4.69655 14.8203 4.42041ZM14.8203 8.44971C14.8203 8.17356 15.0442 7.94971 15.3203 7.94971H17.1303C17.4065 7.94971 17.6303 8.17356 17.6303 8.44971C17.6303 8.72585 17.4065 8.94971 17.1303 8.94971H15.3203C15.0442 8.94971 14.8203 8.72585 14.8203 8.44971ZM15.3203 11.9702C15.0442 11.9702 14.8203 12.1941 14.8203 12.4702C14.8203 12.7464 15.0442 12.9702 15.3203 12.9702H18.1503C18.4265 12.9702 18.6503 12.7464 18.6503 12.4702C18.6503 12.1941 18.4265 11.9702 18.1503 11.9702H15.3203ZM10.8501 6.74951C11.1262 6.74951 11.3501 6.97337 11.3501 7.24951V15.111C12.7802 15.3491 13.8703 16.5922 13.8703 18.0898C13.8703 19.7577 12.5182 21.1098 10.8503 21.1098C9.18242 21.1098 7.83032 19.7577 7.83032 18.0898C7.83032 16.5923 8.92025 15.3494 10.3501 15.1111V7.24951C10.3501 6.97337 10.574 6.74951 10.8501 6.74951ZM10.8503 16.0698C9.73471 16.0698 8.83032 16.9742 8.83032 18.0898C8.83032 19.2054 9.73471 20.1098 10.8503 20.1098C11.9659 20.1098 12.8703 19.2054 12.8703 18.0898C12.8703 16.9742 11.9659 16.0698 10.8503 16.0698Z"
        fill="currentColor"
      />
    </svg>
  )
}
