import React from 'react'

export const Navigation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6886 0.0517957L0.684641 5.97985C-0.196716 6.26998 -0.237593 7.50225 0.622826 7.85119L8.02463 10.8501C8.53709 11.0575 8.94287 11.4643 9.15025 11.9757L12.1493 19.3773C12.4982 20.2377 13.7305 20.1968 14.0206 19.3145L19.9489 1.31098C20.2031 0.533338 19.4663 -0.204429 18.6886 0.0517957Z"
        fill="currentColor"
      />
    </svg>
  )
}
