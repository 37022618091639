import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import { mode } from '@chakra-ui/theme-tools'
import type { PartsStyleFunction } from '@chakra-ui/theme-tools'
import { focusShadow, getModeColor } from '../utils'

const size = {
  fontSize: 'body',
  px: 3,
  height: '44px',
  borderRadius: 'md',
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  const color = getModeColor('functional.graphic', props)
  const textColor = getModeColor('text.body', props)

  return {
    field: {
      ...size,
      width: '100%',
      minWidth: 0,
      outline: 0,
      position: 'relative',
      appearance: 'none',
      transitionProperty: 'common',
      transitionDuration: 'normal',
      transition: 'none',
      color: textColor,
    },
    addon: {
      ...size,
    },
    icon: {
      color,
    },
  }
}

const variantOutline: PartsStyleFunction<typeof parts> = (props) => {
  const backgroundPrimary = getModeColor('background.backgroundPrimary', props)
  const boxShadow = focusShadow(props)

  const fieldBorderColor = mode(
    'light.functional.graphic',
    'dark.functional.graphic'
  )(props)
  const fieldErrorBorderColor = mode(
    'light.status.error',
    'dark.status.error'
  )(props)

  return {
    field: {
      border: '1px solid',
      borderColor: fieldBorderColor,
      borderRadius: 'md',
      bg: 'inherit',
      backgroundColor: backgroundPrimary,

      _hover: {
        borderColor: fieldBorderColor,
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: fieldErrorBorderColor,
        boxShadow: '0',
        _focus: {
          borderColor: fieldErrorBorderColor,
          boxShadow,
        },
      },
      _focus: {
        zIndex: 1,
        boxShadow,
        borderColor: mode('light.brand.primary', 'dark.brand.primary')(props),
      },
    },
    addon: {
      border: '1px solid',
      borderColor: mode(
        'light.functional.divider',
        'dark.functional.divider'
      )(props),
      bg: mode(
        'light.background.backgroundSecondary',
        'dark.background.backgroundSecondary'
      )(props),
    },
  }
}

const variants = {
  outline: variantOutline,
}

const defaultProps = {
  variant: 'outline',
}

export const Input = {
  baseStyle,
  variants,
  defaultProps,
}
