import React from 'react'

export const ThumbsUp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M21.5 12C22.325 12 23 11.325 23 10.5C23 9.675 22.325 9 21.5 9H13.522C14.149 7.476 14.5 5.809 14.5 4.059C14.5 3.894 14.496 3.73 14.49 3.568C14.454 2.694 13.745 2 12.871 2C12.179 2 11.563 2.441 11.347 3.098C10.407 5.965 8.49 8.383 5.999 9.985C5.998 9.99 6 9.995 6 10V19.296C8.447 20.382 11.146 21 13.999 21H19.5C20.325 21 21 20.325 21 19.5C21 18.675 20.325 18 19.5 18H21C21.825 18 22.5 17.325 22.5 16.5C22.5 15.675 21.825 15 21 15H21.5C22.325 15 23 14.325 23 13.5C23 12.675 22.325 12 21.5 12ZM4 9H0V23H4C4.552 23 5 22.552 5 22V10C5 9.448 4.552 9 4 9Z"
      />
    </svg>
  )
}
