import React from 'react'

export const Help = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM11 17.5C10.31 17.5 9.75 16.94 9.75 16.25C9.75 15.56 10.31 15 11 15C11.69 15 12.25 15.56 12.25 16.25C12.25 16.94 11.69 17.5 11 17.5ZM14.159 10.206L12.928 11.189C12.277 11.707 12 12.163 12 12.711V13C12 13.552 11.552 14 11 14C10.448 14 10 13.552 10 13V12.711C10 10.965 11.265 9.957 11.68 9.625L12.863 8.684C12.975 8.579 13.171 8.324 13.171 7.959C13.171 7.631 13.075 7.369 12.877 7.157C12.434 6.68 11.792 6.418 11.064 6.418C10.322 6.418 9.627 6.747 9.156 7.32C9.105 7.385 9.06 7.454 9.023 7.528C8.851 7.87 8.515 8.098 8.133 8.098H8.093C7.374 8.098 6.898 7.349 7.205 6.699C7.311 6.473 7.444 6.26 7.601 6.063C8.462 5.013 9.721 4.418 11.064 4.418C12.355 4.418 13.519 4.907 14.341 5.795C14.883 6.376 15.171 7.125 15.171 7.959C15.17 8.812 14.811 9.61 14.159 10.206Z"
        fill="currentColor"
      />
    </svg>
  )
}
