import React from 'react'

export const InPerson = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M18.5 6.775C19.943 6.775 21.113 5.605 21.113 4.163V3.613C21.113 2.17 19.943 1 18.5 1C17.057 1 15.888 2.17 15.888 3.613V4.163C15.888 5.605 17.057 6.775 18.5 6.775ZM5.5 6.775C6.943 6.775 8.112 5.605 8.112 4.163V3.613C8.112 2.17 6.942 1 5.5 1C4.057 1 2.888 2.17 2.888 3.613V4.163C2.888 5.605 4.057 6.775 5.5 6.775ZM5.5 7.55C2.139 7.571 0 9.271 0 12.55V22.65H11V12.549C11 9.271 8.861 7.571 5.5 7.55ZM24 12.549C24 9.27 21.861 7.57 18.5 7.55C15.139 7.57 13 9.271 13 12.55V15.65H24V12.549ZM12 22.65H24V16.65H12V22.65Z"
      />
    </svg>
  )
}
