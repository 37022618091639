import React from 'react'

export const Sort = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.7 23.5L0.5 17.76L1.16 17.11L6.7 22.24L11.84 17.48L12.49 18.13L6.7 23.5Z"
        fill="currentColor"
      />
      <path
        d="M1.16 6.89L0.5 6.24L6.7 0.5L12.49 5.87L11.84 6.52L6.7 1.76L1.16 6.89Z"
        fill="currentColor"
      />
      <path d="M7.15997 1.13H6.21997V22.87H7.15997V1.13Z" fill="currentColor" />
      <path
        d="M23.4127 6.11097L16.0427 6.1264L16.0446 7.0364L23.4146 7.02097L23.4127 6.11097Z"
        fill="currentColor"
      />
      <path
        d="M23.5038 11.3407L15.9438 11.3566L15.9458 12.2666L23.5057 12.2507L23.5038 11.3407Z"
        fill="currentColor"
      />
      <path
        d="M23.4977 16.9754L15.9377 16.9833L15.9387 17.8933L23.4987 17.8854L23.4977 16.9754Z"
        fill="currentColor"
      />
    </svg>
  )
}
