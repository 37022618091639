import React from 'react'

export const Navigation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27835)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3465 0.000254667H23.1906L1.01713 7.67316C0.749645 7.74098 0.509398 7.88922 0.328807 8.09788C0.148216 8.30653 0.035972 8.56556 0.00722006 8.84001C-0.0215319 9.11446 0.0345915 9.39113 0.168018 9.63268C0.301445 9.87423 0.505753 10.069 0.753377 10.1908L9.62517 13.7875C9.8818 13.921 10.0911 14.1303 10.2246 14.387L13.8213 23.2587C13.9329 23.4731 14.1005 23.6532 14.3064 23.7799C14.5122 23.9066 14.7485 23.975 14.9902 23.9781C15.2907 23.9818 15.5834 23.8829 15.82 23.6975C16.0566 23.5122 16.2227 23.2517 16.291 22.959L23.9819 0.785528C24.005 0.690561 24.0059 0.591548 23.9844 0.49619C23.963 0.400831 23.9199 0.311697 23.8584 0.235721C23.7969 0.159744 23.7188 0.0989729 23.63 0.0581337C23.5412 0.0172945 23.4442 -0.00251194 23.3465 0.000254667ZM1.26291 9.09384C1.24064 9.07913 1.22313 9.05827 1.21249 9.0338C1.20185 9.00933 1.19854 8.98229 1.20296 8.95597C1.20263 8.92706 1.21277 8.899 1.23149 8.87697C1.25022 8.85494 1.27628 8.84042 1.30487 8.83608H1.41277L21.1345 1.99641L10.3445 12.7864C10.2846 12.7505 10.2366 12.6965 10.1767 12.6665L10.0867 12.6246L1.26291 9.09384ZM15.176 22.5694C15.1734 22.6053 15.1734 22.6414 15.176 22.6773C15.1687 22.7037 15.1539 22.7275 15.1334 22.7458C15.113 22.7642 15.0877 22.7762 15.0606 22.7807C15.0335 22.7851 15.0057 22.7816 14.9804 22.7707C14.9552 22.7599 14.9337 22.742 14.9183 22.7192L11.3396 13.9074C11.3281 13.8763 11.3141 13.8462 11.2976 13.8175C11.2677 13.7575 11.2137 13.7096 11.1777 13.6496L21.9678 2.85961L15.176 22.5694Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27835">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
