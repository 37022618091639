import React from 'react'

export const MicOff = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57018 12.601L16.9232 4.248C16.5562 1.854 14.4932 0 12.0002 0C9.25018 0 7.00018 2.25 7.00018 5V9C7.00018 10.42 7.61018 11.692 8.57018 12.601ZM5.72418 15.447L7.14618 14.025C5.82718 12.752 5.00018 10.974 5.00018 9V8C5.00018 7.448 4.55218 7 4.00018 7C3.44818 7 3.00018 7.448 3.00018 8V9C3.00018 11.53 4.04418 13.814 5.72418 15.447ZM0.294191 22.292L22.2922 0.292953C22.6812 -0.0950469 23.3182 -0.0950469 23.7062 0.292953C24.0952 0.681953 24.0952 1.31795 23.7062 1.70695L17.0002 8.41395V8.99995C17.0002 11.75 14.7502 14 12.0002 14C11.8753 14 11.7546 13.9835 11.6339 13.967C11.5788 13.9594 11.5237 13.9519 11.4682 13.946L9.78019 15.634C10.7512 15.96 11.8142 16.083 12.9212 15.941C16.4502 15.488 19.0002 12.313 19.0002 8.75595V7.99995C19.0002 7.44795 19.4482 6.99995 20.0002 6.99995C20.5522 6.99995 21.0002 7.44795 21.0002 7.99995V9.00095C21.0002 13.633 17.4992 17.444 13.0002 17.942V20.005H17.0002C17.5522 20.005 18.0002 20.453 18.0002 21.005V22H6.00019V21.005C6.00019 20.453 6.44819 20.005 7.00019 20.005H11.0052V17.942C10.0282 17.834 9.10319 17.563 8.24619 17.168L1.70819 23.706C1.31919 24.095 0.683191 24.095 0.294191 23.706C-0.0948086 23.317 -0.0948086 22.681 0.294191 22.292Z"
        fill="currentColor"
      />
    </svg>
  )
}
