import React, { forwardRef } from 'react'
import { Box as ChakraBox, BoxProps as ChakraBoxProps } from '@chakra-ui/react'
import { WithStyleProps } from '../../core/style-props'

type BoxProps = WithStyleProps<ChakraBoxProps> & {
  'aria-label'?: string
}

export const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <ChakraBox {...props} ref={ref} />
))

Box.displayName = 'Box'
