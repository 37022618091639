import React from 'react'

export const Show = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 0C6.762 0 2.233 3.255 0 8c2.233 4.745 6.762 8 12 8 5.237 0 9.767-3.255 12-8-2.233-4.745-6.763-8-12-8Zm4.5 6.5c0 2.481-2.019 4.5-4.5 4.5a4.505 4.505 0 0 1-4.5-4.5C7.5 4.019 9.519 2 12 2s4.5 2.019 4.5 4.5ZM12 14c-4.001 0-7.661-2.276-9.752-6a12.117 12.117 0 0 1 3.824-4.158A6.48 6.48 0 0 0 5.5 6.5a6.5 6.5 0 1 0 13 0 6.48 6.48 0 0 0-.572-2.658A12.107 12.107 0 0 1 21.752 8c-2.091 3.724-5.751 6-9.752 6Z"
      />
    </svg>
  )
}
