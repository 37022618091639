import { tagAnatomy as parts } from '@chakra-ui/anatomy'
import {
  mode,
  PartsStyleFunction,
  PartsStyleInterpolation,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { focusShadow } from '../utils'

const baseStyleContainer: SystemStyleFunction = (props) => {
  const boxShadow = focusShadow(props)
  return {
    bg: 'transparent',
    color: mode('light.text.body', 'dark.text.body')(props),
    borderColor: mode(
      'light.functional.graphic',
      'dark.functional.graphic'
    )(props),
    border: '1px',
    minH: '1.5rem',
    minW: '1.5rem',
    fontSize: '3xs',
    fontWeight: 'medium',
    lineHeight: 5,
    borderRadius: 'full',
    px: 5.5,
    py: 2,
    cursor: 'pointer',
    _focus: {
      bg: mode(
        'light.background.bannerPurple',
        'dark.background.bannerPurple'
      )(props),
      boxShadow,
    },
    _hover: {
      bg: mode(
        'light.background.bannerPurple',
        'dark.background.bannerPurple'
      )(props),
      boxShadow,
    },
    _active: {
      bg: mode(
        'light.background.bannerPurple',
        'dark.background.bannerPurple'
      )(props),
      color: mode('light.brand.secondary', 'dark.brand.secondary')(props),
      borderColor: mode('light.brand.secondary', 'dark.brand.secondary')(props),
    },
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: baseStyleContainer(props),
  }
}

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  selected: (props) => ({
    container: {
      px: 3,
      bg: mode(
        'light.background.bannerPurple',
        'dark.background.bannerPurple'
      )(props),
      color: mode('light.brand.secondary', 'dark.brand.secondary')(props),
      borderColor: mode('light.brand.secondary', 'dark.brand.secondary')(props),
    },
    icon: {
      color: mode('light.brand.secondary', 'dark.brand.secondary')(props),
    },
  }),
}

export const Chip = {
  baseStyle,
  variants,
}
