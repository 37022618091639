import React from 'react'

export const Copy = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3638_8038)">
        <path
          d="M8.18742 23.5L23.5208 23.5L23.5208 7.5L8.18742 7.5L8.18742 23.5Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M8.04383 16.5H1.47925V0.5H16.8126V7.35"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_3638_8038">
          <rect width="23" height="24" fill="white" transform="translate(1)" />
        </clipPath>
      </defs>
    </svg>
  )
}
