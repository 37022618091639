import { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { get } from '@chakra-ui/utils'
import { focusShadow, getModeColor } from '../../dna/utils'

const parts = [
  'container',
  'input',
  'dropdownIndicator',
  'indicatorSeparator',
  'clearIndicator',
  'iconWrapper',
  'valueContainer',
  'singleValue',
  'menuList',
  'control',
  'menu',
  'option',
  'menuPortal',
]

const baseStyle: SystemStyleFunction = (props) => {
  const boxShadow = focusShadow(props)

  const brandSecondary = getModeColor('brand.secondary', props)

  const brandPrimary = getModeColor('brand.primary', props)

  const backgroundPrimary = getModeColor('background.backgroundPrimary', props)

  const fieldErrorBorderColor = getModeColor('status.error', props)

  const textBodyColor = getModeColor('text.body', props)

  return {
    container: {
      display: 'inline-block',
      width: '100%',
      textAlign: 'left',
      border: 'none',
      backgroundColor: backgroundPrimary,
    },
    control: {
      display: 'flex',
      borderRadius: get(props.theme, 'radii.lg'),
      border: '1px solid',
      backgroundColor: backgroundPrimary,
      borderColor: `${getModeColor('functional.graphic', props)} !important`,
      height: '2.75rem',
      paddingRight: '28px',
    },
    input: {
      color: textBodyColor,
      overflow: 'hidden',
    },
    menu: {
      borderRadius: get(props.theme, 'radii.lg'),
      backgroundColor: backgroundPrimary,
      boxShadow,
      top: 0,
      paddingTop: '2.625rem',
      zIndex: 1,
      border: `1px solid`,
      borderColor: brandPrimary,
      marginTop: 0,
      width: '100%',
    },
    option: {
      borderBottom: '1px solid',
      borderColor: getModeColor('functional.divider', props),
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      backgroundColor: backgroundPrimary,
      color: getModeColor('text.heading', props),
      boxSizing: 'border-box',
      height: '4.75rem',
      fontSize: '1rem',
      cursor: 'default',
      display: 'block',
      label: 'option',
      padding: '.75rem .5rem',
      userSelect: 'none',
      width: '100%',
      zIndex: 0,
    },
    _focus: {
      // focus for option part
      backgroundColor: brandPrimary,
      color: getModeColor('text.textInverted', props),
    },
    menuList: {
      paddingTop: 0,
      paddingBottom: 0,
      borderTop: '2px solid',
      borderTopColor: brandPrimary,
      backgroundColor: backgroundPrimary,
      borderRadius: `0 0 ${get(props.theme, 'radii.lg')} ${get(
        props.theme,
        'radii.lg'
      )}`,
    },
    dropdownIndicator: {
      display: 'none',
    },
    indicatorSeparator: {
      display: 'none',
    },
    clearIndicator: {
      color: brandSecondary,
      margin: '0',
      fontSize: '1.125rem',
      padding: 0,
      '&:hover': {
        fontWeight: 'bolder',
        color: brandSecondary,
        cursor: 'pointer',
      },
      zIndex: 4,
    },
    iconWrapper: {
      flex: 1,
      position: 'absolute',
      bottom: '2px',
      right: '4px',
      zIndex: 4,
    },
    valueContainer: {
      zIndex: 3,
      height: '2.5rem',
      paddingTop: '0',
      paddingBottom: '0',
    },
    singleValue: {
      paddingBottom: '.125rem',
      color: textBodyColor,
    },
    _invalid: {
      borderColor: `${fieldErrorBorderColor} !important`,
    },
    '.option__hintText': {
      color: textBodyColor,
    },
    '.option__label': {
      color: getModeColor('text.heading', props),
      fontWeight: get(props.theme, 'fontWeights.semibold'),
    },
  }
}

export const AutoSuggest = {
  parts,
  baseStyle,
}
