import React from 'react'

export const MedicalSpecialist = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 12.469V15.05C15.141 15.282 16 16.29 16 17.5V19H15V17.5C15 16.673 14.327 16 13.5 16C12.673 16 12 16.673 12 17.5V19H11V17.5C11 16.293 11.855 15.287 12.992 15.052V12.214C12.067 12.021 11.07 11.915 10 11.909C8.927 11.916 7.927 12.022 7 12.216V16.092C7.581 16.299 8 16.848 8 17.5C8 18.328 7.328 19 6.5 19C5.671 19 5 18.328 5 17.5C5 16.851 5.415 16.303 5.992 16.094V12.471C2.243 13.61 0 16.476 0 21H20C20 16.473 17.754 13.606 14 12.469ZM10 10.5C12.623 10.5 14.75 8.373 14.75 5.75V4.75C14.75 2.127 12.623 0 10 0C7.377 0 5.25 2.127 5.25 4.75V5.75C5.25 8.373 7.377 10.5 10 10.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
