import React from 'react'

export const Down = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.716 1.663l-9.03 9.051a.969.969 0 01-1.373 0L.284 1.663a.976.976 0 010-1.377.969.969 0 011.374 0L10 8.648 18.342.286a.969.969 0 011.374 0 .976.976 0 010 1.377z"
        fill="currentColor"
      />
    </svg>
  )
}
