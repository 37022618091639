import React from 'react'

export const Directions = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5999 5L20.2399 1H12.0999V0H11.0999V1H0.599945V9H11.0999V24H12.0999V9H20.2399L23.5999 5ZM1.59995 8V2H19.7849L22.2849 5L19.7849 8H1.59995Z"
        fill="currentColor"
      />
    </svg>
  )
}
