import React from 'react'

export const FastForward = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 11.7731V17.0761C10 17.8931 10.927 18.3651 11.588 17.8851L22.65 9.84808C23.2 9.44908 23.2 8.62908 22.65 8.23008L11.588 0.193078C10.927 -0.287922 10 0.185078 10 1.00208V6.30508L1.588 0.193078C0.927 -0.287922 0 0.185078 0 1.00208V17.0771C0 17.8941 0.927 18.3661 1.588 17.8861L10 11.7731Z"
        fill="currentColor"
      />
    </svg>
  )
}
