import React from 'react'

export const Hospital = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0V5H22V21H16V14H6V21H0V5H4V0H18ZM12 8H15V6H12V3H10V6H7V8H10V11H12V8ZM7 15H15V21H7V15Z"
        fill="currentColor"
      />
    </svg>
  )
}
