import React from 'react'

export const Delivery = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 8.13V12H10V3H8.36L7 8.13ZM6.5 16C5.119 16 4 17.119 4 18.5C4 19.881 5.119 21 6.5 21C7.881 21 9 19.881 9 18.5C9 17.119 7.881 16 6.5 16ZM8.145 0C6.787 0 5.598 0.912 5.247 2.223L3.55 8.554L1.236 10.235C0.46 10.8 0 11.702 0 12.662V17C0 18.104 0.895 19 2 19H3.05C3.027 18.835 3 18.671 3 18.5C3 16.57 4.57 15 6.5 15C8.43 15 10 16.57 10 18.5C10 18.671 9.973 18.835 9.949 19H22V0H8.145ZM11 13H6V8L7.413 2.727C7.528 2.298 7.916 2 8.36 2H11V13ZM15.488 10L12.389 6.901L15.289 4H16.703L13.802 6.901L16.902 10H15.488ZM19.488 10L16.389 6.901L19.289 4H20.703L17.802 6.901L20.902 10H19.488Z"
        fill="currentColor"
      />
    </svg>
  )
}
