import React from 'react'

export const Pause = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 1C16 0.45 15.55 0 15 0H12C11.45 0 11 0.45 11 1V17C11 17.55 11.45 18 12 18H15C15.55 18 16 17.55 16 17V1ZM5 1C5 0.45 4.55 0 4 0H1C0.45 0 0 0.45 0 1V17C0 17.55 0.45 18 1 18H4C4.55 18 5 17.55 5 17V1Z"
        fill="currentColor"
      />
    </svg>
  )
}
