import React from 'react'

export const Sleep = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9V0H0V15H2V12H18V15H20V9H2ZM8 5.72997C8 4.47997 6.99 3.46997 5.74 3.46997H5.26C4.01 3.46997 3 4.47997 3 5.72997C3 6.97997 4.01 7.98997 5.26 7.98997H5.74C6.98 7.98997 8 6.97997 8 5.72997ZM9 8H19.99V7.84999C19.99 5.11999 19.01 2.98999 16.28 2.98999L9 3V8Z"
        fill="currentColor"
      />
    </svg>
  )
}
