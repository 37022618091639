import React from 'react'

export const Payment = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27502)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8 13.278H21.6V14.478H10.8V13.278ZM10.8 15.678H18V16.878H10.8V15.678ZM22.8 3.59998H1.2C0.88174 3.59998 0.576515 3.7264 0.351472 3.95145C0.126428 4.17649 0 4.48172 0 4.79998V19.2C0 19.5182 0.126428 19.8235 0.351472 20.0485C0.576515 20.2735 0.88174 20.4 1.2 20.4H22.8C23.1183 20.4 23.4235 20.2735 23.6485 20.0485C23.8736 19.8235 24 19.5182 24 19.2V4.79998C24 4.48172 23.8736 4.17649 23.6485 3.95145C23.4235 3.7264 23.1183 3.59998 22.8 3.59998ZM1.2 7.19998H22.8V4.79998H1.2V7.19998ZM1.2 19.2H22.8V8.39998H1.2V19.2Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27502">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
