import React from 'react'

export const FastForward = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.7051 11.5L19.2001 11.99L18.7051 12.5L10.7101 20.495L11.4201 21.2L20.6101 12.01L20.6051 12L20.6101 11.99L11.4201 2.79999L10.7101 3.50999L18.7051 11.5Z"
        fill="currentColor"
      />
      <path
        d="M11.49 11.5L11.985 11.99L11.49 12.5L3.49502 20.495L4.20502 21.2L13.395 12.01L13.39 12L13.395 11.99L4.20502 2.79999L3.49502 3.50999L11.49 11.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
