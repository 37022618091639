const white = '#FFFFFF'

const black = '#000000'

export const light = {
  brand: {
    primary: black,
    primaryFocus: '#9EC0FA',
    secondary: '#3EEAB0',
    tertiary: '',
  },
  text: {
    heading: black,
    body: '#313233',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: white,
    textInverted: white,
    caption: '#646668',
  },
  status: {
    success: '#1FAD53',
    warning: '#FAC905',
    error: '#E1211E',
    errorFocus: '',
    information: '#0D61F2',
  },
  functional: {
    inactive: '#B1B2B4',
    graphic: '#728492',
    divider: '#E5E6E7',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: white,
    graphicInverted: white,
    overlay: 'rgba(0,0,0,0.3)',
  },
  background: {
    backgroundPrimary: white,
    backgroundSecondary: '#F5F5F5',
    backgroundTertiary: '#EDFDF8',
    backgroundQuarternary: '#F2D9E1',
    backgroundQuinary: '#FEE0CD',
    bannerNeutral: '#E5E6E7',

    backgroundHighlight: '',
    buttonPurple: '',
    bannerPurple: '',
    bannerRed: '',
    bannerGreen: '',
    bannerOrange: '',
    bannerGrey: '',
    buttonSecondary: '',
    bannerInteractive: '',
    bannerError: '',
    bannerSuccess: '',
    bannerWarning: '',
  },
  shadow: {
    shadow: '',
  },
  data: {
    primaryData: '#894FB0',
    secondaryData: '#4FABD9',
  },
  graphics: {
    indigo: '#C76083',
    purple: '#894FB0',
    blue: '#4FABD9',
    yellow: '#FBD22D',
    white: 'rgba(255,255,255,255.9)',
    whiteTint: 'rgba(255,255,255,255.3)',
  },
}

const dark: typeof light = {
  brand: {
    primary: white,
    primaryFocus: '#9EC0FA',
    secondary: '#3EEAB0',
    tertiary: '',
  },
  text: {
    heading: white,
    body: '#E5E6E7',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: black,
    textInverted: black,
    caption: '#A8A7A7',
  },
  status: {
    success: '#25D064',
    warning: '#FAC905',
    error: '#E64442',
    errorFocus: '',
    information: '#649AF7',
  },
  functional: {
    inactive: '#7D8083',
    graphic: '#777777',
    divider: '#4B4D4E',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: '#121212',
    graphicInverted: '#121212',
    overlay: 'rgba(0,0,0,0.6)',
  },
  background: {
    backgroundPrimary: black,
    backgroundSecondary: '#323234',
    backgroundTertiary: '#0F8A61',
    backgroundQuarternary: '#4B1B2B',
    backgroundQuinary: '#632903',
    bannerNeutral: '#2B2B2D',

    backgroundHighlight: '',
    buttonPurple: '',
    bannerPurple: '',
    bannerRed: '',
    bannerGreen: '',
    bannerOrange: '',
    bannerGrey: '',
    buttonSecondary: '',
    bannerInteractive: '',
    bannerError: '#5A0D0C',
    bannerSuccess: '#0F572A',
    bannerWarning: '#645002',
  },
  shadow: {
    shadow: '',
  },
  data: {
    primaryData: '#D0B9DF',
    secondaryData: '#ABD7ED',
  },
  graphics: {
    indigo: '#712840',
    purple: '#522F6A',
    blue: '#1B5D7E',
    yellow: '#967903',
    white: 'rgba(0,0,0,0.9)',
    whiteTint: 'rgba(0,0,0,0.3)',
  },
}

export const colors = {
  white,
  black,

  light,
  dark,
}
