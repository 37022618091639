import React from 'react'

export const Next = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.502 19.128L14 11.869V19C14 19.55 14.45 20 15 20H17C17.55 20 18 19.55 18 19V1C18 0.448 17.552 0 17 0H15C14.45 0 14 0.45 14 1V8.131L1.502 0.873C0.836 0.485 0 0.966 0 1.737V18.263C0 19.034 0.836 19.515 1.502 19.128Z"
        fill="currentColor"
      />
    </svg>
  )
}
