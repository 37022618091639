import React from 'react'

export const MedicalRecords = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27486)">
        <path
          d="M7.2 4.19988V6.59988H22.8V19.7999H1.2V4.19988H7.2ZM7.326 2.99988H1.074C0.789158 2.99988 0.515981 3.11303 0.314567 3.31445C0.113153 3.51586 0 3.78904 0 4.07388V20.9999H24V5.39988H8.4V4.07388C8.4 3.78904 8.28685 3.51586 8.08543 3.31445C7.88402 3.11303 7.61084 2.99988 7.326 2.99988Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27486">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
