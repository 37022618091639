import React from 'react'

export const ShareAndroid = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M19.5 16C18.581 16 17.752 16.361 17.128 16.94L8.571 12L17.128 7.06C17.752 7.638 18.581 8 19.5 8C21.433 8 23 6.433 23 4.5C23 2.567 21.433 1 19.5 1C17.567 1 16 2.567 16 4.5C16 4.79 16.045 5.067 16.112 5.336L7.535 10.288C6.934 9.226 5.808 8.5 4.5 8.5C2.567 8.5 1 10.067 1 12C1 13.933 2.567 15.5 4.5 15.5C5.808 15.5 6.935 14.774 7.535 13.712L16.112 18.664C16.045 18.933 16 19.21 16 19.5C16 21.433 17.567 23 19.5 23C21.433 23 23 21.433 23 19.5C23 17.567 21.433 16 19.5 16ZM19.5 3C20.327 3 21 3.673 21 4.5C21 5.327 20.327 6 19.5 6C18.673 6 18 5.327 18 4.5C18 3.673 18.673 3 19.5 3ZM4.5 13.5C3.673 13.5 3 12.827 3 12C3 11.173 3.673 10.5 4.5 10.5C5.327 10.5 6 11.173 6 12C6 12.827 5.327 13.5 4.5 13.5ZM19.5 21C18.673 21 18 20.327 18 19.5C18 18.673 18.673 18 19.5 18C20.327 18 21 18.673 21 19.5C21 20.327 20.327 21 19.5 21Z"
      />
    </svg>
  )
}
