import React from 'react'

export const FavouriteFill = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2305)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0001 23.5047L9.75329 21.5087C3.84712 15.957 0.00238363 12.2377 0.00238363 7.58037C-0.0215923 6.67502 0.135508 5.77401 0.464466 4.93021C0.793424 4.0864 1.28761 3.31681 1.91803 2.66658C2.54844 2.01635 3.30239 1.49859 4.13561 1.14369C4.96884 0.78879 5.86456 0.603894 6.77021 0.599854C7.75374 0.603955 8.72585 0.810943 9.62575 1.20787C10.5256 1.6048 11.334 2.18313 12.0001 2.9067C12.663 2.18478 13.4676 1.60732 14.3637 1.21041C15.2599 0.81349 16.2282 0.605644 17.2083 0.599854C18.1153 0.602479 19.0127 0.786247 19.8478 1.14037C20.6829 1.4945 21.4388 2.01185 22.0713 2.66207C22.7037 3.31229 23.1999 4.08228 23.5308 4.92686C23.8616 5.77143 24.0204 6.67358 23.9979 7.58037C23.9979 12.2377 20.1804 15.957 14.247 21.5087L12.0001 23.5047Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_2305">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
