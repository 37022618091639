import React from 'react'

export const News = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 12H12V14H16V12ZM16 4H4V7H16V4ZM0 0V21H20V0H0ZM9 18H3V17H9V18ZM9 15H3V14H9V15ZM9 12H3V11H9V12ZM17 18H11V17H17V18ZM17 15H11V11H17V15ZM17 8H3V3H17V8Z"
        fill="currentColor"
      />
    </svg>
  )
}
