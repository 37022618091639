import React from 'react'

export const Map = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 1.09L23 4.09V22.48L16.39 19.65L16 19.48L15.61 19.65L8 22.91L1 19.91V1.52L7.61 4.35L8 4.52L8.39 4.35L16 1.09ZM16 0L8 3.43L0 0V20.57L8 24L16 20.57L24 24V3.43L16 0Z"
        fill="currentColor"
      />
      <path d="M8.5 3.39001H7.5V23H8.5V3.39001Z" fill="currentColor" />
      <path d="M16.5 1H15.5V20.61H16.5V1Z" fill="currentColor" />
    </svg>
  )
}
