import React from 'react'

export const Location = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12.5455H21.8182C21.6873 14.9622 20.6683 17.2455 18.9569 18.9569C17.2455 20.6683 14.9622 21.6873 12.5455 21.8182V24H11.4545V21.8182C9.04261 21.6808 6.766 20.6589 5.06026 18.9482C3.35452 17.2374 2.33939 14.9578 2.20909 12.5455H0V11.4545H2.20909C2.34587 9.04704 3.36385 6.77407 5.06896 5.06896C6.77407 3.36385 9.04704 2.34587 11.4545 2.20909V0H12.5455V2.20909C14.9578 2.33939 17.2374 3.35452 18.9482 5.06026C20.6589 6.766 21.6808 9.04261 21.8182 11.4545H24V12.5455ZM20.7273 12.5455H18.5455V11.4545H20.7273C20.5916 9.33171 19.6852 7.33152 18.1786 5.8299C16.6719 4.32828 14.6687 3.42862 12.5455 3.3V5.45455H11.4545V3.3C9.33611 3.43501 7.33949 4.33749 5.83849 5.83849C4.33749 7.33949 3.43501 9.33611 3.3 11.4545H5.45455V12.5455H3.3C3.42862 14.6687 4.32828 16.6719 5.8299 18.1786C7.33152 19.6852 9.33171 20.5916 11.4545 20.7273V18.5455H12.5455V20.7273C14.6731 20.598 16.6799 19.6945 18.1872 18.1872C19.6945 16.6799 20.598 14.6731 20.7273 12.5455Z"
        fill="currentColor"
      />
      <path
        d="M12.0001 8.72751C12.6474 8.72751 13.2801 8.91945 13.8183 9.27906C14.3565 9.63867 14.776 10.1498 15.0237 10.7478C15.2714 11.3458 15.3362 12.0039 15.21 12.6387C15.0837 13.2736 14.772 13.8567 14.3143 14.3144C13.8566 14.7721 13.2734 15.0838 12.6386 15.2101C12.0037 15.3364 11.3457 15.2715 10.7477 15.0238C10.1497 14.7761 9.63855 14.3567 9.27894 13.8185C8.91933 13.2803 8.72739 12.6475 8.72739 12.0002C8.72739 11.1323 9.07219 10.2998 9.68595 9.68607C10.2997 9.07231 11.1321 8.72751 12.0001 8.72751ZM12.0001 7.6366C11.1371 7.6366 10.2934 7.89252 9.57581 8.372C8.85821 8.85149 8.29891 9.53299 7.96864 10.3303C7.63837 11.1277 7.55195 12.0051 7.72032 12.8515C7.88869 13.698 8.30429 14.4755 8.91456 15.0858C9.52482 15.6961 10.3023 16.1117 11.1488 16.28C11.9953 16.4484 12.8727 16.362 13.67 16.0317C14.4674 15.7014 15.1489 15.1421 15.6283 14.4245C16.1078 13.7069 16.3638 12.8633 16.3638 12.0002C16.3638 10.8429 15.904 9.73302 15.0857 8.91468C14.2673 8.09634 13.1574 7.6366 12.0001 7.6366Z"
        fill="currentColor"
      />
    </svg>
  )
}
