import React from 'react'

export const BodyFat = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM8.5 6C9.881 6 11 7.119 11 8.5C11 9.881 9.881 11 8.5 11C7.119 11 6 9.881 6 8.5C6 7.119 7.119 6 8.5 6ZM7.116 17.591C7.018 17.689 6.89 17.737 6.763 17.737C6.636 17.737 6.507 17.688 6.41 17.591C6.215 17.396 6.215 17.079 6.41 16.884L16.884 6.409C17.079 6.214 17.396 6.214 17.591 6.409C17.786 6.604 17.786 6.921 17.591 7.116L7.116 17.591ZM15.5 18C14.119 18 13 16.881 13 15.5C13 14.119 14.119 13 15.5 13C16.881 13 18 14.119 18 15.5C18 16.881 16.881 18 15.5 18ZM15.5 17C16.3284 17 17 16.3284 17 15.5C17 14.6716 16.3284 14 15.5 14C14.6716 14 14 14.6716 14 15.5C14 16.3284 14.6716 17 15.5 17ZM10 8.5C10 9.32843 9.32843 10 8.5 10C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7C9.32843 7 10 7.67157 10 8.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
