import React from 'react'

export const RedMeat = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.1068 16.19C19.4031 14.267 17.3842 11.237 16.8234 7.26801C16.8234 7.26801 16.4657 4.74002 14.7651 2.83402C11.6659 -0.637985 6.30841 -0.965985 2.80004 2.10102C-0.708325 5.16902 -0.805331 10.561 1.86939 14.364C3.26991 16.356 8.41322 21.469 17.8642 21.99C18.91 22.048 19.9579 21.854 20.742 21.168L20.833 21.088C22.288 19.816 22.3861 17.635 21.1068 16.19ZM2.79398 11.952L11.8498 2.99002C12.1378 3.16902 12.4127 3.36902 12.6704 3.59302L3.30023 12.866C3.11127 12.567 2.93949 12.264 2.79398 11.952ZM5.5748 15.351L14.5397 6.47902C14.7469 7.09302 14.8217 7.54101 14.8217 7.54601C14.8247 7.56601 14.8297 7.58401 14.8328 7.60301L6.36701 15.981C6.08206 15.766 5.8153 15.555 5.5748 15.351ZM10.2927 18.249C9.95319 18.103 9.6359 17.949 9.32366 17.794L15.8291 11.356C15.9605 11.7 16.0938 12.039 16.2404 12.363L10.2927 18.249Z"
        fill="currentColor"
      />
    </svg>
  )
}
