import React from 'react'

export const Pin = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_404_26790)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.60001 8.0021C3.59808 9.40857 3.9749 10.7896 4.6909 12.0002L11.7817 24L18.8725 12.0002C19.5885 10.7896 19.9653 9.40857 19.9634 8.0021C19.9375 5.8567 19.0614 3.80922 17.5275 2.30905C15.9936 0.808879 13.9271 -0.0214088 11.7817 0.000419768C9.63624 -0.0214088 7.56979 0.808879 6.03587 2.30905C4.50195 3.80922 3.62591 5.8567 3.60001 8.0021ZM11.7817 21.8182L5.90179 11.902C5.19542 10.7161 4.78043 9.37954 4.6909 8.0021C4.70014 7.08198 4.89116 6.17276 5.253 5.32673C5.61484 4.48069 6.14035 3.71453 6.79931 3.07229C7.45828 2.43005 8.2377 1.92441 9.09275 1.58445C9.94781 1.24448 10.8616 1.07689 11.7817 1.09131C12.7017 1.07689 13.6156 1.24448 14.4706 1.58445C15.3257 1.92441 16.1051 2.43005 16.7641 3.07229C17.423 3.71453 17.9485 4.48069 18.3104 5.32673C18.6722 6.17276 18.8632 7.08198 18.8725 8.0021C18.778 9.35373 18.3609 10.663 17.6561 11.8202L11.7817 21.8182ZM7.96357 7.63665C7.96357 8.3918 8.1875 9.13 8.60704 9.75788C9.02658 10.3858 9.62289 10.8751 10.3206 11.1641C11.0182 11.4531 11.7859 11.5287 12.5266 11.3814C13.2672 11.2341 13.9475 10.8704 14.4815 10.3365C15.0155 9.80249 15.3791 9.12217 15.5264 8.38153C15.6738 7.64089 15.5982 6.87319 15.3092 6.17552C15.0202 5.47785 14.5308 4.88154 13.9029 4.462C13.275 4.04246 12.5368 3.81853 11.7817 3.81853C10.7691 3.81853 9.79791 4.2208 9.08187 4.93684C8.36584 5.65287 7.96357 6.62402 7.96357 7.63665ZM11.7817 10.3639C12.3211 10.3639 12.8484 10.2039 13.2969 9.90425C13.7453 9.60458 14.0949 9.17865 14.3013 8.68031C14.5077 8.18198 14.5617 7.63363 14.4565 7.1046C14.3513 6.57557 14.0915 6.08962 13.7101 5.70821C13.3287 5.3268 12.8428 5.06706 12.3137 4.96183C11.7847 4.8566 11.2364 4.9106 10.738 5.11702C10.2397 5.32344 9.81376 5.673 9.51408 6.12149C9.21441 6.56997 9.05446 7.09726 9.05446 7.63665C9.05446 8.35995 9.3418 9.05363 9.85325 9.56509C10.3647 10.0765 11.0584 10.3639 11.7817 10.3639Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_404_26790">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
