import React from 'react'

export const Logout = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1099 15.47L14.3899 11.75L14.3799 11.76L14.3699 11.75L10.6499 15.47L11.4699 16.3L13.7999 13.97V24H14.9599V13.97L17.2899 16.3L18.1099 15.47Z"
        fill="currentColor"
      />
      <path
        d="M8.26992 1.67L3.41992 0V23.15L8.25992 21.51H12.6299V20.34H8.91992V2.84H19.4199V20.34H16.0799V21.51H20.5899V1.67H8.26992ZM4.57992 1.63L7.74992 2.72V20.45L4.57992 21.52V1.64V1.63Z"
        fill="currentColor"
      />
    </svg>
  )
}
