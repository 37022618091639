import React from 'react'

export const Tv = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27974)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9091 16.091H1.09091V17.8365C1.09091 17.9522 1.13688 18.0632 1.21872 18.145C1.30055 18.2269 1.41154 18.2728 1.52727 18.2728H22.4727C22.5885 18.2728 22.6994 18.2269 22.7813 18.145C22.8631 18.0632 22.9091 17.9522 22.9091 17.8365V16.091ZM1.09091 15.0001H22.9091V4.52739C22.9091 4.41166 22.8631 4.30067 22.7813 4.21884C22.6994 4.13701 22.5885 4.09103 22.4727 4.09103H1.52727C1.41154 4.09103 1.30055 4.13701 1.21872 4.21884C1.13688 4.30067 1.09091 4.41166 1.09091 4.52739V15.0001ZM9.27273 21.5456H9.22909C9.08443 21.5456 8.94569 21.4881 8.8434 21.3858C8.7411 21.2835 8.68364 21.1448 8.68364 21.0001C8.68265 20.9104 8.70522 20.822 8.74909 20.7438L9.29455 19.3638H1.09091C0.801582 19.3638 0.524105 19.2488 0.31952 19.0442C0.114935 18.8397 0 18.5622 0 18.2728V4.09103C0 3.8017 0.114935 3.52423 0.31952 3.31964C0.524105 3.11506 0.801582 3.00012 1.09091 3.00012H22.9091C23.1984 3.00012 23.4759 3.11506 23.6805 3.31964C23.8851 3.52423 24 3.8017 24 4.09103V18.2728C24 18.5622 23.8851 18.8397 23.6805 19.0442C23.4759 19.2488 23.1984 19.3638 22.9091 19.3638H14.6891L15.2345 20.7928C15.2582 20.8595 15.2711 20.9294 15.2727 21.0001C15.2727 21.098 15.2463 21.1941 15.1964 21.2783C15.133 21.3837 15.0374 21.466 14.9236 21.5128C14.8601 21.5332 14.794 21.5443 14.7273 21.5456H9.27273ZM10.4673 19.3638L10.0527 20.4547H13.9255L13.5109 19.3638H10.4673Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27974">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
