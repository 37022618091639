import React from 'react'

export const Hospital = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_28059)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5455 8.23622V0.599854H5.45455V8.23622H0V23.5089H24V8.23622H18.5455ZM1.09091 22.418H5.45455V9.32713H1.09091V22.418ZM9.81818 22.418H11.4545V18.5999H9.81818V22.418ZM12.5455 22.418H14.1818V18.5999H12.5455V22.418ZM17.4545 22.418H15.2727V17.5635H8.72727V22.418H6.54545V1.69076H17.4545V22.418ZM18.5455 22.418H22.9091V9.32713H18.5455V22.418ZM19.6364 12.5999H21.8182V11.5089H19.6364V12.5999ZM2.18182 12.5999H4.36364V11.5089H2.18182V12.5999ZM2.18182 15.8726H4.36364V14.7817H2.18182V15.8726ZM8.72727 11.5089H15.2727V10.418H8.72727V11.5089ZM19.6364 15.8726H21.8182V14.7817H19.6364V15.8726ZM8.72727 14.7817H15.2727V13.6908H8.72727V14.7817ZM10.2655 7.69076L10.6255 8.84713C10.6585 8.98602 10.7195 9.1167 10.8049 9.23114C10.8902 9.34557 10.9981 9.44135 11.1218 9.51258C11.2488 9.44146 11.359 9.3437 11.4446 9.22601C11.5303 9.10832 11.5895 8.97349 11.6182 8.83076L12.5455 5.36713L12.6818 7.54895C12.676 7.66857 12.7096 7.7868 12.7777 7.88538C12.8457 7.98396 12.9443 8.05741 13.0582 8.0944C13.1701 8.12407 13.2886 8.11733 13.3965 8.07517C13.5044 8.033 13.596 7.95759 13.6582 7.85985L14.2036 6.98167L14.6891 7.45076C14.9332 7.58078 15.2036 7.65353 15.48 7.66349H16.3036V6.57258H15.4636C15.3374 6.56312 15.2137 6.53174 15.0982 6.47985L14.5527 6.16895C14.3875 6.07725 14.1949 6.04774 14.0098 6.08576C13.8247 6.12379 13.6593 6.22682 13.5436 6.37622L13.4945 6.43622L13.2709 2.78167C13.2709 2.34531 12.9655 2.26894 12.8455 2.23622C12.7255 2.20349 12.4418 2.23622 12.3273 2.66713L11.1 7.23258L11.0073 6.94349C10.9789 6.84487 10.9275 6.75439 10.8573 6.67949C10.7871 6.60458 10.7002 6.54738 10.6036 6.51258C10.5051 6.4787 10.3999 6.46845 10.2966 6.48266C10.1933 6.49687 10.0949 6.53515 10.0091 6.5944L9.59454 6.86713C9.49327 6.92179 9.38191 6.9552 9.26727 6.96531H7.71273V8.05622H9.27273C9.55783 8.04606 9.83565 7.96346 10.08 7.81622L10.2655 7.69076Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_28059">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
