import React from 'react'

export const Email = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM17.577 13.202C17.14 14.124 16.292 14.653 15.25 14.653C14.378 14.653 13.697 14.184 13.321 13.572C12.727 14.348 11.8 14.857 10.75 14.857C8.958 14.857 7.5 13.399 7.5 11.607V10.321C7.5 8.529 8.958 7.071 10.75 7.071C12.542 7.071 14 8.529 14 10.321V12.5C14 13.057 14.502 13.653 15.25 13.653C15.899 13.653 16.405 13.341 16.673 12.775C16.93 12.232 16.996 11.305 17 10.994C17 7.58 14.42 5 11 5C7.691 5 5 7.691 5 11C5 14.309 7.691 17 11 17C12.05 17 13.08 16.721 13.982 16.205L14.698 16.921C13.592 17.611 12.315 18 11 18C7.14 18 4 14.86 4 11C4 7.14 7.14 4 11 4C14.991 4 18 7.009 18 11C18 11.061 17.979 12.352 17.577 13.202ZM10.75 8.071C9.509 8.071 8.5 9.08 8.5 10.321V11.607C8.5 12.848 9.509 13.857 10.75 13.857C11.991 13.857 13 12.848 13 11.607V10.321C13 9.081 11.991 8.071 10.75 8.071Z"
      />
    </svg>
  )
}
