import React, { forwardRef } from 'react'
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from '@chakra-ui/react'

import type { StyleProps } from '../../core/style-props'

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type HeadingProps = Pick<
  ChakraHeadingProps,
  'size' | 'as' | 'children' | 'sx' | 'tabIndex'
> &
  StyleProps

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  (props, ref) => {
    return <ChakraHeading {...props} ref={ref} />
  }
)

Heading.displayName = 'Heading'
