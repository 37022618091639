import React from 'react'

export const Flag = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 21H3V1C3 0.448 2.552 0 2 0H1C0.448 0 0 0.448 0 1V21ZM9.332 1.021C9.332 1.021 7.138 0.117 4 0.00899994V15.225C7.138 15.333 9.332 16.237 9.332 16.237C13.325 17.658 17.855 17.576 22 15.643V0.428C17.855 2.36 13.325 2.443 9.332 1.021Z"
        fill="currentColor"
      />
    </svg>
  )
}
