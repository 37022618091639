import { SystemStyleFunction, mode } from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleFunction = (props) => {
  return {
    fontFamily: 'heading',
    fontWeight: 'bold',
    color: mode('light.text.heading', 'dark.text.heading')(props),
  }
}

const sizes: Record<string, SystemStyleFunction> = {
  '2xl': (props) => ({
    ...props.theme.textStyles['2xl'],
  }),
  xl: (props) => ({
    ...props.theme.textStyles['xl'],
  }),
  lg: (props) => ({
    ...props.theme.textStyles['lg'],
  }),
  md: (props) => ({
    ...props.theme.textStyles['md'],
  }),
  sm: (props) => ({
    ...props.theme.textStyles['sm'],
  }),
  xs: (props) => ({
    ...props.theme.textStyles['xs'],
  }),
  '2xs': (props) => ({
    ...props.theme.textStyles['2xs'],
  }),
  '3xs': (props) => ({
    ...props.theme.textStyles['3xs'],
  }),
}

const defaultProps = {
  size: 'xl',
}

export const Heading = {
  baseStyle,
  sizes,
  defaultProps,
}
