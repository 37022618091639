import React from 'react'

export const Logout = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 20H3V4H13V7H15V3C15 2.448 14.552 2 14 2H2C1.448 2 1 2.448 1 3V21C1 21.552 1.448 22 2 22H14C14.552 22 15 21.552 15 21V17H13V20ZM22.393 11.293L18.393 7.293C18.002 6.902 17.37 6.902 16.979 7.293C16.588 7.684 16.588 8.316 16.979 8.707L19.271 11H9C8.448 11 8 11.447 8 12C8 12.553 8.448 13 9 13H19.271L16.978 15.293C16.587 15.684 16.587 16.316 16.978 16.707C17.174 16.902 17.43 17 17.685 17C17.941 17 18.197 16.902 18.392 16.707L22.392 12.707C22.783 12.316 22.783 11.683 22.393 11.293Z"
        fill="currentColor"
      />
    </svg>
  )
}
