import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export const RAGOffTarget = (props: React.SVGProps<SVGSVGElement>) => {
  const textColor = useColorModeValue('#FFFFFF', '#121212')
  const bgColor = useColorModeValue('#D13400', '#FA3E00')

  return (
    <svg
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.9995 16.1684V7.83239C21.9995 6.29881 21.1227 4.90023 19.7423 4.23204L12.7426 0.843655C11.6418 0.310793 10.3577 0.310793 9.25692 0.843655L2.25717 4.23204C0.876812 4.90023 0 6.29881 0 7.83239V16.1684C0 17.7021 0.876925 19.1008 2.25742 19.7689L9.25707 23.1566C10.3577 23.6893 11.6415 23.6893 12.7422 23.1566L19.742 19.7689C21.1226 19.1008 21.9995 17.7021 21.9995 16.1684Z"
        fill={bgColor}
      />
      <path
        opacity="0.1"
        d="M22.0002 16.2326V7.81204C22.0002 6.27297 21.1172 4.87041 19.7293 4.20509L12.8337 0.899414L1.83398 19.5577L9.36556 23.1682C10.4615 23.6936 11.7368 23.6922 12.8315 23.1645L19.7371 19.8358C21.1207 19.1689 22.0002 17.7686 22.0002 16.2326Z"
        fill="#32383D"
      />
      <path
        d="M11 9V13.3153"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15.9043H11.0086"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6246 16.6579V7.34268C20.6246 6.579 20.1897 5.88197 19.5037 5.54627L11.8789 1.81477C11.3243 1.54332 10.6753 1.54332 10.1206 1.81477L2.49585 5.54627C1.8099 5.88197 1.375 6.579 1.375 7.34268V16.6579C1.375 17.4217 1.80996 18.1187 2.49598 18.4544L10.1207 22.1852C10.6753 22.4566 11.3241 22.4566 11.8787 22.1852L19.5036 18.4544C20.1896 18.1187 20.6246 17.4217 20.6246 16.6579Z"
        stroke={textColor}
      />
    </svg>
  )
}
