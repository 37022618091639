import React from 'react'

export const Caffeine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 2H18.423L17.557 0.5C17.378 0.191 17.048 0 16.691 0H3.31C2.952 0 2.622 0.191 2.443 0.5L1.577 2H1C0.448 2 0 2.448 0 3C0 3.552 0.448 4 1 4H19C19.552 4 20 3.552 20 3C20 2.448 19.552 2 19 2ZM3.387 20.13C3.453 20.628 3.877 21 4.378 21H15.621C16.123 21 16.547 20.628 16.613 20.13L17.947 10H2.053L3.387 20.13ZM1.921 9H18.078L18.605 5H1.395L1.921 9Z"
        fill="currentColor"
      />
    </svg>
  )
}
