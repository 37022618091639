import React from 'react'

export const CaloriesBurned = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7101 1.14C13.3201 2.2 18.7801 5.98 21.1201 10.14C21.5468 11.1392 21.7252 12.2269 21.6401 13.31C21.6401 15.8667 20.6245 18.3187 18.8166 20.1265C17.0088 21.9344 14.5568 22.95 12.0001 22.95C9.44342 22.95 6.99145 21.9344 5.18359 20.1265C3.37574 18.3187 2.3601 15.8667 2.3601 13.31C2.35369 12.0467 2.60196 10.7952 3.0901 9.63C3.4201 9.06 6.7201 3.63 11.7101 1.09V1.14ZM11.7701 0C5.8901 2.72 2.1901 9.23 2.1901 9.23C1.58024 10.6822 1.30038 12.2516 1.37073 13.8251C1.44108 15.3986 1.85988 16.9368 2.59691 18.3288C3.33394 19.7207 4.37081 20.9317 5.63268 21.8743C6.89455 22.8169 8.34995 23.4675 9.89382 23.7793C11.4377 24.0911 13.0315 24.0563 14.5603 23.6773C16.0891 23.2983 17.5146 22.5846 18.7341 21.5878C19.9536 20.5909 20.9365 19.3358 21.612 17.913C22.2874 16.4901 22.6386 14.935 22.6401 13.36C22.735 12.109 22.515 10.8541 22.0001 9.71C19.0901 4.47 11.7701 0 11.7701 0Z"
        fill="currentColor"
      />
      <path
        d="M17.2901 22.14L16.2901 21.86C16.6295 20.7139 16.7159 19.5078 16.5433 18.325C16.3706 17.1423 15.943 16.0112 15.2901 15.01C14.0001 15.94 12.8901 16.18 12.0001 15.71C10.3701 14.87 10.0001 11.9 10.0001 9.98998C6.70005 15.12 7.40005 21.87 7.41005 21.99L6.41005 22.11C6.41005 21.76 5.54005 13.68 10.15 8.10998C10.2226 8.03586 10.316 7.98562 10.4179 7.96597C10.5197 7.94631 10.6251 7.95818 10.7201 7.99998C10.8189 8.03853 10.9025 8.10812 10.9584 8.19828C11.0143 8.28844 11.0395 8.39431 11.0301 8.49998C10.8901 10.6 11.1401 14.19 12.4501 14.87C13.2001 15.26 14.2901 14.59 15.0601 13.96C15.1624 13.8771 15.2934 13.8379 15.4244 13.851C15.5555 13.8642 15.6761 13.9285 15.7601 14.03C16.6361 15.1656 17.2314 16.4922 17.4973 17.9016C17.7632 19.311 17.6922 20.7632 17.2901 22.14Z"
        fill="currentColor"
      />
    </svg>
  )
}
