import React from 'react'

export const Menu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 17C0 17.55 0.45 18 1 18H19C19.55 18 20 17.55 20 17C20 16.45 19.55 16 19 16H1C0.45 16 0 16.45 0 17ZM0 9C0 9.55 0.45 10 1 10H19C19.55 10 20 9.55 20 9C20 8.45 19.55 8 19 8H1C0.45 8 0 8.45 0 9ZM1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2H19C19.55 2 20 1.55 20 1C20 0.45 19.55 0 19 0H1Z"
        fill="currentColor"
      />
    </svg>
  )
}
