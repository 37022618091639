import { ThemeConfig, theme as defaultTheme } from '@chakra-ui/react'

import { styles } from './styles'

import { foundations } from './foundations'
import * as components from './components'

export const dnaConfig: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  cssVarPrefix: 'dna',
}

const theme = {
  ...defaultTheme,
  ...foundations,
  components: {
    ...defaultTheme.components,
    ...components,
  },
  styles,
  config: dnaConfig,
}

export default theme
