import React from 'react'

export const Image = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.081 12.592L9.559 16.113C8.601 17.072 7.034 17.07 6.078 16.109L5.892 15.922C5.307 15.334 4.355 15.332 3.768 15.919L0 19.686V20C0 20.552 0.448 21 1 21H17C17.552 21 18 20.552 18 20V15.389L15.203 12.592C14.619 12.008 13.665 12.008 13.081 12.592ZM4.5 8C3.673 8 3 8.673 3 9.5C3 10.327 3.673 11 4.5 11C5.327 11 6 10.327 6 9.5C6 8.673 5.327 8 4.5 8ZM21 0H4C3.448 0 3 0.448 3 1V4H17C18.104 4 19 4.895 19 6V16H21C21.552 16 22 15.552 22 15V1C22 0.448 21.552 0 21 0ZM17 5H1C0.448 5 0 5.448 0 6V18.272L3.061 15.212C4.039 14.234 5.625 14.236 6.601 15.216L6.791 15.407C7.358 15.977 8.281 15.978 8.85 15.41L12.375 11.885C13.351 10.909 14.934 10.909 15.911 11.885L18.001 13.975V6C18 5.448 17.552 5 17 5ZM4.5 12C3.119 12 2 10.881 2 9.5C2 8.119 3.119 7 4.5 7C5.881 7 7 8.119 7 9.5C7 10.881 5.881 12 4.5 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
