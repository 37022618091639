import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'
import { focusShadow } from '../../dna/utils'

const baseStyle: SystemStyleFunction = (props) => {
  const boxShadow = focusShadow(props)
  const color = mode(
    'light.brand.primaryFocus',
    'dark.brand.primaryFocus'
  )(props)
  const disabledColor = mode(
    'light.text.textInverted',
    'light.text.textInverted'
  )(props)
  const inactive = mode(
    'light.functional.inactive',
    'dark.functional.inactive'
  )(props)
  const borderColor = mode('light.brand.primary', 'dark.brand.primary')(props)

  return {
    bg: 'transpatent',
    color: color,
    borderColor,
    border: '2px',
    minWidth: 10,
    px: 4,
    height: '48px',
    lineHeight: '1.2',
    borderRadius: '100%',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    transition: 'none',
    boxShadow: mode('buttonOutlineLight', 'buttonOutlineDark')(props),
    _disabled: {
      opacity: 1.0,
      cursor: 'not-allowed',
      boxShadow: 'none',
      bg: inactive,
      color: disabledColor,
    },
    _hover: {
      boxShadow,
      _disabled: {
        boxShadow: 'none',
      },
    },
    _pressed: {
      boxShadow,
    },
    _focus: {
      boxShadow,
    },
  }
}

export const CloseButton = {
  baseStyle,
}
