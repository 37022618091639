import React, { forwardRef } from 'react'
import {
  Stack as ChakraStack,
  VStack as ChakraVStack,
  HStack as ChakraHStack,
  StackProps as ChakraStackProps,
} from '@chakra-ui/react'
import { SpaceProps } from '@chakra-ui/react'

export type StackProps = Pick<
  ChakraStackProps,
  // currently chakra doesnt export StackOptions so we have to list them explicitly
  | 'role'
  | 'align'
  | 'justify'
  | 'wrap'
  | 'spacing'
  | 'direction'
  | 'divider'
  | 'shouldWrapChildren'
  | 'isInline'
  | 'children'
  // Additional CSS props that we want
  | 'width'
  | 'height'
  | keyof SpaceProps
  | 'sx'
>

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ sx, ...props }, ref) => {
    return <ChakraStack {...props} ref={ref} sx={sx} />
  }
)

Stack.displayName = 'Stack'

export const HStack = forwardRef<HTMLDivElement, StackProps>(
  ({ sx, ...props }, ref) => {
    return <ChakraHStack {...props} ref={ref} sx={sx} />
  }
)

HStack.displayName = 'HStack'

export const VStack = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  return <ChakraVStack {...props} ref={ref} />
})

VStack.displayName = 'VStack'
