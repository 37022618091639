import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import dnaTheme, { dnaConfig } from '../dna'

import { colors } from './colors'

const config: ThemeConfig = {
  ...dnaConfig,
  cssVarPrefix: 'telus',
}

const overrides = {
  config,
  colors,
}

export default extendTheme(overrides, dnaTheme)
