import React from 'react'

export const Stop = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 0C17.55 0 18 0.45 18 1V17C18 17.55 17.55 18 17 18H1C0.45 18 0 17.55 0 17V1C0 0.45 0.45 0 1 0H17Z"
        fill="currentColor"
      />
    </svg>
  )
}
