import React from 'react'

export const Family = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 7.774C19.943 7.774 21.112 6.604 21.112 5.162V4.612C21.112 3.169 19.942 2 18.5 2C17.058 2 15.887 3.17 15.887 4.612V5.162C15.887 6.604 17.057 7.774 18.5 7.774ZM5.5 5.774C6.943 5.774 8.113 4.604 8.113 3.162V2.612C8.113 1.169 6.943 0 5.5 0C4.057 0 2.888 1.169 2.888 2.611V3.161C2.888 4.604 4.057 5.774 5.5 5.774ZM18.5 8.549C17.135 8.557 15.986 8.857 15.085 9.413C15.017 10.384 14.581 11.256 13.893 11.865C16.504 12.607 18 14.619 18 17.549V21.549H24V13.549C24 10.27 21.861 8.57 18.5 8.549ZM9.107 11.865C8.36 11.203 7.888 10.236 7.888 9.161V8.611C7.888 8.06 8.022 7.543 8.244 7.075C7.46 6.745 6.549 6.555 5.5 6.549C2.139 6.57 0 8.27 0 11.549V21.549H5V17.549C5 14.619 6.496 12.607 9.107 11.865ZM11.5 12.549C8.139 12.57 6 14.27 6 17.549V21.549H17V17.549C17 14.27 14.861 12.57 11.5 12.549ZM11.5 11.774C12.943 11.774 14.113 10.604 14.113 9.161V8.611C14.113 7.168 12.943 5.998 11.5 5.998C10.057 5.998 8.887 7.168 8.887 8.611V9.161C8.887 10.604 10.057 11.774 11.5 11.774Z"
        fill="currentColor"
      />
    </svg>
  )
}
