import React from 'react'

export const Feedback = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0H2C0.9 0 0 0.9 0 2V19.586C0 20.477 1.077 20.923 1.707 20.293L5 17H18C19.1 17 20 16.1 20 15V2C20 0.9 19.1 0 18 0ZM11 13H4V12H11V13ZM16 9H4V8H16V9ZM16 5H4V4H16V5Z"
        fill="currentColor"
      />
    </svg>
  )
}
