import React from 'react'

export const Email = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_28057)">
        <path
          d="M2.262 22.1999H21.75L12.27 15.6779C12.1907 15.6229 12.0965 15.5934 12 15.5934C11.9035 15.5934 11.8093 15.6229 11.73 15.6779L2.262 22.1999ZM3.6 10.4339V7.97385L1.836 9.17385L3.6 10.4339ZM1.2 10.2299V21.3839L9.282 15.8939L1.2 10.2299ZM20.4 10.4339L22.164 9.19785L20.4 7.99785V10.4339ZM13.65 15.1619L19.2 11.2739V5.39985H4.8V11.2799L10.35 15.1739L11.304 14.5259C11.5108 14.3899 11.7526 14.317 12 14.3159C12.2539 14.3186 12.5024 14.3891 12.72 14.5199L13.65 15.1619ZM22.8 10.2299L14.712 15.8939L22.8 21.4919V10.2299ZM8.784 4.19985H15.216L12 2.03385L8.784 4.19985ZM6.684 4.19985L12 0.599854L17.316 4.19985H20.4V6.47985L24 8.99985V9.38985V23.3999H0V9.38985V8.99985L3.6 6.47985V4.19985H6.684Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_28057">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
