import React from 'react'

export const Test = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0H6.022V4H9V0ZM18 19H17.558C18.893 17.53 19.583 15.644 19.493 13.658C19.312 9.645 15.941 6.5 11.82 6.5H10.989V6C10.989 5.45 10.539 5 9.989 5H5C4.45 5 4 5.45 4 6V12C4 12.55 4.45 13 5 13H9.989C10.539 13 10.989 12.55 10.989 12V9.5H11.82C14.333 9.5 16.387 11.386 16.496 13.794C16.552 15.019 16.113 16.184 15.261 17.075C14.404 17.971 13.248 18.467 11.999 18.49V16H3V19H2C0.895 19 0 19.896 0 21V22H20V21C20 19.896 19.105 19 18 19Z"
        fill="currentColor"
      />
    </svg>
  )
}
