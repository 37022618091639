import React from 'react'

export const Edit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_28038)">
        <path
          d="M3.1413 18.7604L1.67413 22.1994L5.24816 20.8144L3.1413 18.7604ZM6.28105 20.21L22.6606 3.83046C22.7699 3.7205 22.8312 3.57176 22.8312 3.41672C22.8312 3.26167 22.7699 3.11293 22.6606 3.00297L20.9997 1.34214C20.8898 1.23283 20.741 1.17148 20.586 1.17148C20.4309 1.17148 20.2822 1.23283 20.1722 1.34214L3.77512 17.7392L6.28105 20.21ZM2.57204 17.2756L19.333 0.514651C19.6631 0.184947 20.1106 -0.000244141 20.5772 -0.000244141C21.0437 -0.000244141 21.4912 0.184947 21.8213 0.514651L23.4822 2.16962C23.6463 2.33319 23.7765 2.52755 23.8654 2.74155C23.9543 2.95555 24 3.185 24 3.41672C24 3.64844 23.9543 3.87788 23.8654 4.09188C23.7765 4.30589 23.6463 4.50025 23.4822 4.66381L6.72707 21.4248C6.6636 21.4981 6.58295 21.5546 6.49232 21.5891L0.799695 23.7723C0.691752 23.8143 0.573793 23.8234 0.460674 23.7985C0.347556 23.7736 0.244337 23.7157 0.164018 23.6323C0.083699 23.5488 0.0298719 23.4435 0.00931608 23.3295C-0.0112398 23.2155 0.00239476 23.098 0.0485027 22.9917L2.39598 17.5162C2.43302 17.4271 2.49148 17.3485 2.56617 17.2874L2.57204 17.2756Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_28038">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
