import React from 'react'

export const Map = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.18542L16 0V19.8146L8 22V2.18542ZM17 0.27331V20.0879L22.741 21.6563C23.376 21.8297 24 21.3412 24 20.6712V2.96862C24 2.50666 23.696 2.10282 23.259 1.9835L17 0.27331ZM7 1.91211L1.259 0.343669C0.624 0.170304 0 0.658785 0 1.32879V19.0314C0 19.4933 0.304 19.8972 0.741 20.0165L7 21.7267V1.91211Z"
        fill="currentColor"
      />
    </svg>
  )
}
