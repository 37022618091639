import React from 'react'

export const VideoOff = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.867 4.30397C16.583 3.54597 15.855 2.99997 15 2.99997H2C0.9 2.99997 0 3.89997 0 4.99997V19C0 19.594 0.264 20.126 0.679 20.493L16.867 4.30397ZM22.5 6.86597L17 10.041V8.41397L23.706 1.70697C24.095 1.31797 24.095 0.681971 23.706 0.292971C23.317 -0.0960293 22.681 -0.0960293 22.292 0.292971L0.294 22.292C-0.095 22.681 -0.095 23.317 0.294 23.706C0.683 24.095 1.319 24.095 1.708 23.706L4.414 21H15C16.1 21 17 20.1 17 19V13.959L22.5 17.134C23.167 17.519 24 17.038 24 16.268V7.73197C24 6.96197 23.167 6.48097 22.5 6.86597Z"
        fill="currentColor"
      />
    </svg>
  )
}
