import React, { forwardRef } from 'react'
import {
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react'

import type { StyleProps } from '../../core/style-props'

export type TextProps = Pick<
  ChakraTextProps,
  'textStyle' | 'as' | 'children' | 'fontWeight' | 'fontStyle' | 'sx'
> &
  StyleProps

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  (props, ref) => {
    return <ChakraText {...props} ref={ref} />
  }
)

Text.displayName = 'Text'
