import React from 'react'

export const Fluid = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.19995 17.3333C1.19995 19.1014 1.90233 20.7971 3.15257 22.0474C4.40282 23.2976 6.09851 24 7.86662 24C9.63473 24 11.3304 23.2976 12.5807 22.0474C13.8309 20.7971 14.5333 19.1014 14.5333 17.3333C14.5333 13.1933 9.43328 5.08 7.86662 2.66667C6.29995 5.06 1.19995 13.1733 1.19995 17.3333ZM7.86662 22.6667C6.45213 22.6667 5.09558 22.1048 4.09538 21.1046C3.09519 20.1044 2.53328 18.7478 2.53328 17.3333C2.53328 14.6667 5.11995 9.6 7.86662 5.16667C10.6133 9.58 13.2 14.6667 13.2 17.3333C13.2 18.7478 12.638 20.1044 11.6379 21.1046C10.6377 22.1048 9.28111 22.6667 7.86662 22.6667ZM19.1999 0C18.42 1.19333 15.8666 5.25333 15.8666 7.33333C15.8666 8.21739 16.2178 9.06524 16.8429 9.69036C17.468 10.3155 18.3159 10.6667 19.1999 10.6667C20.084 10.6667 20.9319 10.3155 21.557 9.69036C22.1821 9.06524 22.5333 8.21739 22.5333 7.33333C22.5333 5.26 19.9799 1.2 19.1999 0ZM19.1999 9.33333C18.6695 9.33333 18.1608 9.12262 17.7857 8.74755C17.4107 8.37247 17.2 7.86377 17.2 7.33333C17.2 6.47333 17.9666 4.66667 19.1999 2.54C20.4333 4.66667 21.1999 6.46667 21.1999 7.33333C21.1999 7.86377 20.9892 8.37247 20.6142 8.74755C20.2391 9.12262 19.7304 9.33333 19.1999 9.33333Z"
        fill="currentColor"
      />
    </svg>
  )
}
