import React from 'react'

export const Back = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.89133 11.3478L7.22611 12L7.88481 12.6522L18.3131 23.0739L17.387 24L5.40002 12.0065L5.41307 12L5.40002 11.9935L17.387 0L18.3131 0.919565L7.89133 11.3478Z"
        fill="currentColor"
      />
    </svg>
  )
}
