import React from 'react'

export const OnTarget = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="4" width="16" height="16" fill="#DEFBE6" />
      <path
        d="M13.0668 8.44434L13.5966 8.91367L7.26411 16.0497L3.55566 12.2594L4.06411 11.7617L7.26411 15.0043L13.0668 8.44434Z"
        fill="#1B883C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9496 8.89246L7.273 16.4163L3.20215 12.2557L4.06711 11.4088L7.25408 14.6383L13.0452 8.09131L13.9496 8.89246ZM13.0667 8.44434L13.5965 8.91368L7.26407 16.0497L3.55563 12.2595L4.06407 11.7617L7.26407 15.0043L13.0667 8.44434Z"
        fill="#1B883C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3026 8.87126L7.28189 16.7829L2.84863 12.2519L4.07011 11.056L7.24406 14.2723L13.0237 7.73828L14.3026 8.87126ZM13.0452 8.09132L13.9495 8.89247L7.27296 16.4163L3.2023 12.2559L4.06707 11.4088L7.25405 14.6383L13.0452 8.09132ZM13.0667 8.44435L13.5965 8.91367L7.26404 16.0497L3.55559 12.2595L4.06404 11.7617L7.26404 15.0043L13.0667 8.44435ZM4.061 12.1145L7.27402 15.3704L13.0882 8.79738L13.2434 8.93489L7.25511 15.6831L3.90907 12.2633L4.061 12.1145Z"
        fill="#1B883C"
      />
    </svg>
  )
}
