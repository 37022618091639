import React, { forwardRef } from 'react'
import {
  CloseButton as ChakraCloseButton,
  CloseButtonProps as ChakraCloseButtonProps,
  useStyleConfig,
} from '@chakra-ui/react'
import { Icon } from '../Icon'

export type CloseButtonProps = ChakraCloseButtonProps

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  (props, ref) => {
    const iconStyle = useStyleConfig('Icon', { variant: 'closeIconButton' })

    return (
      <ChakraCloseButton {...props} ref={ref}>
        <Icon icon="Close" sx={iconStyle} />
      </ChakraCloseButton>
    )
  }
)

CloseButton.displayName = 'CloseButton'
