import React from 'react'

export const Unit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7494 6.25693L9.94486 4.44332L7.13785 7.26448L6.33584 6.55919L9.14286 3.73804L7.73935 2.2267C7.4386 1.92443 6.83709 1.92443 6.53634 2.2267L2.22556 6.45844C1.92481 6.76071 1.92481 7.36524 2.22556 7.66751L16.2607 21.7733C16.5614 22.0756 17.1629 22.0756 17.4637 21.7733L21.7744 17.4408C22.0752 17.1385 22.0752 16.534 21.7744 16.2317L20.2707 14.7204L17.4637 17.5416L16.7619 16.8363L19.5689 14.0151L17.6642 12.1008L16.2607 13.5113L15.5589 12.806L16.9624 11.3955L15.0576 9.48111L12.2506 12.3023L11.5489 11.597L14.3559 8.77582L12.4511 6.96222L11.0476 8.3728L10.3459 7.66751L11.7494 6.25693Z"
        fill="currentColor"
      />
    </svg>
  )
}
