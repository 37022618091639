import React from 'react'

export const Sexual = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.9639 3.49999C23.7329 1.74299 22.3199 0.301991 20.5669 0.0449906C19.0399 -0.179009 17.6489 0.452991 16.7919 1.52999C15.9359 0.452991 14.5439 -0.179009 13.0169 0.0449906C11.2649 0.301991 9.85089 1.74399 9.62089 3.49999C9.48089 4.56399 9.75889 5.55899 10.3089 6.34899L10.3059 6.34999L15.7789 13.021C16.3029 13.66 17.2809 13.66 17.8049 13.021L23.2779 6.34999L23.2749 6.34899C23.8259 5.55799 24.1039 4.56499 23.9639 3.49999ZM14.3789 14C14.1489 12.244 12.7349 10.802 10.9819 10.545C9.45489 10.321 8.06388 10.953 7.20688 12.03C6.34988 10.953 4.95788 10.321 3.43189 10.545C1.67989 10.802 0.265885 12.244 0.0358851 14C-0.104115 15.064 0.173885 16.058 0.723885 16.849L0.720885 16.85L6.19388 23.521C6.71789 24.16 7.69589 24.16 8.21989 23.521L13.6929 16.85L13.6899 16.849C14.2409 16.058 14.5189 15.065 14.3789 14Z"
        fill="currentColor"
      />
    </svg>
  )
}
