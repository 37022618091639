import React from 'react'

export const Nature = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.87619 18.2914C7.13859 20.5477 9.46231 21.5226 11.7124 21.5226C14.0666 21.4375 16.3021 20.4676 17.9724 18.8064L22.1354 23.0002L22.9999 22.1357L18.8062 17.942C22.1538 14.2633 22.865 9.45028 18.2912 4.87643C15.0294 1.61464 8.59781 0.676575 4.59415 0.676575C2.7548 0.676575 1.40594 0.878904 1.1423 1.14254C0.302331 1.98251 0.112265 13.552 4.87619 18.2914ZM2.07424 2.08674C2.90654 1.94615 3.75026 1.88456 4.59415 1.90281C8.73883 1.90281 14.588 2.90832 17.4206 5.74092C19.4255 7.73969 20.3881 9.83655 20.29 11.9702C20.1281 13.8929 19.2972 15.698 17.9417 17.0713L15.3911 14.5269V8.03398H14.1649V13.3007L7.42062 6.55637L6.55613 7.42086L9.62171 10.4865H6.80751V11.7127H10.8479L17.0711 17.942C15.6411 19.3678 13.7298 20.2075 11.7124 20.2963C9.6769 20.2963 7.67813 19.3276 5.77134 17.4208C1.82899 13.5091 1.58988 4.18974 2.07424 2.08674Z"
        fill="currentColor"
      />
    </svg>
  )
}
