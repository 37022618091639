import {
  ModalBodyProps as ChakraModalProps,
  ModalBody as ChakraModalBody,
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'

export const PopOverBody = forwardRef<HTMLDivElement, ChakraModalProps>(
  (props, ref) => {
    return <ChakraModalBody {...props} ref={ref} />
  }
)

PopOverBody.displayName = 'PopOverBody'
