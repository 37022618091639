import React from 'react'

export const DownTrend = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 5.99999L8.50503 12.505C8.77839 12.7784 9.22161 12.7784 9.49498 12.505L12.505 9.49498C12.7784 9.22161 13.2216 9.22161 13.495 9.49498L21 17"
        stroke="#6A56EB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.5 13.5L21.5 16.8C21.5 17.1866 21.1866 17.5 20.8 17.5L17.5 17.5"
        stroke="#6A56EB"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
