import React from 'react'

export const Verified = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 1V16.29L12 22.77L3 16.29V1H21ZM22 0H2V16.8L12 24L22 16.8V0Z"
        fill="currentColor"
      />
      <path
        d="M10.8799 13.64L6.18994 9.93998L6.80994 9.14998L10.8799 12.36L18.1899 6.60999L18.8099 7.38999L10.8799 13.64Z"
        fill="currentColor"
      />
    </svg>
  )
}
