// export * from './typography'
import { colors } from './colors'
import radii from './radius'
import { typography } from './typography'

export const foundations = {
  colors,
  radii,
  ...typography,
}
