import React from 'react'

export const Attachment = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.5956 3.42272C19.7239 1.52576 16.6762 1.52576 14.8046 3.42272L5.22688 13.1302C3.97881 14.3952 3.97881 16.4539 5.22688 17.7189C5.8317 18.3319 6.63551 18.6693 7.49054 18.6693C8.34655 18.6693 9.15035 18.3319 9.75419 17.7189L16.8939 10.4824C17.279 10.0921 17.279 9.46106 16.8939 9.07069C16.5087 8.68031 15.8862 8.68031 15.501 9.07069L8.36132 16.3071C7.89539 16.7794 7.08568 16.7794 6.61975 16.3071C6.38727 16.0715 6.25921 15.758 6.25921 15.4245C6.25921 15.0911 6.38727 14.7776 6.61975 14.5419L16.1974 4.83446C17.3017 3.71526 19.0984 3.71526 20.2027 4.83446C21.3069 5.95367 21.3069 7.77376 20.2027 8.89297L10.5373 18.6893C8.80953 20.4405 5.99719 20.4405 4.2694 18.6893C3.43112 17.8417 2.97011 16.7135 2.97011 15.5134C2.97011 14.3133 3.43112 13.1851 4.26842 12.3365L11.5815 4.92332C11.9666 4.53295 11.9666 3.90196 11.5815 3.51158C11.1963 3.12121 10.5738 3.12121 10.1886 3.51158L2.87555 10.9247C1.66688 12.1508 1 13.7792 1 15.5134C1 17.2466 1.6659 18.877 2.87555 20.103C4.12361 21.369 5.76373 22 7.40286 22C9.042 22 10.6821 21.368 11.9302 20.103L21.5956 10.3067C23.4681 8.40875 23.4681 5.32069 21.5956 3.42272Z"
        fill="currentColor"
      />
    </svg>
  )
}
