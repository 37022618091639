import * as React from 'react'
export const OffTarget = (
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg width={16} height={24} viewBox="0 0 16 24" fill="none" {...props}>
    <rect y="4" width="16" height="16" fill="#FCECF4" d="M0 2h16v16H0z" />
    <path
      transform="translate(0,2)"
      d="M8.389 4.667h-.711v6.822h.71V4.667zM7.737 14.888A.533.533 0 108.33 14a.533.533 0 00-.593.887z"
      fill="#D60023"
    />
    <path
      transform="translate(0,2)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.428 4.417h1.21v7.322h-1.21V4.417zm.605 10.81a.783.783 0 110-1.565.783.783 0 010 1.566zm.356-10.56v6.822h-.711V4.667h.71zm-.356 10.31a.533.533 0 110-1.066.533.533 0 010 1.067z"
      fill="#D60023"
    />
    <path
      transform="translate(0,2)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.178 4.167h1.71v7.822h-1.71V4.167zm1.586 11.008a1.033 1.033 0 11-1.461-1.461 1.033 1.033 0 011.461 1.461zm-.993-.266a.533.533 0 10.158-.988.534.534 0 00-.158.988zm.262.319a.783.783 0 110-1.566.783.783 0 010 1.566zm-.157-.548a.283.283 0 10.315-.47.283.283 0 00-.315.47zm.263-9.763h-.211v6.322h.21V4.917zm-.711-.5v7.322h1.21V4.417h-1.21zm.96.25h-.71v6.822h.71V4.667z"
      fill="#D60023"
    />
  </svg>
)
