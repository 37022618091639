import React from 'react'

export const Appointment = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27841)">
        <path
          d="M19.2001 2.99985H24.0001V23.3999H6.10352e-05V2.99985H4.80006V0.599854H6.00006V2.99985H18.0001V0.599854H19.2001V2.99985ZM22.8001 8.99985H1.20006V22.1999H22.8001V8.99985ZM22.8001 7.79985V4.19985H19.2001V5.39985H18.0001V4.19985H6.00006V5.39985H4.80006V4.19985H1.20006V7.79985H22.8001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27841">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
