import React from 'react'

export const RatingOutline = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M22.5581 7.99991H15.4721L12.9421 0.917906C12.7841 0.474906 12.3921 0.253906 12.0001 0.253906C11.6081 0.253906 11.2161 0.474906 11.0581 0.917906L8.5281 7.99991H1.4421C0.494099 7.99991 0.0780994 9.19491 0.821099 9.78391L6.3811 14.1889L4.3961 21.1899C4.2011 21.8779 4.7461 22.4669 5.3601 22.4669C5.5421 22.4669 5.7311 22.4149 5.9081 22.2989L12.0001 18.2849L18.0921 22.2969C18.2691 22.4139 18.4581 22.4649 18.6401 22.4649C19.2541 22.4649 19.7991 21.8759 19.6041 21.1879L17.6191 14.1869L23.1791 9.78191C23.9221 9.19491 23.5051 7.99991 22.5581 7.99991ZM15.3301 13.4499L16.9461 19.1479L12.0001 15.8899L7.0531 19.1479L8.6701 13.4499L4.3151 9.99991H9.9381L12.0001 4.22691L14.0621 9.99991H19.6851L15.3301 13.4499Z"
      />
    </svg>
  )
}
