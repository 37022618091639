import React from 'react'

export const Monitor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 22H11V3C11 2.448 10.552 2 10 2H8C7.448 2 7 2.448 7 3V22ZM1 22H5V9C5 8.448 4.552 8 4 8H2C1.448 8 1 8.448 1 9V22ZM19 9V22H23V9C23 8.448 22.552 8 22 8H20C19.448 8 19 8.448 19 9ZM13 22H17V14C17 13.448 16.552 13 16 13H14C13.448 13 13 13.448 13 14V22Z"
        fill="currentColor"
      />
    </svg>
  )
}
