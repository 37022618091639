import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import { mode } from '@chakra-ui/theme-tools'
import type { PartsStyleFunction } from '@chakra-ui/theme-tools'
import { Input } from './input'
import { FormLabel } from './form-label'

const variantOutline: PartsStyleFunction<typeof parts> = (props) => {
  const baseStyleLabel = FormLabel.baseStyle(props)
  const fieldErrorBorderColor = mode(
    'light.status.error',
    'dark.status.error'
  )(props)

  return {
    field: {
      _invalid: {
        _focus: {
          borderColor: fieldErrorBorderColor,
        },
      },
    },
    label: {
      ...baseStyleLabel,
      marginEnd: 0,
      fontWeight: 'normal',
    },
    heading: {
      ...baseStyleLabel,
      marginEnd: 0,
      display: 'inline-flex',
    },
  }
}

const variants = {
  outline: variantOutline,
}

export const DateInput = {
  ...Input,
  variants,
}
