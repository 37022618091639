import React from 'react'

export const MoreHorizontal = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40006 12.2969C2.39842 12.5747 2.45242 12.8501 2.55888 13.1068C2.66534 13.3635 2.82212 13.5964 3.02 13.7917C3.21788 13.9871 3.45287 14.141 3.71116 14.2443C3.96945 14.3477 4.24583 14.3985 4.52406 14.3937C4.79684 14.393 5.06673 14.3378 5.31785 14.2315C5.56897 14.1251 5.79625 13.9696 5.98632 13.7743C6.17639 13.5789 6.32542 13.3475 6.42464 13.0938C6.52385 12.8401 6.57125 12.5691 6.56405 12.2969C6.56405 11.7487 6.34596 11.223 5.95776 10.8354C5.56957 10.4477 5.04305 10.23 4.49406 10.23C3.94506 10.23 3.41855 10.4477 3.03035 10.8354C2.64215 11.223 2.42406 11.7487 2.42406 12.2969H2.40006ZM9.60005 12.2969C9.60007 12.7105 9.72265 13.115 9.95236 13.4593C10.1821 13.8036 10.5086 14.0723 10.891 14.2317C11.2733 14.3911 11.6943 14.434 12.101 14.355C12.5077 14.276 12.8819 14.0787 13.1765 13.7878C13.4711 13.497 13.673 13.1256 13.7567 12.7204C13.8404 12.3153 13.8023 11.8944 13.647 11.5109C13.4917 11.1274 13.2263 10.7983 12.8841 10.565C12.542 10.3317 12.1383 10.2047 11.724 10.2C11.448 10.1992 11.1746 10.2529 10.9195 10.358C10.6643 10.4631 10.4325 10.6175 10.2374 10.8124C10.0422 11.0073 9.88754 11.2387 9.78229 11.4935C9.67703 11.7483 9.62325 12.0213 9.62405 12.2969H9.60005ZM16.8 12.2969C16.8001 12.7105 16.9226 13.115 17.1523 13.4593C17.3821 13.8036 17.7086 14.0723 18.0909 14.2317C18.4733 14.3911 18.8943 14.434 19.301 14.355C19.7077 14.276 20.0819 14.0787 20.3765 13.7878C20.6711 13.497 20.873 13.1256 20.9567 12.7204C21.0404 12.3153 21.0022 11.8944 20.847 11.5109C20.6917 11.1274 20.4263 10.7983 20.0841 10.565C19.7419 10.3317 19.3383 10.2047 18.924 10.2C18.3713 10.2048 17.843 10.4282 17.455 10.8212C17.0669 11.2143 16.8508 11.7449 16.854 12.2969H16.8Z"
        fill="currentColor"
      />
    </svg>
  )
}
