import React from 'react'

export const Tv = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 3H3V14H21V3ZM23 0H1C0.45 0 0 0.45 0 1V16C0 16.55 0.45 17 1 17H11V19H5C4.448 19 4 19.448 4 20V21H20V20C20 19.448 19.552 19 19 19H13V17H23C23.55 17 24 16.55 24 16V1C24 0.45 23.55 0 23 0ZM22 15H2V2H22V15Z"
        fill="currentColor"
      />
    </svg>
  )
}
