const white = '#FFFFFF'

const black = '#000000'

export const light = {
  brand: {
    primary: '#0079C8',
    primaryFocus: '#0D1846',
    secondary: '#0652AE',
    tertiary: '#D02670',
  },
  text: {
    heading: '#21272A',
    body: '#21272A',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: white,
    textInverted: white,
  },
  status: {
    success: '#1B883C',
    warning: '#C85204',
    error: '#D60023',
    errorFocus: '#F4D5D0',
  },
  functional: {
    inactive: '#BDC3C9',
    graphic: '#929BA2',
    divider: '#DDE1E6',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: white,
    graphicInverted: white,
    overlay: 'rgba(0,0,0,0.4)',
  },
  background: {
    backgroundPrimary: white,
    backgroundSecondary: '#F3F5F8',
    /**
     * please use buttonSecondary
     * @deprecated
     */
    buttonPurple: '#DDEDF8',
    /**
     * please use bannerInteractive
     * @deprecated
     */
    bannerPurple: '#DDEDF8',
    /**
     * please use bannerError
     * @deprecated
     */
    bannerRed: '#FCECF4',
    /**
     * please use bannerSuccess
     * @deprecated
     */
    bannerGreen: '#DEFBE6',
    /**
     * please use bannerWarning
     * @deprecated
     */
    bannerOrange: '#FBEACF',
    /**
     * please use bannerNeutral
     * @deprecated
     */
    bannerGrey: '#EBEEF2',

    buttonSecondary: '#DDEDF8',
    bannerInteractive: '#DDEDF8',
    bannerError: '#FCECF4',
    bannerSuccess: '#DEFBE6',
    bannerWarning: '#FBEACF',
    bannerNeutral: '#EBEEF2',
  },
  shadow: {
    shadow: black,
  },
}

const dark: typeof light = {
  brand: {
    primary: '#0079C8',
    primaryFocus: '#0D1846',
    secondary: '#0652AE',
    tertiary: '#D02670',
  },
  text: {
    heading: '#21272A',
    body: '#21272A',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: white,
    textInverted: white,
  },
  status: {
    success: '#1B883C',
    warning: '#C85204',
    error: '#D60023',
    errorFocus: '#F4D5D0',
  },
  functional: {
    inactive: '#BDC3C9',
    graphic: '#929BA2',
    divider: '#DDE1E6',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: white,
    graphicInverted: white,
    overlay: 'rgba(0,0,0,0.4)',
  },
  background: {
    backgroundPrimary: white,
    backgroundSecondary: '#F3F5F8',
    /**
     * please use buttonSecondary
     * @deprecated
     */
    buttonPurple: '#DDEDF8',
    /**
     * please use bannerInteractive
     * @deprecated
     */
    bannerPurple: '#DDEDF8',
    /**
     * please use bannerError
     * @deprecated
     */
    bannerRed: '#FCECF4',
    /**
     * please use bannerSuccess
     * @deprecated
     */
    bannerGreen: '#DEFBE6',
    /**
     * please use bannerWarning
     * @deprecated
     */
    bannerOrange: '#FBEACF',
    /**
     * please use bannerNeutral
     * @deprecated
     */
    bannerGrey: '#EBEEF2',

    buttonSecondary: '#DDEDF8',
    bannerInteractive: '#DDEDF8',
    bannerError: '#FCECF4',
    bannerSuccess: '#DEFBE6',
    bannerWarning: '#FBEACF',
    bannerNeutral: '#EBEEF2',
  },
  shadow: {
    shadow: black,
  },
}

export const colors = {
  white,
  black,

  light,
  dark,
}
