import { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools'
import { getModeColor } from '../../dna/utils'

const baseStyle: SystemStyleFunction = (props) => {
  const iconColor = getModeColor('brand.primaryFocus', props)
  return {
    color: iconColor,
  }
}

const variantCloseIconButton: SystemStyleObject = {
  h: '1.5rem', // 24 px
  w: '1.5rem',
}

const variantIconButton: SystemStyleObject = {
  h: '1.5rem', // 24 px
  w: '1.5rem',
}

export const Icon = {
  baseStyle,
  variants: {
    closeIconButton: variantCloseIconButton,
    'autosuggest-clear': variantIconButton,
  },
}
