import React from 'react'

export const Copy = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70012 1.09998H22.0001C22.3184 1.09998 22.6236 1.2264 22.8486 1.45145C23.0737 1.67649 23.2001 1.98172 23.2001 2.29998V16.4C23.214 16.5658 23.1932 16.7326 23.1392 16.89C23.0851 17.0473 22.9989 17.1917 22.8861 17.3139C22.7732 17.4362 22.6362 17.5336 22.4837 17.6001C22.3312 17.6665 22.1665 17.7006 22.0001 17.7H19.3001V6.79998C19.276 6.3304 19.0786 5.88645 18.7461 5.55397C18.4136 5.22149 17.9697 5.0241 17.5001 4.99998H5.50012V2.29998C5.50012 1.98172 5.62655 1.67649 5.85159 1.45145C6.07664 1.2264 6.38186 1.09998 6.70012 1.09998ZM2.00015 5.99998H17.5002C17.7049 6.0217 17.896 6.11297 18.0416 6.25856C18.1872 6.40414 18.2784 6.59524 18.3002 6.79998V21.2C18.3002 21.4122 18.2159 21.6156 18.0658 21.7657C17.9158 21.9157 17.7123 22 17.5002 22H2.00015C1.89917 22.001 1.79917 21.9802 1.70699 21.9389C1.61482 21.8977 1.53266 21.837 1.46617 21.761C1.39967 21.685 1.3504 21.5955 1.32174 21.4986C1.29309 21.4018 1.28572 21.2999 1.30015 21.2V6.79998C1.29862 6.60404 1.36904 6.41436 1.49807 6.2669C1.62709 6.11944 1.80575 6.02446 2.00015 5.99998Z"
        fill="currentColor"
      />
    </svg>
  )
}
