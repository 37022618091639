import React from 'react'

export const Invite = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 10.5C16.623 10.5 18.75 8.373 18.75 5.75V4.75C18.75 2.127 16.623 0 14 0C11.377 0 9.25 2.127 9.25 4.75V5.75C9.25 8.373 11.377 10.5 14 10.5ZM14 11.909C12.41 11.919 10.974 12.14 9.714 12.56C11.111 13.754 12 15.524 12 17.5C12 18.789 11.618 19.988 10.969 21H24C24 15.038 20.111 11.947 14 11.909ZM5.5 12C2.462 12 0 14.462 0 17.5C0 20.538 2.462 23 5.5 23C8.538 23 11 20.538 11 17.5C11 14.462 8.538 12 5.5 12ZM5.853 20.354L5.146 19.647L6.793 18H3V17H6.793L5.146 15.354L5.853 14.647L8.707 17.5L5.853 20.354Z"
        fill="currentColor"
      />
    </svg>
  )
}
