import React from 'react'

export const Prescription = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 3V21C3 21.552 3.448 22 4 22H20C20.552 22 21 21.552 21 21V3C21 2.448 20.552 2 20 2H4C3.448 2 3 2.448 3 3ZM9 7H11V5H13V7H15V9H13V11H11V9H9V7ZM18 19H6V18H18V19ZM18 15H6V14H18V15Z"
        fill="currentColor"
      />
    </svg>
  )
}
