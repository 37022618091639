import React from 'react'

export const MedicalRecords = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4L8.586 2.586C8.21 2.211 7.702 2 7.171 2H2C1.448 2 1 2.448 1 3V4H10ZM19 14H13V18H19V14ZM1 21V5H22C22.552 5 23 5.448 23 6V21C23 21.552 22.552 22 22 22H2C1.448 22 1 21.552 1 21ZM12 19H20V13H12V19Z"
        fill="currentColor"
      />
    </svg>
  )
}
