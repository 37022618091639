import React from 'react'

export const Food = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27554)">
        <path
          d="M24 5.1443C22.2806 5.17611 20.5875 5.57202 19.0324 6.30591C18.9574 6.1935 18.8718 6.08108 18.7861 5.96867L22.2174 2.53735L21.4626 1.78257L18.0313 5.21389C17.915 5.12461 17.7935 5.04239 17.6673 4.96765C18.4134 3.41555 18.8186 1.72171 18.8557 0L17.7851 0C17.7483 1.55745 17.3835 3.08964 16.7145 4.49658C15.9059 4.21023 15.0328 4.15881 14.1963 4.34829C13.3597 4.53777 12.594 4.96039 11.9877 5.56719C11.961 5.56719 8.97929 8.55956 6.04046 11.9909C2.04172 16.723 0.00219684 20.0901 0.00219684 22.0118C-0.010306 22.2763 0.0306548 22.5407 0.122631 22.789C0.214606 23.0373 0.355709 23.2646 0.537504 23.4571C0.729078 23.6388 0.955386 23.7798 1.20284 23.8718C1.45029 23.9638 1.71377 24.0048 1.97748 23.9924C3.89923 23.9924 7.26631 21.9529 11.977 17.9435C15.4297 15.0046 18.4007 12.023 18.4007 11.9962C19.0074 11.3888 19.4299 10.6221 19.6193 9.78477C19.8088 8.94741 19.7575 8.07354 19.4713 7.26411C20.8807 6.59681 22.4143 6.23211 23.9732 6.1935L24 5.1443ZM17.6673 11.2414C14.4555 14.4908 5.35527 22.9218 1.98283 22.9272C1.85978 22.9376 1.73592 22.9225 1.61895 22.8829C1.50199 22.8433 1.39444 22.78 1.30299 22.697C1.22012 22.6044 1.15698 22.4959 1.11741 22.3781C1.07783 22.2603 1.06266 22.1357 1.07281 22.0118C1.07281 18.6501 9.50389 9.54987 12.7532 6.32197C13.0712 5.97773 13.4556 5.70128 13.8831 5.50922C14.3106 5.31716 14.7725 5.21345 15.2411 5.20431C15.7097 5.19517 16.1753 5.28079 16.6099 5.45604C17.0446 5.63128 17.4394 5.89253 17.7706 6.22411C18.1019 6.5557 18.3627 6.95078 18.5375 7.38564C18.7122 7.82051 18.7973 8.28621 18.7877 8.75479C18.778 9.22336 18.6738 9.68516 18.4813 10.1125C18.2888 10.5398 18.0119 10.9238 17.6673 11.2414Z"
          fill="currentColor"
        />
        <path
          d="M14.2376 6.91824L13.4807 7.67542L16.3201 10.5138L17.077 9.75664L14.2376 6.91824Z"
          fill="currentColor"
        />
        <path
          d="M10.4538 10.7074L9.6969 11.4646L12.5363 14.303L13.2932 13.5458L10.4538 10.7074Z"
          fill="currentColor"
        />
        <path
          d="M7.1435 14.9644L6.3866 15.7216L8.27952 17.6138L9.03643 16.8567L7.1435 14.9644Z"
          fill="currentColor"
        />
        <path
          d="M3.73471 19.1271L2.97754 19.884L4.1129 21.0198L4.87007 20.2629L3.73471 19.1271Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27554">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
