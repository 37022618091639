import React from 'react'

export const VideoClip = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 0V4H18L14 0ZM13 0H1C0.448 0 0 0.448 0 1V19C0 19.552 0.448 20 1 20H17C17.552 20 18 19.552 18 19V5H13V0ZM7 14V8L12.196 11L7 14Z"
        fill="currentColor"
      />
    </svg>
  )
}
