import React from 'react'

export const Sun = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 5.99982C10.8134 5.99982 9.6534 6.35171 8.6667 7.011C7.68001 7.67029 6.91097 8.60736 6.45685 9.70372C6.00272 10.8001 5.8839 12.0065 6.11541 13.1704C6.34692 14.3342 6.91837 15.4033 7.75748 16.2425C8.5966 17.0816 9.66569 17.653 10.8296 17.8845C11.9935 18.116 13.1999 17.9972 14.2962 17.5431C15.3926 17.089 16.3297 16.3199 16.9889 15.3332C17.6482 14.3465 18.0001 13.1865 18.0001 11.9998C18.0001 10.4085 17.368 8.88239 16.2428 7.75718C15.1175 6.63196 13.5914 5.99982 12.0001 5.99982ZM12.0001 16.7998C11.0508 16.7998 10.1227 16.5183 9.33339 15.9909C8.54403 15.4634 7.9288 14.7138 7.5655 13.8367C7.2022 12.9596 7.10714 11.9945 7.29235 11.0634C7.47756 10.1323 7.93472 9.277 8.60601 8.6057C9.2773 7.93441 10.1326 7.47726 11.0637 7.29205C11.9948 7.10684 12.9599 7.20189 13.837 7.56519C14.7141 7.92849 15.4637 8.54372 15.9912 9.33308C16.5186 10.1224 16.8001 11.0505 16.8001 11.9998C16.8001 13.2729 16.2944 14.4938 15.3942 15.3939C14.4941 16.2941 13.2732 16.7998 12.0001 16.7998ZM11.4001 -0.000183105H12.6001V4.79982H11.4001V-0.000183105ZM3.09012 3.93582L3.93612 3.08982L7.33212 6.48582L6.48612 7.33182L3.09012 3.93582ZM0.00012207 12.5998V11.3998H4.80012V12.5998H0.00012207ZM3.93612 20.9098L3.09012 20.0638L6.48612 16.6678L7.33212 17.5138L3.93612 20.9098ZM12.6001 23.9998H11.4001V19.1998H12.6001V23.9998ZM20.9101 20.0638L20.0641 20.9098L16.6681 17.5138L17.5141 16.6678L20.9101 20.0638ZM24.0001 11.3998V12.5998H19.2001V11.3998H24.0001ZM20.0641 3.08982L20.9101 3.93582L17.5141 7.33182L16.6681 6.48582L20.0641 3.08982Z"
        fill="currentColor"
      />
    </svg>
  )
}
