import React from 'react'

export const Edit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M22.1502 3.23789L20.762 1.84972C20.1955 1.28324 19.453 1 18.7116 1C17.9691 1 17.2276 1.28324 16.6611 1.84972L3.00888 15.5004L1 23L8.49897 20.9902L22.1502 7.33957C23.2833 6.20661 23.2833 4.37086 22.1502 3.23789ZM7.4984 19.257L3.73393 20.2652L4.74223 16.5009L15.3725 5.87117L18.1286 8.62721L7.4984 19.257ZM20.7833 5.9717L19.4956 7.25934L16.7394 4.5033L18.0271 3.21566C18.2736 2.97012 18.5617 2.93339 18.7116 2.93339C18.8614 2.93339 19.1485 2.97012 19.395 3.21663L20.7833 4.6048C21.1603 4.98181 21.1603 5.59566 20.7833 5.9717Z"
      />
    </svg>
  )
}
