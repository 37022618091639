import React from 'react'

export const ThumbsDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M17 4.704C14.553 3.617 11.854 3 9.001 3H3.5C2.675 3 2 3.675 2 4.5C2 5.325 2.675 6 3.5 6H2C1.175 6 0.5 6.675 0.5 7.5C0.5 8.325 1.175 9 2 9H1.5C0.675 9 0 9.675 0 10.5C0 11.325 0.675 12 1.5 12C0.675 12 0 12.675 0 13.5C0 14.325 0.675 15 1.5 15H9.478C8.851 16.524 8.5 18.191 8.5 19.941C8.5 20.106 8.503 20.269 8.51 20.432C8.546 21.306 9.255 22 10.129 22C10.821 22 11.437 21.559 11.653 20.902C12.593 18.035 14.51 15.618 17.001 14.015C17.001 14.01 17 14.005 17 14V4.704ZM19.001 5C18.449 5 18.001 5.448 18.001 6V18C18.001 18.552 18.449 19 19.001 19H23.001V5H19.001Z"
      />
    </svg>
  )
}
