import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export const OnTarget = (props: React.SVGProps<SVGSVGElement>) => {
  const pathColor = useColorModeValue('#008A20', '#10B035')
  const bgColor = useColorModeValue('#EDFFF1', '#1A211B')
  return (
    <svg
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill={bgColor} y="4" width="16" height="16" rx="8" />
      <path
        stroke={pathColor}
        d="M12.1284 9.65234L6.62842 15.1523L4.12842 12.6523"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
