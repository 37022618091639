import React from 'react'

export const Work = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27937)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.63636 5.12736V3.49099C7.65551 3.20797 7.77659 2.94148 7.97717 2.7409C8.17776 2.54031 8.44425 2.41923 8.72727 2.40009H15.2727C15.5561 2.41809 15.8233 2.53881 16.0241 2.73962C16.2249 2.94044 16.3456 3.20757 16.3636 3.49099V5.12736H22.4018C22.8292 5.13733 23.2356 5.31413 23.5343 5.61993C23.833 5.92573 24.0001 6.33627 24 6.76372V19.8546C24.0001 20.2821 23.833 20.6926 23.5343 20.9984C23.2356 21.3042 22.8292 21.481 22.4018 21.491H1.59818C1.17084 21.481 0.764371 21.3042 0.465703 20.9984C0.167035 20.6926 -0.000116318 20.2821 6.07303e-08 19.8546V6.76372C-0.000116318 6.33627 0.167035 5.92573 0.465703 5.61993C0.764371 5.31413 1.17084 5.13733 1.59818 5.12736H7.63636ZM15.2727 5.12736V4.03645C15.2727 3.53463 15.2727 3.49099 14.7273 3.49099H9.27273C8.72727 3.49099 8.72727 3.49099 8.72727 4.03645V5.12736H15.2727ZM8.72727 11.6728V10.5819H15.2727V11.6728H22.9091V7.00372C22.9091 6.79635 22.8271 6.59739 22.681 6.45024C22.5348 6.3031 22.3365 6.21971 22.1291 6.21827H1.87091C1.66354 6.21971 1.46516 6.3031 1.31903 6.45024C1.17291 6.59739 1.0909 6.79635 1.09091 7.00372V11.6728H8.72727ZM8.72727 12.7637H1.09091V19.6146C1.0909 19.822 1.17291 20.021 1.31903 20.1681C1.46516 20.3153 1.66354 20.3986 1.87091 20.4001H22.1291C22.3365 20.3986 22.5348 20.3153 22.681 20.1681C22.8271 20.021 22.9091 19.822 22.9091 19.6146V12.7637H15.2727V13.8546H8.72727V12.7637ZM14.1818 12.7637V11.6728H9.81818V12.7637H14.1818Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27937">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
