import React from 'react'

export const Pin = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 4.00467C6.692 4.00467 4 6.69982 4 10.0117C4 13.3235 6.692 16.0187 10 16.0187C13.308 16.0187 16 13.3235 16 10.0117C16 6.69982 13.309 4.00467 10 4.00467ZM10 0C4.477 0 0 4.48223 0 10.0117C0 11.2641 0.24 12.4575 0.659 13.5628C0.659 13.5628 0.728 13.757 0.763 13.8472C0.777 13.8822 0.792 13.9172 0.807 13.9523C1.333 15.2628 2.087 16.6825 3.201 18.1201C5.245 20.7562 7.782 22.6684 9.997 23.997V24C9.998 24 9.998 23.999 9.999 23.999C10 23.999 10.001 24 10.001 24V23.997C12.216 22.6684 14.753 20.7562 16.797 18.1201C17.912 16.6815 18.666 15.2628 19.192 13.9523C19.207 13.9172 19.221 13.8822 19.235 13.8472C19.271 13.756 19.339 13.5628 19.339 13.5628C19.76 12.4575 20 11.2641 20 10.0117C20 4.48223 15.523 0 10 0ZM10 17.0199C6.134 17.0199 3 13.8822 3 10.0117C3 6.14116 6.134 3.0035 10 3.0035C13.866 3.0035 17 6.14116 17 10.0117C17 13.8822 13.866 17.0199 10 17.0199Z"
        fill="currentColor"
      />
    </svg>
  )
}
