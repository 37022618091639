import React from 'react'

export const AudioClip = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 5V0H1C0.448 0 0 0.448 0 1V19C0 19.552 0.448 20 1 20H17C17.552 20 18 19.552 18 19V5H13ZM12.601 9.601L10 7V14C10 15.105 8.881 16 7.5 16C6.119 16 5 15.104 5 14C5 12.896 6.119 12 7.5 12C8.065 12 8.581 12.156 9 12.409V4.586L13.308 8.895L12.601 9.601ZM14 0V4H18L14 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
