import React from 'react'

export const Up = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6522 7.88481L12.013 7.23915L11.3478 7.88481L0.919565 18.3131L0 17.387L11.987 5.40002L12 5.41307L12.013 5.40002L24 17.387L23.0739 18.3131L12.6522 7.88481Z"
        fill="currentColor"
      />
    </svg>
  )
}
