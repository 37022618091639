import React from 'react'

export const Notification = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.521 7.463C19.091 10.405 19.908 13.257 20.95 16H3.05003C4.09203 13.257 4.90903 10.405 5.47803 7.463C6.08803 4.311 8.78903 2 12 2C15.211 2 17.912 4.311 18.521 7.463ZM2.47739 17.4037C2.53107 17.2691 2.58476 17.1344 2.63903 17H21.361C21.4153 17.1344 21.469 17.2691 21.5227 17.4038C21.6807 17.8 21.8386 18.1961 22.011 18.585C22.307 19.251 21.835 20 21.106 20H13.723C13.894 20.295 14 20.634 14 21C14 22.105 13.104 23 12 23C10.896 23 10 22.105 10 21C10 20.634 10.106 20.295 10.277 20H2.89403C2.16603 20 1.69403 19.251 1.98903 18.585C2.16145 18.1961 2.31939 17.8 2.47739 17.4037Z"
        fill="currentColor"
      />
    </svg>
  )
}
