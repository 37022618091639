import * as React from 'react'

export const RAGOnTarget = (
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg width={24} height={24} fill="none" {...props}>
    <g clipPath="url(#RAGOn_svg__clip0_378_26889)">
      <path
        d="M24 0v24H0V0h24zm-3.714 6.84l-.894-.792L9.6 17.118l-5.4-5.472-.858.84L9.6 18.882 20.286 6.84z"
        fill="#1B883C"
      />
    </g>
    <defs>
      <clipPath id="RAGOn_svg__clip0_378_26889">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
