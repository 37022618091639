import React from 'react'

export const Cough = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6 5.99995C15.2817 5.99995 14.9765 6.12638 14.7515 6.35142C14.5264 6.57647 14.4 6.88169 14.4 7.19995V13.956C13.122 13.32 12.6 11.964 12.6 9.59995V1.19995H11.4V9.59995C11.4 11.964 10.878 13.32 9.6 13.956V7.19995C9.6 6.88169 9.47357 6.57647 9.24853 6.35142C9.02348 6.12638 8.71826 5.99995 8.4 5.99995C6.17218 5.99995 4.03561 6.88495 2.4603 8.46025C0.884997 10.0356 0 12.1721 0 14.4V21.6C0 21.9182 0.126428 22.2234 0.351472 22.4485C0.576515 22.6735 0.88174 22.7999 1.2 22.7999H4.8C6.07304 22.7999 7.29394 22.2942 8.19411 21.3941C9.09429 20.4939 9.6 19.273 9.6 18V15.252C10.1338 15.0771 10.6241 14.7902 11.0381 14.4104C11.452 14.0306 11.78 13.5668 12 13.05C12.22 13.5668 12.548 14.0306 12.9619 14.4104C13.3759 14.7902 13.8662 15.0771 14.4 15.252V18C14.4 19.273 14.9057 20.4939 15.8059 21.3941C16.7061 22.2942 17.927 22.7999 19.2 22.7999H22.8C23.1183 22.7999 23.4235 22.6735 23.6485 22.4485C23.8736 22.2234 24 21.9182 24 21.6V14.4C24 12.1721 23.115 10.0356 21.5397 8.46025C19.9644 6.88495 17.8278 5.99995 15.6 5.99995ZM8.4 18C8.4 18.9547 8.02072 19.8704 7.34559 20.5455C6.67045 21.2207 5.75478 21.6 4.8 21.6H1.2V14.4C1.2 12.4904 1.95857 10.659 3.30883 9.30878C4.65909 7.95852 6.49044 7.19995 8.4 7.19995V14.31C8.00311 14.3725 7.60178 14.4026 7.2 14.4H7.008L5.958 11.778L4.842 12.222L5.712 14.4H3.6V15.6H4.428L3.666 17.1299L4.734 17.67L5.772 15.6H7.2C7.60139 15.5978 8.00222 15.5697 8.4 15.516V18ZM19.2 21.6C18.2452 21.6 17.3295 21.2207 16.6544 20.5455C15.9793 19.8704 15.6 18.9547 15.6 18V15.516C15.9978 15.5699 16.3986 15.598 16.8 15.6H18.228L19.266 17.67L20.334 17.1299L19.572 15.6H20.4V14.4H18.288L19.158 12.222L18.042 11.778L16.992 14.4H16.8C16.3982 14.403 15.9968 14.3729 15.6 14.31V7.19995C17.5096 7.19995 19.3409 7.95852 20.6912 9.30878C22.0414 10.659 22.8 12.4904 22.8 14.4V21.6H19.2Z"
        fill="currentColor"
      />
    </svg>
  )
}
