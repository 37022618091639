import React, { forwardRef } from 'react'
import {
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
} from '@chakra-ui/react'

export type SpinnerProps = ChakraSpinnerProps

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  ({ variant = 'primary', ...props }, ref) => {
    return <ChakraSpinner {...props} ref={ref} variant={variant} />
  }
)

Spinner.defaultProps = { ...Spinner.defaultProps, speed: '0.65s' }

Spinner.displayName = 'Spinner'
