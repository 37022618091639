import React from 'react'

export const Add = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 11H13V3C13 2.448 12.552 2 12 2C11.448 2 11 2.448 11 3V11H3C2.448 11 2 11.448 2 12C2 12.552 2.448 13 3 13H11V21C11 21.552 11.448 22 12 22C12.552 22 13 21.552 13 21V13H21C21.552 13 22 12.552 22 12C22 11.448 21.552 11 21 11Z"
        fill="currentColor"
      />
    </svg>
  )
}
