import React from 'react'

export const Allergy = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5532 8.86403L14.7182 7.69903C16.0052 6.41203 16.0052 4.32503 14.7182 3.03803L11.8042 0.125032L10.6402 1.29003C9.35321 2.57703 9.35321 4.66403 10.6402 5.95103L13.5532 8.86403ZM21.5782 4.90403C22.8952 3.58603 23.3002 1.73703 22.7432 0.243032C21.2492 -0.312968 19.3992 0.0910323 18.0822 1.40803C16.7642 2.72503 16.3602 4.57503 16.9162 6.06903C18.4112 6.62503 20.2612 6.22103 21.5782 4.90403ZM9.47421 12.943L10.6402 11.778C11.9272 10.491 11.9272 8.40403 10.6402 7.11703L7.72721 4.20303L6.56121 5.36803C5.27421 6.65503 5.27421 8.74203 6.56121 10.029L9.47421 12.943ZM11.3472 12.484L10.1812 13.649L13.0952 16.562C14.3822 17.849 16.4692 17.849 17.7562 16.562L18.9212 15.397L16.0082 12.484C14.7212 11.197 12.6342 11.197 11.3472 12.484ZM20.0872 8.40603C18.8002 7.11903 16.7132 7.11903 15.4262 8.40603L14.2612 9.57103L17.1752 12.484C18.4622 13.771 20.5482 13.771 21.8362 12.484L23.0002 11.319L20.0872 8.40603ZM7.26821 16.563L6.45621 17.375C6.26121 17.57 5.94421 17.57 5.74921 17.375C5.55421 17.18 5.55421 16.863 5.74921 16.668L6.56121 15.856C7.84821 14.569 7.84821 12.482 6.56121 11.195L3.64821 8.28103L3.27821 8.65003C0.187211 11.741 0.187211 16.754 3.27821 19.845C6.37021 22.936 11.3812 22.936 14.4732 19.845L14.8422 19.475L11.9292 16.562C10.6422 15.276 8.55521 15.276 7.26821 16.563Z"
        fill="currentColor"
      />
    </svg>
  )
}
