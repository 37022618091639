import React from 'react'

export const Weight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 2H4C2.895 2 2 2.895 2 4V20C2 21.104 2.895 22 4 22H20C21.105 22 22 21.104 22 20V4C22 2.895 21.105 2 20 2ZM18.088 8.073L16.109 11.5C15.93 11.809 15.6 12 15.243 12H11.882L10.764 7.83C10.621 7.297 10.073 6.98 9.539 7.123C9.007 7.265 8.69 7.814 8.833 8.347L9.812 12H8.757C8.4 12 8.07 11.809 7.891 11.5L5.912 8.073C5.644 7.608 5.792 7.018 6.24 6.724C7.895 5.636 9.872 5 12 5C14.128 5 16.105 5.636 17.759 6.724C18.208 7.018 18.356 7.608 18.088 8.073Z"
        fill="currentColor"
      />
    </svg>
  )
}
