import React from 'react'

export const Previous = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.498 0.872L4 8.131V1C4 0.45 3.55 0 3 0H1C0.45 0 0 0.45 0 1V19C0 19.552 0.448 20 1 20H3C3.55 20 4 19.55 4 19V11.869L16.498 19.128C17.164 19.515 18 19.034 18 18.263V1.737C18 0.966 17.164 0.485 16.498 0.872Z"
        fill="currentColor"
      />
    </svg>
  )
}
