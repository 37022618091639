import React from 'react'

export const Nature = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.9779 0.604004C21.0859 0.689004 16.7529 1.099 12.8769 3.545C14.3249 4.656 15.5099 5.982 16.3959 7.498C18.8469 11.744 17.4299 17.173 13.2509 19.696C16.6849 20.656 20.4619 19.229 22.3279 15.998C22.9979 14.824 23.6359 13.243 23.8849 11.241C24.5169 6.135 22.3839 1.495 21.9779 0.604004ZM10.6369 14.526L13.0209 18.655C16.5149 16.338 17.6539 11.679 15.5289 7.999C14.8469 6.832 13.7969 5.489 12.1879 4.273C8.08187 1.17 2.99688 0.697004 2.02288 0.604004C1.61588 1.495 -0.517125 6.135 0.116875 11.242C0.364875 13.243 1.00388 14.824 1.67288 15.998H1.67188C3.15388 18.565 5.84387 20 8.60787 20C9.80688 20 11.0149 19.713 12.1499 19.148L9.76988 15.026L3.89488 13.452C3.62788 13.38 3.46988 13.106 3.54188 12.839C3.61388 12.573 3.88888 12.419 4.15387 12.485L9.06388 13.801L4.00787 5.045C3.86987 4.806 3.95187 4.5 4.19088 4.363C4.42887 4.222 4.73488 4.306 4.87388 4.546L9.92988 13.302L11.2459 8.391C11.3179 8.125 11.5919 7.973 11.8579 8.037C12.1249 8.109 12.2829 8.383 12.2109 8.65L10.6369 14.526Z"
        fill="currentColor"
      />
    </svg>
  )
}
