import React from 'react'

export const RecreationalDrugs = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2252)">
        <path
          d="M7.06002 1.00002C7.86564 0.993985 8.66423 1.15025 9.40815 1.4595C10.1521 1.76876 10.8261 2.22466 11.39 2.80002L21.28 12.8C22.3918 13.9369 23.0068 15.4686 22.9899 17.0586C22.973 18.6487 22.3256 20.167 21.19 21.28C20.0337 22.3931 18.4865 23.0073 16.8816 22.9905C15.2767 22.9737 13.7427 22.3271 12.61 21.19L2.72002 11.19C1.60828 10.0531 0.993287 8.52143 1.01016 6.9314C1.02704 5.34137 1.6744 3.82306 2.81002 2.71002C3.94837 1.60588 5.47418 0.991968 7.06002 1.00002ZM7.06002 2.40564e-05C5.21226 -0.00478956 3.43582 0.712962 2.11002 2.00002C1.45266 2.64279 0.928363 3.40876 0.567066 4.25418C0.205769 5.0996 0.0145531 6.00791 0.00433834 6.92724C-0.00587643 7.84657 0.16511 8.7589 0.507533 9.61214C0.849956 10.4654 1.35711 11.2428 2.00002 11.9L11.89 21.9C13.2079 23.229 14.996 23.9856 16.8675 24.0062C18.7389 24.0268 20.5433 23.3097 21.89 22.01C22.5489 21.3671 23.0744 20.6005 23.4366 19.7542C23.7988 18.9078 23.9905 17.9984 24.0007 17.0778C24.0109 16.1573 23.8395 15.2438 23.4962 14.3897C23.1529 13.5355 22.6444 12.7574 22 12.1L12.1 2.10002C11.4482 1.43501 10.6702 0.906741 9.81165 0.546149C8.9531 0.185557 8.03123 -0.000108977 7.10002 2.40564e-05H7.06002Z"
          fill="currentColor"
        />
        <path
          d="M16.1548 7.15254L6.57593 16.523L7.27522 17.2378L16.8541 7.86737L16.1548 7.15254Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_2252">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
