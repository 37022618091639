import React from 'react'

export const SpeakerOff = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27982)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1417 8.33522V22.6245C19.1435 22.6385 19.1421 22.6527 19.1378 22.6661C19.1334 22.6795 19.1261 22.6918 19.1164 22.702C19.1067 22.7123 19.0949 22.7203 19.0818 22.7254C19.0686 22.7305 19.0545 22.7326 19.0404 22.7316H19.0047L13.5093 18.2186L13.1818 17.9507H9.25825L8.06748 19.1415H12.7591L18.2545 23.6247C18.4763 23.8061 18.754 23.905 19.0404 23.9046C19.381 23.9046 19.7079 23.7701 19.9498 23.5304C20.1918 23.2906 20.3293 22.9651 20.3324 22.6245V7.14445L19.1417 8.33522ZM4.85239 16.7599V7.23375C4.85239 6.91794 4.97785 6.61506 5.20116 6.39175C5.42448 6.16844 5.72735 6.04298 6.04317 6.04298H13.1878L13.5153 5.77506L18.9928 1.30371C19.0226 1.30371 19.0345 1.30371 19.0404 1.30371C19.0694 1.30673 19.0961 1.32078 19.1149 1.34296C19.1338 1.36514 19.1433 1.39373 19.1417 1.42279V4.03653L5.41206 17.7423C5.24467 17.6378 5.10589 17.4933 5.0082 17.3218C4.9105 17.1503 4.85697 16.9572 4.85239 16.7599ZM20.3324 2.82195V1.39897C20.3356 1.05575 20.2027 0.725247 19.9628 0.47977C19.7229 0.234293 19.3955 0.0938335 19.0523 0.0891248C18.7663 0.0911011 18.4894 0.189717 18.2664 0.368956L12.771 4.85221H6.04317C5.41154 4.85221 4.80579 5.10312 4.35916 5.54975C3.91253 5.99637 3.66162 6.60213 3.66162 7.23375V16.7599C3.66362 17.1147 3.74485 17.4645 3.89936 17.7838C4.05387 18.1031 4.27777 18.3839 4.5547 18.6056L0 23.1544L0.845448 23.9939L19.1417 5.69766L20.3324 4.50689L24 0.839311L23.1546 -0.000183105L20.3324 2.82195Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27982">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
