import { Colors } from '@chakra-ui/react'

const light = {
  brand: {
    primary: '#4B286D',
    primaryFocus: '#7C53A5',
    secondary: '#613889',
    tertiary: '#2B8000',
  },
  text: {
    heading: '#2C2E30',
    body: '#2C2E30',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: '#FFFFFF',
    textInverted: '#FFFFFF',
  },
  functional: {
    inactive: '#B2B9BF',
    graphic: '#676E73',
    divider: '#E3E6E8',

    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: '#FFFFFF',
    graphicInverted: '#FFFFFF',
    overlay: 'rgba(0, 0, 0, 0.3)',
  },
  background: {
    backgroundPrimary: '#FAFAFA',
    backgroundSecondary: '#F4F4F7',
    /**
     * please use buttonSecondary
     * @deprecated
     */
    buttonPurple: '#D8CBE5',
    /**
     * please use bannerInteractive
     * @deprecated
     */
    bannerPurple: '#F2EFF4',
    /**
     * please use bannerError
     * @deprecated
     */
    bannerRed: '#FFF6F8',
    /**
     * please use bannerSuccess
     * @deprecated
     */
    bannerGreen: '#F4F9F2',
    /**
     * please use bannerWarning
     * @deprecated
     */
    bannerOrange: '#FFF9EE',
    /**
     * please use bannerNeutral
     * @deprecated
     */
    bannerGrey: '#E3E6E8',

    buttonSecondary: '#D8CBE5',
    bannerInteractive: '#F2EFF4',
    bannerError: '#FFF6F8',
    bannerSuccess: '#F4F9F2',
    bannerWarning: '#FFF9EE',
    bannerNeutral: '#E3E6E8',
  },
  status: {
    success: '#2B8000',
    error: '#8C5415',
    warning: '#C12335',
  },
}

const dark = {
  brand: {
    primary: '#B08CD4',
    primaryFocus: '#8760AF',
    secondary: '#9467C1',
    tertiary: '#66CC00',
  },
  text: {
    heading: '#FFFFFF',
    body: '#FFFFFF',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: '#121212',
    textInverted: '#121212',
  },
  functional: {
    inactive: '#979DA1',
    graphic: '#777777',
    divider: '#6B6B6B',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: '#121212',
    graphicInverted: '#121212',
    overlay: 'rgba(0, 0, 0, 0.6)',
  },
  background: {
    backgroundPrimary: '#1E1E1E',
    backgroundSecondary: '#121212',
    /**
     * please use buttonSecondary
     * @deprecated
     */
    buttonPurple: '#32313F',
    /**
     * please use bannerInteractive
     * @deprecated
     */
    bannerPurple: '#1B1921',
    /**
     * please use bannerError
     * @deprecated
     */
    bannerRed: '#221A18',
    /**
     * please use bannerSuccess
     * @deprecated
     */
    bannerGreen: '#1A211B',
    /**
     * please use bannerWarning
     * @deprecated
     */
    bannerOrange: '#211D1A',
    /**
     * please use bannerNeutral
     * @deprecated
     */
    bannerGrey: '#1E1E1E',

    buttonSecondary: '#32313F',
    bannerInteractive: '#1B1921',
    bannerError: '#221A18',
    bannerSuccess: '#1A211B',
    bannerWarning: '#211D1A',
    bannerNeutral: '#1E1E1E',
  },
  status: {
    success: '#66CC00',
    warning: '#FACA69',
    error: '#DD4657',
  },
}

export const colors: Colors = {
  light,
  dark,
}
