import React from 'react'

export const Language = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.615 8C15.89 4.213 14.194 1.002 12.002 1H11.999C9.806 1.001 8.11 4.213 7.385 8H16.615ZM6 12C6 10.962 6.073 9.96 6.197 9H1.425C1.154 9.955 1 10.958 1 12C1 13.042 1.154 14.045 1.425 15H6.197C6.073 14.039 6 13.038 6 12ZM9.225 1.367C5.802 2.258 3.033 4.749 1.761 8H6.348C6.857 5.131 7.894 2.764 9.225 1.367ZM7 12C7 12.993 7.076 14.008 7.218 15H16.783C16.924 14.008 17 12.993 17 12C17 11.007 16.924 9.992 16.782 9H7.218C7.076 9.992 7 11.007 7 12ZM22.239 8C20.968 4.749 18.198 2.259 14.776 1.367C16.106 2.764 17.143 5.131 17.651 8H22.239ZM22.575 9H17.803C17.927 9.96 18 10.962 18 12C18 13.038 17.927 14.04 17.803 15H22.575C22.846 14.045 23 13.041 23 12C23 10.959 22.846 9.955 22.575 9ZM14.775 22.633C18.198 21.742 20.967 19.251 22.238 16H17.651C17.143 18.869 16.106 21.236 14.775 22.633ZM1.761 16C3.032 19.251 5.802 21.742 9.224 22.633C7.894 21.236 6.857 18.869 6.349 16H1.761ZM7.385 16C8.11 19.788 9.807 23 12 23C14.193 23 15.89 19.788 16.615 16H7.385Z"
        fill="currentColor"
      />
    </svg>
  )
}
