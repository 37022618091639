import React from 'react'

export const Expand = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.47 12.53C6.214 12.53 5.958 12.628 5.763 12.823L2 16.587V14.001C2 13.448 1.552 13.001 1 13.001C0.448 13.001 0 13.448 0 14.001V19.001C0 19.554 0.448 20.001 1 20.001H6C6.552 20.001 7 19.554 7 19.001C7 18.448 6.552 18.001 6 18.001H3.414L7.177 14.237C7.568 13.846 7.568 13.214 7.177 12.823C6.982 12.628 6.726 12.53 6.47 12.53ZM19 13.001C18.448 13.001 18 13.448 18 14.001V16.587L14.237 12.823C14.042 12.628 13.786 12.53 13.53 12.53C13.274 12.53 13.018 12.628 12.823 12.823C12.432 13.214 12.432 13.847 12.823 14.237L16.586 18.001H14C13.448 18.001 13 18.448 13 19.001C13 19.554 13.448 20.001 14 20.001H19C19.552 20.001 20 19.554 20 19.001V14.001C20 13.448 19.552 13.001 19 13.001ZM3.414 2H6C6.552 2 7 1.553 7 1C7 0.447 6.552 0 6 0H1C0.448 0 0 0.447 0 1V6C0 6.553 0.448 7 1 7C1.552 7 2 6.553 2 6V3.414L5.763 7.177C5.958 7.373 6.214 7.47 6.47 7.47C6.726 7.47 6.982 7.372 7.177 7.177C7.568 6.786 7.568 6.154 7.177 5.763L3.414 2ZM19 0H14C13.448 0 13 0.447 13 1C13 1.553 13.448 2 14 2H16.586L12.823 5.764C12.432 6.155 12.432 6.787 12.823 7.178C13.018 7.373 13.274 7.471 13.53 7.471C13.786 7.471 14.042 7.373 14.237 7.178L18 3.414V6C18 6.553 18.448 7 19 7C19.552 7 20 6.553 20 6V1C20 0.447 19.552 0 19 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
