import React from 'react'

export const Success = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27825)">
        <path
          d="M22.8001 1.19988V22.7999H1.20008V1.19988H22.8001ZM24.0001 -0.00012207H7.62939e-05V23.9999H24.0001V-0.00012207ZM20.2861 6.83988L19.3921 6.04788L9.60008 17.1179L4.20008 11.6459L3.34208 12.4859L9.60008 18.8819L20.2861 6.83988Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27825">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
