import React from 'react'

export const UpArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5561 2.23024V24H11.3854V2.23024L2.02538 11.5902L1.20001 10.7649L11.959 0L11.9707 0.0117073L11.9825 0L22.7415 10.7649L21.9103 11.5902L12.5561 2.23024Z"
        fill="currentColor"
      />
    </svg>
  )
}
