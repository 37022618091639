import React from 'react'

export const MicOff = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27986)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0201 21.5999V22.7999H16.0201V23.9999H8.8201V22.7999H11.8201V21.5999C10.1202 21.4689 8.50145 20.8197 7.1821 19.7399L8.0221 18.8999C9.27961 19.8767 10.8278 20.4047 12.4201 20.3999C14.1214 20.3996 15.7676 19.7968 17.0669 18.6985C18.3662 17.6003 19.2346 16.0774 19.5181 14.3999H20.7181C20.4431 16.2957 19.5311 18.0415 18.132 19.3501C16.7329 20.6587 14.9301 21.4521 13.0201 21.5999ZM20.6521 4.62588L4.4521 20.8259L3.6001 19.9739L5.5861 17.9999C4.81675 16.9345 4.31465 15.6998 4.1221 14.3999H5.3221C5.49084 15.3792 5.86505 16.3116 6.4201 17.1359L7.8121 15.7439C7.68095 15.3081 7.61622 14.855 7.6201 14.3999V4.79988C7.6201 3.52684 8.12581 2.30594 9.02598 1.40577C9.92616 0.505591 11.1471 -0.00012207 12.4201 -0.00012207C13.6931 -0.00012207 14.914 0.505591 15.8142 1.40577C16.7144 2.30594 17.2201 3.52684 17.2201 4.79988V6.35388L19.7941 3.77388L20.6521 4.62588ZM16.0201 4.79988C16.0201 3.8451 15.6408 2.92942 14.9657 2.25429C14.2906 1.57916 13.3749 1.19988 12.4201 1.19988C11.4653 1.19988 10.5496 1.57916 9.87451 2.25429C9.19938 2.92942 8.8201 3.8451 8.8201 4.79988V14.3999C8.8201 14.5079 8.8201 14.6099 8.8561 14.7119L16.0201 7.55388V4.79988ZM12.4201 17.9999C11.4816 18.0014 10.5804 17.6327 9.9121 16.9739L9.0661 17.8259C9.74132 18.4854 10.596 18.9312 11.5233 19.1076C12.4505 19.284 13.4092 19.183 14.2794 18.8174C15.1496 18.4518 15.8927 17.8377 16.4158 17.052C16.9388 16.2663 17.2186 15.3438 17.2201 14.3999V9.67188L16.0201 10.8719V14.3999C16.0201 15.3547 15.6408 16.2703 14.9657 16.9455C14.2906 17.6206 13.3749 17.9999 12.4201 17.9999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27986">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
