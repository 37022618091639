import { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools'
import { getModeColor } from '../../dna/utils'

const baseStyle: SystemStyleFunction = (props) => {
  const iconColor = getModeColor('brand.secondary', props)
  return {
    color: iconColor,
  }
}

const variantIconButton: SystemStyleObject = {
  h: '1.125rem', // 24 px
  w: '1.125rem',
}

const variants = {
  'autosuggest-clear': variantIconButton,
}

export const Icon = {
  baseStyle,
  variants,
}
