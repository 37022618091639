import React from 'react'

export const Directions = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 19L20 1C20 0.448003 19.552 3.22555e-06 19 3.32207e-06L1 6.46929e-06C0.448 6.5658e-06 -7.8332e-08 0.448007 -1.74847e-07 1.00001L3.32206e-06 19C3.41858e-06 19.552 0.448003 20 1 20L19 20C19.552 20 20 19.552 20 19ZM8 17L4 17L4 9.00001C4 6.79101 5.791 5.00001 8 5.00001L11 5L11 3L17.928 7L11 11L11 9L9 9.00001C8.448 9.00001 8 9.44801 8 10L8 17ZM11.0004 6.00001L8.00044 6.00001C6.34644 6.00001 5.00044 7.34601 5.00044 9.00001L5.00044 16L7.00044 16L7.00044 10C7.00044 8.89701 7.89744 8.00001 9.00044 8.00001L11.0004 8.00001L12.0004 8.00001L12.0004 9.00001L12.0004 9.26801L15.9284 7.00001L12.0004 4.73201L12.0004 5.00001L12.0004 6.00001L11.0004 6.00001Z"
        fill="currentColor"
      />
    </svg>
  )
}
