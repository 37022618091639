import React from 'react'

export const Home = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27839)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.909 20.4545H13.0908V15H10.909V20.4545ZM4.36357 9.60545L11.9999 4.40727L19.6363 9.60545V20.4545H14.1818V13.9364H9.81812V20.4545H4.36357V9.60545ZM11.9999 3L-6.10352e-05 11.1218L0.594484 11.9891L3.32176 10.2545V21.5455H20.7272V10.2545L23.4545 11.9891L23.9999 11.1218L11.9999 3Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27839">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
