import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleFunction = (props) => {
  return {
    ...props.theme.textStyles['2xs'],
    color: mode('light.text.heading', 'dark.text.heading')(props),
    marginEnd: 3,
    marginBottom: 0,
    lineHeight: 6,
    transitionProperty: 'common',
    transitionDuration: 'normal',
    opacity: 1,
    _disabled: {
      opacity: 0.4,
    },
    wordWrap: 'normal',
  }
}
const defaultProps = {}

export const FormLabel = {
  baseStyle,
  defaultProps,
}
