import React from 'react'

export const Id = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5V20C0 21.105 0.895 22 2 22H22C23.105 22 24 21.105 24 20V5C24 3.895 23.105 3 22 3H2C0.895 3 0 3.895 0 5ZM4.625 9.875C4.625 8.563 5.688 7.5 7 7.5C8.311 7.5 9.375 8.563 9.375 9.875V10.375C9.375 11.687 8.311 12.75 7 12.75C5.688 12.75 4.625 11.687 4.625 10.375V9.875ZM2 18C2 15.019 3.945 13.473 7 13.455C10.055 13.474 12 15.019 12 18H2ZM21 18H15V17H21V18ZM21 14H15V13H21V14ZM21 10H15V9H21V10ZM6.99998 14.4551C5.40498 14.4661 3.54598 14.9441 3.09998 17.0001H10.9C10.454 14.9441 8.59498 14.4661 6.99998 14.4551ZM8.375 10.375C8.375 11.133 7.758 11.75 7 11.75C6.242 11.75 5.625 11.133 5.625 10.375V9.875C5.625 9.117 6.242 8.5 7 8.5C7.758 8.5 8.375 9.117 8.375 9.875V10.375Z"
        fill="currentColor"
      />
    </svg>
  )
}
