import React from 'react'

export const RatingOutline = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.716 15.3778L7.866 14.6998L7.356 14.2318L2.82 10.0978L8.736 9.56382L9.468 9.49782L9.744 8.81982L12 3.20982L14.256 8.81982L14.532 9.49782L15.264 9.56382L21.18 10.0978L16.644 14.2318L16.134 14.6998L16.284 15.3778L17.622 21.4378L12.642 18.2758L12 17.8678L11.358 18.2758L6.378 21.4378L7.716 15.3778ZM12 -0.000183105L8.628 8.39982L0 9.14982L6.546 15.1498L4.584 23.9998L12 19.2898L19.416 23.9998L17.454 15.1198L24 9.14982L15.372 8.39982L12 -0.000183105Z"
        fill="currentColor"
      />
    </svg>
  )
}
