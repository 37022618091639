import React from 'react'

export const Refresh = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3635 18.2836L6.7635 19.1782C5.23333 18.1201 4.08133 16.6006 3.47558 14.8416C2.86983 13.0826 2.84203 11.1761 3.39625 9.40013C3.95046 7.6242 5.05769 6.07185 6.55637 4.96957C8.05504 3.86728 9.86675 3.27276 11.7271 3.27273H11.9235L9.41441 0.758182L10.1835 0L14.0399 3.85636L10.1835 7.71273L9.41441 6.94364L11.9781 4.36364H11.7271C10.0968 4.35844 8.50759 4.87517 7.19206 5.8382C5.87654 6.80123 4.90376 8.16002 4.41608 9.71572C3.9284 11.2714 3.95142 12.9424 4.48176 14.4841C5.01211 16.0257 6.02195 17.3572 7.3635 18.2836ZM20.4544 12C20.455 10.5642 20.1014 9.15049 19.425 7.88409C18.7485 6.61769 17.77 5.53775 16.5762 4.74L15.9653 5.65091C17.327 6.56093 18.3601 7.88464 18.912 9.42665C19.4639 10.9687 19.5055 12.6473 19.0305 14.2147C18.5555 15.7821 17.5891 17.1553 16.2741 18.1315C14.959 19.1078 13.3649 19.6353 11.7271 19.6364H11.4762L14.0399 17.0673L13.2708 16.2982L9.41441 20.1545L13.2708 24L14.0399 23.2309L11.5308 20.7273H11.7271C14.0418 20.7273 16.2616 19.8078 17.8983 18.1711C19.5349 16.5344 20.4544 14.3146 20.4544 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
