import { alertAnatomy as parts } from '@chakra-ui/anatomy'
import type { PartsStyleFunction } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import { focusShadow } from '../utils'
const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      px: 4,
      py: 4,
      _focus: {
        boxShadow: focusShadow(props),
        borderColor: mode('light.brand.primary', 'dark.brand.primary')(props),
        outline: 0,
      },
    },
    title: {
      fontWeight: 600,
      fontSize: 'sm',
      lineHeight: 7,
      marginEnd: 2,
    },
    description: {
      fontWeight: 400,
      fontSize: '2xs',
      lineHeight: 6,
      paddingTop: 1,
    },
    linkText: {
      paddingTop: 3,
    },
    icon: {
      marginTop: 1,
      marginEnd: 3,
      alignSelf: 'baseline',
      w: '1.375rem',
      h: '1.375rem',
    },
    close: {
      w: '1.375rem',
      h: '1.375rem',
      _focus: {
        w: '1.375rem',
        h: '1.375rem',
        boxShadow: focusShadow(props),
        borderColor: mode('light.text.heading', 'dark.text.heading')(props),
        outline: 0,
      },
      _hover: {
        cursor: 'pointer',
      },
    },
    rightIcon: {
      ml: 3,
      w: '1.375rem',
      h: '1.375rem',
      _focus: {
        w: '1.375rem',
        h: '1.375rem',
        boxShadow: focusShadow(props),
        borderColor: mode('light.text.heading', 'dark.text.heading')(props),
        outline: 0,
      },
      _hover: {
        cursor: 'pointer',
      },
    },
    _focusVisible: {
      outline: '1px solid white',
    },
  }
}

const variantAlert: PartsStyleFunction<typeof parts> = (props) => {
  const bg = mode('light.brand.secondary', 'dark.brand.secondary')(props)
  const textColor = mode(
    'light.text.textWhite',
    'dark.text.textInverted'
  )(props)
  return {
    container: { bg, color: textColor },
  }
}

const variantInformation: PartsStyleFunction<typeof parts> = (props) => {
  const bg = mode('light.brand.secondary', 'dark.brand.secondary')(props)
  const textColor = mode(
    'light.text.textWhite',
    'dark.text.textInverted'
  )(props)
  return {
    container: { bg, color: textColor },
  }
}

const variantSuccess: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      bg: mode('light.status.success', 'dark.status.success')(props),
      color: mode('light.text.textWhite', 'dark.text.textInverted')(props),
    },
  }
}

const variantError: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: {
      bg: mode('light.status.error', 'dark.status.error')(props),
      color: mode('light.text.textWhite', 'dark.text.textInverted')(props),
    },
  }
}

const variants = {
  alert: variantAlert,
  information: variantInformation,
  success: variantSuccess,
  error: variantError,
}

const defaultProps = {
  variant: 'information',
}

export const Banner = {
  parts: parts.keys,
  baseStyle,
  variants,
  defaultProps,
}
