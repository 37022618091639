import type { PartsStyleFunction } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleFunction = (props) => ({
  container: {
    color: mode('light.text.body', 'dark.text.body')(props),
    fontWeight: 'normal',
    paddingLeft: 2,
    boxShadow: 'dialog',
    borderRadius: '2xl',
    bg: mode(
      'light.background.backgroundPrimary',
      'dark.background.backgroundPrimary'
    )(props),
  },
  item: {
    margin: 0,
    lineHeight: 1.5,
  },
  button: {
    py: 4,
    pr: 4,
    width: '100%',
  },
  headingStyle: {
    mb: 2,
    pt: 4,
    pl: 2,
    pb: 3,
  },
  icon: {
    margin: 1,
  },
  linkWrapper: {
    alignItems: 'center',
  },
  link: {
    padding: 4,
  },
})

export const CardList = {
  parts: ['container', 'item', 'headingStyle', 'icon', 'itemContent'],
  baseStyle,
}
