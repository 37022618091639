import React from 'react'

export const Success = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM6.70982 11.57L8.65982 13.52L14.8898 7.30001C15.2798 6.91001 15.9098 6.91001 16.2998 7.30001C16.6898 7.69001 16.6898 8.32001 16.2998 8.71001L9.36982 15.64C8.97982 16.03 8.34982 16.03 7.95982 15.64L5.29982 12.98C4.90982 12.59 4.90982 11.96 5.29982 11.57C5.68982 11.18 6.31982 11.18 6.70982 11.57Z"
        fill="currentColor"
      />
    </svg>
  )
}
