import React from 'react'

export const Selfie = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 12H2V9H0V14H5V12ZM2 2H5V0H0V5H2V2ZM10 12.5C12.623 12.5 14.75 10.373 14.75 7.75V6.75C14.75 4.127 12.623 2 10 2C7.377 2 5.25 4.127 5.25 6.75V7.75C5.25 10.373 7.377 12.5 10 12.5ZM18 12H15V14H20V9H18V12ZM10 13.909C4.615 13.942 0.959 16.35 0.166 21H19.834C19.041 16.35 15.385 13.942 10 13.909ZM15 0V2H18V5H20V0H15Z"
        fill="currentColor"
      />
    </svg>
  )
}
