import React from 'react'

export const Report = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27496)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.43055 20.5693H7.57407V19.4258H6.43055V20.5693ZM8.71758 20.5693H17.8657V19.4258H8.71758V20.5693ZM6.43055 16.7557H7.57407V15.6122H6.43055V16.7557ZM8.71758 16.7557H17.8657V15.6122H8.71758V16.7557ZM6.43055 12.9478H7.57407V11.8043H6.43055V12.9478ZM8.71758 12.9478H17.8657V11.8043H8.71758V12.9478ZM6.43055 9.13416H7.57407V7.99064H6.43055V9.13416ZM8.71758 9.13416H17.8657V7.99064H8.71758V9.13416ZM16.7222 5.70361V3.41658H20.1528V22.8564H4.14352V3.41658H7.57407V5.70361H16.7222ZM8.71758 2.27306H10.8388L11.1647 1.7013C11.266 1.53066 11.4099 1.38931 11.5823 1.29112C11.7547 1.19294 11.9497 1.14131 12.1481 1.14131C12.3466 1.14131 12.5416 1.19294 12.714 1.29112C12.8864 1.38931 13.0303 1.53066 13.1316 1.7013L13.4575 2.27306H15.5787V4.56009H8.71758V2.27306ZM10.1756 1.12954H7.57407V2.27306H3V23.9999H21.2963V2.27306H16.7222V1.12954H14.1207C13.9189 0.785628 13.6307 0.500447 13.2847 0.302287C12.9387 0.104128 12.5469 -0.00012207 12.1481 -0.00012207C11.7494 -0.00012207 11.3576 0.104128 11.0116 0.302287C10.6655 0.500447 10.3774 0.785628 10.1756 1.12954Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27496">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
