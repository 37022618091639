import React from 'react'

export const Verified = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.9985 10.979V2.99767C21.9985 2.44696 21.5499 2 20.9972 2H3.00285C2.45011 2 2.0015 2.44696 2.0015 2.99767V10.979C2.0015 11.411 1.86031 14.4669 4.31863 17.6225C7.06935 21.1533 11.2009 22.7017 11.999 23C12.7981 22.7017 16.9287 21.1533 19.6794 17.6225C22.1387 14.4669 21.9985 11.411 21.9985 10.979ZM17.188 7.94413L10.2006 14.9049C10.1024 15.0027 9.97428 15.0515 9.8471 15.0515C9.71893 15.0515 9.59076 15.0027 9.49263 14.9049L6.46553 11.8899C6.27027 11.6954 6.27027 11.3791 6.46553 11.1846C6.6608 10.99 6.97823 10.99 7.17349 11.1846L9.8461 13.8474L16.4791 7.23878C16.6743 7.04423 16.9918 7.04423 17.187 7.23878C17.3833 7.43332 17.3833 7.74859 17.188 7.94413Z"
        fill="currentColor"
      />
    </svg>
  )
}
