import React from 'react'

export const List = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 11C2.672 11 2 11.672 2 12.5C2 13.328 2.672 14 3.5 14C4.328 14 5 13.328 5 12.5C5 11.672 4.328 11 3.5 11ZM3.5 19C2.672 19 2 19.671 2 20.5C2 21.328 2.672 22 3.5 22C4.328 22 5 21.328 5 20.5C5 19.671 4.328 19 3.5 19ZM3.5 3C2.672 3 2 3.672 2 4.5C2 5.328 2.672 6 3.5 6C4.328 6 5 5.328 5 4.5C5 3.672 4.328 3 3.5 3ZM8 5.5H21C21.55 5.5 22 5.05 22 4.5C22 3.95 21.55 3.5 21 3.5H8C7.45 3.5 7 3.95 7 4.5C7 5.05 7.45 5.5 8 5.5ZM21 19.5H8C7.45 19.5 7 19.95 7 20.5C7 21.05 7.45 21.5 8 21.5H21C21.55 21.5 22 21.05 22 20.5C22 19.95 21.55 19.5 21 19.5ZM21 11.5H8C7.45 11.5 7 11.95 7 12.5C7 13.05 7.45 13.5 8 13.5H21C21.55 13.5 22 13.05 22 12.5C22 11.95 21.55 11.5 21 11.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
