import React from 'react'

export const BackArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.707 17.707C10.098 17.316 10.098 16.684 9.707 16.293L3.414 10H19C19.552 10 20 9.55201 20 9.00001C20 8.44801 19.552 8.00001 19 8.00001H3.414L9.707 1.70701C10.098 1.31601 10.098 0.684006 9.707 0.293006C9.316 -0.0979941 8.684 -0.0979941 8.293 0.293006L0.293 8.29301C0.098 8.48801 0 8.74401 0 9.00001C0 9.25601 0.098 9.51201 0.293 9.70701L8.293 17.707C8.684 18.098 9.316 18.098 9.707 17.707Z"
        fill="currentColor"
      />
    </svg>
  )
}
