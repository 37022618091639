import React from 'react'

export const Profile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 10.5C12.623 10.5 14.75 8.373 14.75 5.75V4.75C14.75 2.127 12.623 0 10 0C7.377 0 5.25 2.127 5.25 4.75V5.75C5.25 8.373 7.377 10.5 10 10.5ZM10 11.909C3.889 11.947 0 15.038 0 21H20C20 15.038 16.111 11.947 10 11.909ZM16 18H12V16.992H16V18Z"
        fill="currentColor"
      />
    </svg>
  )
}
