import {
  mode,
  SystemStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleFunction = (props) => {
  const textColor = mode(
    'light.status.information',
    'dark.status.information'
  )(props)
  return {
    color: textColor,
    display: 'inline-flex',
    lineHeight: '6',
    _hover: {
      cursor: 'pointer',
    },
    _focus: {
      outline: `solid 2px #326FEC`,
    },
  }
}

const variantStandalone: SystemStyleObject = {
  fontWeight: 'semibold',
  textDecoration: 'underline',
  _hover: {
    textDecoration: 'none',
  },
  _focus: {
    outlineOffset: '0.5rem',
  },
}

const variantInline: SystemStyleFunction = (props) => {
  return {
    fontWeight: 'normal',
    textDecoration: 'underline',
    lineHeight: props.textStyle === 'caption' ? '5' : '6',
    _hover: {
      textDecoration: 'none',
    },
  }
}

const variantDestructive: SystemStyleFunction = (props) => {
  const textColor = mode('light.status.error', 'dark.status.error')(props)
  return {
    fontWeight: 'semibold',
    color: textColor,
    _hover: {
      cursor: 'pointer',
    },
    _focus: {
      outlineOffset: '0.5rem',
    },
  }
}

const variants = {
  standalone: variantStandalone,
  inline: variantInline,
  destructive: variantDestructive,
}

const defaultProps = {
  variant: 'inline',
}

export const Link = {
  baseStyle,
  variants,
  defaultProps,
}
