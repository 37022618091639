import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import type { PartsStyleFunction } from '@chakra-ui/theme-tools'
import { getModeColor } from '../../dna/utils'

import { dnaTheme } from '../..'

const size = {
  fontSize: 'body',
  px: 3,
  height: '44px',
  borderRadius: 'lg',
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  const color = getModeColor('functional.graphic', props)
  return {
    field: {
      ...dnaTheme.components.Input.baseStyle(props).field,
      ...size,
    },
    addon: {
      ...size,
    },
    icon: {
      color,
    },
  }
}

const variantOutline: PartsStyleFunction<typeof parts> = (props) => {
  return {
    field: {
      ...dnaTheme.components.Input.variants.outline(props).field,
      borderRadius: 'lg',
    },
    addon: {
      ...dnaTheme.components.Input.variants.outline(props).addon,
    },
  }
}

const variants = {
  outline: variantOutline,
}

const defaultProps = {
  variant: 'outline',
}

export const Input = {
  baseStyle,
  variants,
  defaultProps,
}
