import React from 'react'

export const SpeakerOn = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27980)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9093 1.68681e-05C15.5879 -0.00157426 15.2761 0.109445 15.0281 0.313797L8.85929 5.14067H2.67047C1.96222 5.14067 1.28297 5.42203 0.782163 5.92284C0.281352 6.42365 0 7.10289 0 7.81114V15.876C0 16.5842 0.281352 17.2635 0.782163 17.7643C1.28297 18.2651 1.96222 18.5464 2.67047 18.5464H8.85929L15.0281 23.3933C15.2781 23.5933 15.5891 23.7017 15.9093 23.7004C16.0993 23.7013 16.2875 23.6647 16.4632 23.5926C16.639 23.5205 16.7987 23.4144 16.9333 23.2804C17.0679 23.1465 17.1748 22.9872 17.2477 22.8118C17.3205 22.6364 17.3581 22.4483 17.3581 22.2584V1.44207C17.3581 1.25213 17.3205 1.06407 17.2477 0.888674C17.1748 0.71328 17.0679 0.554012 16.9333 0.420017C16.7987 0.286022 16.639 0.179941 16.4632 0.107865C16.2875 0.0357883 16.0993 -0.000862467 15.9093 1.68681e-05ZM16.0228 22.2584C16.0228 22.3318 15.9694 22.3519 15.916 22.3519H15.8492L9.68713 17.5116L9.34665 17.2112H2.67047C2.31634 17.2112 1.97672 17.0705 1.72632 16.8201C1.47591 16.5697 1.33524 16.2301 1.33524 15.876V7.81114C1.33524 7.45702 1.47591 7.1174 1.72632 6.86699C1.97672 6.61659 2.31634 6.47591 2.67047 6.47591H9.34665L9.71384 6.18883L15.8759 1.3486C15.8954 1.34199 15.9166 1.34199 15.936 1.3486C15.936 1.3486 16.0495 1.3486 16.0495 1.45542L16.0228 22.2584ZM21.8645 16.9909L20.9565 16.0829C22.0858 14.9504 22.72 13.4162 22.72 11.8169C22.72 10.2175 22.0858 8.68335 20.9565 7.55077L21.8645 6.63614C23.2323 8.01209 24 9.87339 24 11.8135C24 13.7536 23.2323 15.6149 21.8645 16.9909ZM19.3142 15.1015L18.3995 14.1936C18.7144 13.883 18.9645 13.513 19.1351 13.105C19.3058 12.697 19.3937 12.2591 19.3937 11.8169C19.3937 11.3746 19.3058 10.9367 19.1351 10.5287C18.9645 10.1207 18.7144 9.75068 18.3995 9.44013L19.3142 8.5255C20.1805 9.40054 20.6665 10.5821 20.6665 11.8135C20.6665 13.0449 20.1805 14.2265 19.3142 15.1015Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27980">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
