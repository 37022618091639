import React from 'react'

export const Attachment = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_309_27498)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5733 10.4911L11.8064 21.6061L10.9481 20.7719L21.7091 9.65089C23.3115 8.00044 23.1494 5.14966 21.349 3.29516C20.9109 2.83385 20.3856 2.46418 19.8035 2.20764C19.2214 1.95109 18.5941 1.81279 17.958 1.80075C17.4509 1.7951 16.9477 1.89155 16.4786 2.08436C16.0095 2.27717 15.5839 2.56239 15.2273 2.92306L1.98168 16.5708C0.835369 17.7711 0.955402 19.7997 2.25175 21.132C3.54811 22.4644 5.54065 22.5304 6.62695 21.4081L14.9092 12.8798C15.2369 12.4834 15.4007 11.9768 15.3673 11.4636C15.3338 10.9505 15.1055 10.4695 14.7292 10.119C14.5381 9.91673 14.3085 9.75465 14.054 9.64231C13.7994 9.52997 13.525 9.46964 13.2467 9.46484C13.033 9.46076 12.8206 9.50015 12.6225 9.58062C12.4245 9.66109 12.2448 9.78096 12.0944 9.93297L6.30286 15.9346L5.43862 15.0944L11.2362 9.12875C11.4973 8.8607 11.8102 8.64852 12.1558 8.50512C12.5014 8.36171 12.8726 8.29006 13.2467 8.29452C13.6854 8.29456 14.1198 8.38157 14.5246 8.55051C14.9295 8.71946 15.2968 8.96699 15.6054 9.27879C16.1994 9.85341 16.5494 10.6346 16.583 11.4604C16.6165 12.2861 16.3309 13.0931 15.7854 13.714L7.49118 22.2483C7.12582 22.6198 6.68931 22.9139 6.20775 23.1129C5.72619 23.3119 5.20946 23.4118 4.68842 23.4066C4.07036 23.3964 3.46046 23.2638 2.89391 23.0166C2.32736 22.7694 1.81538 22.4124 1.38752 21.9662C-0.352955 20.1658 -0.472987 17.375 1.12345 15.7365L14.3691 2.08883C14.837 1.60953 15.3975 1.23042 16.0166 0.974541C16.6356 0.718668 17.3002 0.591382 17.97 0.600426C18.7643 0.614695 19.5478 0.786498 20.2751 1.10587C21.0025 1.42524 21.6592 1.88583 22.2072 2.46093C24.4518 4.77156 24.6078 8.37854 22.5673 10.4911"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_27498">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
