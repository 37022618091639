import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import dnaTheme, { dnaConfig } from '../dna'

import { colors } from './foundations/colors'
import { typography } from './foundations/typography'
import * as components from './components'

const miamiConfig: ThemeConfig = {
  ...dnaConfig,
  cssVarPrefix: 'miami',
}

const overrides = {
  components: {
    ...dnaTheme.components,
    ...components,
  },
  colors,
  ...typography,
  config: miamiConfig,
}

export default extendTheme(overrides, dnaTheme)
