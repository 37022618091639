import React from 'react'

export const Chat = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M21 14H8C6.9 14 6 14.9 6 16V18C6 19.1 6.9 20 8 20H19L21.293 22.293C21.923 22.923 23 22.477 23 21.586V16C23 14.9 22.1 14 21 14ZM19 10V3C19 1.9 18.1 1 17 1H3C1.9 1 1 1.9 1 3V14.586C1 15.477 2.077 15.923 2.707 15.293L5.477 12H17C18.1 12 19 11.1 19 10ZM15 7H5V6H15V7Z"
      />
    </svg>
  )
}
