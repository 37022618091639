import React from 'react'

export const Prescription = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.639893 0.640015V23.3497H23.3496V0.640015H0.639893ZM22.1315 22.1316H1.86828V1.8684H22.1315V22.1316Z"
        fill="currentColor"
      />
      <path
        d="M11.0244 12.3142H12.9754V9.38258H15.907V7.43161H12.9754V4.5H11.0244V7.43161H8.09277V9.38258H11.0244V12.3142Z"
        fill="currentColor"
      />
      <path
        d="M20.0361 15.4426H3.97412V16.6607H20.0361V15.4426Z"
        fill="currentColor"
      />
      <path
        d="M20.0361 18.3741H3.97412V19.5922H20.0361V18.3741Z"
        fill="currentColor"
      />
    </svg>
  )
}
