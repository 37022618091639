import React from 'react'

export const Alcohol = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 5.357V1C18 0.448 17.552 0 17 0H1C0.448 0 0 0.448 0 1V5.357C0 9.712 3.094 13.341 7.203 14.174C7.667 14.268 8 14.678 8 15.151V16.851C8 17.203 7.815 17.529 7.512 17.71L2.483 20.709C2.181 20.889 1.995 21.216 1.995 21.568V22H16V21.568C16 21.216 15.815 20.89 15.512 20.709L10.487 17.71C10.185 17.53 10 17.203 10 16.851V15.151C10 14.677 10.333 14.268 10.797 14.174C14.906 13.341 18 9.712 18 5.357ZM2 4V3H16V4H2Z"
        fill="currentColor"
      />
    </svg>
  )
}
