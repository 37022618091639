import React from 'react'

export const VideoOn = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 3.866L17 7.041V2C17 0.9 16.1 0 15 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H15C16.1 18 17 17.1 17 16V10.959L22.5 14.134C23.167 14.519 24 14.038 24 13.268V4.732C24 3.962 23.167 3.481 22.5 3.866Z"
        fill="currentColor"
      />
    </svg>
  )
}
