import React from 'react'

export const Fruit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19495 16.993C7.73295 16.605 8.39895 16.504 9.01695 16.652L16.8529 8.816C16.9389 8.322 16.9669 7.976 16.9769 7.837C17.0359 7.081 16.9649 6.534 16.9649 6.534C16.7349 4.235 14.9429 2.319 12.5529 2.034C11.5989 1.92 10.6809 2.088 9.87195 2.466C9.59395 2.597 9.29895 2.669 8.99995 2.715V0H7.99995V2.715C7.70095 2.669 7.40495 2.597 7.12895 2.467C6.31895 2.089 5.40195 1.921 4.44695 2.035C2.05695 2.319 0.264948 4.235 0.0349476 6.535C0.0349476 6.535 -0.0360524 7.082 0.0229476 7.838L0.0233168 7.84278C0.0686358 8.43022 0.379164 12.4554 4.08095 16.208C4.33295 16.464 4.59195 16.708 4.85495 16.941C5.24595 17.289 5.77595 17.439 6.29595 17.377C6.62695 17.338 6.92895 17.194 7.19295 16.995L7.19495 16.993ZM20.1159 6.967L21.0829 6C24.9719 9.889 24.9719 16.194 21.0839 20.083C19.1389 22.028 16.5909 23 14.0419 23C11.4929 23 8.94394 22.028 6.99994 20.083L7.96594 19.116C9.58894 20.739 11.7459 21.633 14.0409 21.633C16.3359 21.633 18.4929 20.739 20.1159 19.116C21.7389 17.493 22.6319 15.336 22.6319 13.041C22.6319 10.746 21.7389 8.589 20.1159 6.967ZM14.52 12.563L21.502 14.434C21.586 13.979 21.633 13.514 21.633 13.042C21.633 11.015 20.844 9.10799 19.41 7.67499L14.52 12.563ZM19.41 18.41C20.267 17.552 20.888 16.524 21.254 15.404L14.627 13.628L16.403 20.255C17.523 19.888 18.552 19.267 19.41 18.41ZM14.042 20.634C12.014 20.634 10.108 19.844 8.67395 18.41L13.563 13.522L15.434 20.503C14.979 20.587 14.514 20.634 14.042 20.634Z"
        fill="currentColor"
      />
    </svg>
  )
}
