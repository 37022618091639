import type {
  SystemStyleInterpolation,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { Input } from './input'

const baseStyle: SystemStyleFunction = (props) => {
  return {
    ...Input.baseStyle(props).field,
    paddingY: '8px',
    minHeight: '80px',
    lineHeight: 'short',
    verticalAlign: 'top',
  }
}

const variants: Record<string, SystemStyleInterpolation> = {
  outline: (props) => Input.variants.outline(props).field ?? {},
}

const defaultProps = {
  variant: 'outline',
}

export const Textarea = {
  baseStyle,
  variants,
  defaultProps,
}
