import React from 'react'

export const Progress = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2333)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8 21.6H9V14.4H4.8V21.6ZM10.2 21.6H14.4V9.59995H10.2V21.6ZM15.6 21.6H19.8V3.59995H15.6V21.6ZM0 1.19995V22.7999H24V21.6H21V2.39995H14.4V8.39995H9V13.2H3.6V21.6H1.2V1.19995H0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_2333">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
