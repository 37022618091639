import React from 'react'

export const Cough = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0399 13.0403C17.9699 13.2403 17.7799 13.3703 17.5699 13.3703C17.5099 13.3703 17.4599 13.3603 17.3999 13.3403L15.8799 12.8003V14.4303C15.8799 14.7103 15.6599 14.9303 15.3799 14.9303C15.0999 14.9303 14.8799 14.7103 14.8799 14.4303V12.3003L11.8799 9.30031L8.87993 12.3003V14.4303C8.87993 14.7103 8.65993 14.9303 8.37993 14.9303C8.09993 14.9303 7.87993 14.7103 7.87993 14.4303V12.8003L6.35993 13.3403C6.29993 13.3603 6.24993 13.3703 6.18993 13.3703C5.98993 13.3703 5.78993 13.2403 5.71993 13.0403C5.61993 12.7803 5.75993 12.4903 6.01993 12.4003L8.10993 11.6603L11.3699 8.40031V4.07031C11.3699 3.79031 11.5899 3.57031 11.8699 3.57031H11.8799H11.8899C12.1699 3.57031 12.3899 3.79031 12.3899 4.07031V8.39031L15.6499 11.6503L17.7399 12.4003C17.9999 12.4903 18.1299 12.7803 18.0399 13.0403ZM21.53 12.0999C20.34 7.05988 17.74 5.12988 15.39 5.12988C14.31 5.12988 13.72 6.10988 13.39 7.67988V7.97988L16.2 10.7899L18.07 11.4599C18.45 11.5899 18.75 11.8699 18.92 12.2299C19.09 12.5899 19.11 12.9999 18.98 13.3799C18.77 13.9799 18.2 14.3799 17.57 14.3799C17.4 14.3799 17.23 14.3499 17.06 14.2899L16.87 14.2199V14.4299C16.87 15.2599 16.2 15.9299 15.37 15.9299C14.54 15.9299 13.87 15.2599 13.87 14.4299V12.7099L13.02 11.8599L13 13.2799V14.9299C13 20.1099 13 20.5799 17.87 20.9699C22.73 21.3599 22.75 17.2699 21.53 12.0999ZM10.97 11.6196L9.87997 12.7096V14.4296C9.87997 15.2596 9.20997 15.9296 8.37997 15.9296C7.54997 15.9296 6.87997 15.2596 6.87997 14.4296V14.2196L6.69997 14.2796C6.53997 14.3396 6.36997 14.3696 6.18997 14.3696C5.55997 14.3696 4.98997 13.9696 4.77997 13.3796C4.63997 12.9996 4.65997 12.5896 4.82997 12.2296C4.99997 11.8696 5.29997 11.5896 5.67997 11.4596L7.54997 10.7896L10.36 7.97965V6.77965C10.01 5.74965 9.45997 5.13965 8.59997 5.13965C6.24997 5.13965 3.64997 7.06965 2.45997 12.1096C1.23997 17.2796 1.25997 21.3696 6.11997 20.9796C11 20.5796 11 20.1096 11 14.9296V13.2796L10.97 11.6196Z"
        fill="currentColor"
      />
    </svg>
  )
}
