import React from 'react'

export const BackArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.23024 11.3854H24V12.5561H2.23024L11.5902 21.9161L10.7649 22.7415L0 11.9825L0.00585379 11.9707L0 11.9649L10.7649 1.20001L11.5902 2.03123L2.23024 11.3854Z"
        fill="currentColor"
      />
    </svg>
  )
}
