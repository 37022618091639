import React from 'react'

export const Upload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 5.41425L7.70699 8.70725C7.31599 9.09825 6.68399 9.09825 6.29299 8.70725C5.90199 8.31625 5.90199 7.68425 6.29299 7.29325L11.293 2.29325C11.684 1.90225 12.316 1.90225 12.707 2.29325L17.707 7.29325C18.098 7.68425 18.098 8.31625 17.707 8.70725C17.512 8.90225 17.256 9.00025 17 9.00025C16.744 9.00025 16.488 8.90225 16.293 8.70725L13 5.41425L13 17C13 17.553 12.552 18 12 18C11.448 18 11 17.553 11 17L11 5.41425ZM3 20C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20H3Z"
        fill="currentColor"
      />
    </svg>
  )
}
