import { radioAnatomy as parts } from '@chakra-ui/anatomy'
import {
  mode,
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { Checkbox } from './checkbox'
import { getModeColor } from '../utils'

const baseStyleControl: SystemStyleFunction = (props) => {
  const { control = {} } = Checkbox.baseStyle(props)
  const bgBefore = mode('light.brand.primary', 'dark.brand.primary')(props)

  return {
    ...control,
    borderRadius: 'full',
    _checked: {
      borderColor: bgBefore,
      boxShadow: 'none',
      _before: {
        content: `""`,
        display: 'inline-block',
        w: 3.5,
        h: 3.5,
        borderRadius: '50%',
        bg: bgBefore,
        margin: 1,
      },
    },
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  const backgroundPrimary = getModeColor('background.backgroundPrimary', props)

  return {
    label: Checkbox.baseStyle(props).label,
    control: { ...baseStyleControl(props), backgroundColor: backgroundPrimary },
    container: {
      alignItems: 'flex-start',
      width: '100%',
    },
    formError: {
      marginBottom: 2,
    },
    hintText: {
      marginTop: 1,
      color: mode('light.text.body', 'dark.text.body')(props),
      display: 'block',
    },
    content: {
      marginTop: 3,
      marginLeft: 9,
    },
    groupContainer: {
      padding: 4,
      border: '0.5px solid',
      backgroundColor: backgroundPrimary,
      borderColor: mode(
        'light.functional.graphic',
        'dark.functional.graphic'
      )(props),
      borderRadius: 'base',
    },
  }
}

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    control: { w: 6, h: 6 },
    label: { fontSize: 'body', marginLeft: 3 },
  },
}

const defaultProps = {
  size: 'md',
}

export const Radio = {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps,
}
