import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import { getModeColor, focusShadow } from '../../dna/utils'
import { dnaTheme } from '../..'

const baseStyleControl: SystemStyleFunction = (props) => {
  return {
    w: '100%',
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    border: '1px solid',
    borderColor: mode(
      'light.functional.graphic',
      'dark.functional.graphic'
    )(props),
    borderRadius: 'base',
    color: 'white',
    _checked: {
      bg: mode('light.brand.primary', 'dark.status.information')(props),
      borderColor: mode(
        'light.brand.primary',
        'dark.brand.primaryFocus'
      )(props),
    },
    _focus: {
      boxShadow: focusShadow(props),
      borderColor: mode('light.brand.primary', 'dark.brand.primary')(props),
      outline: 0,
    },
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  const backgroundPrimary = getModeColor('background.backgroundPrimary', props)

  return {
    ...dnaTheme.components.Checkbox.baseStyle(props),
    control: { ...baseStyleControl(props), backgroundColor: backgroundPrimary },
  }
}

export const Checkbox = {
  parts: [...parts.keys, 'hintText', 'formError', 'content', 'groupContainer'],
  baseStyle,
  sizes: dnaTheme.components.Checkbox.sizes,
  defaultProps: dnaTheme.components.Checkbox.defaultProps,
}
