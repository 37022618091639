import React from 'react'

export const Close = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.87 12L21.6 20.73L20.73 21.6L12 12.87L3.27602 21.6L2.40002 20.73L11.13 12L2.40002 3.27602L3.27602 2.40002L12 11.13L20.73 2.40002L21.6 3.27602L12.87 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
