import React from 'react'

export const ForwardArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.7698 12.5561H0V11.3854H21.7698L12.4098 2.02538L13.2351 1.20001L24 11.959L23.9883 11.9707L24 11.9766L13.2351 22.7415L12.4098 21.9103L21.7698 12.5561Z"
        fill="currentColor"
      />
    </svg>
  )
}
