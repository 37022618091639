import { SystemStyleFunction, mode } from '@chakra-ui/theme-tools'
import { Button } from './button'
import { getModeColor } from '../../dna/utils'

const baseStyle: SystemStyleFunction = (props) => {
  return {
    ...Button.baseStyle(props),
  }
}

const variantSecondary: SystemStyleFunction = (props) => {
  const styles = Button.variants.secondary(props)

  return {
    ...styles,
    color: getModeColor('brand.primary', props),
  }
}

const variantTertiary: SystemStyleFunction = (props) => {
  const styles = Button.variants.tertiary(props)
  const inactive = mode(
    'light.functional.inactive',
    'dark.functional.inactive'
  )(props)

  return {
    ...styles,
    color: getModeColor('brand.primary', props),
    _disabled: {
      bg: inactive,
      color: mode('light.text.textInverted', 'light.text.textInverted')(props),
      border: 'none',
    },
  }
}

const variants = {
  primary: Button.variants.primary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
  'autosuggest-clear': Button.variants['autosuggest-clear'],
}

const defaultProps = Button.defaultProps

export const IconButton = {
  baseStyle,
  variants,
  defaultProps,
}
