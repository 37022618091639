import React from 'react'

export const Feedback = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_28197)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 0.599976V18.6H9.6L4.8 23.634V18.6H0V0.599976H24ZM22.8 1.79998V17.4H8.7L6 20.4V17.4H1.2V1.79998H22.8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_28197">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
