import React from 'react'

export const DigitalTwin = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 7.35C13.836 7.35 15.325 5.861 15.325 4.025V3.325C15.325 1.489 13.836 0 12 0C10.164 0 8.675 1.489 8.675 3.325V4.025C8.675 5.861 10.164 7.35 12 7.35ZM19 15.902V14.7C19 10.526 16.278 8.363 12 8.336C7.722 8.363 5 10.526 5 14.7V15.905C6.478 16.313 8.759 16.7 12 16.7C15.246 16.7 17.525 16.312 19 15.902ZM21 12.607V14.769C21.733 15.123 21.979 15.414 22 15.493C21.87 16.1 18.597 17.7 12 17.7C5.403 17.7 2.13 16.1 1.999 15.51C2.021 15.421 2.267 15.125 3 14.77V12.607C1.017 13.342 0 14.31 0 15.5C0 17.177 2.106 18.32 5 18.986V21.7H19V18.986C21.894 18.32 24 17.177 24 15.5C24 14.309 22.983 13.341 21 12.607Z"
        fill="currentColor"
      />
    </svg>
  )
}
