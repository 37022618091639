import React from 'react'

export const GetStarted = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_377_26845)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.298 1.80012C20.2854 1.72966 20.2484 1.66586 20.1936 1.61981C20.1388 1.57377 20.0696 1.5484 19.998 1.54812H19.95L13.452 2.73012C13.3738 2.74477 13.3044 2.78929 13.2585 2.85426C13.2125 2.91922 13.1937 2.99952 13.206 3.07812L16.896 23.7541C16.9099 23.8235 16.9473 23.8859 17.002 23.9307C17.0567 23.9756 17.1253 24.0001 17.196 24.0001H17.244L23.742 22.8361C23.8202 22.8215 23.8896 22.777 23.9355 22.712C23.9815 22.647 24.0003 22.5667 23.988 22.4881L20.298 1.80012ZM14.55 3.73812L19.272 2.89212L19.692 5.25612L15 6.12012L14.55 3.73812ZM15.18 7.28412L19.908 6.43812L22.2 19.4521L17.478 20.2921L15.18 7.28412ZM17.922 22.6381L17.712 21.4381L22.434 20.5981L22.644 21.7981L17.922 22.6381ZM12.9 0.00012207H7.5C7.42043 0.00012207 7.34413 0.0317292 7.28787 0.0879901C7.23161 0.144251 7.2 0.220557 7.2 0.300122V3.60012H0.3C0.220435 3.60012 0.144129 3.63173 0.087868 3.68799C0.0316071 3.74425 0 3.82056 0 3.90012V23.7001C0 23.7797 0.0316071 23.856 0.087868 23.9123C0.144129 23.9685 0.220435 24.0001 0.3 24.0001H12.9C12.9796 24.0001 13.0559 23.9685 13.1121 23.9123C13.1684 23.856 13.2 23.7797 13.2 23.7001V0.300122C13.2 0.220557 13.1684 0.144251 13.1121 0.0879901C13.0559 0.0317292 12.9796 0.00012207 12.9 0.00012207ZM1.2 22.8001H7.2V21.6001H1.2V22.8001ZM1.2 20.4001H7.2V7.20012H1.2V20.4001ZM1.2 6.00012H7.2V4.80012H1.2V6.00012ZM8.4 22.8001H12V21.6001H8.4V22.8001ZM12 20.4001H8.4V3.60012H12V20.4001ZM8.4 2.40012H12V1.20012H8.4V2.40012ZM2.7 12.0001H5.7C5.77956 12.0001 5.85587 11.9685 5.91213 11.9123C5.96839 11.856 6 11.7797 6 11.7001V8.70012C6 8.62056 5.96839 8.54425 5.91213 8.48799C5.85587 8.43173 5.77956 8.40012 5.7 8.40012H2.7C2.62043 8.40012 2.54413 8.43173 2.48787 8.48799C2.43161 8.54425 2.4 8.62056 2.4 8.70012V11.7001C2.4 11.7797 2.43161 11.856 2.48787 11.9123C2.54413 11.9685 2.62043 12.0001 2.7 12.0001ZM3.6 10.8001H4.8V9.60012H3.6V10.8001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_377_26845">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
