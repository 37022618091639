import { SystemStyleObject } from '@chakra-ui/theme-tools'
import type { SystemStyleFunction } from '@chakra-ui/theme-tools'
import { dnaTheme } from '../..'

const baseStyle: SystemStyleObject = {
  ...dnaTheme.components.Badge.baseStyle,
}

const variantPrimarySolid: SystemStyleFunction = (props) => {
  return {
    ...dnaTheme.components.Badge.variants.primarySolid(props),
  }
}

const variantPrimaryOutline: SystemStyleFunction = (props) => {
  return {
    ...dnaTheme.components.Badge.variants.primaryOutline(props),
  }
}

const variantSecondarySolid: SystemStyleFunction = (props) => {
  return {
    ...dnaTheme.components.Badge.variants.secondarySolid(props),
  }
}

const variantSecondaryOutline: SystemStyleFunction = (props) => {
  return {
    ...dnaTheme.components.Badge.variants.secondaryOutline(props),
  }
}

const variants = {
  primarySolid: variantPrimarySolid,
  secondarySolid: variantSecondarySolid,
  primaryOutline: variantPrimaryOutline,
  secondaryOutline: variantSecondaryOutline,
}

const defaultProps = {
  variant: 'primarySolid',
}

export const Badge = {
  baseStyle,
  variants,
  defaultProps,
}
