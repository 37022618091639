import React from 'react'

export const Mood = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2703 1.46012C15.5803 0.460117 13.9103 0.120117 12.0203 0.120117C6.33026 0.120117 3.41025 3.97012 3.41025 8.36012C3.41025 9.01012 3.24025 9.65012 2.92025 10.2101L0.950254 13.6201C0.570255 14.2901 1.05025 15.1201 1.82025 15.1201H2.40025C2.95025 15.1201 3.40025 15.5701 3.40025 16.1201V19.1201C3.40025 20.2201 4.30025 21.1201 5.40025 21.1201H12.0503C13.5803 21.1201 15.0603 20.5301 16.1403 19.4501C18.2003 17.4001 19.4603 15.1301 20.0903 13.8101C20.7803 12.5101 21.1703 11.0301 21.1703 9.45012C21.1903 5.92012 19.9103 3.02012 17.2703 1.46012Z"
        fill="currentColor"
      />
      <path
        d="M13.7802 4.63989C13.8102 4.63989 13.8502 4.64989 13.8802 4.65989C13.9002 4.66989 13.9202 4.67989 13.9302 4.68989C14.0302 4.75989 14.0802 4.88989 14.0302 5.00989L12.7902 8.19989L14.3002 8.78989C14.3902 8.81989 14.4502 8.89989 14.4702 8.98989C14.4902 9.07989 14.4602 9.16989 14.3902 9.23989L10.3102 13.1499C10.2602 13.1999 10.1902 13.2299 10.1202 13.2299C10.0902 13.2299 10.0502 13.2199 10.0202 13.2099C10.0002 13.1999 9.98022 13.1899 9.97022 13.1799C9.86022 13.1099 9.82022 12.9799 9.87022 12.8599L10.9302 10.1199L9.16022 9.42989C9.07022 9.39989 9.01022 9.31989 8.99022 9.22989C8.97022 9.13989 9.00022 9.04989 9.07022 8.97989L13.5802 4.71989C13.6402 4.65989 13.7102 4.63989 13.7802 4.63989ZM13.7802 3.63989C13.4502 3.63989 13.1402 3.75989 12.9102 3.98989L8.40022 8.24989C8.08022 8.54989 7.94022 8.98989 8.03022 9.41989C8.11022 9.84989 8.41022 10.1999 8.82022 10.3599L9.65022 10.6799L8.95022 12.4899C8.74022 13.0399 8.94022 13.6799 9.43022 14.0099C9.51022 14.0599 9.59022 14.0999 9.68022 14.1399C9.83022 14.1999 9.98022 14.2299 10.1402 14.2299C10.4702 14.2299 10.7802 14.0999 11.0202 13.8799L15.1002 9.96989C15.4102 9.66989 15.5502 9.22989 15.4702 8.80989C15.3802 8.37989 15.0902 8.02989 14.6802 7.86989L14.1002 7.64989L14.9802 5.38989C15.2002 4.82989 15.0002 4.20989 14.5102 3.87989C14.4302 3.82989 14.3502 3.77989 14.2602 3.74989C14.0902 3.66989 13.9402 3.63989 13.7802 3.63989Z"
        fill="white"
      />
    </svg>
  )
}
