import React from 'react'

export const MentalHealth = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2512 4.48229C15.9745 3.73371 14.7278 3.42857 12.9472 3.42857C10.8374 3.42857 9.07654 4.02629 7.98812 5.112C7.72122 5.37943 6.84887 6.328 6.84887 7.328C6.84887 7.89714 7.22554 8.32229 7.83098 8.43543C7.90378 8.44457 10.9576 8.89714 12.0009 11.92C12.6145 13.5143 14.15 14.568 15.9005 14.568C17.8474 14.568 19.1496 13.7189 19.7689 12.0434C20.0243 11.3554 20.0578 10.5634 20.03 9.58514C19.9688 7.40571 18.9035 5.45029 17.2512 4.48229ZM19.0179 1.53257C17.0675 0.388572 15.131 0 12.9472 0C6.37861 0 3.00475 4.39543 2.99897 9.41829C2.99781 10.1611 2.80486 10.8914 2.42818 11.5349L0.156605 15.4286C-0.288236 16.1909 0.267527 17.1429 1.15721 17.1429H1.84238C2.48018 17.1429 2.99781 17.6549 2.99781 18.2857V21.7143C2.99781 22.9771 4.03192 24 5.30868 24H12.9969C14.7647 24 16.4736 23.3257 17.7273 22.0926C20.1063 19.752 21.5656 17.1611 22.2947 15.6434C23.0885 14.152 23.5472 12.4606 23.5472 10.6583C23.5472 6.62857 22.0682 3.32 19.0179 1.53257ZM20.855 12.4366C20.3166 13.8926 19.019 15.7109 15.9017 15.7109C13.6139 15.7109 11.6751 14.2857 10.9079 12.2891C10.0818 9.89714 7.61607 9.55771 7.61607 9.55771C6.46411 9.34286 5.69344 8.45486 5.69344 7.328C5.69344 6.00571 6.612 4.86171 7.16892 4.30743C8.50229 2.97714 10.5647 2.28571 12.9472 2.28571C14.9253 2.28571 16.3893 2.648 17.8393 3.49943C19.7192 4.60114 21.1069 6.81943 21.1855 9.55429C21.2132 10.5303 21.1947 11.52 20.855 12.4366Z"
        fill="currentColor"
      />
    </svg>
  )
}
