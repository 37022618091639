import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleOverlay: SystemStyleFunction = (props) => {
  return {
    pos: 'fixed',
    left: '0',
    top: '0',
    w: '100vw',
    h: '100vh',
    bg: mode('light.functional.overlay', 'dark.functional.overlay')(props),
    zIndex: 'modal',
  }
}

const baseStyleDialogContainer: SystemStyleFunction = (props) => {
  const { isCentered, scrollBehavior } = props

  return {
    py: '3.75rem',
    display: 'flex',
    width: '100vw',
    height: '100vh',
    '@supports(height: -webkit-fill-available)': {
      height: '-webkit-fill-available',
    },
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 'modal',
    justifyContent: 'center',
    alignItems: isCentered ? 'center' : 'flex-start',
    overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
  }
}

const baseStyleDialog: SystemStyleFunction = (props) => {
  const { scrollBehavior } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    maxW: { xsm: 72, sm: 85, md: '36.625rem' },
    outline: 0,
    px: 0,
    borderRadius: 'lg',
    background: mode(
      'light.background.backgroundPrimary',
      'dark.background.backgroundPrimary'
    )(props),
    color: 'inherit',
    my: 'auto',
    zIndex: 'modal',
    maxH: scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : undefined,
    boxShadow: 'dialog',
  }
}

const largeStyleDialog: SystemStyleFunction = (props) => {
  const { scrollBehavior } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    maxW: { xsm: 'none', sm: 85, md: '36.625rem' },
    outline: 0,
    px: 0,
    borderRadius: 'lg',
    background: mode(
      'light.background.backgroundPrimary',
      'dark.background.backgroundPrimary'
    )(props),
    color: 'inherit',
    my: 'auto',
    zIndex: 'modal',
    maxH: scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : undefined,
    boxShadow: 'dialog',
  }
}

const baseStyleCloseButton: SystemStyleObject = {
  position: 'absolute',
  top: 5,
  insetEnd: 5,
  fontSize: 18,
  padding: '14px',
}

const baseStyleBody: SystemStyleFunction = (props) => {
  const { scrollBehavior, withFooter } = props

  return {
    py: 0,
    px: 5,
    mb: withFooter ? 0 : 5,
    overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
  }
}

const baseStyleHeader: SystemStyleObject = {
  py: 0,
  px: 5,
}

const baseStyleFooter: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  pb: 5,
  pt: 6,
  justifyContent: 'start',
  px: 5,
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  overlay: baseStyleOverlay(props),
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog(props),
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  footer: baseStyleFooter,
  header: baseStyleHeader,
})

const largeStyle: PartsStyleFunction<typeof parts> = (props) => ({
  overlay: baseStyleOverlay(props),
  dialogContainer: baseStyleDialogContainer(props),
  dialog: largeStyleDialog(props),
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  footer: baseStyleFooter,
  header: baseStyleHeader,
})

const defaultProps = {}

const variants = {
  base: baseStyle,
  large: largeStyle,
}

export const PopOver = {
  parts: [...parts.keys, 'withFooter'],
  baseStyle,
  defaultProps,
  variants,
}
