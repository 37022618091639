import React from 'react'

export const Up = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.284188 9.33774L9.31309 0.285648C9.69298 -0.095216 10.308 -0.095216 10.6869 0.285648L19.7158 9.33774C20.0947 9.7186 20.0947 10.3352 19.7158 10.7151C19.3369 11.095 18.7209 11.095 18.342 10.7151L10 2.35166L1.658 10.7151C1.27909 11.095 0.664076 11.095 0.284188 10.7151C-0.0947292 10.3352 -0.0947292 9.71763 0.284188 9.33774Z"
        fill="currentColor"
      />
    </svg>
  )
}
