import React from 'react'

export const Chat = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_28066)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.09091 7.36916H13.0909V14.771H10.9091V16.7455L10.8055 16.6201L9.38182 14.771H1.09091V7.36916ZM10.9091 5.25826H22.9091V12.7255H20.4055L18.7309 14.6128L18.5455 14.8255V12.7255H14.1818V6.31644H10.9091V5.25826ZM9.81818 4.20007V6.31644H0V15.8292H8.78182L9.01636 16.1019L12 19.4728V15.8292H14.1818V13.7128H17.4545V17.2583L18.3655 16.3364L20.8418 13.7128H24V4.20007H9.81818Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_28066">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
