import React from 'react'

export const Ambulance = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.2 7.5L6.602 12H11V7H10.067C9.71 7 9.379 7.192 9.2 7.5ZM9.644 4C8.572 4 7.582 4.572 7.046 5.5L3.55 11.554L1.236 13.235C0.46 13.799 0 14.701 0 15.662V18C0 19.105 0.895 20 2 20H3.05C3.027 19.835 3 19.671 3 19.5C3 17.57 4.57 16 6.5 16C8.43 16 10 17.57 10 19.5C10 19.671 9.973 19.835 9.949 20H22V4H9.644ZM12 13H4.87L8.334 7C8.691 6.381 9.352 6 10.066 6H12V13ZM20 11H18V13H16V11H14V9H16V7H18V9H20V11ZM13 1.5C13 0.672 12.328 0 11.5 0C10.671 0 10 0.672 10 1.5V3H13V1.5ZM6.5 17C5.119 17 4 18.119 4 19.5C4 20.881 5.119 22 6.5 22C7.881 22 9 20.881 9 19.5C9 18.119 7.881 17 6.5 17Z"
        fill="currentColor"
      />
    </svg>
  )
}
