import React from 'react'

export const Fruit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27958)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4777 5.65735C12.3959 5.70098 12.2596 3.47591 12.9195 2.48881C13.1773 2.05547 13.5451 1.69805 13.9857 1.45285C14.4263 1.20764 14.924 1.08339 15.4281 1.09269V0.00196929C14.8158 -0.0177049 14.2077 0.110278 13.6553 0.375108C13.1029 0.639937 12.6223 1.03383 12.2541 1.52352C11.8479 2.11583 11.5626 2.78255 11.4148 3.4854C11.2669 4.18825 11.2593 4.91339 11.3925 5.61918C9.78597 4.7906 8.01303 4.33571 6.20608 4.2885C4.74996 4.31577 -0.00012207 4.94839 -0.00012207 11.9999C-0.00012207 19.7276 5.06627 23.9978 8.56749 23.9978C9.50084 24.024 10.4257 23.8139 11.2561 23.387C11.4819 23.2507 11.7353 23.1669 11.9978 23.1416C12.2603 23.1669 12.5137 23.2507 12.7395 23.387C13.5699 23.8139 14.4948 24.024 15.4281 23.9978C18.9293 23.9978 23.9957 19.7276 23.9957 11.9999C23.9957 4.94839 19.2456 4.31577 17.7895 4.2885C15.9389 4.34221 14.1238 4.80995 12.4777 5.65735ZM15.3518 22.9071C14.5659 22.9326 13.7867 22.7561 13.0885 22.3945C12.7548 22.2048 12.3812 22.0965 11.9978 22.0781C11.6144 22.0965 11.2408 22.2048 10.9071 22.3945C10.2089 22.7561 9.42968 22.9326 8.64384 22.9071C5.9334 22.9071 1.0906 19.2968 1.0906 12.1035C1.0906 6.19181 4.76087 5.48829 6.34242 5.45557C9.02559 5.39558 11.7524 7.04257 11.796 7.05893L12.0142 7.18982L12.2323 7.05893C12.2596 7.05893 14.9591 5.42285 17.6859 5.45557C19.2675 5.48829 22.9377 6.19181 22.9377 12.1035C22.905 19.2968 18.0622 22.9071 15.3518 22.9071Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27958">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
