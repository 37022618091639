import React from 'react'

export const SpeakerOff = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.7062 0.293947C23.3172 -0.0950527 22.6812 -0.0950527 22.2922 0.293947L0.294191 22.2919C-0.0948086 22.6809 -0.0948086 23.3169 0.294191 23.7059C0.683191 24.0949 1.31919 24.0949 1.70819 23.7059L7.41419 17.9999H7.74219L14.4122 22.8459C15.0732 23.3269 16.0002 22.8539 16.0002 22.0369V9.41395L23.7062 1.70695C24.0952 1.31895 24.0952 0.681947 23.7062 0.293947ZM2.00019 17.9999H3.17219L16.0002 5.17195V1.96295C16.0002 1.14595 15.0732 0.673947 14.4122 1.15395L7.74219 5.99995H2.00019C1.44819 5.99995 1.00019 6.44795 1.00019 6.99995V16.9999C1.00019 17.5519 1.44819 17.9999 2.00019 17.9999Z"
        fill="currentColor"
      />
    </svg>
  )
}
