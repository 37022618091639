import React from 'react'

export const Alert = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22ZM11 5C11.552 5 12 5.448 12 6V13C12 13.552 11.552 14 11 14C10.448 14 10 13.552 10 13V6C10 5.448 10.448 5 11 5ZM11 15C11.69 15 12.25 15.56 12.25 16.25C12.25 16.94 11.69 17.5 11 17.5C10.31 17.5 9.75 16.94 9.75 16.25C9.75 15.56 10.31 15 11 15Z"
        fill="currentColor"
      />
    </svg>
  )
}
