import * as React from 'react'

export const FoodAndDrink = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" fill="none" {...props}>
    <g clipPath="url(#Diet_svg__clip0_304_27921)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.571 6.684V1.31h1.194v5.374a2.985 2.985 0 01-1.707 2.68l-.681.305V22.28a.526.526 0 01-.525.526h-.144a.532.532 0 01-.525-.526V9.67l-.675-.329a2.986 2.986 0 01-1.714-2.657V1.31H5.99v5.374h1.194V1.31h1.194v5.374h1.194zM4.645.116A1.05 1.05 0 003.6 1.16v5.523A4.18 4.18 0 005.99 10.44v11.84A1.726 1.726 0 007.649 24h.262a1.726 1.726 0 001.66-1.72V10.44a4.179 4.179 0 002.389-3.756V1.16A1.05 1.05 0 0010.915.116h-6.27zM17.931 22.28V13.54a1.194 1.194 0 00-.956-1.165 1.792 1.792 0 01-1.433-1.755V3.1a1.791 1.791 0 113.583 0v19.18a.526.526 0 01-.526.525h-.143a.532.532 0 01-.525-.526zM14.348 3.101v7.518a2.985 2.985 0 002.388 2.92v8.741a1.726 1.726 0 001.66 1.72h.263a1.726 1.726 0 001.66-1.72V2.874a2.988 2.988 0 00-5.97.227z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="Diet_svg__clip0_304_27921">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
