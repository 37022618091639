import React from 'react'

export const UpTrend = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 12L7.50502 5.99498C7.77839 5.72161 8.22161 5.72161 8.49497 5.99497L11.505 9.00503C11.7784 9.27839 12.2216 9.27839 12.495 9.00503L20 1.5"
        stroke="#6A56EB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.5 1H19.8C20.1866 1 20.5 1.3134 20.5 1.7V5"
        stroke="#6A56EB"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
