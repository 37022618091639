import React from 'react'

export const Sun = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.838 8.452C5.615 6.886 6.887 5.615 8.452 4.838L6.422 4.294L3.515 3.515L4.294 6.422L4.838 8.452ZM4 12C4 11.106 4.153 10.249 4.424 9.446L2.606 10.495L0 12L2.606 13.505L4.424 14.554C4.153 13.751 4 12.894 4 12ZM14.554 4.424L13.504 2.607L12 0L10.495 2.606L9.446 4.424C10.249 4.153 11.106 4 12 4C12.894 4 13.751 4.153 14.554 4.424ZM19.162 8.452L19.706 6.421L20.485 3.514L17.578 4.293L15.548 4.837C17.114 5.615 18.385 6.886 19.162 8.452ZM4.838 15.548L4.294 17.578L3.515 20.485L6.422 19.706L8.452 19.162C6.886 18.385 5.615 17.114 4.838 15.548ZM19.162 15.548C18.385 17.114 17.113 18.385 15.548 19.162L17.578 19.706L20.485 20.485L19.706 17.578L19.162 15.548ZM9.446 19.576L10.495 21.393L12 24L13.505 21.394L14.555 19.577C13.751 19.847 12.894 20 12 20C11.106 20 10.249 19.847 9.446 19.576ZM21.394 10.495L19.577 9.446C19.847 10.249 20 11.106 20 12C20 12.894 19.847 13.751 19.576 14.554L21.393 13.505L24 12L21.394 10.495ZM12 5C8.14 5 5 8.14 5 12C5 15.86 8.14 19 12 19C15.86 19 19 15.86 19 12C19 8.14 15.86 5 12 5Z"
        fill="currentColor"
      />
    </svg>
  )
}
