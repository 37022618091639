const white = '#FFFFFF'

const black = '#000000'

export const light = {
  brand: {
    primary: '#5500CC',
    primaryFocus: '#817AFF',
    secondary: '#6A56EB',
    tertiary: '#08A4CF',
  },
  text: {
    heading: '#32383D',
    body: '#44525F',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: white,
    textInverted: white,
    caption: '',
  },
  status: {
    success: '#008A20',
    warning: '#B25100',
    error: '#D13400',
    errorFocus: '#F4D5D0',
  },
  functional: {
    inactive: '#BBC2C7',
    graphic: '#728492',
    divider: '#D7DADC',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: white,
    graphicInverted: white,
    overlay: 'rgba(0,0,0,0.3)',
  },
  background: {
    backgroundPrimary: white,
    backgroundSecondary: '#F7F7F8',
    backgroundHighlight: '#6A56EB',
    /**
     * please use buttonSecondary
     * @deprecated
     */
    buttonPurple: '#D7D5F2',
    /**
     * please use bannerInteractive
     * @deprecated
     */
    bannerPurple: '#F7F5FF',
    /**
     * please use bannerError
     * @deprecated
     */
    bannerRed: '#FFE4E0',
    /**
     * please use bannerSuccess
     * @deprecated
     */
    bannerGreen: '#EDFFF1',
    /**
     * please use bannerWarning
     * @deprecated
     */
    bannerOrange: '#FFF2E8',
    /**
     * please use bannerNeutral
     * @deprecated
     */
    bannerGrey: '#ECECEC',

    buttonSecondary: '#D7D5F2',
    bannerInteractive: '#F7F5FF',
    bannerError: '#FFE4E0',
    bannerSuccess: '#EDFFF1',
    bannerWarning: '#FFF2E8',
    bannerNeutral: '#ECECEC',

    backgroundTertiary: '',
    backgroundQuarternary: '',
    backgroundQuinary: '',
  },
  shadow: {
    shadow: black,
  },
  data: {
    primaryData: '#6A56EB',
    secondaryData: '#08A4CF',
  },
  graphics: {
    indigo: '',
    purple: '',
    blue: '',
    yellow: '',
    white: '',
    whiteTint: '',
  },
}

const dark: typeof light = {
  brand: {
    primary: '#8F75FF',
    primaryFocus: '#B3AFFF',
    secondary: '#5565F7',
    tertiary: '#13BCF1',
  },
  text: {
    heading: white,
    body: '#BABFC4',
    /**
     * not in our foundation file
     * please use textInverted
     * @deprecated
     */
    textWhite: white,
    textInverted: '#121212',
    caption: '',
  },
  status: {
    success: '#10B035',
    warning: '#E56800',
    error: '#FA3E00',
    errorFocus: '#F4D5D0',
  },
  functional: {
    inactive: '#979DA1',
    graphic: '#777777',
    divider: '#6B6B6B',
    /**
     * not in our foundation file
     * please use graphicInverted
     * @deprecated
     */
    graphicWhite: white,
    graphicInverted: '#121212',
    overlay: 'rgba(0,0,0,0.6)',
  },
  background: {
    backgroundPrimary: '#1E1E1E',
    backgroundSecondary: '#121212',
    backgroundHighlight: '#5565F7',
    /**
     * please use buttonSecondary
     * @deprecated
     */
    buttonPurple: '#32313F',
    /**
     * please use bannerInteractive
     * @deprecated
     */
    bannerPurple: '#1B1921',
    /**
     * please use bannerError
     * @deprecated
     */
    bannerRed: '#221A18',
    /**
     * please use bannerSuccess
     * @deprecated
     */
    bannerGreen: '#1A211B',
    /**
     * please use bannerWarning
     * @deprecated
     */
    bannerOrange: '#211D1A',
    /**
     * please use bannerNeutral
     * @deprecated
     */
    bannerGrey: '#282828',

    buttonSecondary: '#32313F',
    bannerInteractive: '#1B1921',
    bannerError: '#221A18',
    bannerSuccess: '#1A211B',
    bannerWarning: '#211D1A',
    bannerNeutral: '#282828',

    backgroundTertiary: '',
    backgroundQuarternary: '',
    backgroundQuinary: '',
  },
  shadow: {
    shadow: black,
  },
  data: {
    primaryData: '#5565F7',
    secondaryData: '#13BCF1',
  },
  graphics: {
    indigo: '',
    purple: '',
    blue: '',
    yellow: '',
    white: '',
    whiteTint: '',
  },
}

export const colors = {
  white,
  black,

  light,
  dark,
}
