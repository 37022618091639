import React from 'react'

export const Smoking = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 21H19V16H0V21ZM20 16V21H22V16H20ZM20.943 14.998C20.962 14.999 20.981 15 21.001 15C21.527 15 21.968 14.589 21.999 14.058C22.086 12.542 21.323 11.392 20.829 10.919C20.35 10.425 19.207 9.674 17.704 9.763C16.718 9.795 16.138 9.22 16.056 9.137C16.05 9.132 15.442 8.532 15.498 7.557C15.585 6.041 14.822 4.891 14.328 4.418C13.849 3.924 12.705 3.181 11.203 3.262C10.216 3.315 9.637 2.719 9.555 2.636C9.549 2.631 8.941 2.031 8.997 1.056C9.028 0.504001 8.607 0.0310005 8.055 5.45697e-07C7.516 -0.0129995 7.032 0.390001 6.999 0.940001C6.91 2.498 7.718 3.669 8.142 4.049C8.589 4.54 9.763 5.352 11.317 5.258C12.302 5.229 12.876 5.795 12.897 5.816C12.905 5.826 12.914 5.835 12.922 5.843C12.935 5.857 12.948 5.869 12.962 5.882C13.067 5.994 13.55 6.567 13.5 7.442C13.411 9 14.218 10.17 14.642 10.55C15.089 11.041 16.265 11.855 17.817 11.759C18.808 11.722 19.379 12.299 19.397 12.318C19.405 12.327 19.414 12.335 19.422 12.344C19.435 12.358 19.448 12.37 19.462 12.383C19.566 12.495 20.05 13.068 20 13.943C19.97 14.494 20.391 14.967 20.943 14.998ZM12 2C14.757 2 17 4.243 17 7C17 7.553 17.448 8 18 8C18.552 8 19 7.553 19 7C19 3.141 15.86 5.45697e-07 12 5.45697e-07C11.448 5.45697e-07 11 0.447001 11 1C11 1.553 11.448 2 12 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
