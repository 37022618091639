import React from 'react'

export const Menu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_278_29564)">
        <path
          d="M21.3333 2.39999H0V3.73333H21.3333V2.39999Z"
          fill="currentColor"
        />
        <path d="M24 8.39999H0V9.73333H24V8.39999Z" fill="currentColor" />
        <path d="M21.3333 14.4H0V15.7333H21.3333V14.4Z" fill="currentColor" />
        <path d="M22.6667 20.4H0V21.7333H22.6667V20.4Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_278_29564">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
