import React from 'react'

export const News = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27943)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.88569 20.5713H11.6V19.4284H5.88569V20.5713ZM12.7428 20.5713H18.4571V19.4284H12.7428V20.5713ZM5.88569 18.2856H11.6V17.1427H5.88569V18.2856ZM12.7428 18.2856H18.4571V17.1427H12.7428V18.2856ZM5.88569 15.9999H11.6V14.857H5.88569V15.9999ZM12.7428 15.9999H18.4571V14.857H12.7428V15.9999ZM5.88569 13.7142H18.4571V12.5713H5.88569V13.7142ZM12.7428 11.4284H18.4571V10.2856H12.7428V11.4284ZM12.7428 9.14273H18.4571V7.99988H12.7428V9.14273ZM12.7428 6.85702H18.4571V5.71416H12.7428V6.85702ZM7.02855 10.2856H10.4571V4.57131H7.02855V10.2856ZM5.88569 11.4284H11.6V3.42845H5.88569V11.4284ZM12.7428 4.57131H18.4571V3.42845H12.7428V4.57131ZM4.74283 22.857H19.6V1.14274H4.74283V22.857ZM3.59998 23.9999H20.7428V-0.00012207H3.59998V23.9999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27943">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
