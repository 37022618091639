import React from 'react'

export const Delete = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 5H16V4C16 2.343 14.657 1 13 1H11C9.343 1 8 2.343 8 4V5H4C3.448 5 3 5.448 3 6C3 6.552 3.448 7 4 7V20C4 21.657 5.343 23 7 23H17C18.657 23 20 21.657 20 20V7C20.552 7 21 6.552 21 6C21 5.448 20.552 5 20 5ZM9 19H8V9H9V19ZM14 5H10V4C10 3.449 10.449 3 11 3H13C13.551 3 14 3.449 14 4V5ZM16 19H15V9H16V19Z"
        fill="currentColor"
      />
    </svg>
  )
}
