import React from 'react'

export const VideoOn = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27964)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.434 5.00366L15.234 10.6797V6.25766C15.234 5.9394 15.1076 5.63418 14.8825 5.40913C14.6575 5.18409 14.3523 5.05766 14.034 5.05766H1.2C0.88174 5.05766 0.576515 5.18409 0.351472 5.40913C0.126428 5.63418 0 5.9394 0 6.25766V18.2577C0 18.5759 0.126428 18.8811 0.351472 19.1062C0.576515 19.3312 0.88174 19.4577 1.2 19.4577H14.034C14.3523 19.4577 14.6575 19.3312 14.8825 19.1062C15.1076 18.8811 15.234 18.5759 15.234 18.2577V13.8357L22.434 19.5117C22.5745 19.6205 22.7421 19.6887 22.9186 19.7089C23.0951 19.7291 23.2738 19.7006 23.4352 19.6263C23.5967 19.5521 23.7347 19.4351 23.8342 19.2879C23.9338 19.1408 23.9911 18.9691 24 18.7917V5.72366C23.9911 5.54621 23.9338 5.37457 23.8342 5.22741C23.7347 5.08025 23.5967 4.9632 23.4352 4.88898C23.2738 4.81476 23.0951 4.78621 22.9186 4.80642C22.7421 4.82664 22.5745 4.89486 22.434 5.00366ZM1.2 18.2577H14.034V6.25766H1.2V18.2577ZM22.8 18.2577L15.234 12.2577L22.8 6.25766V18.2577Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27964">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
