import React from 'react'

export const FavouriteOutline = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M7.1715 3.96329C8.4465 3.96329 9.6365 4.52871 10.4355 5.51428L12.0005 7.44518L13.5655 5.5133C14.3645 4.52871 15.5545 3.96329 16.8295 3.96329C17.0415 3.96329 17.2575 3.97899 17.4715 4.00942C19.2585 4.26661 20.7265 5.73319 20.9635 7.4972C21.1335 8.7645 20.7075 9.72455 20.3005 10.3243L12.0045 20.0367L3.7015 10.3243C3.2935 9.72553 2.8685 8.76647 3.0375 7.49917C3.2735 5.73515 4.7425 4.2676 6.5295 4.00942C6.7445 3.97899 6.9595 3.96329 7.1715 3.96329ZM7.1715 2C6.8655 2 6.5545 2.0216 6.2395 2.06773C3.5645 2.45254 1.4065 4.61215 1.0545 7.24394C0.841498 8.83813 1.2655 10.3273 2.1055 11.5121L10.4715 21.2991C10.8715 21.7664 11.4375 22 12.0035 22C12.5695 22 13.1355 21.7664 13.5355 21.2991L21.8955 11.5121C22.7355 10.3273 23.1595 8.83715 22.9455 7.24198C22.5935 4.61117 20.4355 2.45254 17.7615 2.06773C17.4465 2.0216 17.1355 2 16.8295 2C14.8715 2 13.1305 2.89722 12.0005 4.29214C10.8695 2.89722 9.1285 2 7.1715 2Z"
      />
    </svg>
  )
}
