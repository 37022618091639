import React, { forwardRef, ReactNode } from 'react'
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  ChakraProps,
} from '@chakra-ui/react'
import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '../../core/component-types'
import { Icon } from '../Icon'
import { IconType } from '../Icon/iconRegistry'
import { Box } from '../Box'
import { StyleProps } from '../../core/style-props'

/**
 * Note: the typings are complex to make it polymorphic (allow the `as` prop to
 * to dictate the types of the component).  This implementation is taken from
 * https://www.benmvp.com/blog/forwarding-refs-polymorphic-react-component-typescript/
 *
 * An improved/simpler version would be to create our own forward-ref function
 * to wrap and provide all these typings. - similar to how chakra does it https://github.com/chakra-ui/chakra-ui/blob/main/packages/system/src/forward-ref.tsx.
 * This would remove a lot of the boilerplate.
 * It should pass all the test cases in Link.test.tsx which the chakra forwardRef function does not
 */

type Props = Pick<ChakraLinkProps, 'isExternal'> & {
  variant?: 'standalone' | 'inline' | 'destructive'
  leftIcon?: IconType
  rightIcon?: IconType
  leftElement?: ReactNode
  rightElement?: ReactNode
  sx?: ChakraProps['sx']
} & StyleProps

type LinkProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<
  C,
  Props
>

export interface LinkComponent {
  <C extends React.ElementType = 'a'>(
    props: LinkProps<C>
  ): React.ReactElement | null
  displayName?: string
}

export const Link: LinkComponent = forwardRef(
  <C extends React.ElementType = 'a'>(
    props: LinkProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const { leftIcon, rightIcon, leftElement, rightElement, children } = props
    return (
      // @ts-expect-error: clash with the chakra 'as' typings which we can ignore
      <ChakraLink // Not expecting TS errors below this point
        {...props}
        ref={ref}
      >
        {leftIcon ? (
          <Box marginRight={2} paddingTop={0.5}>
            <Icon icon={leftIcon} />
          </Box>
        ) : (
          leftElement && (
            <Box marginRight={2} paddingTop={0.5}>
              {leftElement}
            </Box>
          )
        )}
        {children}
        {rightIcon ? (
          <Box marginLeft={2} paddingTop={0.5}>
            <Icon icon={rightIcon} />
          </Box>
        ) : (
          rightElement && (
            <Box marginLeft={2} paddingTop={0.5}>
              {rightElement}
            </Box>
          )
        )}
      </ChakraLink>
    )
  }
)

Link.displayName = 'Link'
