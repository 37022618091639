import React from 'react'

export const Urine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2413)">
        <path d="M23 1V7.3H1V1H23ZM24 0H0V8.35H24V0Z" fill="currentColor" />
        <path
          d="M21 8.35005V23H3V8.35005H21ZM22 7.35005H2V24H22V7.30005V7.35005Z"
          fill="currentColor"
        />
        <path
          d="M21 11.35V19.7H15V11.35H21ZM22 10.35H14V20.74H22V10.3V10.35Z"
          fill="currentColor"
        />
        <path d="M7 11.87H3V12.91H7V11.87Z" fill="currentColor" />
        <path d="M7 13.96H3V15H7V13.96Z" fill="currentColor" />
        <path d="M7 16.04H3V17.08H7V16.04Z" fill="currentColor" />
        <path d="M7 18.13H3V19.17H7V18.13Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_21_2413">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
