import React from 'react'

export const MicOn = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27984)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5041 21.5999V22.7999H15.5041V23.9999H8.3041V22.7999H11.3041V21.5999C9.39084 21.4599 7.58315 20.6692 6.18159 19.3593C4.78002 18.0495 3.86903 16.2993 3.6001 14.3999H4.8001C5.09242 16.0686 5.9638 17.5808 7.26097 18.6704C8.55813 19.7601 10.198 20.3575 11.8921 20.3575C13.5862 20.3575 15.2261 19.7601 16.5232 18.6704C17.8204 17.5808 18.6918 16.0686 18.9841 14.3999H20.1841C19.9161 16.2955 19.0086 18.0428 17.6119 19.3522C16.2152 20.6616 14.4131 21.4546 12.5041 21.5999ZM7.1041 14.3999V4.79988C7.1041 3.52684 7.60981 2.30594 8.50998 1.40577C9.41016 0.505591 10.6311 -0.00012207 11.9041 -0.00012207C13.1771 -0.00012207 14.398 0.505591 15.2982 1.40577C16.1984 2.30594 16.7041 3.52684 16.7041 4.79988V14.3999C16.7041 15.6729 16.1984 16.8938 15.2982 17.794C14.398 18.6942 13.1771 19.1999 11.9041 19.1999C10.6311 19.1999 9.41016 18.6942 8.50998 17.794C7.60981 16.8938 7.1041 15.6729 7.1041 14.3999ZM8.3041 14.3999C8.3041 15.3547 8.68338 16.2703 9.35851 16.9455C10.0336 17.6206 10.9493 17.9999 11.9041 17.9999C12.8589 17.9999 13.7746 17.6206 14.4497 16.9455C15.1248 16.2703 15.5041 15.3547 15.5041 14.3999V4.79988C15.5041 3.8451 15.1248 2.92942 14.4497 2.25429C13.7746 1.57916 12.8589 1.19988 11.9041 1.19988C10.9493 1.19988 10.0336 1.57916 9.35851 2.25429C8.68338 2.92942 8.3041 3.8451 8.3041 4.79988V14.3999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27984">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
