import React from 'react'

export const Weight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2140)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.6 -0.000160217H2.4C1.76348 -0.000160217 1.15303 0.252696 0.702944 0.702783C0.252856 1.15287 0 1.76332 0 2.39984V21.5998C0 22.2364 0.252856 22.8468 0.702944 23.2969C1.15303 23.747 1.76348 23.9998 2.4 23.9998H21.6C22.2365 23.9998 22.847 23.747 23.2971 23.2969C23.7471 22.8468 24 22.2364 24 21.5998V2.39984C24 1.76332 23.7471 1.15287 23.2971 0.702783C22.847 0.252696 22.2365 -0.000160217 21.6 -0.000160217ZM22.8 21.5998C22.8 21.9181 22.6736 22.2233 22.4485 22.4484C22.2235 22.6734 21.9183 22.7998 21.6 22.7998H2.4C2.08174 22.7998 1.77652 22.6734 1.55147 22.4484C1.32643 22.2233 1.2 21.9181 1.2 21.5998V2.39984C1.2 2.08158 1.32643 1.77636 1.55147 1.55131C1.77652 1.32627 2.08174 1.19984 2.4 1.19984H21.6C21.9183 1.19984 22.2235 1.32627 22.4485 1.55131C22.6736 1.77636 22.8 2.08158 22.8 2.39984V21.5998ZM3.6 5.35784L6.654 10.7998C8.35248 10.0409 10.1937 9.65426 12.054 9.66584C13.8788 9.66059 15.6835 10.0473 17.346 10.7998L20.4 5.35784C17.9711 3.48412 15.0013 2.44646 11.934 2.39984C8.90625 2.44227 5.97699 3.48195 3.6 5.35784ZM12.996 4.93184L11.556 8.48984C10.0731 8.5442 8.60587 8.81298 7.2 9.28784L5.196 5.68784C7.19088 4.35082 9.53264 3.62515 11.934 3.59984C14.3846 3.64308 16.7737 4.37497 18.828 5.71184L16.818 9.31184C15.5387 8.85935 14.201 8.59264 12.846 8.51984L14.112 5.39984L12.996 4.93184Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_2140">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
