import React from 'react'

export const Hide = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.2559 11.9572C15.2531 12.7246 14.9814 13.4666 14.4879 14.0542C13.9944 14.6418 13.3104 15.0376 12.5551 15.1728C11.7998 15.308 11.021 15.174 10.3543 14.7941C9.6876 14.4142 9.17529 13.8125 8.90657 13.0938L7.94489 13.6402C8.33459 14.5715 9.03466 15.3393 9.92621 15.813C10.8178 16.2868 11.8458 16.4373 12.8357 16.2391C13.8257 16.0408 14.7165 15.506 15.3569 14.7255C15.9972 13.945 16.3477 12.9668 16.3487 11.9572C16.3459 11.0033 16.0312 10.0765 15.4526 9.31808L14.5018 9.86449C14.9894 10.4529 15.2561 11.1931 15.2559 11.9572Z"
        fill="currentColor"
      />
      <path
        d="M3.89588 14.7275C2.86012 13.9471 1.94983 13.0129 1.19662 11.9572C2.42584 10.2509 4.04631 8.86417 5.92212 7.91343C7.79792 6.96269 9.87437 6.47563 11.9773 6.4931C13.5912 6.48558 15.1929 6.77259 16.7037 7.34003L17.8949 6.65156C16.0343 5.8168 14.0165 5.39014 11.9773 5.40028C9.61399 5.39175 7.28507 5.96621 5.19682 7.07276C3.10858 8.17931 1.32547 9.7838 0.00544235 11.7441C0.00303535 11.7622 0.00303535 11.7806 0.00544235 11.7987C0.00310966 11.826 0.00310966 11.8534 0.00544235 11.8807C-0.00173274 11.9314 -0.00173274 11.983 0.00544235 12.0337C0.00310966 12.061 0.00310966 12.0884 0.00544235 12.1157C0.00303535 12.1338 0.00303535 12.1522 0.00544235 12.1703C0.794562 13.3604 1.76422 14.4204 2.87956 15.3122L3.89588 14.7275Z"
        fill="currentColor"
      />
      <path
        d="M11.9772 13.5964C12.412 13.5964 12.8289 13.4237 13.1363 13.1162C13.4437 12.8088 13.6164 12.3919 13.6164 11.9571C13.6151 11.7195 13.5621 11.485 13.4612 11.2699C13.3602 11.0548 13.2137 10.8642 13.0318 10.7113L10.3653 12.2522C10.4343 12.6294 10.6334 12.9705 10.9279 13.2161C11.2224 13.4617 11.5937 13.5962 11.9772 13.5964Z"
        fill="currentColor"
      />
      <path
        d="M7.60602 11.9572C7.60674 12.1583 7.62135 12.3591 7.64973 12.5583L8.69884 11.9572C8.69847 11.3815 8.84969 10.8159 9.13728 10.3172C9.42487 9.81855 9.83871 9.40439 10.3372 9.1164C10.8356 8.8284 11.4011 8.67673 11.9768 8.67664C12.5524 8.67655 13.118 8.82804 13.6165 9.11587L14.6656 8.50936C14.0199 8.00573 13.2457 7.69362 12.4312 7.60858C11.6168 7.52354 10.7948 7.66899 10.059 8.02837C9.32314 8.38774 8.70306 8.94657 8.26937 9.6412C7.83568 10.3358 7.60584 11.1383 7.60602 11.9572Z"
        fill="currentColor"
      />
      <path
        d="M23.9984 11.8807C24.0007 11.8535 24.0007 11.826 23.9984 11.7988C23.9984 11.7988 23.9984 11.7605 23.9984 11.7441C22.7402 9.87246 21.0545 8.32723 19.0807 7.23624L17.9879 7.87554C19.8787 8.8347 21.511 10.2346 22.7471 11.9572C21.5187 13.6617 19.8999 15.0472 18.0262 15.9979C16.1525 16.9485 14.0784 17.4366 11.9774 17.4213C9.46645 17.4467 7.00101 16.7507 4.87406 15.416L3.83588 16.0225C6.23008 17.6681 9.07228 18.5379 11.9774 18.5141C14.3384 18.5224 16.6648 17.9477 18.7504 16.841C20.836 15.7344 22.6161 14.13 23.9328 12.1703C23.9328 12.1703 23.9328 12.1321 23.9328 12.1157C23.9352 12.0884 23.9352 12.061 23.9328 12.0337C23.94 11.983 23.94 11.9315 23.9328 11.8807H23.9984Z"
        fill="currentColor"
      />
      <path
        d="M21.7166 6.94868L21.1702 6.00227L2.24202 16.9305L2.78843 17.8769L21.7166 6.94868Z"
        fill="currentColor"
      />
    </svg>
  )
}
