import React from 'react'

export const Secure = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27902)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0285 11.4287V6.85724C19.0285 5.03861 18.3061 3.29447 17.0201 2.00851C15.7342 0.722544 13.99 9.72748e-05 12.1714 9.72748e-05C10.3528 9.72748e-05 8.60864 0.722544 7.32267 2.00851C6.03671 3.29447 5.31426 5.03861 5.31426 6.85724V11.4287H3.59998V24.0001H20.7428V11.4287H19.0285ZM6.45712 6.85724C6.45712 5.34172 7.05916 3.88827 8.13079 2.81663C9.20243 1.74499 10.6559 1.14295 12.1714 1.14295C13.6869 1.14295 15.1404 1.74499 16.212 2.81663C17.2837 3.88827 17.8857 5.34172 17.8857 6.85724V11.4287H16.7428V6.85724C16.7777 6.2478 16.6833 5.63787 16.4658 5.06747C16.2484 4.49706 15.9129 3.97905 15.4812 3.54741C15.0496 3.11576 14.5316 2.78023 13.9612 2.56281C13.3908 2.34539 12.7808 2.25099 12.1714 2.28581C11.562 2.25099 10.952 2.34539 10.3816 2.56281C9.81122 2.78023 9.29322 3.11576 8.86157 3.54741C8.42992 3.97905 8.09439 4.49706 7.87697 5.06747C7.65955 5.63787 7.56516 6.2478 7.59998 6.85724V11.4287H6.45712V6.85724ZM15.6 11.4287H8.74283V6.85724C8.74283 5.94793 9.10406 5.07586 9.74704 4.43287C10.39 3.78989 11.2621 3.42867 12.1714 3.42867C13.0807 3.42867 13.9528 3.78989 14.5958 4.43287C15.2388 5.07586 15.6 5.94793 15.6 6.85724V11.4287ZM4.74283 22.8572H19.6V12.5715H4.74283V22.8572ZM13.8857 19.0972V17.4858C13.8857 16.5315 13.3143 16.0001 12.1714 16.0001C11.0285 16.0001 10.4571 16.5315 10.4571 17.4858V19.0972C10.4571 20.0572 11.0285 20.5715 12.1714 20.5715C13.3143 20.5715 13.8857 20.0458 13.8857 19.0972ZM11.6 17.5315C11.6 17.3201 11.8571 17.1487 12.1714 17.1487C12.4857 17.1487 12.7428 17.3201 12.7428 17.5315V19.0515C12.7428 19.263 12.4857 19.4344 12.1714 19.4344C11.8571 19.4344 11.6 19.263 11.6 19.0515V17.5315Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27902">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
