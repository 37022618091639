import React from 'react'

export const Download = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.20001 22.8H22.8V24H1.20001V22.8ZM12.6 19.044L19.644 12L20.496 12.846L12 21.336L3.51601 12.846L4.36201 12L11.4 19.038V0H12.6V19.044Z"
        fill="currentColor"
      />
    </svg>
  )
}
