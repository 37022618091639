import React, { forwardRef } from 'react'
import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  HTMLChakraProps,
} from '@chakra-ui/react'

export type FormControlProps = Pick<
  ChakraFormControlProps,
  | 'id'
  | 'isDisabled'
  | 'isRequired'
  | 'isInvalid'
  | 'isReadOnly'
  | 'label'
  | keyof HTMLChakraProps<'div'>
> & {
  ariaDescribedby?: string
}

export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  ({ ariaDescribedby, ...props }, ref) => {
    return (
      <ChakraFormControl
        {...props}
        ref={ref}
        aria-describedby={ariaDescribedby}
      />
    )
  }
)

FormControl.displayName = 'FormControl'
