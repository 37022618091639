import { listAnatomy as parts } from '@chakra-ui/anatomy'
import type { PartsStyleFunction } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: {
    color: mode('light.text.body', 'dark.text.body')(props),
    fontWeight: 'normal',
    paddingLeft: 2,
  },
  item: {
    marginBottom: 3,
    lineHeight: 1.5,
  },
  heading: {
    mb: 2,
  },
  hint: {
    mb: 3,
    fontWeight: 'normal',
  },
  icon: {
    margin: 1,
    marginEnd: 3,
  },
})

const variantNoPadding: PartsStyleFunction<typeof parts> = () => {
  return {
    container: {
      padding: 0,
    },
  }
}

const variants = {
  'no-padding': variantNoPadding,
}

export const List = {
  parts: parts.keys,
  baseStyle,
  variants,
}
