import React from 'react'

export const Salt = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5472 8.61501L15.3852 0.453012C14.6662 -0.265988 13.4612 -0.106988 12.9522 0.773012L6.46819 12.004L6.53219 12.068L11.9952 17.532L23.2262 11.048C24.1072 10.539 24.2662 9.33401 23.5472 8.61501ZM0.823195 21.816C0.231195 21.975 -0.120805 22.584 0.0381946 23.176C0.197195 23.768 0.806195 24.12 1.39819 23.961C1.99019 23.802 2.34219 23.193 2.18319 22.601C2.02519 22.009 1.41619 21.657 0.823195 21.816ZM1.52719 17.937C0.934195 18.096 0.583195 18.705 0.742195 19.297C0.901195 19.889 1.51019 20.241 2.10219 20.082C2.69419 19.923 3.04619 19.314 2.88719 18.722C2.72919 18.13 2.12019 17.778 1.52719 17.937ZM5.14119 13.459C3.63019 14.97 3.63019 17.419 5.14119 18.93C5.89619 19.685 6.88619 20.063 7.87619 20.063C8.86619 20.063 9.85619 19.685 10.6112 18.93L11.2952 18.246L5.82519 12.776L5.14119 13.459ZM4.57419 20.984C3.98219 21.143 3.63019 21.752 3.78919 22.344C3.94819 22.936 4.55719 23.288 5.14919 23.129C5.74219 22.97 6.09319 22.361 5.93419 21.769C5.77619 21.177 5.16719 20.825 4.57419 20.984Z"
        fill="currentColor"
      />
    </svg>
  )
}
