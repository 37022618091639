import React from 'react'

export const Jab = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.600098 24L4.51847 20.0816H4.55304L9.08221 20.6579C9.12814 20.6634 9.17457 20.6634 9.2205 20.6579C9.35087 20.6584 9.48008 20.6333 9.60071 20.5838C9.72134 20.5344 9.83104 20.4616 9.92351 20.3697L17.3108 12.9882L18.9415 14.619L19.7598 13.8295L17.3108 11.3748L21.3847 7.30084L23.0155 8.93157L23.8107 8.13637L15.6685 0L14.8733 0.783674L16.4983 2.41441L12.4243 6.48836L9.98113 4.03361L9.18593 4.84034L10.7994 6.48836L3.38905 13.8929C3.28119 14.0037 3.19976 14.1374 3.15087 14.2841C3.10197 14.4308 3.08688 14.5866 3.1067 14.74L3.71174 19.2519C3.70924 19.2632 3.70924 19.275 3.71174 19.2864L0.628909 22.3808L0.600098 24ZM7.35352 13.5933L6.3624 12.5791L7.36504 11.5822L8.37921 12.5964L9.17441 11.8012L8.16024 10.7813L9.12831 9.79592L10.1425 10.8158L10.9722 10.0091L9.92351 8.9892L11.5946 7.31813L16.4983 12.193L9.16864 19.5285L4.83539 18.9522L4.25916 14.6363L5.53839 13.357L6.55832 14.4058L7.35352 13.5933ZM16.5156 10.5796L13.2253 7.28355L17.3108 3.2096L20.6068 6.50564L16.5156 10.5796Z"
        fill="currentColor"
      />
    </svg>
  )
}
