import React from 'react'

export const Privacy = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9715 11V3C21.9715 2.448 21.5235 2 20.9715 2H3.0015C2.4495 2 2.0015 2.448 2.0015 3V11C2.0015 11.018 2.00125 11.0405 2.00096 11.0674C1.99426 11.6877 1.9625 14.6274 4.3155 17.659C7.0625 21.198 11.1875 22.75 11.9855 23.049C12.7835 22.75 16.9085 21.198 19.6555 17.659C22.01 14.6267 21.9786 11.6863 21.972 11.067V11.0669C21.9717 11.0402 21.9715 11.0179 21.9715 11ZM9.1515 7.25C9.1515 5.676 10.4275 4.4 12.0015 4.4C13.5755 4.4 14.8515 5.676 14.8515 7.25V7.85C14.8515 9.424 13.5755 10.7 12.0015 10.7C10.4275 10.7 9.1515 9.424 9.1515 7.85V7.25ZM6.0015 17C6.0015 13.423 8.3345 11.568 12.0015 11.546C15.6685 11.569 18.0015 13.423 18.0015 17H6.0015ZM12.0015 12.5449C9.94449 12.5589 7.53349 13.1989 7.07849 15.9999H16.9255C16.4695 13.1989 14.0585 12.5589 12.0015 12.5449ZM13.8515 7.8499C13.8515 8.8699 13.0215 9.6999 12.0015 9.6999C10.9815 9.6999 10.1515 8.8699 10.1515 7.8499V7.2499C10.1515 6.2299 10.9815 5.3999 12.0015 5.3999C13.0215 5.3999 13.8515 6.2299 13.8515 7.2499V7.8499Z"
        fill="currentColor"
      />
    </svg>
  )
}
