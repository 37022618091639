import React from 'react'

export const Search = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.7162 20.3475L17.7516 16.3814C17.4217 16.0513 17.4013 15.5411 17.6655 15.1568C20.2579 11.3863 19.8786 6.1878 16.5275 2.83543C14.6376 0.944821 12.1613 0 9.68504 0C6.71039 0 3.73574 1.36302 1.77812 4.09003C-0.592705 7.39206 -0.592705 11.969 1.77812 15.2711C3.73574 17.9981 6.71039 19.3611 9.68504 19.3611C11.6175 19.3611 13.549 18.7851 15.2018 17.6341C15.5714 17.3766 16.0775 17.4453 16.3959 17.7638L20.3479 21.7164C20.5366 21.9051 20.7844 22 21.0321 22C21.2798 22 21.5275 21.9051 21.7162 21.7164C22.0946 21.3388 22.0946 20.726 21.7162 20.3475ZM9.68504 17.425C7.61711 17.425 5.67303 16.6196 4.21087 15.1568C2.7487 13.6941 1.94359 11.7493 1.94359 9.68054C1.94359 7.61181 2.7487 5.66699 4.21087 4.20426C5.67303 2.74153 7.61711 1.93611 9.68504 1.93611C11.753 1.93611 13.6971 2.74153 15.1592 4.20426C16.6214 5.66699 17.4265 7.61181 17.4265 9.68054C17.4265 11.7493 16.6214 13.6941 15.1592 15.1568C13.6971 16.6196 11.752 17.425 9.68504 17.425Z"
        fill="currentColor"
      />
    </svg>
  )
}
