import * as icons from './icons'

export type IconType = keyof typeof icons

export type IconComponent = (
  props: React.SVGProps<SVGSVGElement>
) => JSX.Element

export type IconRegistry = Record<IconType, IconComponent>

export const iconRegistry: IconRegistry = { ...icons }
