import React from 'react'

export const Loader = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818C11.4979 3.81818 11.0909 3.41117 11.0909 2.90909C11.0909 2.40701 11.4979 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 11.4979 2.40701 11.0909 2.90909 11.0909C3.41117 11.0909 3.81818 11.4979 3.81818 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
