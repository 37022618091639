import React from 'react'

export const Travel = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 7V19C0 20.1 0.9 21 2 21H3V5H2C0.9 5 0 5.9 0 7ZM9 8V10.121L12.047 13.168L14.168 11.047L11.121 8H9ZM16 2C16 0.896 15.105 0 14 0H8C6.895 0 6 0.895 6 2V5H4V21H18V5H16V2ZM8 2H14V5H8V2ZM12.047 14.583L8 10.535V7H11.535L15.582 11.047L12.047 14.583ZM20 5H19V21H20C21.1 21 22 20.1 22 19V7C22 5.9 21.1 5 20 5Z"
        fill="currentColor"
      />
    </svg>
  )
}
