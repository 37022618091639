import React from 'react'

export const UpArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.29325 9.707C0.68425 10.098 1.31625 10.098 1.70725 9.707L8.00025 3.414V19C8.00025 19.552 8.44825 20 9.00025 20C9.55225 20 10.0002 19.552 10.0002 19V3.414L16.2932 9.707C16.6842 10.098 17.3162 10.098 17.7073 9.707C18.0983 9.316 18.0983 8.684 17.7073 8.293L9.70725 0.293C9.51225 0.098 9.25625 0 9.00025 0C8.74425 0 8.48825 0.098 8.29325 0.293L0.29325 8.293C-0.09775 8.684 -0.09775 9.316 0.29325 9.707Z"
        fill="currentColor"
      />
    </svg>
  )
}
