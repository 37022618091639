import { progressAnatomy as parts } from '@chakra-ui/anatomy'
import { PartsStyleFunction, StyleFunctionProps } from '@chakra-ui/theme-tools'
import type {
  SystemStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { getModeColor } from '../utils'

function filledStyle(props: StyleFunctionProps): SystemStyleObject {
  const bgColor = getModeColor('brand.secondary', props)

  return {
    height: 1,
    borderRadius: '0.125rem',
    position: 'relative',
    bottom: '1.5px',
    bgColor,
    maxWidth: '100%',
  }
}

const baseStyleLabel: SystemStyleObject = {
  lineHeight: '1',
  fontSize: '0.25em',
  fontWeight: 'bold',
  color: 'white',
}

const baseStyleTrack: SystemStyleFunction = (props) => {
  return {
    overflow: 'unset',
    height: 'px',
    bg: getModeColor('functional.graphic', props),
  }
}

const baseStyleFilledTrack: SystemStyleFunction = (props) => {
  return {
    transitionProperty: 'all',
    transitionDuration: 'slow',
    transitionTimingFunction: 'ease',
    ...filledStyle(props),
  }
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  label: baseStyleLabel,
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack(props),
})

export const Progress = {
  parts: parts.keys,
  baseStyle,
}
