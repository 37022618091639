import React from 'react'

export const RatingFilled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 -0.00012207L15.372 8.36988L24 9.14988L17.454 15.1199L19.416 23.9999L12 19.2899L4.584 23.9999L6.546 15.1199L0 9.14988L8.628 8.36988L12 -0.00012207Z"
        fill="currentColor"
      />
    </svg>
  )
}
