import React from 'react'

export const Medication = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 12V18H20V12H16ZM15 5C15.55 5 16 4.55 16 4V1C16 0.45 15.55 0 15 0H9C8.45 0 8 0.45 8 1V3C9.302 3 10.401 3.839 10.816 5H15ZM15 11H19.976C19.918 10.294 19.626 9.626 19.121 9.121L16 6H11V20C11 20.771 10.699 21.468 10.22 22H18C19.104 22 20 21.105 20 20V19H15V11ZM8 4H2C0.9 4 0 4.9 0 6V20C0 21.1 0.9 22 2 22H8C9.1 22 10 21.1 10 20V6C10 4.9 9.1 4 8 4ZM3 20C2.448 20 2 19.552 2 19C2 18.448 2.448 18 3 18C3.552 18 4 18.448 4 19C4 19.552 3.552 20 3 20ZM3 16C2.448 16 2 15.552 2 15C2 14.448 2.448 14 3 14C3.552 14 4 14.448 4 15C4 15.552 3.552 16 3 16ZM3 12C2.448 12 2 11.552 2 11C2 10.448 2.448 10 3 10C3.552 10 4 10.448 4 11C4 11.552 3.552 12 3 12ZM3 8C2.448 8 2 7.552 2 7C2 6.448 2.448 6 3 6C3.552 6 4 6.448 4 7C4 7.552 3.552 8 3 8ZM7 20C6.448 20 6 19.552 6 19C6 18.448 6.448 18 7 18C7.552 18 8 18.448 8 19C8 19.552 7.552 20 7 20ZM7 16C6.448 16 6 15.552 6 15C6 14.448 6.448 14 7 14C7.552 14 8 14.448 8 15C8 15.552 7.552 16 7 16ZM7 12C6.448 12 6 11.552 6 11C6 10.448 6.448 10 7 10C7.552 10 8 10.448 8 11C8 11.552 7.552 12 7 12ZM7 8C6.448 8 6 7.552 6 7C6 6.448 6.448 6 7 6C7.552 6 8 6.448 8 7C8 7.552 7.552 8 7 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
