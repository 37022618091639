import React from 'react'

export const Monitor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_378_26863)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8 21.6H9V14.4H4.8V21.6ZM10.2 21.6H14.4V9.60001H10.2V21.6ZM15.6 21.6H19.8V3.60001H15.6V21.6ZM0 1.20001V22.8H24V21.6H21V2.40001H14.4V8.40001H9V13.2H3.6V21.6H1.2V1.20001H0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_378_26863">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
