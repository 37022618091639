import React from 'react'

export const Medication = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_28068)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.40784 1.19995C3.97359 1.19995 2.59809 1.7697 1.58392 2.78387C0.569753 3.79804 0 5.17354 0 6.60779V17.4235C2.1372e-08 18.8577 0.569753 20.2332 1.58392 21.2474C2.59809 22.2616 3.97359 22.8313 5.40784 22.8313C6.84209 22.8313 8.21759 22.2616 9.23176 21.2474C10.2459 20.2332 10.8157 18.8577 10.8157 17.4235V6.60779C10.8157 5.17354 10.2459 3.79804 9.23176 2.78387C8.21759 1.7697 6.84209 1.19995 5.40784 1.19995ZM9.61394 17.4235C9.61394 18.539 9.1708 19.6088 8.382 20.3976C7.5932 21.1864 6.52337 21.6296 5.40784 21.6296C4.29231 21.6296 3.22248 21.1864 2.43368 20.3976C1.64488 19.6088 1.20174 18.539 1.20174 17.4235V12.0156H9.61394V17.4235ZM9.61394 10.8139H1.20174V6.60779C1.20174 5.49226 1.64488 4.42243 2.43368 3.63363C3.22248 2.84483 4.29231 2.40169 5.40784 2.40169C6.52337 2.40169 7.5932 2.84483 8.382 3.63363C9.1708 4.42243 9.61394 5.49226 9.61394 6.60779V10.8139ZM22.6588 13.0371C22.096 12.3505 21.3895 11.7957 20.5891 11.4115C19.7887 11.0274 18.9139 10.8234 18.0261 10.8139C16.4325 10.8139 14.9042 11.4469 13.7773 12.5738C12.6505 13.7006 12.0174 15.229 12.0174 16.8226C12.0223 18.2042 12.5089 19.5407 13.3934 20.6021C13.6449 20.9156 13.9289 21.2016 14.2406 21.4553C15.3883 22.3921 16.8429 22.8695 18.3225 22.7949C19.8021 22.7202 21.2013 22.0989 22.2489 21.0514C23.2965 20.0038 23.9178 18.6046 23.9924 17.125C24.067 15.6454 23.5897 14.1908 22.6528 13.0431L22.6588 13.0371ZM13.2192 16.8226C13.2192 15.5477 13.7256 14.325 14.6271 13.4236C15.5286 12.5221 16.7512 12.0156 18.0261 12.0156C19.0915 12.0176 20.1248 12.3797 20.9584 13.0431L14.2467 19.7548C13.5853 18.9201 13.2235 17.8875 13.2192 16.8226ZM18.0261 21.6296C16.9608 21.6276 15.9274 21.2655 15.0939 20.6021L21.8056 13.8903C22.469 14.7239 22.8311 15.7573 22.8331 16.8226C22.8331 18.0975 22.3267 19.3202 21.4252 20.2216C20.5237 21.1231 19.301 21.6296 18.0261 21.6296Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_28068">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
