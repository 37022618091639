import React from 'react'

export const Home = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.824 6.40104L8.824 0.589043C9.525 0.079043 10.475 0.079043 11.176 0.589043L19.176 6.40104C19.693 6.77704 20 7.37904 20 8.01904V20C20 20.552 19.552 21 19 21H13V11H7V21H1C0.448 21 0 20.552 0 20V8.01904C0 7.37904 0.306 6.77704 0.824 6.40104ZM8 12H12V21H8V12Z"
        fill="currentColor"
      />
    </svg>
  )
}
