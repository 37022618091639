import React from 'react'

export const Confirm = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.7062 0.295331C21.3152 -0.0984437 20.6833 -0.0984437 20.2922 0.295331L7.11325 13.5689L1.70725 8.1245C1.31625 7.73073 0.68325 7.73073 0.29325 8.1245C-0.09775 8.51828 -0.09775 9.15476 0.29325 9.54854L6.40725 15.7049C6.60225 15.9013 6.85825 16 7.11425 16C7.37025 16 7.62625 15.9013 7.82125 15.7049L21.7062 1.71937C22.0972 1.3266 22.0972 0.689106 21.7062 0.295331Z"
        fill="currentColor"
      />
    </svg>
  )
}
