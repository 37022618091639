import React from 'react'

export const Rewind = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg {...props}"
      {...props}
    >
      <path
        d="M5.29489 11.5L4.79989 11.99L5.29489 12.5L13.2899 20.495L12.5799 21.2L3.38989 12.01L3.39489 12L3.38989 11.99L12.5799 2.79999L13.2899 3.50999L5.29489 11.5Z"
        fill="currentColor"
      />
      <path
        d="M12.51 11.5L12.015 11.99L12.51 12.5L20.505 20.495L19.795 21.2L10.605 12.01L10.61 12L10.605 11.99L19.795 2.79999L20.505 3.50999L12.51 11.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
