import React from 'react'

export const Photo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.999 1C6.999 0.448 6.551 0 5.999 0H3C2.448 0 2 0.448 2 1V2H6.999V1ZM13.595 4.595C10.838 4.595 8.595 6.838 8.595 9.595C8.595 12.352 10.838 14.595 13.595 14.595C16.352 14.595 18.595 12.352 18.595 9.595C18.595 6.838 16.352 4.595 13.595 4.595ZM12.595 8.595C12.043 8.595 11.595 8.147 11.595 7.595C11.595 7.043 12.043 6.595 12.595 6.595C13.147 6.595 13.595 7.043 13.595 7.595C13.595 8.147 13.147 8.595 12.595 8.595ZM20 1H10.078C9.363 1 8.703 1.381 8.346 2L8.057 2.5C7.879 2.809 7.549 3 7.191 3H2C0.895 3 0 3.895 0 5V16C0 17.104 0.896 18 2 18H20C21.105 18 22 17.104 22 16V3C22 1.895 21.104 1 20 1ZM13.595 15.595C10.281 15.595 7.595 12.909 7.595 9.595C7.595 6.281 10.281 3.595 13.595 3.595C16.909 3.595 19.595 6.281 19.595 9.595C19.595 12.909 16.909 15.595 13.595 15.595Z"
        fill="currentColor"
      />
    </svg>
  )
}
