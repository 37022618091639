import React, { forwardRef } from 'react'
import { Divider as ChakraDivider } from '@chakra-ui/react'
import type { StyleProps } from '../../core/style-props'

export type DividerProps = {
  variant?: 'solid' | 'thin'
} & StyleProps

export const Divider = forwardRef<HTMLHRElement, DividerProps>((props, ref) => {
  return <ChakraDivider {...props} ref={ref} />
})

Divider.displayName = 'Divider'
