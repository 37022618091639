import React from 'react'

export const Voucher = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.17439 18.7673L1.24639 11.5733V21.7753C1.24639 22.3273 1.69439 22.7753 2.24639 22.7753H20.2464C20.7984 22.7753 21.2464 22.3273 21.2464 21.7753V15.9953L5.62338 20.1813C4.55639 20.4683 3.46039 19.8343 3.17439 18.7673ZM15.7014 7.57232C15.4004 6.58032 14.5194 6.06432 13.5524 6.32432C12.6084 6.57732 12.0584 7.48632 12.3014 8.39432C12.3714 8.65532 12.2254 8.94132 11.9664 9.01932C11.7004 9.09632 11.4234 8.93632 11.3404 8.67532C11.1104 7.91332 10.4254 7.42532 9.67339 7.42532C9.52239 7.42532 9.36839 7.44632 9.21539 7.48732C8.25139 7.74432 7.74238 8.63132 7.97739 9.64032C8.07539 10.0603 8.42139 11.1113 9.56939 11.9823C10.7774 12.8993 12.2284 13.2083 13.0904 13.3123C13.7924 12.7883 14.9044 11.7913 15.4924 10.3943C16.0524 9.06332 15.8264 7.98232 15.7024 7.58032C15.7034 7.57932 15.7014 7.57232 15.7014 7.57232ZM23.4584 13.3323L20.3524 1.74132C20.2094 1.20832 19.6614 0.891319 19.1274 1.03432L1.74139 5.69232C1.20739 5.83532 0.891385 6.38432 1.03439 6.91732L4.14039 18.5093C4.28339 19.0423 4.83139 19.3593 5.36539 19.2163L22.7524 14.5573C23.2854 14.4143 23.6014 13.8653 23.4584 13.3323ZM16.4154 10.7833C15.6874 12.5113 14.2894 13.6883 13.5204 14.2363C13.4354 14.2973 13.3324 14.3303 13.2284 14.3303C13.2124 14.3303 13.1964 14.3293 13.1804 14.3283C12.2484 14.2373 10.4624 13.9153 8.96538 12.7793C7.55738 11.7113 7.12639 10.3923 7.00639 9.87532C6.64539 8.32732 7.46638 6.92032 8.95639 6.51932C9.85038 6.28032 10.7564 6.50432 11.4164 7.04932C11.7164 6.25032 12.3944 5.59832 13.2934 5.35832C14.7834 4.95132 16.1984 5.76632 16.6584 7.28132L16.6594 7.28732C16.8144 7.79432 17.1014 9.15032 16.4154 10.7833Z"
        fill="currentColor"
      />
    </svg>
  )
}
