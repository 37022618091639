import React from 'react'

export const Music = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27912)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.20012 22.8H22.8001V1.2H1.20012V22.8ZM0.00012207 24H24.0001V0H0.00012207V24ZM18.0001 6.6L16.8001 7.002V14.646C16.8124 15.1363 16.6882 15.6204 16.4413 16.0442C16.1945 16.468 15.8347 16.8148 15.4021 17.046C14.0761 17.694 12.5641 17.43 12.1201 16.518C11.6761 15.606 12.3901 14.556 13.6501 14.052C14.2668 13.7931 14.9501 13.7385 15.6001 13.896V7.398L9.60012 9.402V17.046C9.61242 17.5363 9.48821 18.0204 9.24134 18.4442C8.99448 18.868 8.63468 19.2148 8.20212 19.446C6.87612 20.094 5.36412 19.83 4.92012 18.918C4.47612 18.006 5.19012 16.956 6.45012 16.452C7.06679 16.1931 7.75014 16.1385 8.40012 16.296V9.798L7.20012 10.2V7.8L18.0001 4.2V6.6Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27912">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
