import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'
import { dnaTheme } from '../..'

const baseStyle: SystemStyleFunction = (props) => {
  return {
    ...dnaTheme.components.Button.baseStyle(props),
    borderRadius: '8px',
  }
}

const variantPrimary: SystemStyleFunction = (props) => {
  return {
    ...dnaTheme.components.Button.variants.primary(props),
  }
}

const variantSecondary: SystemStyleFunction = (props) => {
  const borderColor = mode(
    'light.functional.graphic',
    'dark.functional.graphic'
  )(props)
  return {
    ...dnaTheme.components.Button.variants.secondary(props),
    bg: 'transparent',
    borderColor: borderColor,
    border: '1px',
  }
}

const variantTertiary: SystemStyleFunction = (props) => {
  return {
    ...dnaTheme.components.Button.variants.tertiary(props),
    _pressed: {
      textDecoration: 'none',
    },
    _hover: {
      textDecoration: 'none',
    },
  }
}

const variantAutoSuggest: SystemStyleFunction = (props) => {
  const color = mode('light.brand.primary', 'dark.brand.primary')(props)

  return {
    ...dnaTheme.components.Button.variants['autosuggest-clear'],
    color: color,
    _pressed: {
      color: color,
    },
    _focus: {
      outline: `1px solid`,
      color: color,
    },
    _active: {
      color: color,
    },
  }
}

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
  'autosuggest-clear': variantAutoSuggest,
}

const defaultProps = {
  variant: 'primary',
}

export const Button = {
  baseStyle,
  variants,
  defaultProps,
}
