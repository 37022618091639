import React from 'react'

export const Facebook = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 10 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9.97 3.083H7.823v.066h-.415c-.154.048-.292.156-.485.198-.118.197-.334.365-.415.595-.204.577-.07 2.033-.07 2.775h3.393l-.416 3.236H6.438v8.324H2.977V9.953H0V6.717h2.977c-.009-1.115-.165-3.042.138-3.9C3.627 1.375 4.538.565 6.092.11h.416c.89-.243 2.5-.008 3.461 0v2.973h.001Z"
      />
    </svg>
  )
}
