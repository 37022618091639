import { progressAnatomy as parts } from '@chakra-ui/anatomy'
import { PartsStyleFunction } from '@chakra-ui/theme-tools'
import { dnaTheme } from '../..'

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  ...dnaTheme.components.Progress.baseStyle(props),
})

export const Progress = {
  parts: parts.keys,
  baseStyle,
}
