import React from 'react'

export const NoSmoking = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_304_27941)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2977 17.8074L12.1125 16.6223L9.74215 14.2519L8.55697 13.0667L3.42516 7.94677L2.58961 8.78825L6.8681 13.0667H0V17.8074H11.6088L15.0754 21.2741L15.9169 20.4385L13.2858 17.8074H13.2977ZM5.92588 16.6223H1.18518V14.2519H8.05327L10.4236 16.6223H5.92588ZM10.234 13.0667H18.9628V17.8074H14.9747L13.7895 16.6223H17.7776V14.2519H11.4192L10.234 13.0667ZM20.148 17.8074H21.3332V13.0667H20.148V17.8074ZM23.7687 11.3897C23.7387 11.5525 23.717 11.7166 23.7035 11.8816H22.5183C22.5316 11.6568 22.5593 11.4331 22.6013 11.2119C22.7317 10.3527 23.0754 8.08307 22.4472 7.35418C22.3849 7.27814 22.3045 7.21882 22.2135 7.18158C22.1224 7.14433 22.0236 7.13034 21.9258 7.14085H21.3332V6.54826C21.3332 4.27272 20.545 3.61495 20.148 3.58532V2.40015C20.2428 2.40015 22.3228 2.44163 22.5183 6.03864C22.8453 6.13586 23.1374 6.32512 23.3598 6.58382C24.3257 7.73344 23.9643 10.1038 23.7687 11.3897ZM21.108 7.80455C21.908 8.77047 21.5998 10.4356 21.4102 11.4253C21.3737 11.5753 21.3479 11.7278 21.3332 11.8816H20.148C20.1621 11.6562 20.1938 11.4323 20.2428 11.2119C20.3732 10.5245 20.6458 9.09639 20.1954 8.55714C20.1147 8.47067 20.0145 8.40462 19.9033 8.36444C19.792 8.32426 19.6728 8.31109 19.5554 8.32603H18.9628V7.73344C18.9628 5.4579 18.1747 4.80013 17.7776 4.7705V3.58532C17.8725 3.58532 19.9465 3.6268 20.148 7.20604C20.5246 7.29569 20.8617 7.50584 21.108 7.80455ZM22.5183 17.8074H23.7035V13.0667H22.5183V17.8074Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_27941">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
