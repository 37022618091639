import React from 'react'

export const SpeakerOn = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.111 5.83406C20.793 5.36506 20.148 5.25206 19.69 5.58506L19.679 5.59306C19.245 5.90806 19.14 6.50906 19.442 6.95206C20.424 8.39206 21 10.1291 21 12.0001C21 13.8711 20.424 15.6081 19.442 17.0481C19.14 17.4911 19.245 18.0921 19.679 18.4071L19.69 18.4151C20.148 18.7481 20.793 18.6351 21.111 18.1661C22.303 16.4071 23 14.2851 23 12.0001C23 9.71506 22.303 7.59306 21.111 5.83406ZM18.272 7.89706C17.961 7.42306 17.308 7.31506 16.85 7.64806C16.408 7.96906 16.326 8.57206 16.623 9.03106C17.176 9.88706 17.5 10.9061 17.5 12.0001C17.5 13.0941 17.177 14.1121 16.622 14.9691C16.325 15.4271 16.408 16.0311 16.849 16.3521C17.307 16.6851 17.96 16.5761 18.271 16.1031C19.047 14.9241 19.5 13.5161 19.5 12.0001C19.5 10.4841 19.047 9.07606 18.272 7.89706ZM13.412 1.15406L6.742 6.00006H1C0.448 6.00006 0 6.44806 0 7.00006V17.0001C0 17.5521 0.448 18.0001 1 18.0001H6.742L13.412 22.8461C14.073 23.3271 15 22.8541 15 22.0371V1.96306C15 1.14506 14.073 0.673059 13.412 1.15406Z"
        fill="currentColor"
      />
    </svg>
  )
}
